/* eslint-disable */

import { useEffect, useState } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { getRandomInteger } from '../../utils/math'
import { getRandomNumber } from '../../utils/sortingMethods'
import './Fraction.css'

const Fraction = (props) => {
  const { numerator, denominator, isCorrect } = props

  return (
    <div className="fraction">
      <span className="fraction--numerator" style={{ color: isCorrect ? 'green' : 'white' }}>{numerator}</span>
      <span className="fraction--bar" />
      <span className="fraction--denominator" style={{ color: isCorrect ? 'green' : 'white' }}>{denominator}</span>
    </div>
  )
}

const FractionInequality = (props) => {
  const { color, isCorrect, problemsAttempted, firstNumber, secondNumber, setFirstNumber, setSecondNumber, operator } = props

  const [firstNumerator, setFirstNumerator] = useState(null)
  const [secondNumerator, setSecondNumerator] = useState(null)
  const [firstDenominator, setFirstDenominator] = useState(null)
  const [secondDenominator, setSecondDenominator] = useState(null)

  useEffect(() => {
      setFirstNumerator(firstNumber)
      setSecondNumerator(getRandomNumber())
  }, [problemsAttempted])

  useEffect(() => {
    const randomFirstDenominator = getRandomInteger(Math.ceil(firstNumerator + 1), 10)
      setFirstDenominator(randomFirstDenominator)
      const randomSecondDenominator = getRandomInteger(Math.ceil(secondNumerator), 10)
      setSecondDenominator(randomSecondDenominator)
  }, [firstNumerator, secondNumerator])

  useEffect(() => {
    if (firstDenominator !== null && secondDenominator !== null) {
      setFirstNumber(firstNumerator / firstDenominator)
      setSecondNumber(secondNumerator / secondDenominator)
    }
  }, [firstDenominator, secondDenominator])

  return (
    <>
      <Flex mt={2} flexDirection="row" justify="center">
        <div className="pie" style={{ ['--p']: ((firstNumerator / firstDenominator) * 100), ['--c']: color, marginRight: '10px' }} />
        <div className="pie" style={{ ['--p']: ((secondNumerator / secondDenominator) * 100), ['--c']: color, marginLeft: '10px' }} />
      </Flex>
      <Flex mt={2} flexDirection="row" justify="center">
        <div style={{ width: "35%", paddingRight: '8px' }}>
          <Fraction numerator={firstNumerator} denominator={firstDenominator} isCorrect={isCorrect} />
        </div>
        <Heading as="h2" size="lg" style={{ color: isCorrect ? 'green' : 'white' }}>{operator}</Heading>
        <div style={{ width: "35%", paddingLeft: '8px' }}>
          <Fraction numerator={secondNumerator} denominator={secondDenominator} isCorrect={isCorrect} />
        </div>
      </Flex>
    </>
  )
}

export default FractionInequality
