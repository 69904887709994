import { EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, FacebookIcon, XIcon, WhatsappIcon, TelegramIcon } from "react-share";
import { useLocation } from 'react-router-dom'

function SocialMediaButtons(props) {
  const { socialMediaData } = props;
  const { URL, homeMessage, scoreMessage } = socialMediaData
  const location = useLocation()
  const message = location !== '/' ? scoreMessage : homeMessage

  return (
    <div className="Share-container">
      <FacebookShareButton url={URL}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        title={message}
        url={URL}
        hashtags={["edutainment", "webgame"]}
      >
        <XIcon size={32} round />
      </TwitterShareButton>
      <TelegramShareButton
        url={URL}
        title={message}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      <WhatsappShareButton url={URL} title={message} separator=":: ">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <EmailShareButton
        url={URL}
        subject={'Play a fun game with me!'}
        body={message}
        onClick={() => { }}
        openShareDialogOnClick={true}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  )
}
export default SocialMediaButtons;
