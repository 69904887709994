import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import './index.css';
import App from './components/App';
import CountryMain from './components/guess-the-country/CountryMain';
import StateMain from './components/guess-the-state/StateMain';
import ContactFormContainer from './components/shared-components/ContactFormContainer';
import MathMain from './components/emoji-math/MathMain';
import WomensHistoryMain from './components/womens-history/WomensHistoryMain'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/guess-the-country" element={<CountryMain />} />
        <Route path="/guess-the-state" element={<StateMain />} />
        <Route path="/emoji-math" element={<MathMain />} />
        <Route path="/womens-history" element={<WomensHistoryMain />} />
        <Route path="/contact-us" element={<ContactFormContainer />} />
        <Route path="/sign-up-for-newsletter" element={<ContactFormContainer />} />
      </Routes>
    </Router>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
