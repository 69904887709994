/* eslint-disable */
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../shared-components/Nav'
import Score from '../shared-components/Score'
import { Button, Radio, RadioGroup, Flex, Heading, Input, Stack, Text } from '@chakra-ui/react'
import emojis from '../../utils/emoji'
import animals from './data/animals'
import Confetti from 'react-confetti'
import { getRandomNumber, selectGif, shuffleArray } from '../../utils/sortingMethods'
import getBorderCSS from '../shared-components/clueBorders'
import { getDivisor } from '../../utils/math'
import StartScreen from './StartScreen'
import EmojiClue from './EmojiClue'
import FractionInequality from './FractionInequality'

const evaluate = {
  '+': function (x, y) { return x + y },
  '-': function (x, y) { return x - y },
  '*': function (x, y) { return x * y },
  '/': function (x, y) { return x / y },
  '>': function (x, y) { return x > y },
  '<': function (x, y) { return x < y }
}

const MathMain = () => {
  const [emojiArr, setEmojiArr] = useState([])
  const [score, setScore] = useState(0)
  const [onFirstScreen, setOnFirstScreen] = useState(true)
  const [gameOperators, setGameOperators] = useState([])
  const [additionAndSubtraction, setAdditionAndSubtraction] = useState(false)
  const [multiplicationAndDivision, setMultiplicationAndDivision] = useState(false)
  const [simpleInequalities, setSimpleInequalities] = useState(false)
  const [fractionInequalities, setFractionInequalities] = useState(false)
  const [inequalityAnswer, setInequalityAnswer] = useState(null)
  const [problemsAttempted, setProblemsAttempted] = useState(0)
  const [guess, setGuess] = useState('')
  const [firstNumber, setFirstNumber] = useState(getRandomNumber())
  const [secondNumber, setSecondNumber] = useState(null)
  const [operator, setOperator] = useState(null)
  const [isCorrect, setIsCorrect] = useState(false)
  const [showTryAgain, setShowTryAgain] = useState(false)
  const [gifNumber, setGifNumber] = useState(null)
  const [level, setLevel] = useState(1)
  const [showConfetti, setShowConfetti] = useState(true)
  const [borderCSS, setBorderCSS] = useState({ border: 'white solid 2px' })
  const [showFractionInequality, setShowFractionInequality] = useState(false)

  const windowWidth = useRef(window.innerWidth)
  const windowHeight = useRef(window.innerHeight)

  const QUESTIONS_PER_LEVEL = 10
  const TOTAL_PROBLEMS = 100
  const blinkingStars = <span className="Blink-me">⭐🌟⭐</span>

  const getOperator = () => gameOperators[Math.floor(Math.random() * gameOperators.length)]
  const getOperatorToDisplay = () => {
    if (operator === '*') {
      return 'x'
    } else if (operator === '/') {
      return '÷'
    } else {
      return operator
    }
  }
  const isInequality = operator === '>' || operator === '<'

  const handleFractionInequalities = () => {
    setShowFractionInequality(true)
    const inequalityOperator = ['>', '<'][Math.floor(Math.random() * 2)]
    setOperator(inequalityOperator)
  }

  const checkIfOnlyFractionInequalities = () => fractionInequalities && !additionAndSubtraction && !multiplicationAndDivision && !simpleInequalities

  useEffect(() => {
    setEmojiArr(shuffleArray(emojis))
  }, [])

  useEffect(() => {
    if (checkIfOnlyFractionInequalities()) handleFractionInequalities()
  }, [onFirstScreen])

  useEffect(() => {
    if (gameOperators.length > 0 && !checkIfOnlyFractionInequalities()) {
      setOperator(getOperator())
    }
  }, [gameOperators])

  useEffect(() => {
    if (operator !== null) {
      if (operator === '-') {
        setSecondNumber(getRandomNumber(Math.ceil(firstNumber)))
      } else if (operator === '/') {
        const randomNumber = getDivisor(Math.ceil(firstNumber))
        setSecondNumber(randomNumber)
      } else {
        if (!showFractionInequality) setSecondNumber(getRandomNumber())
      }
    }
  }, [firstNumber, operator, showFractionInequality])

  useEffect(() => {
    if (isCorrect) {
      const randomNumber = selectGif()
      setGifNumber(randomNumber)
    } else {
      setGifNumber(null)
    }
  }, [isCorrect])

  useEffect(() => {
    if (score > 0 &&
      score % QUESTIONS_PER_LEVEL === 0 &&
      score > problemsAttempted) {
      setShowConfetti(true)
      setLevel(level + 1)
    }
    if (score <= problemsAttempted) {
      setShowConfetti(false)
    }
  }, [score, problemsAttempted])

  useEffect(() => {
    if (level > 1) {
      const styling = getBorderCSS(level)
      setBorderCSS(styling)
    }
  }, [level])

  useEffect(() => {
    if (fractionInequalities &&
      ((problemsAttempted > 0 && problemsAttempted % 3 === 0) || checkIfOnlyFractionInequalities())) {
      handleFractionInequalities()
    } else {
      setShowFractionInequality(false)
    }
  }, [problemsAttempted])

  const handleGuess = () => {
    if (isInequality || showFractionInequality) {
      const parsedAnswer = inequalityAnswer === 'true' ? true : false
      if (evaluate[operator](firstNumber, secondNumber) === parsedAnswer) {
        setIsCorrect(true)
        setScore(score + 1)
        setShowTryAgain(false)
      } else {
        setShowTryAgain(true)
      }
      setInequalityAnswer(null)
    } else if (evaluate[operator](firstNumber, secondNumber) === Number(guess)) {
      setIsCorrect(true)
      setScore(score + 1)
      setShowTryAgain(false)
    } else {
      setShowTryAgain(true)
    }
    setGuess('')
  }

  const loadNextProblem = () => {
    setIsCorrect(false)
    setGuess('')
    setFirstNumber(getRandomNumber())
    setOperator(getOperator())
    setProblemsAttempted(problemsAttempted + 1)
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Emoji Math</title>
        <meta name="description" content="Have fun with simple math problems." />
      </Helmet>

      <>
        <Nav name="Emoji Math" />

        {onFirstScreen ?
          <StartScreen
            setOnFirstScreen={setOnFirstScreen}
            simpleInequalities={simpleInequalities}
            multiplicationAndDivision={multiplicationAndDivision}
            additionAndSubtraction={additionAndSubtraction}
            setGameOperators={setGameOperators}
            setAdditionAndSubtraction={setAdditionAndSubtraction}
            setMultiplicationAndDivision={setMultiplicationAndDivision}
            setSimpleInequalities={setSimpleInequalities}
            fractionInequalities={fractionInequalities}
            setFractionInequalities={setFractionInequalities}
          /> :
          <>
            <Score
              level={level}
              score={score}
              amountGuessed={problemsAttempted}
              outline={borderCSS['border']?.split(' ')[0] || null}
            />

            {showConfetti ?
              <>
                <Confetti
                  width={windowWidth.current}
                  height={windowHeight.current}
                />
                {problemsAttempted === TOTAL_PROBLEMS ?
                  <div className="Congrats-container">
                    <p className="Congrats-message">{blinkingStars} Congratulations! Great job! {blinkingStars}</p>
                    <p className="Congrats-message">You completed all {TOTAL_PROBLEMS} math problems.</p>
                    <div>
                      <iframe title="Celebrating chickens" src="https://giphy.com/embed/llUeFDNRaLWvokhbat" width="320" height="270" ></iframe>
                    </div>
                  </div>
                  : <p className="Blink-me">Next level reached! Starting level {level}.</p>
                }
              </>
              : null}

            {problemsAttempted < TOTAL_PROBLEMS ?
              <div className="Clue-container">
                <div style={{ ...borderCSS, textAlign: 'center' }} className="Clue-content">
                  {showFractionInequality ?
                    <FractionInequality
                      problemsAttempted={problemsAttempted}
                      firstNumber={firstNumber}
                      secondNumber={secondNumber}
                      setFirstNumber={setFirstNumber}
                      setSecondNumber={setSecondNumber}
                      operator={operator}
                      color={borderCSS['border']?.split(' ')[0] || null}
                      isCorrect={isCorrect}
                    /> :
                    <EmojiClue
                      emojiArr={emojiArr}
                      firstNumber={firstNumber}
                      operatorToDisplay={getOperatorToDisplay()}
                      problemsAttempted={problemsAttempted}
                      secondNumber={secondNumber}
                      isCorrect={isCorrect}
                      operator={operator}
                    />}

                  {isCorrect ?
                    <Heading as="h2" size="lg" color="green" mt={3}>{evaluate[operator](firstNumber, secondNumber)}</Heading> : null}
                </div>
              </div> : null}

            {!isCorrect ?
              <>
                {isInequality || showFractionInequality ?
                  <Flex flexDirection="column" alignItems="center" flexWrap="wrap" mb={3}>
                    <RadioGroup onChange={setInequalityAnswer} value={inequalityAnswer}>
                      <Stack direction='column'>
                        <Radio value='true'>True</Radio>
                        <Radio value='false'>False</Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                  :
                  <Input onChange={e => setGuess(e.target.value)} value={guess} placeholder="Solve" htmlSize={4} width="auto" variant="filled" />
                }
                <Button isDisabled={!isInequality && guess === '' && !showFractionInequality} onClick={() => handleGuess()}>
                  Submit
                </Button>
                {showTryAgain ? <p style={{ color: 'red', margin: 0, fontWeight: 800 }}>Try again!</p> : null}
              </> :
              <>
                <Text color="green" mb={2}>{blinkingStars} Correct! {blinkingStars}</Text>
                <Flex flex={1} justify="center" mt={2} mb={2}>
                  {gifNumber > -1 ? <img className="Congrats-gif" alt="dancing animal" src={animals[gifNumber]} /> : null}
                </Flex>
                <Button onClick={(() => loadNextProblem())}>Next problem</Button>
              </>
            }
          </>
        }
      </>
    </div>
  )
}

export default MathMain
