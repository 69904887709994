import Clues from './Clues'
import Answer from './Answer';
import Letters from '../shared-components/Letters'
import { useState } from 'react';

function Country(props) {
  const {
    borderCSS,
    countryData,
    currentQuestion,
    setCurrentQuestion,
    score,
    setScore,
    countriesGuessed,
    setCountriesGuessed
  } = props;
  const [numberOfLettersGuessed, setNumberOfLettersGuessed] = useState(0)
  const [isCorrect, setIsCorrect] = useState(false)
  const [showAnswer, setShowAnswer] = useState(false)

  return (
    <>
      <Clues borderCSS={borderCSS} countryData={countryData} />

      <Letters
        name={countryData.name.common}
        numberOfLettersGuessed={numberOfLettersGuessed}
        showAnswer={showAnswer}
        isCorrect={isCorrect}
        currentQuestion={currentQuestion}
      />

      <Answer
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        countryData={countryData}
        countriesGuessed={countriesGuessed}
        setCountriesGuessed={setCountriesGuessed}
        score={score}
        setScore={setScore}
        numberOfLettersGuessed={numberOfLettersGuessed}
        setNumberOfLettersGuessed={setNumberOfLettersGuessed}
        showAnswer={showAnswer}
        setShowAnswer={setShowAnswer}
        isCorrect={isCorrect}
        setIsCorrect={setIsCorrect}
      />
    </>
  );
}

export default Country;
