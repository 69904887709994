import { shuffleArray } from './sortingMethods'

const getMultiples = (dividend) => {
  let numbers = []
  let i = dividend
  while (i > 0) {
    numbers.push(i)
    i--
  }
  return numbers.filter(x => dividend % x === 0)
}

const getDivisor = (dividend) => {
  const multiples = getMultiples(dividend)
  const shuffledMultiples = shuffleArray(multiples)
  return shuffledMultiples[0]
}

const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export { getDivisor, getRandomInteger }
