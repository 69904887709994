import { Flex, Text } from '@chakra-ui/react'

const Score = (props) => {
  const {
    amountGuessed,
    level,
    score,
    outline='white',
    totalLevels=10
  } = props
  const textShadow = outline !== 'white' ? `1px 1px 1px ${outline}` : 'none'

  return (
    <Flex className="Score-container" flexDirection="row" justifyContent="center" flexWrap="wrap">
      <Text style={{ marginRight: '20px', textShadow}}>SCORE: {score}</Text>
      <Text style={{ marginRight: '20px', textShadow }}>ATTEMPTED: {amountGuessed}</Text>
      <Text style={{ textShadow}}>LEVEL: {level}/{totalLevels}</Text>
    </Flex>
  );
}

export default Score
