/* eslint-disable */
import { useEffect, useState } from 'react';

function Letters(props) {
  const {
    name,
    numberOfLettersGuessed,
    showAnswer,
    isCorrect,
    currentQuestion
  } = props;
  const [lettersForDisplay, setLettersForDisplay] = useState([])
  const [lettersToReveal, setLettersToReveal] = useState([])

  const getBoxes = () => {
    const nameArr = name.split('')
    const lettersToDisplay = nameArr.map(letter => {
      return letter !== ' ' ? '_' : letter
    })
    setLettersForDisplay(lettersToDisplay)
  }

  const generateLetters = () => {
    const lettersToDisplay = lettersForDisplay.slice()
    const indexToUpdate = lettersToReveal[numberOfLettersGuessed - 1]
    const letterUpdate = name.split('')[indexToUpdate]
    lettersToDisplay[indexToUpdate] =
      typeof letterUpdate === 'string' ? letterUpdate.toUpperCase() : letterUpdate

    setLettersForDisplay(lettersToDisplay)
  }

  const lettersForOneWord = () => {
    const countryNameArr = name.split('')
    // first, last, middle
    let _lettersToReveal = [0]
    _lettersToReveal.push(countryNameArr.length - 1)
    let middleIndex = Math.ceil(countryNameArr.length / 2)
    if (countryNameArr[middleIndex] === ' ') {
      middleIndex = middleIndex - 1
    }
    _lettersToReveal.push(middleIndex)
    setLettersToReveal(_lettersToReveal)
  }

  const lettersForMultipleWords = (wordsArr) => {
    // first and last of each word
    let _lettersToReveal = []
    let adder = 0
    wordsArr.forEach(word => {
      const wordArr = word.split('')
      _lettersToReveal.push(adder)
      _lettersToReveal.push(adder + wordArr.length - 1)
      adder = adder + wordArr.length + 1
    })
    setLettersToReveal(_lettersToReveal)
  }

  const getLettersToReveal = () => {
    const countryWordsArr = name.split(' ')
    if (numberOfLettersGuessed === 0) {
      setLettersToReveal([])
    }
    if (countryWordsArr.length > 1) {
      lettersForMultipleWords(countryWordsArr)
    } else {
      lettersForOneWord()
    }
  }

  useEffect(() => {
    if (numberOfLettersGuessed === 0) {
      getBoxes()
      setLettersToReveal([])
    }
  }, [])

  useEffect(() => {
    if (numberOfLettersGuessed === 0) {
      getBoxes()
      getLettersToReveal()
    }
    if (numberOfLettersGuessed > 0) {
      generateLetters()
    }
  }, [numberOfLettersGuessed])

  useEffect(() => {
    if (currentQuestion > 1) {
      getBoxes()
      getLettersToReveal()
    }
  }, [currentQuestion])

  useEffect(() => {
    if (showAnswer || isCorrect) {
      const countryArr = name.split('')
      const letters = countryArr.map((letter) => {
        return letter.toUpperCase()
      })
      setLettersForDisplay(letters)
      setLettersToReveal([])
    }
  }, [showAnswer, isCorrect])

  return (
    <div className="Letters-container">
      <ul className="Letters-list">
        <li>
          {lettersForDisplay.map((letter, index) => {
            return <span key={index} className='Letter'>{letter}</span>
          })}
        </li>
      </ul>
    </div>
  );
}

export default Letters;
