import { useState } from 'react'
import { isValidEmail } from '../../utils/validationScripts'
import { Button, FormControl, FormLabel, Input, Text } from '@chakra-ui/react'
import emailjs from '@emailjs/browser'

const EmailSignUpForm = (props) => {
  emailjs.init('W2ccMnigxVFU0FxLX')

  const { setEmailSent } = props;
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const formMesage = "Get notified by email when we add new games or features!"

  const buttonIsDisabled = name === '' || email === '' || !isValidEmail(email)

  const submit = () => {
    if (name && email) {
      const serviceId = 'service_1om6vcq'
      const templateId = 'template_hdn94la'
      const templateParams = {
        name,
        email
      }

      emailjs.send(serviceId, templateId, templateParams)
        .then(response => {
          setName('')
          setEmail('')
          setEmailSent(true)
        })
        .catch(error => console.log(error))
    }
  }
  return (
    <>
      <Text textAlign="center" mb={6} p={4} fontSize="lg">{formMesage}</Text>
      <FormControl isRequired>
        <FormLabel>Your name</FormLabel><Input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired mt={4}>
        <FormLabel>Email address</FormLabel>
        <Input
          type="email"
          placeholder="Your email address"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </FormControl>
      <Button onClick={submit} mt={4} mb={4} isDisabled={buttonIsDisabled}>Sign up</Button>
    </>
  )
}

export default EmailSignUpForm
