import { useRef } from 'react'
import State from './State'
import SocialMediaButtons from '../shared-components/SocialMediaButtons'
import Score from '../shared-components/Score'
import Nav from '../shared-components/Nav'
import Confetti from 'react-confetti'
import { stateSocialMediaData } from '../shared-components/social-media-data'

function Game(props) {
  const {
    stateData,
    currentQuestion,
    setCurrentQuestion,
    score,
    setScore,
    statesGuessed,
    setStatesGuessed,
    level,
    borderCSS,
    showConfetti
  } = props
  const windowWidth = useRef(window.innerWidth)
  const windowHeight = useRef(window.innerHeight)
  const blinkingStars = <span className="Blink-me">⭐🌟⭐</span>
  const TOTAL_STATES = 50

  return (
    <>
      <Nav name="Guess the US State" />

      <Score
        level={level}
        score={score}
        amountGuessed={statesGuessed}
        outline={borderCSS['border']?.split(' ')[0] || null}
      />

      {showConfetti ?
        <>
          <Confetti
            width={windowWidth.current}
            height={windowHeight.current}
          />
          {statesGuessed === TOTAL_STATES ?
            <div className="Congrats-container">
              <p className="Congrats-message">{blinkingStars} Congratulations! Great job! {blinkingStars}</p>
              <p className="Congrats-message">You attempted to guess all {TOTAL_STATES} states.</p>
              {score === TOTAL_STATES
                ? <h4 className="Congrats-message">🏆 And you got a perfect score! 👏🏼👏🏼</h4>
                : <p className="Congrats-message">Play again to get the states in a new and different order.</p>
              }
              <div>
                <iframe title="Celebrating chickens" src="https://giphy.com/embed/llUeFDNRaLWvokhbat" width="320" height="270" ></iframe>
              </div>
            </div>
            : <p className="Blink-me">Next level reached! Starting level {level}.</p>
          }
        </>
        : null}

      {statesGuessed < TOTAL_STATES ?
        <State
          borderCSS={borderCSS}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          stateData={stateData}
          statesGuessed={statesGuessed}
          setStatesGuessed={setStatesGuessed}
          score={score}
          setScore={setScore}
        />
        : null}

      <div className="Share-score-container">
        <div style={{ marginTop: '30px' }}>Share your score: </div>
        <SocialMediaButtons socialMediaData={stateSocialMediaData(score, statesGuessed)} />
      </div>
    </>
  )
}

export default Game
