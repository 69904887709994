import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Nav from './Nav'
import ContactUsForm from './ContactUsForm'
import EmailSignUpForm from './EmailSignUpForm'
import { Box, Flex, Image, Text } from '@chakra-ui/react'

const ContactFormContainer = () => {
  const [emailSent, setEmailSent] = useState(false)
  const location = useLocation()
  const isContactUsForm = location.pathname === '/contact-us'
  const contactUsTYMessage = 'Thank you for your message, we will be in touch in no time!'
  const signUpTYMessage = 'Thanks for signing up and keeping in touch!'

  return (
    <Box className="App">
      <Nav name="Fun Games for Learning" buttonText="Go Home" />

      <Flex alignItems="center" flexDirection="column" mt={8}>
        <Box style={{ maxWidth: '500px' }} id="contact-form">
          {!emailSent ?
            <>
              {isContactUsForm ?
                <ContactUsForm setEmailSent={setEmailSent} />
                : <EmailSignUpForm setEmailSent={setEmailSent} />
              }
            </>
            : <Flex alignItems="center" flexDirection="column">
              <Text p={[4, 0]} >
                {isContactUsForm ? contactUsTYMessage : signUpTYMessage}
              </Text>
              <Image
                mt={8}
                borderRadius="full"
                className="Congrats-gif"
                alt="dancing cat"
                src="https://media1.tenor.com/m/DBh-krlsUYUAAAAd/cat-dance.gif"
              />
            </Flex>
          }
        </Box>
      </Flex >
    </Box >
  )
}

export default ContactFormContainer
