import { Button, Flex, Heading } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const Nav = (props) => {
  const { buttonText = "Home / Restart", name } = props
  return (
    <nav className="Nav">
      <Flex justify="center" flexWrap="wrap">
        <Heading className="Game-title" mr={6}>{name}</Heading>
        <Link to="/"><Button size="sm" mt="5px">{buttonText}</Button></Link>
      </Flex>
    </nav>
  )
}

export default Nav
