import { useState } from 'react'
import ShowAnswer from './ShowAnswer'
import { Button, Input } from '@chakra-ui/react'

function Answer(props) {
  const {
    currentQuestion,
    setCurrentQuestion,
    stateData,
    statesGuessed,
    setStatesGuessed,
    showAnswer,
    setShowAnswer,
    isCorrect,
    setIsCorrect,
    score,
    setScore,
    hints,
    setHints,
    numberOfLettersGuessed,
    setNumberOfLettersGuessed,
  } = props
  const [guesses, setGuesses] = useState([])
  const [guess, setGuess] = useState('')
  const [showTryAgain, setShowTryAgain] = useState(false)

  const handleGivingUp = () => {
    setStatesGuessed(statesGuessed + 1)
    setShowAnswer(true)
  }

  const checkIfCorrectGuess = () => {
    const _isCorrect =
      guess.trim().toLowerCase() === stateData.name.toLowerCase()
    if (_isCorrect) {
      setIsCorrect(true)
      setShowAnswer(true)
      setScore(score + 1)
      setStatesGuessed(statesGuessed + 1)
    } else {
      setGuess('')
      setShowTryAgain(true)
      const timer = setTimeout(() => {
        setShowTryAgain(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }

  const handleGuess = () => {
    const updatedGuesses = guesses.concat(guess.trim())
    setGuesses(updatedGuesses)
    if (updatedGuesses.length >= 5) {
      handleGivingUp()
    } else {
      checkIfCorrectGuess()
    }
  }

  const goToNextState = () => {
    setNumberOfLettersGuessed(0)
    setIsCorrect(false)
    setShowAnswer(false)
    setGuesses([])
    setGuess('')
    setCurrentQuestion(currentQuestion + 1)
    setHints(0)
  }

  const getGuessLetterAmount = () => {
    let guesses = 2
    const wordCount = stateData.name.split(' ').length
    if (wordCount > 1) {
      guesses = (wordCount * 2) - 1
    }
    return guesses
  }

  return (
    <>
      {!isCorrect && guesses.length && !showAnswer ?
        <div className="Guesses-container">
          <p className="Guesses">Guesses: {guesses.join(', ')}<br />for {guesses.length} total.</p>
          {showTryAgain ? <p style={{ color: 'red', margin: 0, fontWeight: 800 }}>Try again!</p> : null}
        </div>
        : null
      }

      {!isCorrect && !showAnswer ?
        <>
          <p className="Guess-label"><label>🇺🇸 Know the state?</label></p>
          <Input onChange={e => setGuess(e.target.value)} value={guess} placeholder="Enter a state" htmlSize={28} width="auto" variant="filled" />
          <Button isDisabled={guess === ''} onClick={() => handleGuess()}>
            Submit Guess
          </Button>

          <div className="Button-container">
            {hints < 2 ?
              <Button
                className="Button-padding-right"
                onClick={() => setHints(hints + 1)}
              >
                Show hint
              </Button>
              : null}
            {hints === 2 && numberOfLettersGuessed <= getGuessLetterAmount() ?
              <Button
                className="Button-padding-right"
                onClick={() => setNumberOfLettersGuessed(numberOfLettersGuessed + 1)}
              >
                Show letter
              </Button>
              : null}
            <Button onClick={() => handleGivingUp()}>Just tell me</Button>
          </div>
        </>
        : null}

      {isCorrect || showAnswer ?
        <ShowAnswer
          isCorrect={isCorrect}
          goToNextState={goToNextState}
          stateName={stateData.name}
          guesses={guesses}
        />
        : null}
    </>
  )
}

export default Answer
