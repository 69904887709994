import { Link } from 'react-router-dom'
import { Button, Card, CardHeader, CardBody, CardFooter, Flex, Heading, Text } from '@chakra-ui/react'

function StartCard(props) {
  const { heading, subtitle, link } = props

  return (
    <Card maxW='md'>
      <CardHeader>
        <Heading style={{ color: 'darkblue' }}>{heading}</Heading>
      </CardHeader>
      <CardBody pt={0}>
        <Text>{subtitle}</Text>
      </CardBody>
      <CardFooter pt={0}>
        <Flex flex='1' justify='center'>
          <Link to={link}>
            <Button>Start the game</Button>
          </Link>
        </Flex>
      </CardFooter>
    </Card>
  )
}

export default StartCard
