const animals = [
  'https://media1.tenor.com/m/8dSgkI-0TWEAAAAd/ridewithp.gif',
  'https://media1.tenor.com/m/IpXwtZtCGj4AAAAd/otter-dancing.gif',
  'https://media1.tenor.com/m/ySxCDvEbZVkAAAAd/lets-party.gif',
  'https://media1.tenor.com/m/DHV1NwKb7pcAAAAd/racoon-dance-cane-dance.gif',
  'https://media1.tenor.com/m/RHRTOTned1MAAAAd/aww-cute.gif',
  'https://media1.tenor.com/m/_TV6qVC4toAAAAAd/panda-dancing.gif',
  'https://media1.tenor.com/m/yDZbINfsNoMAAAAC/yes-parrot-dance.gif',
  'https://media1.tenor.com/m/POX01QeiS_QAAAAC/guinea-pig-dance.gif',
  'https://media1.tenor.com/m/W3NiVFuEOzsAAAAC/kia-hamsters.gif',
  'https://media1.tenor.com/m/Dz3THBRNLGMAAAAC/chicken-chicken-dance.gif'
]

export default animals
