const dogs = [
  'https://media1.tenor.com/m/-gJ1mRXkZQEAAAAC/funny-a-nimals-dogs.gif',
  'https://media1.tenor.com/m/PY9ApEAXDXsAAAAC/sunday-happy.gif',
  'https://media1.tenor.com/m/U-g_SmVeq40AAAAC/poodle-dancing-dog-poodle.gif',
  'https://media1.tenor.com/m/Mf937DWwuj0AAAAd/twerk-dance.gif',
  'https://media1.tenor.com/m/fgSunDOnZBUAAAAC/dog-gangnam-style.gif',
  'https://media1.tenor.com/m/Zjc6O7J7J8kAAAAd/dog-dancing-happy.gif',
  'https://media1.tenor.com/m/jdcMLq1sfjkAAAAC/dancing-voting-day.gif',
  'https://media1.tenor.com/m/sfUa2bZjpssAAAAd/dog-happy-dance.gif',
  'https://media1.tenor.com/m/kLzDO1BdnBAAAAAd/dog-perro.gif',
  'https://media1.tenor.com/m/YapHRPixrEYAAAAd/dog-dance.gif'
]

export default dogs
