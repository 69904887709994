import { Flex, Heading } from '@chakra-ui/react'

const EmojiClue = (props) => {
  const { emojiArr, firstNumber, operator, isCorrect, operatorToDisplay, problemsAttempted, secondNumber } = props
  const isInequality = operator === '>' || operator === '<'

  return (
    <>
      <Flex mt={2} flexDirection="row" justify="center">
        <div style={{ width: "35%", paddingRight: '8px' }}>
          {Array(firstNumber).fill(0).map(
            (_, index) => <span style={{ marginRight: '5px', fontSize: '30px' }} key={index}>{emojiArr[problemsAttempted]}</span>)
          }
        </div>
        <Heading as="h2" size="lg">{operatorToDisplay}</Heading>
        <div style={{ width: "35%", paddingLeft: '8px' }}>
          {Array(Math.ceil(secondNumber)).fill(0).map(
            (_, index) => <span style={{ marginRight: '5px', fontSize: '30px' }} key={index}>{emojiArr[problemsAttempted]}</span>)
          }
        </div>
      </Flex>

      <Heading as="h2" size="lg" mt={3} style={{ color: isInequality && isCorrect ? 'green' : 'white' }}>{firstNumber} {operatorToDisplay} {secondNumber}</Heading>
    </>
  )
}

export default EmojiClue
