import BuyMeACoffee from './shared-components/BuyMeACoffee.js'
import StartCard from './shared-components/StartCard.js'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import './App.css'

function App() {
  return (
    <Box className="App" p={[4, 6, 12]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fun Games for Learning</title>
        <meta name="description" content="Educational and fun quiz style games to learn about countries and US states. Get clues and reveal letters. Share your score." />
      </Helmet>
      <Heading as="h1" size="2xl" className="Game-title" mb={8}>Fun Games for Learning</Heading>
      <Flex flex="1" gap="4" justify="center" flexWrap="wrap">
        <StartCard
          heading="Women's History"
          subtitle={<>
            Celebrate Women's History Month!<br />
            A quiz on important events in US history.<br />
            🤰 Learn and discuss with others.
          </>}
          link="/womens-history"
        />
        <StartCard
          heading="Guess the Country"
          subtitle={<>
            Test your knowledge of the world's countries.<br />
            Get a list of clues. Ask for letters.<br />
            🌏 Have fun!
          </>}
          link="/guess-the-country"
        />
        <StartCard
          heading="Guess the US State"
          subtitle={<>
            Test your knowledge of the United States.<br />
            Get fun clues for each state.<br />
            🇺🇸 Enjoy yourself!
          </>}
          link="/guess-the-state"
        />
        <StartCard
          heading="Emoji Math"
          subtitle={<>
            Solve simple math problems like addition, subtraction,<br />
            multiplication and division. Even inequalities!<br />
            🐙 With the help of emoji.
          </>}
          link="/emoji-math"
        />
      </Flex>
      <Flex flex="1" gap="4" justify="center" flexWrap="wrap" flexDirection="row" mt={6}>
        <Link to="/sign-up-for-newsletter"><Button>Sign up for news</Button></Link>
        <Box w={165}><BuyMeACoffee /></Box>
        <Link to="/contact-us"><Button>Talk to us</Button></Link>
      </Flex>
    </Box>
  )
}

export default App
