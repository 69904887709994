import { Button, Checkbox, Flex, Heading, Stack } from '@chakra-ui/react'

const StartScreen = (props) => {
  const {
    additionAndSubtraction,
    setAdditionAndSubtraction, 
    multiplicationAndDivision,
    setMultiplicationAndDivision,
    setGameOperators,
    setOnFirstScreen,
    simpleInequalities,
    setSimpleInequalities,
    fractionInequalities,
    setFractionInequalities
  } = props

  const handleStart = () => {
    let operators = []

    if (additionAndSubtraction) {
      operators = operators.concat(['+', '-'])
    }
    if (multiplicationAndDivision) {
      operators = operators.concat(['*', '/'])
    }
    if (simpleInequalities) {
      operators = operators.concat(['>', '<'])
    }

    setGameOperators(operators)
    setOnFirstScreen(false)
  }

  const checkIfAnyBoxesChecked = () => {
    return additionAndSubtraction || multiplicationAndDivision || simpleInequalities || fractionInequalities
  }

  return (
    <Flex flexDirection="column" alignItems="center" flexWrap="wrap">
      <Heading as="h3" size="lg" mt={10}>Choose the problems<br /> you'd like to solve</Heading>
      <Stack mt={3} spacing={5}>
        <Checkbox onChange={() => setAdditionAndSubtraction(!additionAndSubtraction)} textAlign='left'>
          <p>Addition and subtraction<br /> 1 + 2 and 4 - 3</p>
        </Checkbox>
        <Checkbox onChange={() => setMultiplicationAndDivision(!multiplicationAndDivision)} textAlign='left'>
          <p>Multiplication and division<br /> 3 x 3 and 4 ÷ 2</p>
        </Checkbox>
        <Checkbox onChange={() => setSimpleInequalities(!simpleInequalities)} textAlign='left'>
          <p>Simple inequalities<br /> 3 &gt; 2 and 5 &lt; 10</p>
        </Checkbox>
        <Checkbox onChange={() => setFractionInequalities(!fractionInequalities)} textAlign='left'>
          <p>Fraction inequalities<br /> 2/3 &gt; 5/8 and 1/2 &lt; 3/4</p>
        </Checkbox>
      </Stack>
      <Button mt={3} isDisabled={!checkIfAnyBoxesChecked()} onClick={() => handleStart()}>Start Game</Button>
    </Flex>
  )
}

export default StartScreen
