function Clues(props) {
  const { borderCSS, countryData } = props;

  return (
    <div className="Clue-container">
      <div className="Clue-content" style={borderCSS}>
        <ul>
          <li><div className="Clue-title">Capital:</div>
            <span style={{ paddingLeft: '4px' }}>
              {countryData?.capital && countryData?.capital.length ?
                countryData.capital[0]
                : ''}
            </span>
          </li>
          <li><div className="Clue-title">Continent:</div> {countryData?.continents.join(", ")}</li>
          <li>
            <div className="Clue-title">Currencies:</div> <div className='Clue-word-wrap'>{countryData?.currencies && countryData?.currencies.length ?
              countryData?.currencies.join(', ') : ''}</div>
          </li>
          <li><div className="Clue-title">Landlocked:</div> {countryData?.landlocked}</li>
          {countryData?.languages ? <li><div className="Clue-title">Languages:</div> <div className='Clue-word-wrap'>{Object.values(countryData?.languages).join(', ')}</div></li> : null}
          <li><div className="Clue-title">Population:</div> {countryData?.population.toLocaleString()}</li>
          {countryData?.borders &&
            <li>
              <div className="Clue-title">Borders:</div> <div className='Clue-word-wrap'>{countryData?.borders.join(', ')}</div>
            </li>
          }
          <li className="Flag" style={{ padding: 0}}><span>{countryData?.flag}</span></li>
        </ul>
      </div>
    </div>
  );
}

export default Clues;