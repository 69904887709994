import Answer from './Answer'
import Clues from './Clues'
import Letters from '../shared-components/Letters'
import { useState } from 'react'

function State(props) {
  const {
    borderCSS,
    currentQuestion,
    setCurrentQuestion,
    stateData,
    statesGuessed,
    setStatesGuessed,
    score,
    setScore
  } = props
  const [numberOfLettersGuessed, setNumberOfLettersGuessed] = useState(0)
  const [isCorrect, setIsCorrect] = useState(false)
  const [showAnswer, setShowAnswer] = useState(false)
  const [hints, setHints] = useState(0)

  return (
    <>
      <Clues borderCSS={borderCSS} stateData={stateData} hints={hints} />

      {hints > 1 || showAnswer ?
        <Letters
          name={stateData.name}
          numberOfLettersGuessed={numberOfLettersGuessed}
          showAnswer={showAnswer}
          isCorrect={isCorrect}
          currentQuestion={currentQuestion}
        />
        : null}

      <Answer
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        stateData={stateData}
        statesGuessed={statesGuessed}
        setStatesGuessed={setStatesGuessed}
        numberOfLettersGuessed={numberOfLettersGuessed}
        setNumberOfLettersGuessed={setNumberOfLettersGuessed}
        showAnswer={showAnswer}
        setShowAnswer={setShowAnswer}
        isCorrect={isCorrect}
        setIsCorrect={setIsCorrect}
        score={score}
        setScore={setScore}
        hints={hints}
        setHints={setHints}
      />
    </>
  )
}

export default State
