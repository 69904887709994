const facts = [
  {
    question: "When was the ERA (Equal Rights Amendment) first introduced to Congress?",
    answer: "1923",
    explanation: 'The ERA simply states: "Equality of rights under the law shall not be denied or abridged by the United States or by any state on account of sex."',
    options: ["1898", "1912", "1923", "1938"],
    link: "https://www.cnn.com/2022/02/13/politics/equal-rights-amendment-era-explained/index.html"
  },
  {
    question: "When did we hit 100 years of the ERA (Equal Rights Amendment) not being passed?",
    answer: "2023",
    explanation: "Phyllis Schlafly worked hard in the 1970s on her STOP ERA campaign with much success. We can't seem to turn that tide.",
    link: "https://www.reuters.com/legal/government/us-equal-rights-amendment-blocked-again-century-after-introduction-2023-04-27/",
    options: ["1998", "2012", "2020", "2023"]
  },
  {
    question: "When did women get the right to vote in the US?",
    answer: "1920",
    explanation: "The women's right to vote, known as women's suffrage, was granted by the 19th Amendment to the US Constitution.",
    options: ["1898", "1912", "1920", "1940"],
    link: "https://www.history.com/topics/womens-history/19th-amendment-1"
  },
  {
    question: "When was the first women's rights convention in the US?",
    answer: "1848",
    explanation: "The Seneca Falls Convention, held in Seneca Falls, New York, was the first women's rights convention in the US. It would be 72 years before they would be granted the right to vote.",
    options: ["1845", "1848", "1912", "1920"],
    link: "https://www.nps.gov/wori/index.htm"
  },
  {
    question: "When did Roe vs. Wade pass, granting women abortion rights?",
    answer: "1973",
    explanation: "The Supreme Court ruled (7-2) that unduly restrictive state regulation of abortion is unconstitutional.",
    options: ["1959", "1967", "1973", "1979"],
    link: "https://www.britannica.com/event/Roe-v-Wade"
  },
  {
    question: "When was Roe vs. Wade overturned, reversing womens' abortion rights?",
    answer: "2022",
    explanation: "Roe vs. Wade lasted 49 years before the States were given the right to decide on abortion for themselves by the Supreme Court, even though it was settled law.",
    options: ["2002", "2013", "2020", "2022"],
    link: "https://www.npr.org/2022/06/24/1102305878/supreme-court-abortion-roe-v-wade-decision-overturn"
  },
  {
    question: "When did the Equal Credit Opportunity Act pass, allowing women to get credit without a male co-signer for the first time?",
    answer: "1974",
    explanation: "Before the Equal Credit Opportunity Act, women were not allowed to have their own independent credit cards.",
    options: ["1962", "1971", "1974", "1982"],
    link: "https://www.ftc.gov/legal-library/browse/statutes/equal-credit-opportunity-act"
  },
  {
    question: "When was the Family and Medical Leave Act (FMLA) established, allowing women to take time off work for the birth of a child?",
    answer: "1993",
    explanation: "The FMLA entitles eligible employees of covered employers to take unpaid, job-protected leave for specified family and medical reasons with continuation of group health insurance coverage.",
    options: ["1975", "1981", "1989", "1993"],
    link: "https://www.dol.gov/agencies/whd/fmla"
  },
  {
    question: "When was the first woman inducted into the Rock & Roll Hall of Fame?",
    answer: "1987",
    explanation: "Aretha Franklin. She was many things: singer, songwriter, pianist, actress, and civil rights activist. Respect.",
    link: "https://www.womenofthehall.org/inductee/aretha-franklin/",
    options: ["1964", "1972", "1981", "1987"]
  },
  {
    question: "When did Amelia Earhart fly solo across the Atlantic?",
    answer: "1932",
    explanation: "She sadly and mysteriously disappeared when flying over the Pacific in 1937.",
    options: ["1922", "1927", "1932", "1938"],
    link: "https://www.biography.com/history-culture/amelia-earhart"
  },
  {
    question: "When was the first woman placed on the Supreme Court?",
    answer: "1981",
    explanation: "Sandra Day O'Connor. She just passed away in December 2023 at age 93.",
    options: ["1975", "1981", "1987", "1992"],
    link: "https://www.biography.com/legal-figures/sandra-day-oconnor"
  },
  {
    question: "When did the US have its first female Vice President?",
    answer: "2020",
    explanation: "Kamala Harris is the first female Vice President of the US. There still has not been a female President.",
    options: ["2001", "2013", "2016", "2020"],
    link: "https://twitter.com/VP"
  },
  {
    question: "When did the Equal Pay Act pass?",
    answer: "1963",
    explanation: "The Equal Pay Act mandates equal wages for men and women doing the same work.",
    options: ["1950", "1963", "1975", "1980"],
    link: "https://www.eeoc.gov/statutes/equal-pay-act-1963"
  },
  {
    question: "When was there the first female Speaker of the House?",
    answer: "2007",
    explanation: "Nancy Pelosi became first female Speaker of the House in 2007.",
    options: ["1992", "2001", "2007", "2013"],
    link: "https://www.biography.com/political-figures/nancy-pelosi"
  },
  {
    question: "When was there the first female Secretary of the Treasury?",
    answer: "2021",
    explanation: "Janet Yellen previously served as the 15th chair of the Federal Reserve from 2014 to 2018. She is the first person to hold those positions having also led the White House Council of Economic Advisers and the first woman to hold either post.",
    options: ["2001", "2011", "2017", "2021"],
    link: "https://home.treasury.gov/about/general-information/officials/janet-yellen"
  },
  {
    question: "After how many years was Britney Spears freed of her conservatorship?",
    answer: "13",
    explanation: 'On February 1, 2008, American singer Britney Spears was involuntarily placed under a conservatorship by Judge Reva Goetz, with her father, James "Jamie" Spears, and attorney Andrew M. Wallet, as conservators. The conservatorship lasted until November 2021.',
    link: "https://en.wikipedia.org/wiki/Britney_Spears_conservatorship_case",
    options: ["5", "9", "13", "15"]
  },
  {
    question: "When did a woman first buy a seat on the floor of the New York Stock Exchange?",
    answer: "1967",
    explanation: "On December 28, 1967 Muriel Siebert became the first woman to buy a seat on the New York Stock Exchange, the only woman among 1,365 men on the trading floor.",
    link: "https://www.smithsonianmag.com/smart-news/muriel-siebert-first-woman-with-a-seat-on-the-stock-exchange-dies-at-age-80-1539679/",
    options: ["1951", "1967", "1972", "1979"]
  },
  {
    question: "When was there the first female self-made billionaire in the US?",
    answer: "2010",
    explanation: "Martha Stewart became the first self-made female billioniare in the US a year after taking her company, Martha Stewart Living Omnimedia, public.",
    link: "https://fortune.com/2021/04/01/martha-stewart-cbd-burger-fi-americas-first-female-self-made-billionaire/",
    options: ["1989", "1995", "2000", "2010"]
  },
  {
    question: "When did the first woman win an Oscar for Best Director?",
    answer: "2000",
    explanation: "Katherine Bigelow won Best Director for The Hurt Locker.",
    link: "https://www.theguardian.com/film/2010/mar/08/kathryn-bigelow-oscars-best-director",
    options: ["1981", "1988", "1995", "2000"]
  },
  {
    question: "When was there the first female lawyer in the US?",
    answer: "1869",
    explanation: "Arabella Mansfield became the first female lawyer in the United States in 1869, admitted to the Iowa bar; she made her career as a college educator and administrator.",
    link: "https://en.wikipedia.org/wiki/Arabella_Mansfield",
    options: ["1852", "1869", "1888", "1897"]
  },
]

export { facts }
