/* eslint-disable */
import dogs from './data/dogs'
import { selectGif } from '../../utils/sortingMethods'
import { useEffect, useState } from 'react'
import { Button, Flex } from '@chakra-ui/react'

function ShowAnswer(props) {
  const {
    stateName,
    goToNextState,
    isCorrect,
    guesses
  } = props

  const [gifNumber, setGifNumber] = useState(null)
  const blinkingStars = <span className="Blink-me">⭐🌟⭐</span>

  useEffect(() => {
    if (isCorrect) {
      const randomNumber = selectGif()
      setGifNumber(randomNumber)
    } else {
      setGifNumber(null)
    }
  }, [isCorrect])

  return (
    <>
      {isCorrect ?
        <>
          <p>{blinkingStars} You got it! {blinkingStars}</p>
          <Flex flex={1} justify="center" mt={2}>
            {gifNumber > -1 ? <img className="Congrats-gif" alt="dancing dog" src={dogs[gifNumber]} /> : null}
          </Flex>
        </>
        : null}

      {guesses.length >= 5 ?
        <div style={{ color: 'red' }}>You used up all five guesses.</div>
        : null}

      <div className="Button-container">
        <Button onClick={() => goToNextState()}>Ready for the next state?</Button>
      </div>

      <Flex flex={1} justify="center" mt={6}>
        <iframe
          width="450"
          height="300"
          id="gmap_canvas"
          title={`Map of ${stateName}`}
          src={`https://maps.google.com/maps?q=${stateName.split(' ').join('+')}&t=&z=4&ie=UTF8&iwloc=&output=embed`}
        >
        </iframe>
      </Flex>
    </>
  )
}

export default ShowAnswer
