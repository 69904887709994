/* eslint-disable */
import Game from '../guess-the-state/Game'
import { randomizeStates } from '../../utils/sortingMethods'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import getBorderCSS from '../shared-components/clueBorders'

function StateMain() {
  const [states, setStates] = useState([])
  const [score, setScore] = useState(0)
  const [statesGuessed, setStatesGuessed] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [level, setLevel] = useState(1)
  const [showConfetti, setShowConfetti] = useState(true)
  const [borderCSS, setBorderCSS] = useState({ border: 'white solid 2px'})
  const STATES_PER_LEVEL = 5

  const shuffleStates = () => {
    const shuffledStates = randomizeStates()
    setStates(shuffledStates)
  }

  useEffect(() => {
    shuffleStates()
  }, [])

  useEffect(() => {
    if (statesGuessed > 0 &&
      statesGuessed % STATES_PER_LEVEL === 0) {
      setShowConfetti(true)
      setLevel(level + 1)
    } else {
      setShowConfetti(false)
    }
  }, [statesGuessed])

  useEffect(() => {
    if (currentQuestion > 1) {
      setShowConfetti(false)
    }
  }, [currentQuestion])

  useEffect(() => {
    if (level > 1) {
      const styling = getBorderCSS(level)
      setBorderCSS(styling)
    }
  }, [level])

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Guess the State Game</title>
        <meta name="description" content="Quiz style game to test your knowledge of the 50 states of the United State of America." />
      </Helmet>

      <Game
        stateData={states[currentQuestion - 1]}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        score={score}
        setScore={setScore}
        statesGuessed={statesGuessed}
        setStatesGuessed={setStatesGuessed}
        level={level}
        borderCSS={borderCSS}
        showConfetti={showConfetti}
      />

      <footer>
        <div>Thank you to <a className="App-link" href="https://gisgeography.com/state-outlines-blank-maps-united-states/" target="blank">GISGeographpy</a> for the state outlines.</div>
      </footer>
    </div>
  )
}

export default StateMain
