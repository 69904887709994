/* eslint-disable */
import dinosaurs from './data/dinosaurs';
import { selectGif } from '../../utils/sortingMethods';
import { useEffect, useState } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'

function ShowAnswer(props) {
  const {
    correctGuesses,
    countryName,
    goToNextCountry,
    isCorrect,
    size,
    guesses
  } = props

  const [zoom, setZoom] = useState(6)
  const [gifNumber, setGifNumber] = useState(null)

  useEffect(() => {
    if (size > 6000000) {
      setZoom(0)
    }
    if (size < 6000000 && size > 1500000) {
      setZoom(2)
    }
    if (size < 1500000 && size > 100000) {
      setZoom(4)
    }
    if (size < 100000 && size > 20000) {
      setZoom(6)
    }
    if (size < 20000) {
      setZoom(8)
    }
  }, [])

  useEffect(() => {
    if (isCorrect) {
      const randomNumber = selectGif()
      setGifNumber(randomNumber)
    } else {
      setGifNumber(null)
    }
  }, [isCorrect])

  const blinkingStars = <span className="Blink-me">⭐🌟⭐</span>

  return (
    <>
      {isCorrect ?
        <>
          <p>{blinkingStars} You got it! {blinkingStars}</p>
          <Flex flex={1} justify="center" mt={2}>
            {gifNumber > -1 ? <img className="Congrats-gif" alt="dancing dinosaur" src={dinosaurs[gifNumber]} /> : null}
          </Flex>
        </>
        : null}

      {correctGuesses.length > 1 ?
        <Box className="Guesses-container" mt={2}>
          <p className="Guesses">The correct country is known as:<br />
            {correctGuesses.join(', ')}
          </p>
        </Box>
        : null}

      {guesses.length >= 5 ?
        <div style={{ color: 'red' }}>You used up all five guesses.</div>
        : null}

      <div className="Button-container">
        <Button onClick={() => goToNextCountry()}>Ready for the next country?</Button>
      </div>

      <Flex flex={1} justify="center" mt={6}>
        <iframe
          width="450"
          height="300"
          id="gmap_canvas"
          title={`Map of ${countryName}`}
          src={`https://maps.google.com/maps?q=${countryName.split(' ').join('+')}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
        >
        </iframe>
      </Flex>
    </>
  );
}

export default ShowAnswer;
