const countries = [
  {
    name: {
      common: "Christmas Island",
      official: "Territory of Christmas Island",
      nativeName: {
        eng: {
          official: "Territory of Christmas Island",
          common: "Christmas Island"
        }
      }
    },
    tld: [
      ".cx"
    ],
    cca2: "CX",
    ccn3: "162",
    cca3: "CXR",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Australian dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "Flying Fish Cove"
    ],
    altSpellings: [
      "CX",
      "Territory of Christmas Island"
    ],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: {
      eng: "English"
    },
    latlng: [
      -10.5,
      105.66666666
    ],
    landlocked: "Yes",
    area: 135,
    demonyms: {
      eng: {
        f: "Christmas Islander",
        m: "Christmas Islander"
      }
    },
    flag: "🇨🇽",
    maps: {
      googleMaps: "https://goo.gl/maps/ZC17hHsQZpShN5wk9",
      openStreetMaps: "https://www.openstreetmap.org/relation/6365444"
    },
    population: 2072,
    car: {
      signs: [
        "AUS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+07:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cx.png",
      svg: "https://flagcdn.com/cx.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cx.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cx.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -10.42,
        105.68
      ]
    },
    nativeName: [
      "Christmas Island",
      "Territory of Christmas Island"
    ]
  },
  {
    name: {
      common: "Eritrea",
      official: "State of Eritrea",
      nativeName: {
        ara: {
          official: "دولة إرتريا",
          common: "إرتريا\u200e"
        },
        eng: {
          official: "State of Eritrea",
          common: "Eritrea"
        },
        tir: {
          official: "ሃገረ ኤርትራ",
          common: "ኤርትራ"
        }
      }
    },
    tld: [
      ".er"
    ],
    cca2: "ER",
    ccn3: "232",
    cca3: "ERI",
    cioc: "ERI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eritrean nakfa Nfk"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "91"
      ]
    },
    capital: [
      "Asmara"
    ],
    altSpellings: [
      "ER",
      "State of Eritrea",
      "ሃገረ ኤርትራ",
      "Dawlat Iritriyá",
      "ʾErtrā",
      "Iritriyā"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      ara: "Arabic",
      eng: "English",
      tir: "Tigrinya"
    },
    latlng: [
      15,
      39
    ],
    landlocked: "Yes",
    borders: [
      "DJI",
      "ETH",
      "SDN"
    ],
    area: 117600,
    demonyms: {
      eng: {
        f: "Eritrean",
        m: "Eritrean"
      },
      fra: {
        f: "Érythréenne",
        m: "Érythréen"
      }
    },
    flag: "🇪🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/HRyqUpnPwwG6jY5j6",
      openStreetMaps: "https://www.openstreetmap.org/relation/296961"
    },
    population: 5352000,
    fifa: "ERI",
    car: {
      signs: [
        "ER"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/er.png",
      svg: "https://flagcdn.com/er.svg",
      alt: "The flag of Eritrea comprises three triangles — a large red isosceles triangle with its base spanning the hoist end and its apex at the midpoint on the fly end, and a green and blue right-angled triangle above and beneath the red triangle. On the hoist side of the red triangle is a golden vertical olive branch encircled by a golden olive wreath."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/er.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/er.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        15.33,
        38.93
      ]
    },
    nativeName: [
      "Eritrea",
      "State of Eritrea",
      "دولة إرتريا",
      "إرتريا\u200e",
          "ሃገረ ኤርትራ",
      "ኤርትራ"
    ]
  },
  {
    name: {
      common: "Samoa",
      official: "Independent State of Samoa",
      nativeName: {
        eng: {
          official: "Independent State of Samoa",
          common: "Samoa"
        },
        smo: {
          official: "Malo Saʻoloto Tutoʻatasi o Sāmoa",
          common: "Sāmoa"
        }
      }
    },
    tld: [
      ".ws"
    ],
    cca2: "WS",
    ccn3: "882",
    cca3: "WSM",
    cioc: "SAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Samoan tālā T"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "85"
      ]
    },
    capital: [
      "Apia"
    ],
    altSpellings: [
      "WS",
      "Independent State of Samoa",
      "Malo Saʻoloto Tutoʻatasi o Sāmoa"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      smo: "Samoan"
    },
    latlng: [
      -13.58333333,
      -172.33333333
    ],
    landlocked: "Yes",
    area: 2842,
    demonyms: {
      eng: {
        f: "Samoan",
        m: "Samoan"
      },
      fra: {
        f: "Samoane",
        m: "Samoan"
      }
    },
    flag: "🇼🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/CFC9fEFP9cfkYUBF9",
      openStreetMaps: "https://www.openstreetmap.org/relation/1872673"
    },
    population: 198410,
    gini: {
      2013: 38.7
    },
    fifa: "SAM",
    car: {
      signs: [
        "WS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+13:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ws.png",
      svg: "https://flagcdn.com/ws.svg",
      alt: "The flag of Samoa has a red field. A blue rectangle, bearing a representation of the Southern Cross made up of five large and one smaller five-pointed white stars, is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ws.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ws.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -13.82,
        -171.77
      ]
    },
    nativeName: [
      "Samoa",
      "Independent State of Samoa",
      "Malo Saʻoloto Tutoʻatasi o Sāmoa",
      "Sāmoa"
    ]
  },
  {
    name: {
      common: "North Macedonia",
      official: "Republic of North Macedonia",
      nativeName: {
        mkd: {
          official: "Република Северна Македонија",
          common: "Македонија"
        }
      }
    },
    tld: [
      ".mk"
    ],
    cca2: "MK",
    ccn3: "807",
    cca3: "MKD",
    cioc: "MKD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "denar den"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "89"
      ]
    },
    capital: [
      "Skopje"
    ],
    altSpellings: [
      "MK",
      "The former Yugoslav Republic of Macedonia",
      "Republic of North Macedonia",
      "Macedonia, The Former Yugoslav Republic of",
      "Република Северна Македонија"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      mkd: "Macedonian"
    },
    latlng: [
      41.83333333,
      22
    ],
    landlocked: "Yes",
    borders: [
      "ALB",
      "BGR",
      "GRC",
      "UNK",
      "SRB"
    ],
    area: 25713,
    demonyms: {
      eng: {
        f: "Macedonian",
        m: "Macedonian"
      },
      fra: {
        f: "Macédonienne",
        m: "Macédonien"
      }
    },
    flag: "🇲🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/55Q8MEnF6ACdu3q79",
      openStreetMaps: "https://www.openstreetmap.org/relation/53293"
    },
    population: 2077132,
    gini: {
      2018: 33
    },
    fifa: "MKD",
    car: {
      signs: [
        "MK"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mk.png",
      svg: "https://flagcdn.com/mk.svg",
      alt: "The flag of North Macedonia has a red field, at the center of which is a golden-yellow sun with eight broadening rays that extend to the edges of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        42,
        21.43
      ]
    },
    nativeName: [
      "North Macedonia",
      "Republic of North Macedonia",
      "Република Северна Македонија",
      "Македонија"
    ]
  },
  {
    name: {
      common: "Djibouti",
      official: "Republic of Djibouti",
      nativeName: {
        ara: {
          official: "جمهورية جيبوتي",
          common: "جيبوتي\u200e"
        },
        fra: {
          official: "République de Djibouti",
          common: "Djibouti"
        }
      }
    },
    tld: [
      ".dj"
    ],
    cca2: "DJ",
    ccn3: "262",
    cca3: "DJI",
    cioc: "DJI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Djiboutian franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "53"
      ]
    },
    capital: [
      "Djibouti"
    ],
    altSpellings: [
      "DJ",
      "Jabuuti",
      "Gabuuti",
      "Republic of Djibouti",
      "République de Djibouti",
      "Gabuutih Ummuuno",
      "Jamhuuriyadda Jabuuti"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      ara: "Arabic",
      fra: "French"
    },
    latlng: [
      11.5,
      43
    ],
    landlocked: "Yes",
    borders: [
      "ERI",
      "ETH",
      "SOM"
    ],
    area: 23200,
    demonyms: {
      eng: {
        f: "Djibouti",
        m: "Djibouti"
      },
      fra: {
        f: "Djiboutienne",
        m: "Djiboutien"
      }
    },
    flag: "🇩🇯",
    maps: {
      googleMaps: "https://goo.gl/maps/V1HWfzN3bS1kwf4C6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192801"
    },
    population: 988002,
    gini: {
      2017: 41.6
    },
    fifa: "DJI",
    car: {
      signs: [
        "DJI"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/dj.png",
      svg: "https://flagcdn.com/dj.svg",
      alt: "The flag of Djibouti is composed of two equal horizontal bands of light blue and light green, with a white isosceles triangle superimposed on the hoist side of the field. The triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a red five-pointed star at its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/dj.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/dj.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        11.58,
        43.15
      ]
    },
    nativeName: [
      "Djibouti",
      "Republic of Djibouti",
      "جمهورية جيبوتي",
      "جيبوتي\u200e",
          "République de Djibouti"
    ]
  },
  {
    name: {
      common: "Jordan",
      official: "Hashemite Kingdom of Jordan",
      nativeName: {
        ara: {
          official: "المملكة الأردنية الهاشمية",
          common: "الأردن"
        }
      }
    },
    tld: [
      ".jo",
      "الاردن."
    ],
    cca2: "JO",
    ccn3: "400",
    cca3: "JOR",
    cioc: "JOR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Jordanian dinar د.ا"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "62"
      ]
    },
    capital: [
      "Amman"
    ],
    altSpellings: [
      "JO",
      "Hashemite Kingdom of Jordan",
      "al-Mamlakah al-Urdunīyah al-Hāshimīyah"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      31,
      36
    ],
    landlocked: "Yes",
    borders: [
      "IRQ",
      "ISR",
      "PSE",
      "SAU",
      "SYR"
    ],
    area: 89342,
    demonyms: {
      eng: {
        f: "Jordanian",
        m: "Jordanian"
      },
      fra: {
        f: "Jordanienne",
        m: "Jordanien"
      }
    },
    flag: "🇯🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/ko1dzSDKg8Gsi9A98",
      openStreetMaps: "https://www.openstreetmap.org/relation/184818"
    },
    population: 10203140,
    gini: {
      2010: 33.7
    },
    fifa: "JOR",
    car: {
      signs: [
        "HKJ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/jo.png",
      svg: "https://flagcdn.com/jo.svg",
      alt: "The flag of Jordan is composed of three equal horizontal bands of black, white and green, with a red isosceles triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about half the width of the field and bears a small seven-pointed white star at its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/jo.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/jo.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        31.95,
        35.93
      ]
    },
    nativeName: [
      "Jordan",
      "Hashemite Kingdom of Jordan",
      "المملكة الأردنية الهاشمية",
      "الأردن"
    ]
  },
  {
    name: {
      common: "Pakistan",
      official: "Islamic Republic of Pakistan",
      nativeName: {
        eng: {
          official: "Islamic Republic of Pakistan",
          common: "Pakistan"
        },
        urd: {
          official: "اسلامی جمہوریۂ پاكستان",
          common: "پاكستان"
        }
      }
    },
    tld: [
      ".pk"
    ],
    cca2: "PK",
    ccn3: "586",
    cca3: "PAK",
    cioc: "PAK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Pakistani rupee ₨"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "2"
      ]
    },
    capital: [
      "Islamabad"
    ],
    altSpellings: [
      "PK",
      "Pākistān",
      "Islamic Republic of Pakistan",
      "Islāmī Jumhūriya'eh Pākistān"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      eng: "English",
      urd: "Urdu"
    },
    latlng: [
      30,
      70
    ],
    landlocked: "Yes",
    borders: [
      "AFG",
      "CHN",
      "IND",
      "IRN"
    ],
    area: 881912,
    demonyms: {
      eng: {
        f: "Pakistani",
        m: "Pakistani"
      },
      fra: {
        f: "Pakistanaise",
        m: "Pakistanais"
      }
    },
    flag: "🇵🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/5LYujdfR5yLUXoERA",
      openStreetMaps: "https://www.openstreetmap.org/relation/307573"
    },
    population: 220892331,
    gini: {
      2018: 31.6
    },
    fifa: "PAK",
    car: {
      signs: [
        "PK"
      ],
      side: "left"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pk.png",
      svg: "https://flagcdn.com/pk.svg",
      alt: "The flag of Pakistan is composed of a white vertical band on its hoist side that takes up about one-fourth the width of the field and a dark green rectangular area that spans the rest of the field. A white fly-side facing crescent and five-pointed star are centered in the dark green area."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        33.68,
        73.05
      ]
    },
    nativeName: [
      "Pakistan",
      "Islamic Republic of Pakistan",
      "اسلامی جمہوریۂ پاكستان",
      "پاكستان"
    ]
  },
  {
    name: {
      common: "French Polynesia",
      official: "French Polynesia",
      nativeName: {
        fra: {
          official: "Polynésie française",
          common: "Polynésie française"
        }
      }
    },
    tld: [
      ".pf"
    ],
    cca2: "PF",
    ccn3: "258",
    cca3: "PYF",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "CFP franc ₣"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "89"
      ]
    },
    capital: [
      "Papeetē"
    ],
    altSpellings: [
      "PF",
      "Polynésie française",
      "French Polynesia",
      "Pōrīnetia Farāni"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      fra: "French"
    },
    latlng: [
      17.6797,
      149.4068
    ],
    landlocked: "Yes",
    area: 4167,
    demonyms: {
      eng: {
        f: "French Polynesian",
        m: "French Polynesian"
      },
      fra: {
        f: "Polynésienne",
        m: "Polynésien"
      }
    },
    flag: "🇵🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/xgg6BQTRyeQg4e1m6",
      openStreetMaps: "https://www.openstreetmap.org/relation/3412620"
    },
    population: 280904,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-10:00",
      "UTC-09:30",
      "UTC-09:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pf.png",
      svg: "https://flagcdn.com/pf.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pf.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pf.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -17.53,
        -149.56
      ]
    },
    nativeName: [
      "French Polynesia",
      "Polynésie française"
    ]
  },
  {
    name: {
      common: "Ireland",
      official: "Republic of Ireland",
      nativeName: {
        eng: {
          official: "Republic of Ireland",
          common: "Ireland"
        },
        gle: {
          official: "Poblacht na hÉireann",
          common: "Éire"
        }
      }
    },
    tld: [
      ".ie"
    ],
    cca2: "IE",
    ccn3: "372",
    cca3: "IRL",
    cioc: "IRL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "53"
      ]
    },
    capital: [
      "Dublin"
    ],
    altSpellings: [
      "IE",
      "Éire",
      "Republic of Ireland",
      "Poblacht na hÉireann"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      eng: "English",
      gle: "Irish"
    },
    latlng: [
      53,
      -8
    ],
    landlocked: "Yes",
    borders: [
      "GBR"
    ],
    area: 70273,
    demonyms: {
      eng: {
        f: "Irish",
        m: "Irish"
      },
      fra: {
        f: "Irlandaise",
        m: "Irlandais"
      }
    },
    flag: "🇮🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/hxd1BKxgpchStzQC6",
      openStreetMaps: "https://www.openstreetmap.org/relation/62273"
    },
    population: 4994724,
    gini: {
      2017: 31.4
    },
    fifa: "IRL",
    car: {
      signs: [
        "IRL"
      ],
      side: "left"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ie.png",
      svg: "https://flagcdn.com/ie.svg",
      alt: "The flag of Ireland is composed of three equal vertical bands of green, white and orange."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ie.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ie.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        53.32,
        -6.23
      ]
    },
    nativeName: [
      "Ireland",
      "Republic of Ireland",
      "Poblacht na hÉireann",
      "Éire"
    ]
  },
  {
    name: {
      common: "Mauritania",
      official: "Islamic Republic of Mauritania",
      nativeName: {
        ara: {
          official: "الجمهورية الإسلامية الموريتانية",
          common: "موريتانيا"
        }
      }
    },
    tld: [
      ".mr"
    ],
    cca2: "MR",
    ccn3: "478",
    cca3: "MRT",
    cioc: "MTN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Mauritanian ouguiya UM"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "22"
      ]
    },
    capital: [
      "Nouakchott"
    ],
    altSpellings: [
      "MR",
      "Islamic Republic of Mauritania",
      "al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      20,
      -12
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "MLI",
      "SEN",
      "ESH"
    ],
    area: 1030700,
    demonyms: {
      eng: {
        f: "Mauritanian",
        m: "Mauritanian"
      },
      fra: {
        f: "Mauritanienne",
        m: "Mauritanien"
      }
    },
    flag: "🇲🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/im2MmQ5jFjzxWBks5",
      openStreetMaps: "https://www.openstreetmap.org/relation/192763"
    },
    population: 4649660,
    gini: {
      2014: 32.6
    },
    fifa: "MTN",
    car: {
      signs: [
        "RIM"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mr.png",
      svg: "https://flagcdn.com/mr.svg",
      alt: "The flag of Mauritania has a green field with a thin red horizontal band at the top and bottom of the field. At the center of the field is a five-pointed yellow star above an upward facing yellow crescent."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.07,
        -15.97
      ]
    },
    nativeName: [
      "Mauritania",
      "Islamic Republic of Mauritania",
      "الجمهورية الإسلامية الموريتانية",
      "موريتانيا"
    ]
  },
  {
    name: {
      common: "Denmark",
      official: "Kingdom of Denmark",
      nativeName: {
        dan: {
          official: "Kongeriget Danmark",
          common: "Danmark"
        }
      }
    },
    tld: [
      ".dk"
    ],
    cca2: "DK",
    ccn3: "208",
    cca3: "DNK",
    cioc: "DEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Danish krone kr"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "5"
      ]
    },
    capital: [
      "Copenhagen"
    ],
    altSpellings: [
      "DK",
      "Danmark",
      "Kingdom of Denmark",
      "Kongeriget Danmark"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      dan: "Danish"
    },
    latlng: [
      56,
      10
    ],
    landlocked: "Yes",
    borders: [
      "DEU"
    ],
    area: 43094,
    demonyms: {
      eng: {
        f: "Danish",
        m: "Danish"
      },
      fra: {
        f: "Danoise",
        m: "Danois"
      }
    },
    flag: "🇩🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/UddGPN7hAyrtpFiT6",
      openStreetMaps: "https://www.openstreetmap.org/relation/50046"
    },
    population: 5831404,
    gini: {
      2018: 28.2
    },
    fifa: "DEN",
    car: {
      signs: [
        "DK"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00",
      "UTC-03:00",
      "UTC-01:00",
      "UTC",
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/dk.png",
      svg: "https://flagcdn.com/dk.svg",
      alt: "The flag of Denmark has a red field with a large white cross that extend to the edges of the field. The vertical part of this cross is offset towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/dk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/dk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        55.67,
        12.58
      ]
    },
    nativeName: [
      "Denmark",
      "Kingdom of Denmark",
      "Kongeriget Danmark",
      "Danmark"
    ]
  },
  {
    name: {
      common: "Namibia",
      official: "Republic of Namibia",
      nativeName: {
        afr: {
          official: "Republiek van Namibië",
          common: "Namibië"
        },
        deu: {
          official: "Republik Namibia",
          common: "Namibia"
        },
        eng: {
          official: "Republic of Namibia",
          common: "Namibia"
        },
        her: {
          official: "Republic of Namibia",
          common: "Namibia"
        },
        hgm: {
          official: "Republic of Namibia",
          common: "Namibia"
        },
        kwn: {
          official: "Republic of Namibia",
          common: "Namibia"
        },
        loz: {
          official: "Republic of Namibia",
          common: "Namibia"
        },
        ndo: {
          official: "Republic of Namibia",
          common: "Namibia"
        },
        tsn: {
          official: "Lefatshe la Namibia",
          common: "Namibia"
        }
      }
    },
    tld: [
      ".na"
    ],
    cca2: "NA",
    ccn3: "516",
    cca3: "NAM",
    cioc: "NAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Namibian dollar $",
      "South African rand R"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "64"
      ]
    },
    capital: [
      "Windhoek"
    ],
    altSpellings: [
      "NA",
      "Namibië",
      "Republic of Namibia"
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      afr: "Afrikaans",
      deu: "German",
      eng: "English",
      her: "Herero",
      hgm: "Khoekhoe",
      kwn: "Kwangali",
      loz: "Lozi",
      ndo: "Ndonga",
      tsn: "Tswana"
    },
    latlng: [
      -22,
      17
    ],
    landlocked: "Yes",
    borders: [
      "AGO",
      "BWA",
      "ZAF",
      "ZMB"
    ],
    area: 825615,
    demonyms: {
      eng: {
        f: "Namibian",
        m: "Namibian"
      },
      fra: {
        f: "Namibienne",
        m: "Namibien"
      }
    },
    flag: "🇳🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/oR1i8BFEYX3EY83WA",
      openStreetMaps: "https://www.openstreetmap.org/relation/195266"
    },
    population: 2540916,
    gini: {
      2015: 59.1
    },
    fifa: "NAM",
    car: {
      signs: [
        "NAM"
      ],
      side: "left"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/na.png",
      svg: "https://flagcdn.com/na.svg",
      alt: "The flag of Namibia features a white-edged red diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a blue and green triangle respectively. A gold sun with twelve triangular rays is situated on the hoist side of the upper triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/na.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/na.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -22.57,
        17.08
      ]
    },
    nativeName: [
      "Namibia",
      "Republic of Namibia",
      "Republiek van Namibië",
      "Namibië",
      "Republik Namibia",
      "Lefatshe la Namibia"
    ]
  },
  {
    name: {
      common: "Ghana",
      official: "Republic of Ghana",
      nativeName: {
        eng: {
          official: "Republic of Ghana",
          common: "Ghana"
        }
      }
    },
    tld: [
      ".gh"
    ],
    cca2: "GH",
    ccn3: "288",
    cca3: "GHA",
    cioc: "GHA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Ghanaian cedi ₵"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "33"
      ]
    },
    capital: [
      "Accra"
    ],
    altSpellings: [
      "GH"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      8,
      -2
    ],
    landlocked: "Yes",
    borders: [
      "BFA",
      "CIV",
      "TGO"
    ],
    area: 238533,
    demonyms: {
      eng: {
        f: "Ghanaian",
        m: "Ghanaian"
      },
      fra: {
        f: "Ghanéenne",
        m: "Ghanéen"
      }
    },
    flag: "🇬🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/Avy5RSmdsXFBaiXq8",
      openStreetMaps: "https://www.openstreetmap.org/relation/192781"
    },
    population: 31072945,
    gini: {
      2016: 43.5
    },
    fifa: "GHA",
    car: {
      signs: [
        "GH"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gh.png",
      svg: "https://flagcdn.com/gh.svg",
      alt: "The flag of Ghana is composed of three equal horizontal bands of red, gold and green, with a five-pointed black star centered in the gold band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gh.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gh.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        5.55,
        -0.22
      ]
    },
    nativeName: [
      "Ghana",
      "Republic of Ghana"
    ]
  },
  {
    name: {
      common: "South Sudan",
      official: "Republic of South Sudan",
      nativeName: {
        eng: {
          official: "Republic of South Sudan",
          common: "South Sudan"
        }
      }
    },
    tld: [
      ".ss"
    ],
    cca2: "SS",
    ccn3: "728",
    cca3: "SSD",
    cioc: "SSD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "South Sudanese pound £"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "11"
      ]
    },
    capital: [
      "Juba"
    ],
    altSpellings: [
      "SS"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      7,
      30
    ],
    landlocked: "Yes",
    borders: [
      "CAF",
      "COD",
      "ETH",
      "KEN",
      "SDN",
      "UGA"
    ],
    area: 619745,
    demonyms: {
      eng: {
        f: "South Sudanese",
        m: "South Sudanese"
      },
      fra: {
        f: "Sud-Soudanaise",
        m: "Sud-Soudanais"
      }
    },
    flag: "🇸🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/Zm1AYCXb9HSNF1P27",
      openStreetMaps: "https://www.openstreetmap.org/relation/1656678"
    },
    population: 11193729,
    gini: {
      2016: 44.1
    },
    fifa: "SSD",
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ss.png",
      svg: "https://flagcdn.com/ss.svg",
      alt: "The flag of South Sudan is composed of three equal horizontal bands of black, red with white top and bottom edges, and green. A blue equilateral triangle which spans about two-fifth the width of the field is superimposed on the hoist side with its base on the hoist end of the field. At the center of this triangle is a five-pointed yellow star."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ss.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ss.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        4.85,
        31.62
      ]
    },
    nativeName: [
      "South Sudan",
      "Republic of South Sudan"
    ]
  },
  {
    name: {
      common: "Slovakia",
      official: "Slovak Republic",
      nativeName: {
        slk: {
          official: "Slovenská republika",
          common: "Slovensko"
        }
      }
    },
    tld: [
      ".sk"
    ],
    cca2: "SK",
    ccn3: "703",
    cca3: "SVK",
    cioc: "SVK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "21"
      ]
    },
    capital: [
      "Bratislava"
    ],
    altSpellings: [
      "SK",
      "Slovak Republic",
      "Slovenská republika"
    ],
    region: "Europe",
    subregion: "Central Europe",
    languages: {
      slk: "Slovak"
    },
    latlng: [
      48.66666666,
      19.5
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "CZE",
      "HUN",
      "POL",
      "UKR"
    ],
    area: 49037,
    demonyms: {
      eng: {
        f: "Slovak",
        m: "Slovak"
      },
      fra: {
        f: "Slovaque",
        m: "Slovaque"
      }
    },
    flag: "🇸🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/uNSH2wW4bLoZVYJj7",
      openStreetMaps: "https://www.openstreetmap.org/relation/14296"
    },
    population: 5458827,
    gini: {
      2018: 25
    },
    fifa: "SVK",
    car: {
      signs: [
        "SK"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sk.png",
      svg: "https://flagcdn.com/sk.svg",
      alt: "The flag of Slovakia is composed of three equal horizontal bands of white, blue and red. The coat of arms of Slovakia is superimposed at the center of the field slightly towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        48.15,
        17.12
      ]
    },
    nativeName: [
      "Slovakia",
      "Slovak Republic",
      "Slovenská republika",
      "Slovensko"
    ]
  },
  {
    name: {
      common: "American Samoa",
      official: "American Samoa",
      nativeName: {
        eng: {
          official: "American Samoa",
          common: "American Samoa"
        },
        smo: {
          official: "Sāmoa Amelika",
          common: "Sāmoa Amelika"
        }
      }
    },
    tld: [
      ".as"
    ],
    cca2: "AS",
    ccn3: "016",
    cca3: "ASM",
    cioc: "ASA",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "684"
      ]
    },
    capital: [
      "Pago Pago"
    ],
    altSpellings: [
      "AS",
      "Amerika Sāmoa",
      "Amelika Sāmoa",
      "Sāmoa Amelika"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      smo: "Samoan"
    },
    latlng: [
      -14.33333333,
      -170
    ],
    landlocked: "Yes",
    area: 199,
    demonyms: {
      eng: {
        f: "American Samoan",
        m: "American Samoan"
      },
      fra: {
        f: "Samoane",
        m: "Samoan"
      }
    },
    flag: "🇦🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/Re9ePMjwP1sFCBFA6",
      openStreetMaps: "https://www.openstreetmap.org/relation/2177187"
    },
    population: 55197,
    fifa: "ASA",
    car: {
      signs: [
        "USA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-11:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/as.png",
      svg: "https://flagcdn.com/as.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -14.27,
        -170.7
      ]
    },
    nativeName: [
      "American Samoa",
      "Sāmoa Amelika"
    ]
  },
  {
    name: {
      common: "Moldova",
      official: "Republic of Moldova",
      nativeName: {
        ron: {
          official: "Republica Moldova",
          common: "Moldova"
        }
      }
    },
    tld: [
      ".md"
    ],
    cca2: "MD",
    ccn3: "498",
    cca3: "MDA",
    cioc: "MDA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Moldovan leu L"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "73"
      ]
    },
    capital: [
      "Chișinău"
    ],
    altSpellings: [
      "MD",
      "Moldova, Republic of",
      "Republic of Moldova",
      "Republica Moldova"
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: {
      ron: "Romanian"
    },
    latlng: [
      47,
      29
    ],
    landlocked: "Yes",
    borders: [
      "ROU",
      "UKR"
    ],
    area: 33846,
    demonyms: {
      eng: {
        f: "Moldovan",
        m: "Moldovan"
      },
      fra: {
        f: "Moldave",
        m: "Moldave"
      }
    },
    flag: "🇲🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/JjmyUuULujnDeFPf7",
      openStreetMaps: "https://www.openstreetmap.org/relation/58974"
    },
    population: 2617820,
    gini: {
      2018: 25.7
    },
    fifa: "MDA",
    car: {
      signs: [
        "MD"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/md.png",
      svg: "https://flagcdn.com/md.svg",
      alt: "The flag of Moldova is composed of three equal vertical bands of blue, yellow and red, with the national coat of arms centered in the yellow band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/md.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/md.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        47.01,
        28.9
      ]
    },
    nativeName: [
      "Moldova",
      "Republic of Moldova",
      "Republica Moldova"
    ]
  },
  {
    name: {
      common: "North Korea",
      official: "Democratic People's Republic of Korea",
      nativeName: {
        kor: {
          official: "조선민주주의인민공화국",
          common: "조선"
        }
      }
    },
    tld: [
      ".kp"
    ],
    cca2: "KP",
    ccn3: "408",
    cca3: "PRK",
    cioc: "PRK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "North Korean won ₩"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "50"
      ]
    },
    capital: [
      "Pyongyang"
    ],
    altSpellings: [
      "KP",
      "Democratic People's Republic of Korea",
      "DPRK",
      "조선민주주의인민공화국",
      "Chosŏn Minjujuŭi Inmin Konghwaguk",
      "Korea, Democratic People's Republic of",
      "북한",
      "북조선"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      kor: "Korean"
    },
    latlng: [
      40,
      127
    ],
    landlocked: "Yes",
    borders: [
      "CHN",
      "KOR",
      "RUS"
    ],
    area: 120538,
    demonyms: {
      eng: {
        f: "North Korean",
        m: "North Korean"
      },
      fra: {
        f: "Nord-coréenne",
        m: "Nord-coréen"
      }
    },
    flag: "🇰🇵",
    maps: {
      googleMaps: "https://goo.gl/maps/9q5T2DMeH5JL7Tky6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192734"
    },
    population: 25778815,
    fifa: "PRK",
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+09:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kp.png",
      svg: "https://flagcdn.com/kp.svg",
      alt: "The flag of North Korea is composed of three horizontal bands — a large central white-edged red band, and a blue band above and beneath the red band. On the hoist side of the red band is a red five-pointed star within a white circle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kp.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kp.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        39.02,
        125.75
      ]
    },
    nativeName: [
      "North Korea",
      "Democratic People's Republic of Korea",
      "조선민주주의인민공화국",
      "조선"
    ]
  },
  {
    name: {
      common: "Macau",
      official: "Macao Special Administrative Region of the People's Republic of China",
      nativeName: {
        por: {
          official: "Região Administrativa Especial de Macau da República Popular da China",
          common: "Macau"
        },
        zho: {
          official: "中华人民共和国澳门特别行政区",
          common: "澳门"
        }
      }
    },
    tld: [
      ".mo"
    ],
    cca2: "MO",
    ccn3: "446",
    cca3: "MAC",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Macanese pataca P"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "53"
      ]
    },
    altSpellings: [
      "MO",
      "澳门",
      "Macao",
      "Macao Special Administrative Region of the People's Republic of China",
      "中華人民共和國澳門特別行政區",
      "Região Administrativa Especial de Macau da República Popular da China"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      por: "Portuguese",
      zho: "Chinese"
    },
    latlng: [
      22.16666666,
      113.55
    ],
    landlocked: "Yes",
    borders: [
      "CHN"
    ],
    area: 30,
    demonyms: {
      eng: {
        f: "Macanese",
        m: "Macanese"
      },
      fra: {
        f: "Macanaise",
        m: "Macanais"
      }
    },
    flag: "🇲🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/whymRdk3dZFfAAs4A",
      openStreetMaps: "https://www.openstreetmap.org/relation/1867188"
    },
    population: 649342,
    fifa: "MAC",
    car: {
      signs: [
        "MO"
      ],
      side: "left"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mo.png",
      svg: "https://flagcdn.com/mo.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mo.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mo.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {},
    nativeName: [
      "Macau",
      "Macao Special Administrative Region of the People's Republic of China",
      "Região Administrativa Especial de Macau da República Popular da China",
      "中华人民共和国澳门特别行政区",
      "澳门"
    ]
  },
  {
    name: {
      common: "Turks and Caicos Islands",
      official: "Turks and Caicos Islands",
      nativeName: {
        eng: {
          official: "Turks and Caicos Islands",
          common: "Turks and Caicos Islands"
        }
      }
    },
    tld: [
      ".tc"
    ],
    cca2: "TC",
    ccn3: "796",
    cca3: "TCA",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "649"
      ]
    },
    capital: [
      "Cockburn Town"
    ],
    altSpellings: [
      "TC"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      21.75,
      -71.58333333
    ],
    landlocked: "Yes",
    area: 948,
    demonyms: {
      eng: {
        f: "Turks and Caicos Islander",
        m: "Turks and Caicos Islander"
      }
    },
    flag: "🇹🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/R8VUDQfwZiFtvmyn8",
      openStreetMaps: "https://www.openstreetmap.org/relation/547479"
    },
    population: 38718,
    fifa: "TCA",
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tc.png",
      svg: "https://flagcdn.com/tc.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        21.46,
        -71.14
      ]
    },
    nativeName: [
      "Turks and Caicos Islands"
    ]
  },
  {
    name: {
      common: "Seychelles",
      official: "Republic of Seychelles",
      nativeName: {
        crs: {
          official: "Repiblik Sesel",
          common: "Sesel"
        },
        eng: {
          official: "Republic of Seychelles",
          common: "Seychelles"
        },
        fra: {
          official: "République des Seychelles",
          common: "Seychelles"
        }
      }
    },
    tld: [
      ".sc"
    ],
    cca2: "SC",
    ccn3: "690",
    cca3: "SYC",
    cioc: "SEY",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Seychellois rupee ₨"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "48"
      ]
    },
    capital: [
      "Victoria"
    ],
    altSpellings: [
      "SC",
      "Republic of Seychelles",
      "Repiblik Sesel",
      "République des Seychelles"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      crs: "Seychellois Creole",
      eng: "English",
      fra: "French"
    },
    latlng: [
      -4.58333333,
      55.66666666
    ],
    landlocked: "Yes",
    area: 452,
    demonyms: {
      eng: {
        f: "Seychellois",
        m: "Seychellois"
      },
      fra: {
        f: "Seychelloise",
        m: "Seychellois"
      }
    },
    flag: "🇸🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/aqCcy2TKh5TV5MAX8",
      openStreetMaps: "https://www.openstreetmap.org/relation/536765"
    },
    population: 98462,
    gini: {
      2018: 32.1
    },
    fifa: "SEY",
    car: {
      signs: [
        "SY"
      ],
      side: "left"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sc.png",
      svg: "https://flagcdn.com/sc.svg",
      alt: "The flag of Seychelles is composed of five broadening oblique bands of blue, yellow, red, white and green, which extend from the hoist side of the bottom edge to the top and fly edges of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sc.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sc.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -4.62,
        55.45
      ]
    },
    nativeName: [
      "Seychelles",
      "Republic of Seychelles",
      "Repiblik Sesel",
      "Sesel",
      "République des Seychelles"
    ]
  },
  {
    name: {
      common: "Armenia",
      official: "Republic of Armenia",
      nativeName: {
        hye: {
          official: "Հայաստանի Հանրապետություն",
          common: "Հայաստան"
        }
      }
    },
    tld: [
      ".am"
    ],
    cca2: "AM",
    ccn3: "051",
    cca3: "ARM",
    cioc: "ARM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Armenian dram ֏"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "74"
      ]
    },
    capital: [
      "Yerevan"
    ],
    altSpellings: [
      "AM",
      "Hayastan",
      "Republic of Armenia",
      "Հայաստանի Հանրապետություն"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      hye: "Armenian"
    },
    latlng: [
      40,
      45
    ],
    landlocked: "Yes",
    borders: [
      "AZE",
      "GEO",
      "IRN",
      "TUR"
    ],
    area: 29743,
    demonyms: {
      eng: {
        f: "Armenian",
        m: "Armenian"
      },
      fra: {
        f: "Arménienne",
        m: "Arménien"
      }
    },
    flag: "🇦🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/azWUtK9bUQYEyccbA",
      openStreetMaps: "https://www.openstreetmap.org/relation/364066"
    },
    population: 2963234,
    gini: {
      2019: 29.9
    },
    fifa: "ARM",
    car: {
      signs: [
        "AM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/am.png",
      svg: "https://flagcdn.com/am.svg",
      alt: "The flag of Armenia is composed of three equal horizontal bands of red, blue and orange."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/am.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/am.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        40.17,
        44.5
      ]
    },
    nativeName: [
      "Armenia",
      "Republic of Armenia",
      "Հայաստանի Հանրապետություն",
      "Հայաստան"
    ]
  },
  {
    name: {
      common: "Curaçao",
      official: "Country of Curaçao",
      nativeName: {
        eng: {
          official: "Country of Curaçao",
          common: "Curaçao"
        },
        nld: {
          official: "Land Curaçao",
          common: "Curaçao"
        },
        pap: {
          official: "Pais Kòrsou",
          common: "Pais Kòrsou"
        }
      }
    },
    tld: [
      ".cw"
    ],
    cca2: "CW",
    ccn3: "531",
    cca3: "CUW",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Netherlands Antillean guilder ƒ"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "99"
      ]
    },
    capital: [
      "Willemstad"
    ],
    altSpellings: [
      "CW",
      "Curacao",
      "Kòrsou",
      "Country of Curaçao",
      "Land Curaçao",
      "Pais Kòrsou"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English",
      nld: "Dutch",
      pap: "Papiamento"
    },
    latlng: [
      12.116667,
      -68.933333
    ],
    landlocked: "Yes",
    area: 444,
    demonyms: {
      eng: {
        f: "Curaçaoan",
        m: "Curaçaoan"
      },
      fra: {
        f: "Curacienne",
        m: "Curacien"
      }
    },
    flag: "🇨🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/9D3hTeA3qKaRT7S16",
      openStreetMaps: "https://www.openstreetmap.org/relation/1216719"
    },
    population: 155014,
    fifa: "CUW",
    car: {
      signs: [
        "CW"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cw.png",
      svg: "https://flagcdn.com/cw.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.1,
        -68.92
      ]
    },
    nativeName: [
      "Curaçao",
      "Country of Curaçao",
      "Land Curaçao",
      "Pais Kòrsou"
    ]
  },
  {
    name: {
      common: "Guadeloupe",
      official: "Guadeloupe",
      nativeName: {
        fra: {
          official: "Guadeloupe",
          common: "Guadeloupe"
        }
      }
    },
    tld: [
      ".gp"
    ],
    cca2: "GP",
    ccn3: "312",
    cca3: "GLP",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "90"
      ]
    },
    capital: [
      "Basse-Terre"
    ],
    altSpellings: [
      "GP",
      "Gwadloup"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      fra: "French"
    },
    latlng: [
      16.25,
      -61.583333
    ],
    landlocked: "Yes",
    area: 1628,
    demonyms: {
      eng: {
        f: "Guadeloupian",
        m: "Guadeloupian"
      },
      fra: {
        f: "Guadeloupéenne",
        m: "Guadeloupéen"
      }
    },
    flag: "🇬🇵",
    maps: {
      googleMaps: "https://goo.gl/maps/Dy9R2EufJtoWm8UN9",
      openStreetMaps: "https://www.openstreetmap.org/relation/7109289"
    },
    population: 400132,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gp.png",
      svg: "https://flagcdn.com/gp.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gp.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gp.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        16.03,
        -61.73
      ]
    },
    nativeName: [
      "Guadeloupe"
    ]
  },
  {
    name: {
      common: "British Indian Ocean Territory",
      official: "British Indian Ocean Territory",
      nativeName: {
        eng: {
          official: "British Indian Ocean Territory",
          common: "British Indian Ocean Territory"
        }
      }
    },
    tld: [
      ".io"
    ],
    cca2: "IO",
    ccn3: "086",
    cca3: "IOT",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "46"
      ]
    },
    capital: [
      "Diego Garcia"
    ],
    altSpellings: [
      "IO"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      -6,
      71.5
    ],
    landlocked: "Yes",
    area: 60,
    demonyms: {
      eng: {
        f: "Indian",
        m: "Indian"
      }
    },
    flag: "🇮🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/bheNucgekVEYozoi6",
      openStreetMaps: "https://www.openstreetmap.org/relation/1993867"
    },
    population: 3000,
    car: {
      signs: [
        "GB"
      ],
      side: "right"
    },
    timezones: [
      "UTC+06:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/io.png",
      svg: "https://flagcdn.com/io.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -7.3,
        72.4
      ]
    },
    nativeName: [
      "British Indian Ocean Territory"
    ]
  },
  {
    name: {
      common: "Equatorial Guinea",
      official: "Republic of Equatorial Guinea",
      nativeName: {
        fra: {
          official: "République de la Guinée Équatoriale",
          common: "Guinée équatoriale"
        },
        por: {
          official: "República da Guiné Equatorial",
          common: "Guiné Equatorial"
        },
        spa: {
          official: "República de Guinea Ecuatorial",
          common: "Guinea Ecuatorial"
        }
      }
    },
    tld: [
      ".gq"
    ],
    cca2: "GQ",
    ccn3: "226",
    cca3: "GNQ",
    cioc: "GEQ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Central African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "40"
      ]
    },
    capital: [
      "Malabo"
    ],
    altSpellings: [
      "GQ",
      "Republic of Equatorial Guinea",
      "República de Guinea Ecuatorial",
      "République de Guinée équatoriale",
      "República da Guiné Equatorial"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      fra: "French",
      por: "Portuguese",
      spa: "Spanish"
    },
    latlng: [
      2,
      10
    ],
    landlocked: "Yes",
    borders: [
      "CMR",
      "GAB"
    ],
    area: 28051,
    demonyms: {
      eng: {
        f: "Equatorial Guinean",
        m: "Equatorial Guinean"
      },
      fra: {
        f: "Équato-guinéenne",
        m: "Équato-guinéen"
      }
    },
    flag: "🇬🇶",
    maps: {
      googleMaps: "https://goo.gl/maps/ucWfFd8aW1FbGMva9",
      openStreetMaps: "https://www.openstreetmap.org/relation/192791"
    },
    population: 1402985,
    fifa: "EQG",
    car: {
      signs: [
        "GQ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gq.png",
      svg: "https://flagcdn.com/gq.svg",
      alt: "The flag of Equatorial Guinea is composed of three equal horizontal bands of green, white and red with the national coat of arms centered in the white band and an isosceles triangle superimposed on the hoist side of the field. The triangle is light blue, has its base on the hoist end and spans about one-fifth the width of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gq.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gq.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        3.75,
        8.78
      ]
    },
    nativeName: [
      "Equatorial Guinea",
      "Republic of Equatorial Guinea",
      "République de la Guinée Équatoriale",
      "Guinée équatoriale",
      "República da Guiné Equatorial",
      "Guiné Equatorial",
      "República de Guinea Ecuatorial",
      "Guinea Ecuatorial"
    ]
  },
  {
    name: {
      common: "Syria",
      official: "Syrian Arab Republic",
      nativeName: {
        ara: {
          official: "الجمهورية العربية السورية",
          common: "سوريا"
        }
      }
    },
    tld: [
      ".sy",
      "سوريا."
    ],
    cca2: "SY",
    ccn3: "760",
    cca3: "SYR",
    cioc: "SYR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Syrian pound £"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "63"
      ]
    },
    capital: [
      "Damascus"
    ],
    altSpellings: [
      "SY",
      "Syrian Arab Republic",
      "Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      35,
      38
    ],
    landlocked: "Yes",
    borders: [
      "IRQ",
      "ISR",
      "JOR",
      "LBN",
      "TUR"
    ],
    area: 185180,
    demonyms: {
      eng: {
        f: "Syrian",
        m: "Syrian"
      },
      fra: {
        f: "Syrienne",
        m: "Syrien"
      }
    },
    flag: "🇸🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/Xe3VnFbwdb4nv2SM9",
      openStreetMaps: "https://www.openstreetmap.org/relation/184840"
    },
    population: 17500657,
    gini: {
      2003: 37.5
    },
    fifa: "SYR",
    car: {
      signs: [
        "SYR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sy.png",
      svg: "https://flagcdn.com/sy.svg",
      alt: "The flag of Syria is composed of three equal horizontal bands of red, white and black. At the center of the white band are two small five-pointed green stars arranged in a horizontal line."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sy.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sy.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        33.5,
        36.3
      ]
    },
    nativeName: [
      "Syria",
      "Syrian Arab Republic",
      "الجمهورية العربية السورية",
      "سوريا"
    ]
  },
  {
    name: {
      common: "Angola",
      official: "Republic of Angola",
      nativeName: {
        por: {
          official: "República de Angola",
          common: "Angola"
        }
      }
    },
    tld: [
      ".ao"
    ],
    cca2: "AO",
    ccn3: "024",
    cca3: "AGO",
    cioc: "ANG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Angolan kwanza Kz"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "44"
      ]
    },
    capital: [
      "Luanda"
    ],
    altSpellings: [
      "AO",
      "República de Angola",
      "ʁɛpublika de an'ɡɔla"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      por: "Portuguese"
    },
    latlng: [
      -12.5,
      18.5
    ],
    landlocked: "Yes",
    borders: [
      "COG",
      "COD",
      "ZMB",
      "NAM"
    ],
    area: 1246700,
    demonyms: {
      eng: {
        f: "Angolan",
        m: "Angolan"
      },
      fra: {
        f: "Angolaise",
        m: "Angolais"
      }
    },
    flag: "🇦🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/q42Qbf1BmQL3fuZg9",
      openStreetMaps: "https://www.openstreetmap.org/relation/195267"
    },
    population: 32866268,
    gini: {
      2018: 51.3
    },
    fifa: "ANG",
    car: {
      signs: [
        "ANG"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ao.png",
      svg: "https://flagcdn.com/ao.svg",
      alt: "The flag of Angola features two equal horizontal bands of red and black, with a yellow emblem at its centre. This emblem consists of a five-pointed star within the hoist-side facing half of a cogwheel that is crossed on its lower end by a machete."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ao.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ao.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -8.83,
        13.22
      ]
    },
    nativeName: [
      "Angola",
      "Republic of Angola",
      "República de Angola"
    ]
  },
  {
    name: {
      common: "Tajikistan",
      official: "Republic of Tajikistan",
      nativeName: {
        rus: {
          official: "Республика Таджикистан",
          common: "Таджикистан"
        },
        tgk: {
          official: "Ҷумҳурии Тоҷикистон",
          common: "Тоҷикистон"
        }
      }
    },
    tld: [
      ".tj"
    ],
    cca2: "TJ",
    ccn3: "762",
    cca3: "TJK",
    cioc: "TJK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Tajikistani somoni ЅМ"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "92"
      ]
    },
    capital: [
      "Dushanbe"
    ],
    altSpellings: [
      "TJ",
      "Toçikiston",
      "Republic of Tajikistan",
      "Ҷумҳурии Тоҷикистон",
      "Çumhuriyi Toçikiston"
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: {
      rus: "Russian",
      tgk: "Tajik"
    },
    latlng: [
      39,
      71
    ],
    landlocked: "Yes",
    borders: [
      "AFG",
      "CHN",
      "KGZ",
      "UZB"
    ],
    area: 143100,
    demonyms: {
      eng: {
        f: "Tadzhik",
        m: "Tadzhik"
      },
      fra: {
        f: "Tadjike",
        m: "Tadjike"
      }
    },
    flag: "🇹🇯",
    maps: {
      googleMaps: "https://goo.gl/maps/8rQgW88jEXijhVb58",
      openStreetMaps: "https://www.openstreetmap.org/relation/214626"
    },
    population: 9537642,
    gini: {
      2015: 34
    },
    fifa: "TJK",
    car: {
      signs: [
        "TJ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tj.png",
      svg: "https://flagcdn.com/tj.svg",
      alt: "The flag of Tajikistan is composed of three horizontal bands of red, white and green in the ratio of 2:3:2. A golden-yellow crown surmounted by an arc of seven five-pointed golden-yellow stars is centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tj.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tj.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        38.55,
        68.77
      ]
    },
    nativeName: [
      "Tajikistan",
      "Republic of Tajikistan",
      "Республика Таджикистан",
      "Таджикистан",
      "Ҷумҳурии Тоҷикистон",
      "Тоҷикистон"
    ]
  },
  {
    name: {
      common: "Venezuela",
      official: "Bolivarian Republic of Venezuela",
      nativeName: {
        spa: {
          official: "República Bolivariana de Venezuela",
          common: "Venezuela"
        }
      }
    },
    tld: [
      ".ve"
    ],
    cca2: "VE",
    ccn3: "862",
    cca3: "VEN",
    cioc: "VEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Venezuelan bolívar soberano Bs.S."
    ],
    idd: {
      root: "+5",
      suffixes: [
        "8"
      ]
    },
    capital: [
      "Caracas"
    ],
    altSpellings: [
      "VE",
      "Bolivarian Republic of Venezuela",
      "Venezuela, Bolivarian Republic of",
      "República Bolivariana de Venezuela"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      8,
      -66
    ],
    landlocked: "Yes",
    borders: [
      "BRA",
      "COL",
      "GUY"
    ],
    area: 916445,
    demonyms: {
      eng: {
        f: "Venezuelan",
        m: "Venezuelan"
      },
      fra: {
        f: "Vénézuélienne",
        m: "Vénézuélien"
      }
    },
    flag: "🇻🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/KLCwDN8sec7z2kse9",
      openStreetMaps: "https://www.openstreetmap.org/relation/272644"
    },
    population: 28435943,
    gini: {
      2006: 44.8
    },
    fifa: "VEN",
    car: {
      signs: [
        "YV"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ve.png",
      svg: "https://flagcdn.com/ve.svg",
      alt: "The flag of Venezuela is composed of three equal horizontal bands of yellow, blue and red. At the center of the blue band are eight five-pointed white stars arranged in a horizontal arc."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ve.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ve.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        10.48,
        -66.87
      ]
    },
    nativeName: [
      "Venezuela",
      "Bolivarian Republic of Venezuela",
      "República Bolivariana de Venezuela"
    ]
  },
  {
    name: {
      common: "United States Minor Outlying Islands",
      official: "United States Minor Outlying Islands",
      nativeName: {
        eng: {
          official: "United States Minor Outlying Islands",
          common: "United States Minor Outlying Islands"
        }
      }
    },
    tld: [
      ".us"
    ],
    cca2: "UM",
    ccn3: "581",
    cca3: "UMI",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "68"
      ]
    },
    capital: [
      "Washington DC"
    ],
    altSpellings: [
      "UM"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      eng: "English"
    },
    latlng: [
      19.3,
      166.633333
    ],
    landlocked: "Yes",
    area: 34.2,
    demonyms: {
      eng: {
        f: "American Islander",
        m: "American Islander"
      }
    },
    flag: "🇺🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/hZKnrzgeK69dDyPF8",
      openStreetMaps: "https://www.openstreetmap.org/relation/6430384"
    },
    population: 300,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC-11:00",
      "UTC-10:00",
      "UTC+12:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/um.png",
      svg: "https://flagcdn.com/um.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {},
    nativeName: [
      "United States Minor Outlying Islands"
    ]
  },
  {
    name: {
      common: "Åland Islands",
      official: "Åland Islands",
      nativeName: {
        swe: {
          official: "Landskapet Åland",
          common: "Åland"
        }
      }
    },
    tld: [
      ".ax"
    ],
    cca2: "AX",
    ccn3: "248",
    cca3: "ALA",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "5818"
      ]
    },
    capital: [
      "Mariehamn"
    ],
    altSpellings: [
      "AX",
      "Aaland",
      "Aland",
      "Ahvenanmaa"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      swe: "Swedish"
    },
    latlng: [
      60.116667,
      19.9
    ],
    landlocked: "Yes",
    area: 1580,
    demonyms: {
      eng: {
        f: "Ålandish",
        m: "Ålandish"
      },
      fra: {
        f: "Ålandaise",
        m: "Ålandais"
      }
    },
    flag: "🇦🇽",
    maps: {
      googleMaps: "https://goo.gl/maps/ewFb3vYsfUmVCoSb8",
      openStreetMaps: "https://www.openstreetmap.org/relation/1650407"
    },
    population: 29458,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ax.png",
      svg: "https://flagcdn.com/ax.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ax.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ax.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        60.12,
        19.9
      ]
    },
    nativeName: [
      "Åland Islands",
      "Landskapet Åland",
      "Åland"
    ]
  },
  {
    name: {
      common: "Poland",
      official: "Republic of Poland",
      nativeName: {
        pol: {
          official: "Rzeczpospolita Polska",
          common: "Polska"
        }
      }
    },
    tld: [
      ".pl"
    ],
    cca2: "PL",
    ccn3: "616",
    cca3: "POL",
    cioc: "POL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Polish złoty zł"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "8"
      ]
    },
    capital: [
      "Warsaw"
    ],
    altSpellings: [
      "PL",
      "Republic of Poland",
      "Rzeczpospolita Polska"
    ],
    region: "Europe",
    subregion: "Central Europe",
    languages: {
      pol: "Polish"
    },
    latlng: [
      52,
      20
    ],
    landlocked: "Yes",
    borders: [
      "BLR",
      "CZE",
      "DEU",
      "LTU",
      "RUS",
      "SVK",
      "UKR"
    ],
    area: 312679,
    demonyms: {
      eng: {
        f: "Polish",
        m: "Polish"
      },
      fra: {
        f: "Polonaise",
        m: "Polonais"
      }
    },
    flag: "🇵🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/gY9Xw4Sf4415P4949",
      openStreetMaps: "https://www.openstreetmap.org/relation/49715"
    },
    population: 37950802,
    gini: {
      2018: 30.2
    },
    fifa: "POL",
    car: {
      signs: [
        "PL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pl.png",
      svg: "https://flagcdn.com/pl.svg",
      alt: "The flag of Poland is composed of two equal horizontal bands of white and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pl.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pl.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        52.25,
        21
      ]
    },
    nativeName: [
      "Poland",
      "Republic of Poland",
      "Rzeczpospolita Polska",
      "Polska"
    ]
  },
  {
    name: {
      common: "Saudi Arabia",
      official: "Kingdom of Saudi Arabia",
      nativeName: {
        ara: {
          official: "المملكة العربية السعودية",
          common: "العربية السعودية"
        }
      }
    },
    tld: [
      ".sa",
      ".السعودية"
    ],
    cca2: "SA",
    ccn3: "682",
    cca3: "SAU",
    cioc: "KSA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Saudi riyal ر.س"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "66"
      ]
    },
    capital: [
      "Riyadh"
    ],
    altSpellings: [
      "Saudi",
      "SA",
      "Kingdom of Saudi Arabia",
      "Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      25,
      45
    ],
    landlocked: "Yes",
    borders: [
      "IRQ",
      "JOR",
      "KWT",
      "OMN",
      "QAT",
      "ARE",
      "YEM"
    ],
    area: 2149690,
    demonyms: {
      eng: {
        f: "Saudi Arabian",
        m: "Saudi Arabian"
      },
      fra: {
        f: "Saoudienne",
        m: "Saoudien"
      }
    },
    flag: "🇸🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/5PSjvdJ1AyaLFRrG9",
      openStreetMaps: "https://www.openstreetmap.org/relation/307584"
    },
    population: 34813867,
    fifa: "KSA",
    car: {
      signs: [
        "SA"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sa.png",
      svg: "https://flagcdn.com/sa.svg",
      alt: "The flag of Saudi Arabia has a green field, at the center of which is an Arabic inscription — the Shahada — in white above a white horizontal sabre with its tip pointed to the hoist side of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sa.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sa.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        24.65,
        46.7
      ]
    },
    nativeName: [
      "Saudi Arabia",
      "Kingdom of Saudi Arabia",
      "المملكة العربية السعودية",
      "العربية السعودية"
    ]
  },
  {
    name: {
      common: "Republic of the Congo",
      official: "Republic of the Congo",
      nativeName: {
        fra: {
          official: "République du Congo",
          common: "République du Congo"
        },
        kon: {
          official: "Repubilika ya Kongo",
          common: "Repubilika ya Kongo"
        },
        lin: {
          official: "Republíki ya Kongó",
          common: "Republíki ya Kongó"
        }
      }
    },
    tld: [
      ".cg"
    ],
    cca2: "CG",
    ccn3: "178",
    cca3: "COG",
    cioc: "CGO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Central African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "42"
      ]
    },
    capital: [
      "Brazzaville"
    ],
    altSpellings: [
      "CG",
      "Congo",
      "Congo-Brazzaville"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      fra: "French",
      kon: "Kikongo",
      lin: "Lingala"
    },
    latlng: [
      -1,
      15
    ],
    landlocked: "Yes",
    borders: [
      "AGO",
      "CMR",
      "CAF",
      "COD",
      "GAB"
    ],
    area: 342000,
    demonyms: {
      eng: {
        f: "Congolese",
        m: "Congolese"
      },
      fra: {
        f: "Congolaise",
        m: "Congolais"
      }
    },
    flag: "🇨🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/Phf5dDDKdfCtuBTb6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192794"
    },
    population: 5657000,
    gini: {
      2011: 48.9
    },
    fifa: "CGO",
    car: {
      signs: [
        "RCB"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cg.png",
      svg: "https://flagcdn.com/cg.svg",
      alt: "The flag of the Republic of the Congo features a yellow diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a green and red triangle respectively."
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -4.25,
        15.28
      ]
    },
    nativeName: [
      "Republic of the Congo",
      "République du Congo",
      "Repubilika ya Kongo",
      "Republíki ya Kongó"
    ]
  },
  {
    name: {
      common: "Bosnia and Herzegovina",
      official: "Bosnia and Herzegovina",
      nativeName: {
        bos: {
          official: "Bosna i Hercegovina",
          common: "Bosna i Hercegovina"
        },
        hrv: {
          official: "Bosna i Hercegovina",
          common: "Bosna i Hercegovina"
        },
        srp: {
          official: "Босна и Херцеговина",
          common: "Босна и Херцеговина"
        }
      }
    },
    tld: [
      ".ba"
    ],
    cca2: "BA",
    ccn3: "070",
    cca3: "BIH",
    cioc: "BIH",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bosnia and Herzegovina convertible mark undefined"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "87"
      ]
    },
    capital: [
      "Sarajevo"
    ],
    altSpellings: [
      "BA",
      "Bosnia-Herzegovina",
      "Босна и Херцеговина"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      bos: "Bosnian",
      hrv: "Croatian",
      srp: "Serbian"
    },
    latlng: [
      44,
      18
    ],
    landlocked: "Yes",
    borders: [
      "HRV",
      "MNE",
      "SRB"
    ],
    area: 51209,
    demonyms: {
      eng: {
        f: "Bosnian, Herzegovinian",
        m: "Bosnian, Herzegovinian"
      },
      fra: {
        f: "Bosnienne",
        m: "Bosnien"
      }
    },
    flag: "🇧🇦",
    maps: {
      googleMaps: "https://www.google.com/maps/place/Bosnia+and+Herzegovina",
      openStreetMaps: "https://www.openstreetmap.org/relation/2528142"
    },
    population: 3280815,
    gini: {
      2011: 33
    },
    fifa: "BIH",
    car: {
      signs: [
        "BIH"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ba.png",
      svg: "https://flagcdn.com/ba.svg",
      alt: "The flag of Bosnia and Herzegovina has a blue field, at the center of which is a large yellow hoist-side facing right-angled triangle that is based on the top edge and spans the height of the field. Adjacent to the hypotenuse of this triangle are nine adjoining five-pointed white stars with the top and bottom stars cut in half by the edges of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ba.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ba.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        43.87,
        18.42
      ]
    },
    nativeName: [
      "Bosnia and Herzegovina",
      "Bosna i Hercegovina",
      "Босна и Херцеговина"
    ]
  },
  {
    name: {
      common: "United States Virgin Islands",
      official: "Virgin Islands of the United States",
      nativeName: {
        eng: {
          official: "Virgin Islands of the United States",
          common: "United States Virgin Islands"
        }
      }
    },
    tld: [
      ".vi"
    ],
    cca2: "VI",
    ccn3: "850",
    cca3: "VIR",
    cioc: "ISV",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "340"
      ]
    },
    capital: [
      "Charlotte Amalie"
    ],
    altSpellings: [
      "VI",
      "Virgin Islands, U.S."
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      18.35,
      -64.933333
    ],
    landlocked: "Yes",
    area: 347,
    demonyms: {
      eng: {
        f: "Virgin Islander",
        m: "Virgin Islander"
      }
    },
    flag: "🇻🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/mBfreywj8dor6q4m9",
      openStreetMaps: "openstreetmap.org/relation/286898"
    },
    population: 106290,
    fifa: "VIR",
    car: {
      signs: [
        "USA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/vi.png",
      svg: "https://flagcdn.com/vi.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.35,
        -64.93
      ]
    },
    nativeName: [
      "United States Virgin Islands",
      "Virgin Islands of the United States"
    ]
  },
  {
    name: {
      common: "New Caledonia",
      official: "New Caledonia",
      nativeName: {
        fra: {
          official: "Nouvelle-Calédonie",
          common: "Nouvelle-Calédonie"
        }
      }
    },
    tld: [
      ".nc"
    ],
    cca2: "NC",
    ccn3: "540",
    cca3: "NCL",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "CFP franc ₣"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "87"
      ]
    },
    capital: [
      "Nouméa"
    ],
    altSpellings: [
      "NC"
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: {
      fra: "French"
    },
    latlng: [
      -21.5,
      165.5
    ],
    landlocked: "Yes",
    area: 18575,
    demonyms: {
      eng: {
        f: "New Caledonian",
        m: "New Caledonian"
      },
      fra: {
        f: "Néo-Calédonienne",
        m: "Néo-Calédonien"
      }
    },
    flag: "🇳🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/cBhtCeMdob4U7FRU9",
      openStreetMaps: "https://www.openstreetmap.org/relation/3407643"
    },
    population: 271960,
    fifa: "NCL",
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC+11:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/nc.png",
      svg: "https://flagcdn.com/nc.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/nc.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/nc.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -22.27,
        166.45
      ]
    },
    nativeName: [
      "New Caledonia",
      "Nouvelle-Calédonie"
    ]
  },
  {
    name: {
      common: "San Marino",
      official: "Republic of San Marino",
      nativeName: {
        ita: {
          official: "Repubblica di San Marino",
          common: "San Marino"
        }
      }
    },
    tld: [
      ".sm"
    ],
    cca2: "SM",
    ccn3: "674",
    cca3: "SMR",
    cioc: "SMR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "78"
      ]
    },
    capital: [
      "City of San Marino"
    ],
    altSpellings: [
      "SM",
      "Republic of San Marino",
      "Repubblica di San Marino"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      ita: "Italian"
    },
    latlng: [
      43.76666666,
      12.41666666
    ],
    landlocked: "Yes",
    borders: [
      "ITA"
    ],
    area: 61,
    demonyms: {
      eng: {
        f: "Sammarinese",
        m: "Sammarinese"
      },
      fra: {
        f: "Saint-Marinaise",
        m: "Saint-Marinais"
      }
    },
    flag: "🇸🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/rxCVJjm8dVY93RPY8",
      openStreetMaps: "https://www.openstreetmap.org/relation/54624"
    },
    population: 33938,
    fifa: "SMR",
    car: {
      signs: [
        "RSM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sm.png",
      svg: "https://flagcdn.com/sm.svg",
      alt: "The flag of San Marino is composed of two equal horizontal bands of white and light blue, with the national coat of arms superimposed in the center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        43.94,
        12.45
      ]
    },
    nativeName: [
      "San Marino",
      "Republic of San Marino",
      "Repubblica di San Marino"
    ]
  },
  {
    name: {
      common: "Czechia",
      official: "Czech Republic",
      nativeName: {
        ces: {
          official: "Česká republika",
          common: "Česko"
        },
        slk: {
          official: "Česká republika",
          common: "Česko"
        }
      }
    },
    tld: [
      ".cz"
    ],
    cca2: "CZ",
    ccn3: "203",
    cca3: "CZE",
    cioc: "CZE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Czech koruna Kč"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "20"
      ]
    },
    capital: [
      "Prague"
    ],
    altSpellings: [
      "CZ",
      "Česká republika",
      "Česko"
    ],
    region: "Europe",
    subregion: "Central Europe",
    languages: {
      ces: "Czech",
      slk: "Slovak"
    },
    latlng: [
      49.75,
      15.5
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "DEU",
      "POL",
      "SVK"
    ],
    area: 78865,
    demonyms: {
      eng: {
        f: "Czech",
        m: "Czech"
      },
      fra: {
        f: "Tchèque",
        m: "Tchèque"
      }
    },
    flag: "🇨🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/47dmgeXMZyhDHyQW8",
      openStreetMaps: "https://www.openstreetmap.org/relation/51684"
    },
    population: 10698896,
    gini: {
      2018: 25
    },
    fifa: "CZE",
    car: {
      signs: [
        "CZ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cz.png",
      svg: "https://flagcdn.com/cz.svg",
      alt: "The flag of Czechia is composed of two equal horizontal bands of white and red, with a blue isosceles triangle superimposed on the hoist side of the field. The triangle has its base on the hoist end and spans about two-fifth the width of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        50.08,
        14.47
      ]
    },
    nativeName: [
      "Czechia",
      "Czech Republic",
      "Česká republika",
      "Česko"
    ]
  },
  {
    name: {
      common: "Guatemala",
      official: "Republic of Guatemala",
      nativeName: {
        spa: {
          official: "República de Guatemala",
          common: "Guatemala"
        }
      }
    },
    tld: [
      ".gt"
    ],
    cca2: "GT",
    ccn3: "320",
    cca3: "GTM",
    cioc: "GUA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Guatemalan quetzal Q"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "02"
      ]
    },
    capital: [
      "Guatemala City"
    ],
    altSpellings: [
      "GT"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      15.5,
      -90.25
    ],
    landlocked: "Yes",
    borders: [
      "BLZ",
      "SLV",
      "HND",
      "MEX"
    ],
    area: 108889,
    demonyms: {
      eng: {
        f: "Guatemalan",
        m: "Guatemalan"
      },
      fra: {
        f: "Guatémaltèque",
        m: "Guatémaltèque"
      }
    },
    flag: "🇬🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/JoRAbem4Hxb9FYbVA",
      openStreetMaps: "https://www.openstreetmap.org/relation/1521463"
    },
    population: 16858333,
    gini: {
      2014: 48.3
    },
    fifa: "GUA",
    car: {
      signs: [
        "GCA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gt.png",
      svg: "https://flagcdn.com/gt.svg",
      alt: "The flag of Guatemala is composed of three equal vertical bands of light blue, white and light blue, with the national coat of arms centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gt.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gt.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14.62,
        -90.52
      ]
    },
    nativeName: [
      "Guatemala",
      "Republic of Guatemala",
      "República de Guatemala"
    ]
  },
  {
    name: {
      common: "South Korea",
      official: "Republic of Korea",
      nativeName: {
        kor: {
          official: "대한민국",
          common: "한국"
        }
      }
    },
    tld: [
      ".kr",
      ".한국"
    ],
    cca2: "KR",
    ccn3: "410",
    cca3: "KOR",
    cioc: "KOR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "South Korean won ₩"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "2"
      ]
    },
    capital: [
      "Seoul"
    ],
    altSpellings: [
      "KR",
      "Korea, Republic of",
      "Republic of Korea",
      "남한",
      "남조선"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      kor: "Korean"
    },
    latlng: [
      37,
      127.5
    ],
    landlocked: "Yes",
    borders: [
      "PRK"
    ],
    area: 100210,
    demonyms: {
      eng: {
        f: "South Korean",
        m: "South Korean"
      },
      fra: {
        f: "Sud-coréenne",
        m: "Sud-coréen"
      }
    },
    flag: "🇰🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/7ecjaJXefjAQhxjGA",
      openStreetMaps: "https://www.openstreetmap.org/relation/307756"
    },
    population: 51780579,
    gini: {
      2016: 31.4
    },
    fifa: "KOR",
    car: {
      signs: [
        "ROK"
      ],
      side: "right"
    },
    timezones: [
      "UTC+09:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kr.png",
      svg: "https://flagcdn.com/kr.svg",
      alt: "The flag of South Korea has a white field, at the center of which is a red and blue Taegeuk circle surrounded by four black trigrams, one in each corner."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        37.55,
        126.98
      ]
    },
    nativeName: [
      "South Korea",
      "Republic of Korea",
      "대한민국",
      "한국"
    ]
  },
  {
    name: {
      common: "Estonia",
      official: "Republic of Estonia",
      nativeName: {
        est: {
          official: "Eesti Vabariik",
          common: "Eesti"
        }
      }
    },
    tld: [
      ".ee"
    ],
    cca2: "EE",
    ccn3: "233",
    cca3: "EST",
    cioc: "EST",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "72"
      ]
    },
    capital: [
      "Tallinn"
    ],
    altSpellings: [
      "EE",
      "Eesti",
      "Republic of Estonia",
      "Eesti Vabariik"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      est: "Estonian"
    },
    latlng: [
      59,
      26
    ],
    landlocked: "Yes",
    borders: [
      "LVA",
      "RUS"
    ],
    area: 45227,
    demonyms: {
      eng: {
        f: "Estonian",
        m: "Estonian"
      },
      fra: {
        f: "Estonienne",
        m: "Estonien"
      }
    },
    flag: "🇪🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/6SsynwGUodL1sDvq8",
      openStreetMaps: "https://www.openstreetmap.org/relation/79510"
    },
    population: 1331057,
    gini: {
      2018: 30.3
    },
    fifa: "EST",
    car: {
      signs: [
        "EST"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ee.png",
      svg: "https://flagcdn.com/ee.svg",
      alt: "The flag of Estonia is composed of three equal horizontal bands of blue, black and white."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ee.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ee.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        59.43,
        24.72
      ]
    },
    nativeName: [
      "Estonia",
      "Republic of Estonia",
      "Eesti Vabariik",
      "Eesti"
    ]
  },
  {
    name: {
      common: "Nepal",
      official: "Federal Democratic Republic of Nepal",
      nativeName: {
        nep: {
          official: "नेपाल संघीय लोकतान्त्रिक गणतन्त्र",
          common: "नेपाल"
        }
      }
    },
    tld: [
      ".np"
    ],
    cca2: "NP",
    ccn3: "524",
    cca3: "NPL",
    cioc: "NEP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Nepalese rupee ₨"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "77"
      ]
    },
    capital: [
      "Kathmandu"
    ],
    altSpellings: [
      "NP",
      "Federal Democratic Republic of Nepal",
      "Loktāntrik Ganatantra Nepāl"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      nep: "Nepali"
    },
    latlng: [
      28,
      84
    ],
    landlocked: "Yes",
    borders: [
      "CHN",
      "IND"
    ],
    area: 147181,
    demonyms: {
      eng: {
        f: "Nepalese",
        m: "Nepalese"
      },
      fra: {
        f: "Népalaise",
        m: "Népalais"
      }
    },
    flag: "🇳🇵",
    maps: {
      googleMaps: "https://goo.gl/maps/UMj2zpbQp7B5c3yT7",
      openStreetMaps: "https://www.openstreetmap.org/relation/184633"
    },
    population: 29136808,
    gini: {
      2010: 32.8
    },
    fifa: "NEP",
    car: {
      signs: [
        "NEP"
      ],
      side: "left"
    },
    timezones: [
      "UTC+05:45"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/np.png",
      svg: "https://flagcdn.com/np.svg",
      alt: "The flag of Nepal is the world's only non-quadrilateral flag of a sovereign country. It takes the shape of two adjoining right-angled triangles and has a crimson red field with deep blue edges. Within the smaller upper triangle is an emblem of the upper half of a white sun resting on an upward facing white crescent. The lower triangle bears a white sun with twelve rays."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/np.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/np.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        27.72,
        85.32
      ]
    },
    nativeName: [
      "Nepal",
      "Federal Democratic Republic of Nepal",
      "नेपाल संघीय लोकतान्त्रिक गणतन्त्र",
      "नेपाल"
    ]
  },
  {
    name: {
      common: "Kosovo",
      official: "Republic of Kosovo",
      nativeName: {
        sqi: {
          official: "Republika e Kosovës",
          common: "Kosova"
        },
        srp: {
          official: "Република Косово",
          common: "Косово"
        }
      }
    },
    cca2: "XK",
    cca3: "UNK",
    cioc: "KOS",
    status: "user-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "83"
      ]
    },
    capital: [
      "Pristina"
    ],
    altSpellings: [
      "XK",
      "Република Косово"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      sqi: "Albanian",
      srp: "Serbian"
    },
    latlng: [
      42.666667,
      21.166667
    ],
    landlocked: "Yes",
    borders: [
      "ALB",
      "MKD",
      "MNE",
      "SRB"
    ],
    area: 10908,
    demonyms: {
      eng: {
        f: "Kosovar",
        m: "Kosovar"
      },
      fra: {
        f: "Kosovare",
        m: "Kosovar"
      }
    },
    flag: "🇽🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/CSC4Yc8SWPgburuD9",
      openStreetMaps: "https://www.openstreetmap.org/relation/2088990"
    },
    population: 1775378,
    gini: {
      2017: 29
    },
    fifa: "KVX",
    car: {
      signs: [
        "CS"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/xk.png",
      svg: "https://flagcdn.com/xk.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/xk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/xk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        42.67,
        21.17
      ]
    },
    nativeName: [
      "Kosovo",
      "Republic of Kosovo",
      "Republika e Kosovës",
      "Kosova",
      "Република Косово",
      "Косово"
    ]
  },
  {
    name: {
      common: "Botswana",
      official: "Republic of Botswana",
      nativeName: {
        eng: {
          official: "Republic of Botswana",
          common: "Botswana"
        },
        tsn: {
          official: "Lefatshe la Botswana",
          common: "Botswana"
        }
      }
    },
    tld: [
      ".bw"
    ],
    cca2: "BW",
    ccn3: "072",
    cca3: "BWA",
    cioc: "BOT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Botswana pula P"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "67"
      ]
    },
    capital: [
      "Gaborone"
    ],
    altSpellings: [
      "BW",
      "Republic of Botswana",
      "Lefatshe la Botswana"
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      eng: "English",
      tsn: "Tswana"
    },
    latlng: [
      -22,
      24
    ],
    landlocked: "Yes",
    borders: [
      "NAM",
      "ZAF",
      "ZMB",
      "ZWE"
    ],
    area: 582000,
    demonyms: {
      eng: {
        f: "Motswana",
        m: "Motswana"
      },
      fra: {
        f: "Botswanaise",
        m: "Botswanais"
      }
    },
    flag: "🇧🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/E364KeLy6N4JwxwQ8",
      openStreetMaps: "https://www.openstreetmap.org/relation/1889339"
    },
    population: 2351625,
    gini: {
      2015: 53.3
    },
    fifa: "BOT",
    car: {
      signs: [
        "BW"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bw.png",
      svg: "https://flagcdn.com/bw.svg",
      alt: "The flag of Botswana has a light blue field with a white-edged black horizontal band across its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -24.63,
        25.9
      ]
    },
    nativeName: [
      "Botswana",
      "Republic of Botswana",
      "Lefatshe la Botswana"
    ]
  },
  {
    name: {
      common: "Philippines",
      official: "Republic of the Philippines",
      nativeName: {
        eng: {
          official: "Republic of the Philippines",
          common: "Philippines"
        },
        fil: {
          official: "Republic of the Philippines",
          common: "Pilipinas"
        }
      }
    },
    tld: [
      ".ph"
    ],
    cca2: "PH",
    ccn3: "608",
    cca3: "PHL",
    cioc: "PHI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Philippine peso ₱"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "3"
      ]
    },
    capital: [
      "Manila"
    ],
    altSpellings: [
      "PH",
      "Republic of the Philippines",
      "Repúblika ng Pilipinas"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      eng: "English",
      fil: "Filipino"
    },
    latlng: [
      13,
      122
    ],
    landlocked: "Yes",
    area: 342353,
    demonyms: {
      eng: {
        f: "Filipino",
        m: "Filipino"
      },
      fra: {
        f: "Philippine",
        m: "Philippin"
      }
    },
    flag: "🇵🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/k8T2fb5VMUfsWFX6A",
      openStreetMaps: "https://www.openstreetmap.org/relation/443174"
    },
    population: 109581085,
    gini: {
      2018: 42.3
    },
    fifa: "PHI",
    car: {
      signs: [
        "RP"
      ],
      side: "right"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ph.png",
      svg: "https://flagcdn.com/ph.svg",
      alt: "The flag of Philippines is composed of two equal horizontal bands of blue and red, with a white equilateral triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a central golden-yellow sun with eight rays and a five-pointed golden-yellow star at each vertex."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ph.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ph.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14.6,
        120.97
      ]
    },
    nativeName: [
      "Philippines",
      "Republic of the Philippines",
      "Pilipinas"
    ]
  },
  {
    name: {
      common: "Saint Pierre and Miquelon",
      official: "Saint Pierre and Miquelon",
      nativeName: {
        fra: {
          official: "Collectivité territoriale de Saint-Pierre-et-Miquelon",
          common: "Saint-Pierre-et-Miquelon"
        }
      }
    },
    tld: [
      ".pm"
    ],
    cca2: "PM",
    ccn3: "666",
    cca3: "SPM",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "08"
      ]
    },
    capital: [
      "Saint-Pierre"
    ],
    altSpellings: [
      "PM",
      "Collectivité territoriale de Saint-Pierre-et-Miquelon"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      fra: "French"
    },
    latlng: [
      46.83333333,
      -56.33333333
    ],
    landlocked: "Yes",
    area: 242,
    demonyms: {
      eng: {
        f: "Saint-Pierrais, Miquelonnais",
        m: "Saint-Pierrais, Miquelonnais"
      },
      fra: {
        f: "Saint-Pierraise, Miquelonaise",
        m: "Saint-Pierrais, Miquelonais"
      }
    },
    flag: "🇵🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/bUM8Yc8pA8ghyhmt6",
      openStreetMaps: "https://www.openstreetmap.org/relation/3406826"
    },
    population: 6069,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-03:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pm.png",
      svg: "https://flagcdn.com/pm.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        46.77,
        -56.18
      ]
    },
    nativeName: [
      "Saint Pierre and Miquelon",
      "Collectivité territoriale de Saint-Pierre-et-Miquelon",
      "Saint-Pierre-et-Miquelon"
    ]
  },
  {
    name: {
      common: "Iraq",
      official: "Republic of Iraq",
      nativeName: {
        ara: {
          official: "جمهورية العراق",
          common: "العراق"
        },
        arc: {
          official: "ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ",
          common: "ܩܘܼܛܢܵܐ"
        },
        ckb: {
          official: "کۆماری عێراق",
          common: "کۆماری"
        }
      }
    },
    tld: [
      ".iq"
    ],
    cca2: "IQ",
    ccn3: "368",
    cca3: "IRQ",
    cioc: "IRQ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Iraqi dinar ع.د"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "64"
      ]
    },
    capital: [
      "Baghdad"
    ],
    altSpellings: [
      "IQ",
      "Republic of Iraq",
      "Jumhūriyyat al-‘Irāq"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic",
      arc: "Aramaic",
      ckb: "Sorani"
    },
    latlng: [
      33,
      44
    ],
    landlocked: "Yes",
    borders: [
      "IRN",
      "JOR",
      "KWT",
      "SAU",
      "SYR",
      "TUR"
    ],
    area: 438317,
    demonyms: {
      eng: {
        f: "Iraqi",
        m: "Iraqi"
      },
      fra: {
        f: "Irakienne",
        m: "Irakien"
      }
    },
    flag: "🇮🇶",
    maps: {
      googleMaps: "https://goo.gl/maps/iL8Bmy1sUCW9fUk18",
      openStreetMaps: "https://www.openstreetmap.org/relation/304934"
    },
    population: 40222503,
    gini: {
      2012: 29.5
    },
    fifa: "IRQ",
    car: {
      signs: [
        "IRQ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/iq.png",
      svg: "https://flagcdn.com/iq.svg",
      alt: "The flag of Iraq is composed of three equal horizontal bands of red, white and black. In the central white band are Arabic inscriptions in green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/iq.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/iq.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        33.33,
        44.4
      ]
    },
    nativeName: [
      "Iraq",
      "Republic of Iraq",
      "جمهورية العراق",
      "العراق",
      "ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ",
      "ܩܘܼܛܢܵܐ",
      "کۆماری عێراق",
      "کۆماری"
    ]
  },
  {
    name: {
      common: "Lebanon",
      official: "Lebanese Republic",
      nativeName: {
        ara: {
          official: "الجمهورية اللبنانية",
          common: "لبنان"
        },
        fra: {
          official: "République libanaise",
          common: "Liban"
        }
      }
    },
    tld: [
      ".lb"
    ],
    cca2: "LB",
    ccn3: "422",
    cca3: "LBN",
    cioc: "LBN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Lebanese pound ل.ل"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "61"
      ]
    },
    capital: [
      "Beirut"
    ],
    altSpellings: [
      "LB",
      "Lebanese Republic",
      "Al-Jumhūrīyah Al-Libnānīyah"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic",
      fra: "French"
    },
    latlng: [
      33.83333333,
      35.83333333
    ],
    landlocked: "Yes",
    borders: [
      "ISR",
      "SYR"
    ],
    area: 10452,
    demonyms: {
      eng: {
        f: "Lebanese",
        m: "Lebanese"
      },
      fra: {
        f: "Libanaise",
        m: "Libanais"
      }
    },
    flag: "🇱🇧",
    maps: {
      googleMaps: "https://goo.gl/maps/Sz5VCU8UFBqMyTdc9",
      openStreetMaps: "https://www.openstreetmap.org/relation/184843"
    },
    population: 6825442,
    gini: {
      2011: 31.8
    },
    fifa: "LBN",
    car: {
      signs: [
        "RL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lb.png",
      svg: "https://flagcdn.com/lb.svg",
      alt: "The flag of Lebanon is composed of three horizontal bands of red, white and red. The white band is twice the height of the red bands and bears a green Lebanese Cedar tree at its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lb.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lb.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        33.87,
        35.5
      ]
    },
    nativeName: [
      "Lebanon",
      "Lebanese Republic",
      "الجمهورية اللبنانية",
      "لبنان",
      "République libanaise",
      "Liban"
    ]
  },
  {
    name: {
      common: "Burundi",
      official: "Republic of Burundi",
      nativeName: {
        fra: {
          official: "République du Burundi",
          common: "Burundi"
        },
        run: {
          official: "Republika y'Uburundi ",
          common: "Uburundi"
        }
      }
    },
    tld: [
      ".bi"
    ],
    cca2: "BI",
    ccn3: "108",
    cca3: "BDI",
    cioc: "BDI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Burundian franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "57"
      ]
    },
    capital: [
      "Gitega"
    ],
    altSpellings: [
      "BI",
      "Republic of Burundi",
      "Republika y'Uburundi",
      "République du Burundi"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      fra: "French",
      run: "Kirundi"
    },
    latlng: [
      -3.5,
      30
    ],
    landlocked: "Yes",
    borders: [
      "COD",
      "RWA",
      "TZA"
    ],
    area: 27834,
    demonyms: {
      eng: {
        f: "Burundian",
        m: "Burundian"
      },
      fra: {
        f: "Burundaise",
        m: "Burundais"
      }
    },
    flag: "🇧🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/RXPWoRrB9tfrJpUG7",
      openStreetMaps: "https://www.openstreetmap.org/relation/195269"
    },
    population: 11890781,
    gini: {
      2013: 38.6
    },
    fifa: "BDI",
    car: {
      signs: [
        "RU"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bi.png",
      svg: "https://flagcdn.com/bi.svg",
      alt: "The flag of Burundi is divided by a white diagonal cross into four alternating triangular areas of red at the top and bottom, and green on the hoist and fly sides. A white circle, with three green-edged red six-pointed stars arranged to form a triangle, is superimposed at the center of the cross."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bi.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bi.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -3.43,
        29.93
      ]
    },
    nativeName: [
      "Burundi",
      "Republic of Burundi",
      "République du Burundi",
      "Republika y'Uburundi ",
      "Uburundi"
    ]
  },
  {
    name: {
      common: "Mongolia",
      official: "Mongolia",
      nativeName: {
        mon: {
          official: "Монгол улс",
          common: "Монгол улс"
        }
      }
    },
    tld: [
      ".mn"
    ],
    cca2: "MN",
    ccn3: "496",
    cca3: "MNG",
    cioc: "MGL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Mongolian tögrög ₮"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "76"
      ]
    },
    capital: [
      "Ulan Bator"
    ],
    altSpellings: [
      "MN"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      mon: "Mongolian"
    },
    latlng: [
      46,
      105
    ],
    landlocked: "Yes",
    borders: [
      "CHN",
      "RUS"
    ],
    area: 1564110,
    demonyms: {
      eng: {
        f: "Mongolian",
        m: "Mongolian"
      },
      fra: {
        f: "Mongole",
        m: "Mongol"
      }
    },
    flag: "🇲🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/A1X7bMCKThBDNjzH6",
      openStreetMaps: "https://www.openstreetmap.org/relation/161033"
    },
    population: 3278292,
    gini: {
      2018: 32.7
    },
    fifa: "MNG",
    car: {
      signs: [
        "MGL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+07:00",
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mn.png",
      svg: "https://flagcdn.com/mn.svg",
      alt: "The flag of Mongolia is composed of three equal vertical bands of red, blue and red, with the national emblem — the Soyombo — in gold centered in the hoist-side red band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        47.92,
        106.91
      ]
    },
    nativeName: [
      "Mongolia",
      "Монгол улс"
    ]
  },
  {
    name: {
      common: "Faroe Islands",
      official: "Faroe Islands",
      nativeName: {
        dan: {
          official: "Færøerne",
          common: "Færøerne"
        },
        fao: {
          official: "Føroyar",
          common: "Føroyar"
        }
      }
    },
    tld: [
      ".fo"
    ],
    cca2: "FO",
    ccn3: "234",
    cca3: "FRO",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Danish krone kr",
      "Faroese króna kr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "98"
      ]
    },
    capital: [
      "Tórshavn"
    ],
    altSpellings: [
      "FO",
      "Føroyar",
      "Færøerne"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      dan: "Danish",
      fao: "Faroese"
    },
    latlng: [
      62,
      -7
    ],
    landlocked: "Yes",
    area: 1393,
    demonyms: {
      eng: {
        f: "Faroese",
        m: "Faroese"
      },
      fra: {
        f: "Féroïenne",
        m: "Féroïen"
      }
    },
    flag: "🇫🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/6sTru4SmHdEVcNkM6",
      openStreetMaps: "https://www.openstreetmap.org/relation/52939"
    },
    population: 48865,
    fifa: "FRO",
    car: {
      signs: [
        "FO"
      ],
      side: "right"
    },
    timezones: [
      "UTC+00:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/fo.png",
      svg: "https://flagcdn.com/fo.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/fo.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/fo.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        62.01,
        -6.77
      ]
    },
    nativeName: [
      "Faroe Islands",
      "Færøerne",
      "Føroyar"
    ]
  },
  {
    name: {
      common: "Paraguay",
      official: "Republic of Paraguay",
      nativeName: {
        grn: {
          official: "Tetã Paraguái",
          common: "Paraguái"
        },
        spa: {
          official: "República de Paraguay",
          common: "Paraguay"
        }
      }
    },
    tld: [
      ".py"
    ],
    cca2: "PY",
    ccn3: "600",
    cca3: "PRY",
    cioc: "PAR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Paraguayan guaraní ₲"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "95"
      ]
    },
    capital: [
      "Asunción"
    ],
    altSpellings: [
      "PY",
      "Republic of Paraguay",
      "República del Paraguay",
      "Tetã Paraguái"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      grn: "Guaraní",
      spa: "Spanish"
    },
    latlng: [
      -23,
      -58
    ],
    landlocked: "Yes",
    borders: [
      "ARG",
      "BOL",
      "BRA"
    ],
    area: 406752,
    demonyms: {
      eng: {
        f: "Paraguayan",
        m: "Paraguayan"
      },
      fra: {
        f: "Paraguayenne",
        m: "Paraguayen"
      }
    },
    flag: "🇵🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/JtnqG73WJn1Gx6mz6",
      openStreetMaps: "https://www.openstreetmap.org/relation/287077"
    },
    population: 7132530,
    gini: {
      2019: 45.7
    },
    fifa: "PAR",
    car: {
      signs: [
        "PY"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/py.png",
      svg: "https://flagcdn.com/py.svg",
      alt: "The flag of Paraguay features three equal horizontal bands of red, white and blue, with an emblem centered in the white band. On the obverse side of the flag depicted, this emblem is the national coat of arms."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/py.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/py.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -25.28,
        -57.57
      ]
    },
    nativeName: [
      "Paraguay",
      "Republic of Paraguay",
      "Tetã Paraguái",
      "Paraguái",
      "República de Paraguay"
    ]
  },
  {
    name: {
      common: "Svalbard and Jan Mayen",
      official: "Svalbard og Jan Mayen",
      nativeName: {
        nor: {
          official: "Svalbard og Jan Mayen",
          common: "Svalbard og Jan Mayen"
        }
      }
    },
    tld: [
      ".sj"
    ],
    cca2: "SJ",
    ccn3: "744",
    cca3: "SJM",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "krone kr"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "779"
      ]
    },
    capital: [
      "Longyearbyen"
    ],
    altSpellings: [
      "SJ",
      "Svalbard and Jan Mayen Islands"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      nor: "Norwegian"
    },
    latlng: [
      78,
      20
    ],
    landlocked: "Yes",
    area: -1,
    demonyms: {
      eng: {
        f: "Norwegian",
        m: "Norwegian"
      }
    },
    flag: "🇸🇯",
    maps: {
      googleMaps: "https://goo.gl/maps/L2wyyn3cQ16PzQ5J8",
      openStreetMaps: "https://www.openstreetmap.org/relation/1337397"
    },
    population: 2562,
    car: {
      signs: [
        "N"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sj.png",
      svg: "https://flagcdn.com/sj.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        78.22,
        15.63
      ]
    },
    nativeName: [
      "Svalbard and Jan Mayen",
      "Svalbard og Jan Mayen"
    ]
  },
  {
    name: {
      common: "Tokelau",
      official: "Tokelau",
      nativeName: {
        eng: {
          official: "Tokelau",
          common: "Tokelau"
        },
        smo: {
          official: "Tokelau",
          common: "Tokelau"
        },
        tkl: {
          official: "Tokelau",
          common: "Tokelau"
        }
      }
    },
    tld: [
      ".tk"
    ],
    cca2: "TK",
    ccn3: "772",
    cca3: "TKL",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "New Zealand dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "90"
      ]
    },
    capital: [
      "Fakaofo"
    ],
    altSpellings: [
      "TK"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      smo: "Samoan",
      tkl: "Tokelauan"
    },
    latlng: [
      -9,
      -172
    ],
    landlocked: "Yes",
    area: 12,
    demonyms: {
      eng: {
        f: "Tokelauan",
        m: "Tokelauan"
      }
    },
    flag: "🇹🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/Ap5qN8qien6pT9UN6",
      openStreetMaps: "https://www.openstreetmap.org/relation/2186600"
    },
    population: 1411,
    car: {
      signs: [
        ""
      ],
      side: "left"
    },
    timezones: [
      "UTC+13:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tk.png",
      svg: "https://flagcdn.com/tk.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -9.38,
        -171.22
      ]
    },
    nativeName: [
      "Tokelau"
    ]
  },
  {
    name: {
      common: "Guernsey",
      official: "Bailiwick of Guernsey",
      nativeName: {
        eng: {
          official: "Bailiwick of Guernsey",
          common: "Guernsey"
        },
        fra: {
          official: "Bailliage de Guernesey",
          common: "Guernesey"
        },
        nfr: {
          official: "Dgèrnésiais",
          common: "Dgèrnésiais"
        }
      }
    },
    tld: [
      ".gg"
    ],
    cca2: "GG",
    ccn3: "831",
    cca3: "GGY",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "British pound £",
      "Guernsey pound £"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "St. Peter Port"
    ],
    altSpellings: [
      "GG",
      "Bailiwick of Guernsey",
      "Bailliage de Guernesey"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      eng: "English",
      fra: "French",
      nfr: "Guernésiais"
    },
    latlng: [
      49.46666666,
      -2.58333333
    ],
    landlocked: "Yes",
    area: 78,
    demonyms: {
      eng: {
        f: "Channel Islander",
        m: "Channel Islander"
      },
      fra: {
        f: "Guernesiaise",
        m: "Guernesiais"
      }
    },
    flag: "🇬🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/6kXnQU5QvEZMD9VB7",
      openStreetMaps: "https://www.openstreetmap.org/relation/270009"
    },
    population: 62999,
    car: {
      signs: [
        "GBG"
      ],
      side: "left"
    },
    timezones: [
      "UTC+00:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gg.png",
      svg: "https://flagcdn.com/gg.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        49.45,
        -2.54
      ]
    },
    nativeName: [
      "Guernsey",
      "Bailiwick of Guernsey",
      "Bailliage de Guernesey",
      "Guernesey",
      "Dgèrnésiais"
    ]
  },
  {
    name: {
      common: "Caribbean Netherlands",
      official: "Bonaire, Sint Eustatius and Saba",
      nativeName: {
        nld: {
          official: "Bonaire, Sint Eustatius en Saba",
          common: "Caribisch Nederland"
        },
        pap: {
          official: "Boneiru, Sint Eustatius y Saba",
          common: "Boneiru, Sint Eustatius y Saba"
        }
      }
    },
    tld: [
      ".bq",
      ".nl"
    ],
    cca2: "BQ",
    ccn3: "535",
    cca3: "BES",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "99"
      ]
    },
    capital: [
      "Kralendijk"
    ],
    altSpellings: [
      "BES islands"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English",
      nld: "Dutch",
      pap: "Papiamento"
    },
    latlng: [
      12.18,
      -68.25
    ],
    landlocked: "Yes",
    area: 328,
    demonyms: {
      eng: {
        f: "Dutch",
        m: "Dutch"
      },
      fra: {
        f: "Néerlandaise",
        m: "Néerlandais"
      }
    },
    flag: "🇧🇶",
    maps: {
      googleMaps: "https://goo.gl/maps/4XVes1P6uEDTz77WA",
      openStreetMaps: "https://www.openstreetmap.org/relation/1216720"
    },
    population: 25987,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bq.png",
      svg: "https://flagcdn.com/bq.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bq.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bq.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.14,
        -68.27
      ]
    },
    nativeName: [
      "Caribbean Netherlands",
      "Bonaire, Sint Eustatius and Saba",
      "Bonaire, Sint Eustatius en Saba",
      "Caribisch Nederland",
      "Boneiru, Sint Eustatius y Saba"
    ]
  },
  {
    name: {
      common: "Algeria",
      official: "People's Democratic Republic of Algeria",
      nativeName: {
        ara: {
          official: "الجمهورية الديمقراطية الشعبية الجزائرية",
          common: "الجزائر"
        }
      }
    },
    tld: [
      ".dz",
      "الجزائر."
    ],
    cca2: "DZ",
    ccn3: "012",
    cca3: "DZA",
    cioc: "ALG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Algerian dinar د.ج"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "13"
      ]
    },
    capital: [
      "Algiers"
    ],
    altSpellings: [
      "DZ",
      "Dzayer",
      "Algérie"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      28,
      3
    ],
    landlocked: "Yes",
    borders: [
      "TUN",
      "LBY",
      "NER",
      "ESH",
      "MRT",
      "MLI",
      "MAR"
    ],
    area: 2381741,
    demonyms: {
      eng: {
        f: "Algerian",
        m: "Algerian"
      },
      fra: {
        f: "Algérienne",
        m: "Algérien"
      }
    },
    flag: "🇩🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/RsAyAfyaiNVb8DpW8",
      openStreetMaps: "https://www.openstreetmap.org/relation/192756"
    },
    population: 44700000,
    gini: {
      2011: 27.6
    },
    fifa: "ALG",
    car: {
      signs: [
        "DZ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/dz.png",
      svg: "https://flagcdn.com/dz.svg",
      alt: "The flag of Algeria features two equal vertical bands of green and white. A five-pointed red star within a fly-side facing red crescent is centered over the two-color boundary."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/dz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/dz.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        36.75,
        3.05
      ]
    },
    nativeName: [
      "Algeria",
      "People's Democratic Republic of Algeria",
      "الجمهورية الديمقراطية الشعبية الجزائرية",
      "الجزائر"
    ]
  },
  {
    name: {
      common: "France",
      official: "French Republic",
      nativeName: {
        fra: {
          official: "République française",
          common: "France"
        }
      }
    },
    tld: [
      ".fr"
    ],
    cca2: "FR",
    ccn3: "250",
    cca3: "FRA",
    cioc: "FRA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "3"
      ]
    },
    capital: [
      "Paris"
    ],
    altSpellings: [
      "FR",
      "French Republic",
      "République française"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      fra: "French"
    },
    latlng: [
      46,
      2
    ],
    landlocked: "Yes",
    borders: [
      "AND",
      "BEL",
      "DEU",
      "ITA",
      "LUX",
      "MCO",
      "ESP",
      "CHE"
    ],
    area: 551695,
    demonyms: {
      eng: {
        f: "French",
        m: "French"
      },
      fra: {
        f: "Française",
        m: "Français"
      }
    },
    flag: "🇫🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/g7QxxSFsWyTPKuzd7",
      openStreetMaps: "https://www.openstreetmap.org/relation/1403916"
    },
    population: 67391582,
    gini: {
      2018: 32.4
    },
    fifa: "FRA",
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-10:00",
      "UTC-09:30",
      "UTC-09:00",
      "UTC-08:00",
      "UTC-04:00",
      "UTC-03:00",
      "UTC+01:00",
      "UTC+02:00",
      "UTC+03:00",
      "UTC+04:00",
      "UTC+05:00",
      "UTC+10:00",
      "UTC+11:00",
      "UTC+12:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/fr.png",
      svg: "https://flagcdn.com/fr.svg",
      alt: "The flag of France is composed of three equal vertical bands of blue, white and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/fr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/fr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        48.87,
        2.33
      ]
    },
    nativeName: [
      "France",
      "French Republic",
      "République française"
    ]
  },
  {
    name: {
      common: "Netherlands",
      official: "Kingdom of the Netherlands",
      nativeName: {
        nld: {
          official: "Koninkrijk der Nederlanden",
          common: "Nederland"
        }
      }
    },
    tld: [
      ".nl"
    ],
    cca2: "NL",
    ccn3: "528",
    cca3: "NLD",
    cioc: "NED",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "Amsterdam"
    ],
    altSpellings: [
      "NL",
      "Holland",
      "Nederland",
      "The Netherlands"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      nld: "Dutch"
    },
    latlng: [
      52.5,
      5.75
    ],
    landlocked: "Yes",
    borders: [
      "BEL",
      "DEU"
    ],
    area: 41850,
    demonyms: {
      eng: {
        f: "Dutch",
        m: "Dutch"
      },
      fra: {
        f: "Néerlandaise",
        m: "Néerlandais"
      }
    },
    flag: "🇳🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/Hv6zQswGhFxoVVBm6",
      openStreetMaps: "https://www.openstreetmap.org/relation/47796"
    },
    population: 16655799,
    gini: {
      2018: 28.1
    },
    fifa: "NED",
    car: {
      signs: [
        "NL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/nl.png",
      svg: "https://flagcdn.com/nl.svg",
      alt: "The flag of the Netherlands is composed of three equal horizontal bands of red, white and blue."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/nl.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/nl.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        52.35,
        4.92
      ]
    },
    nativeName: [
      "Netherlands",
      "Kingdom of the Netherlands",
      "Koninkrijk der Nederlanden",
      "Nederland"
    ]
  },
  {
    name: {
      common: "Greenland",
      official: "Greenland",
      nativeName: {
        kal: {
          official: "Kalaallit Nunaat",
          common: "Kalaallit Nunaat"
        }
      }
    },
    tld: [
      ".gl"
    ],
    cca2: "GL",
    ccn3: "304",
    cca3: "GRL",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "krone kr."
    ],
    idd: {
      root: "+2",
      suffixes: [
        "99"
      ]
    },
    capital: [
      "Nuuk"
    ],
    altSpellings: [
      "GL",
      "Grønland"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      kal: "Greenlandic"
    },
    latlng: [
      72,
      -40
    ],
    landlocked: "Yes",
    area: 2166086,
    demonyms: {
      eng: {
        f: "Greenlandic",
        m: "Greenlandic"
      },
      fra: {
        f: "Groenlandaise",
        m: "Groenlandais"
      }
    },
    flag: "🇬🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/j3289UPEQXt1ceSy8",
      openStreetMaps: "https://www.openstreetmap.org/relation/2184073"
    },
    population: 56367,
    car: {
      signs: [
        "DK"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00",
      "UTC-03:00",
      "UTC-01:00",
      "UTC+00:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gl.png",
      svg: "https://flagcdn.com/gl.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gl.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gl.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        64.18,
        -51.75
      ]
    },
    nativeName: [
      "Greenland",
      "Kalaallit Nunaat"
    ]
  },
  {
    name: {
      common: "Sint Maarten",
      official: "Sint Maarten",
      nativeName: {
        eng: {
          official: "Sint Maarten",
          common: "Sint Maarten"
        },
        fra: {
          official: "Saint-Martin",
          common: "Saint-Martin"
        },
        nld: {
          official: "Sint Maarten",
          common: "Sint Maarten"
        }
      }
    },
    tld: [
      ".sx"
    ],
    cca2: "SX",
    ccn3: "534",
    cca3: "SXM",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Netherlands Antillean guilder ƒ"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "721"
      ]
    },
    capital: [
      "Philipsburg"
    ],
    altSpellings: [
      "SX",
      "Sint Maarten (Dutch part)"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English",
      fra: "French",
      nld: "Dutch"
    },
    latlng: [
      18.033333,
      -63.05
    ],
    landlocked: "Yes",
    borders: [
      "MAF"
    ],
    area: 34,
    demonyms: {
      eng: {
        f: "St. Maartener",
        m: "St. Maartener"
      },
      fra: {
        f: "Saint-Martinoise",
        m: "Saint-Martinois"
      }
    },
    flag: "🇸🇽",
    maps: {
      googleMaps: "https://goo.gl/maps/DjvcESy1a1oGEZuNA",
      openStreetMaps: "https://www.openstreetmap.org/relation/1231790"
    },
    population: 40812,
    car: {
      signs: [
        "SX"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sx.png",
      svg: "https://flagcdn.com/sx.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.02,
        -63.03
      ]
    },
    nativeName: [
      "Sint Maarten",
      "Saint-Martin"
    ]
  },
  {
    name: {
      common: "Chad",
      official: "Republic of Chad",
      nativeName: {
        ara: {
          official: "جمهورية تشاد",
          common: "تشاد\u200e"
        },
        fra: {
          official: "République du Tchad",
          common: "Tchad"
        }
      }
    },
    tld: [
      ".td"
    ],
    cca2: "TD",
    ccn3: "148",
    cca3: "TCD",
    cioc: "CHA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Central African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "35"
      ]
    },
    capital: [
      "N'Djamena"
    ],
    altSpellings: [
      "TD",
      "Tchad",
      "Republic of Chad",
      "République du Tchad"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      ara: "Arabic",
      fra: "French"
    },
    latlng: [
      15,
      19
    ],
    landlocked: "Yes",
    borders: [
      "CMR",
      "CAF",
      "LBY",
      "NER",
      "NGA",
      "SDN"
    ],
    area: 1284000,
    demonyms: {
      eng: {
        f: "Chadian",
        m: "Chadian"
      },
      fra: {
        f: "Tchadienne",
        m: "Tchadien"
      }
    },
    flag: "🇹🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/ziUdAZ8skuNfx5Hx7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2361304"
    },
    population: 16425859,
    gini: {
      2011: 43.3
    },
    fifa: "CHA",
    car: {
      signs: [
        "TCH",
        "TD"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/td.png",
      svg: "https://flagcdn.com/td.svg",
      alt: "The flag of Chad is composed of three equal vertical bands of blue, gold and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/td.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/td.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.1,
        15.03
      ]
    },
    nativeName: [
      "Chad",
      "Republic of Chad",
      "جمهورية تشاد",
      "تشاد\u200e",
          "République du Tchad",
      "Tchad"
    ]
  },
  {
    name: {
      common: "Finland",
      official: "Republic of Finland",
      nativeName: {
        fin: {
          official: "Suomen tasavalta",
          common: "Suomi"
        },
        swe: {
          official: "Republiken Finland",
          common: "Finland"
        }
      }
    },
    tld: [
      ".fi"
    ],
    cca2: "FI",
    ccn3: "246",
    cca3: "FIN",
    cioc: "FIN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "58"
      ]
    },
    capital: [
      "Helsinki"
    ],
    altSpellings: [
      "FI",
      "Suomi",
      "Republic of Finland",
      "Suomen tasavalta",
      "Republiken Finland"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      fin: "Finnish",
      swe: "Swedish"
    },
    latlng: [
      64,
      26
    ],
    landlocked: "Yes",
    borders: [
      "NOR",
      "SWE",
      "RUS"
    ],
    area: 338424,
    demonyms: {
      eng: {
        f: "Finnish",
        m: "Finnish"
      },
      fra: {
        f: "Finlandaise",
        m: "Finlandais"
      }
    },
    flag: "🇫🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/HjgWDCNKRAYHrkMn8",
      openStreetMaps: "openstreetmap.org/relation/54224"
    },
    population: 5530719,
    gini: {
      2018: 27.3
    },
    fifa: "FIN",
    car: {
      signs: [
        "FIN"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/fi.png",
      svg: "https://flagcdn.com/fi.svg",
      alt: "The flag of Finland has a white field with a large blue cross that extend to the edges of the field. The vertical part of this cross is offset towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/fi.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/fi.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        60.17,
        24.93
      ]
    },
    nativeName: [
      "Finland",
      "Republic of Finland",
      "Suomen tasavalta",
      "Suomi",
      "Republiken Finland"
    ]
  },
  {
    name: {
      common: "Panama",
      official: "Republic of Panama",
      nativeName: {
        spa: {
          official: "República de Panamá",
          common: "Panamá"
        }
      }
    },
    tld: [
      ".pa"
    ],
    cca2: "PA",
    ccn3: "591",
    cca3: "PAN",
    cioc: "PAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Panamanian balboa B/.",
      "United States dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "07"
      ]
    },
    capital: [
      "Panama City"
    ],
    altSpellings: [
      "PA",
      "Republic of Panama",
      "República de Panamá"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      9,
      -80
    ],
    landlocked: "Yes",
    borders: [
      "COL",
      "CRI"
    ],
    area: 75417,
    demonyms: {
      eng: {
        f: "Panamanian",
        m: "Panamanian"
      },
      fra: {
        f: "Panaméenne",
        m: "Panaméen"
      }
    },
    flag: "🇵🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/sEN7sKqeawa5oPNLA",
      openStreetMaps: "https://www.openstreetmap.org/relation/287668"
    },
    population: 4314768,
    gini: {
      2019: 49.8
    },
    fifa: "PAN",
    car: {
      signs: [
        "PA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pa.png",
      svg: "https://flagcdn.com/pa.svg",
      alt: "The flag of Panama is composed of four equal rectangular areas — a white rectangular area with a blue five-pointed star at its center, a red rectangular area, a white rectangular area with a red five-pointed star at its center, and a blue rectangular area — in the upper hoist side, upper fly side, lower fly side and lower hoist side respectively."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pa.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pa.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        8.97,
        -79.53
      ]
    },
    nativeName: [
      "Panama",
      "Republic of Panama",
      "República de Panamá",
      "Panamá"
    ]
  },
  {
    name: {
      common: "Palestine",
      official: "State of Palestine",
      nativeName: {
        ara: {
          official: "دولة فلسطين",
          common: "فلسطين"
        }
      }
    },
    tld: [
      ".ps",
      "فلسطين."
    ],
    cca2: "PS",
    ccn3: "275",
    cca3: "PSE",
    cioc: "PLE",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Egyptian pound E£",
      "Israeli new shekel ₪",
      "Jordanian dinar JD"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "70"
      ]
    },
    capital: [
      "Ramallah",
      "Jerusalem"
    ],
    altSpellings: [
      "PS",
      "Palestine, State of",
      "State of Palestine",
      "Dawlat Filasṭin"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      31.9,
      35.2
    ],
    landlocked: "Yes",
    borders: [
      "ISR",
      "EGY",
      "JOR"
    ],
    area: 6220,
    demonyms: {
      eng: {
        f: "Palestinian",
        m: "Palestinian"
      },
      fra: {
        f: "Palestinienne",
        m: "Palestinien"
      }
    },
    flag: "🇵🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/QvTbkRdmdWEoYAmt5",
      openStreetMaps: "https://www.openstreetmap.org/relation/1703814"
    },
    population: 4803269,
    gini: {
      2016: 33.7
    },
    fifa: "PLE",
    car: {
      signs: [
        "PS"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ps.png",
      svg: "https://flagcdn.com/ps.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ps.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ps.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        31.9,
        35.2
      ]
    },
    nativeName: [
      "Palestine",
      "State of Palestine",
      "دولة فلسطين",
      "فلسطين"
    ]
  },
  {
    name: {
      common: "Yemen",
      official: "Republic of Yemen",
      nativeName: {
        ara: {
          official: "الجمهورية اليمنية",
          common: "اليَمَن"
        }
      }
    },
    tld: [
      ".ye"
    ],
    cca2: "YE",
    ccn3: "887",
    cca3: "YEM",
    cioc: "YEM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Yemeni rial ﷼"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "67"
      ]
    },
    capital: [
      "Sana'a"
    ],
    altSpellings: [
      "YE",
      "Yemeni Republic",
      "al-Jumhūriyyah al-Yamaniyyah"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      15,
      48
    ],
    landlocked: "Yes",
    borders: [
      "OMN",
      "SAU"
    ],
    area: 527968,
    demonyms: {
      eng: {
        f: "Yemeni",
        m: "Yemeni"
      },
      fra: {
        f: "Yéménite",
        m: "Yéménite"
      }
    },
    flag: "🇾🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/WCmE76HKcLideQQw7",
      openStreetMaps: "https://www.openstreetmap.org/relation/305092"
    },
    population: 29825968,
    gini: {
      2014: 36.7
    },
    fifa: "YEM",
    car: {
      signs: [
        "YAR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ye.png",
      svg: "https://flagcdn.com/ye.svg",
      alt: "The flag of Yemen is composed of three equal horizontal bands of red, white and black."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ye.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ye.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        15.37,
        44.19
      ]
    },
    nativeName: [
      "Yemen",
      "Republic of Yemen",
      "الجمهورية اليمنية",
      "اليَمَن"
    ]
  },
  {
    name: {
      common: "Brazil",
      official: "Federative Republic of Brazil",
      nativeName: {
        por: {
          official: "República Federativa do Brasil",
          common: "Brasil"
        }
      }
    },
    tld: [
      ".br"
    ],
    cca2: "BR",
    ccn3: "076",
    cca3: "BRA",
    cioc: "BRA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Brazilian real R$"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "5"
      ]
    },
    capital: [
      "Brasília"
    ],
    altSpellings: [
      "BR",
      "Brasil",
      "Federative Republic of Brazil",
      "República Federativa do Brasil"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      por: "Portuguese"
    },
    latlng: [
      -10,
      -55
    ],
    landlocked: "Yes",
    borders: [
      "ARG",
      "BOL",
      "COL",
      "GUF",
      "GUY",
      "PRY",
      "PER",
      "SUR",
      "URY",
      "VEN"
    ],
    area: 8515767,
    demonyms: {
      eng: {
        f: "Brazilian",
        m: "Brazilian"
      },
      fra: {
        f: "Brésilienne",
        m: "Brésilien"
      }
    },
    flag: "🇧🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/waCKk21HeeqFzkNC9",
      openStreetMaps: "https://www.openstreetmap.org/relation/59470"
    },
    population: 212559409,
    gini: {
      2019: 53.4
    },
    fifa: "BRA",
    car: {
      signs: [
        "BR"
      ],
      side: "right"
    },
    timezones: [
      "UTC-05:00",
      "UTC-04:00",
      "UTC-03:00",
      "UTC-02:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/br.png",
      svg: "https://flagcdn.com/br.svg",
      alt: "The flag of Brazil has a green field with a large yellow rhombus in the center. Within the rhombus is a dark blue globe with twenty-seven small five-pointed white stars depicting a starry sky and a thin white convex horizontal band inscribed with the national motto 'Ordem e Progresso' across its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/br.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/br.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -15.79,
        -47.88
      ]
    },
    nativeName: [
      "Brazil",
      "Federative Republic of Brazil",
      "República Federativa do Brasil",
      "Brasil"
    ]
  },
  {
    name: {
      common: "Nigeria",
      official: "Federal Republic of Nigeria",
      nativeName: {
        eng: {
          official: "Federal Republic of Nigeria",
          common: "Nigeria"
        }
      }
    },
    tld: [
      ".ng"
    ],
    cca2: "NG",
    ccn3: "566",
    cca3: "NGA",
    cioc: "NGR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Nigerian naira ₦"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "34"
      ]
    },
    capital: [
      "Abuja"
    ],
    altSpellings: [
      "NG",
      "Nijeriya",
      "Naíjíríà",
      "Federal Republic of Nigeria"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      10,
      8
    ],
    landlocked: "Yes",
    borders: [
      "BEN",
      "CMR",
      "TCD",
      "NER"
    ],
    area: 923768,
    demonyms: {
      eng: {
        f: "Nigerian",
        m: "Nigerian"
      },
      fra: {
        f: "Nigériane",
        m: "Nigérian"
      }
    },
    flag: "🇳🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/LTn417qWwBPFszuV9",
      openStreetMaps: "https://www.openstreetmap.org/relation/192787"
    },
    population: 206139587,
    gini: {
      2018: 35.1
    },
    fifa: "NGA",
    car: {
      signs: [
        "WAN"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ng.png",
      svg: "https://flagcdn.com/ng.svg",
      alt: "The flag of Nigeria is composed of three equal vertical bands of green, white and green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ng.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ng.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        9.08,
        7.53
      ]
    },
    nativeName: [
      "Nigeria",
      "Federal Republic of Nigeria"
    ]
  },
  {
    name: {
      common: "Palau",
      official: "Republic of Palau",
      nativeName: {
        eng: {
          official: "Republic of Palau",
          common: "Palau"
        },
        pau: {
          official: "Beluu er a Belau",
          common: "Belau"
        }
      }
    },
    tld: [
      ".pw"
    ],
    cca2: "PW",
    ccn3: "585",
    cca3: "PLW",
    cioc: "PLW",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "80"
      ]
    },
    capital: [
      "Ngerulmud"
    ],
    altSpellings: [
      "PW",
      "Republic of Palau",
      "Beluu er a Belau"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      eng: "English",
      pau: "Palauan"
    },
    latlng: [
      7.5,
      134.5
    ],
    landlocked: "Yes",
    area: 459,
    demonyms: {
      eng: {
        f: "Palauan",
        m: "Palauan"
      },
      fra: {
        f: "Paluane",
        m: "Paluan"
      }
    },
    flag: "🇵🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/MVasQBbUkQP7qQDR9",
      openStreetMaps: "https://www.openstreetmap.org/relation/571805"
    },
    population: 18092,
    car: {
      signs: [
        "PAL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+09:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pw.png",
      svg: "https://flagcdn.com/pw.svg",
      alt: "The flag of Palau has a light blue field with a large golden-yellow circle that is offset slightly towards the hoist side of center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        7.5,
        134.62
      ]
    },
    nativeName: [
      "Palau",
      "Republic of Palau",
      "Beluu er a Belau",
      "Belau"
    ]
  },
  {
    name: {
      common: "Japan",
      official: "Japan",
      nativeName: {
        jpn: {
          official: "日本",
          common: "日本"
        }
      }
    },
    tld: [
      ".jp",
      ".みんな"
    ],
    cca2: "JP",
    ccn3: "392",
    cca3: "JPN",
    cioc: "JPN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Japanese yen ¥"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "Tokyo"
    ],
    altSpellings: [
      "JP",
      "Nippon",
      "Nihon"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      jpn: "Japanese"
    },
    latlng: [
      36,
      138
    ],
    landlocked: "Yes",
    area: 377930,
    demonyms: {
      eng: {
        f: "Japanese",
        m: "Japanese"
      },
      fra: {
        f: "Japonaise",
        m: "Japonais"
      }
    },
    flag: "🇯🇵",
    maps: {
      googleMaps: "https://goo.gl/maps/NGTLSCSrA8bMrvnX9",
      openStreetMaps: "https://www.openstreetmap.org/relation/382313"
    },
    population: 125836021,
    gini: {
      2013: 32.9
    },
    fifa: "JPN",
    car: {
      signs: [
        "J"
      ],
      side: "left"
    },
    timezones: [
      "UTC+09:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/jp.png",
      svg: "https://flagcdn.com/jp.svg",
      alt: "The flag of Japan features a crimson-red circle at the center of a white field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/jp.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/jp.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        35.68,
        139.75
      ]
    },
    nativeName: [
      "Japan",
      "日本"
    ]
  },
  {
    name: {
      common: "Tanzania",
      official: "United Republic of Tanzania",
      nativeName: {
        eng: {
          official: "United Republic of Tanzania",
          common: "Tanzania"
        },
        swa: {
          official: "Jamhuri ya Muungano wa Tanzania",
          common: "Tanzania"
        }
      }
    },
    tld: [
      ".tz"
    ],
    cca2: "TZ",
    ccn3: "834",
    cca3: "TZA",
    cioc: "TAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Tanzanian shilling Sh"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "55"
      ]
    },
    capital: [
      "Dodoma"
    ],
    altSpellings: [
      "TZ",
      "Tanzania, United Republic of",
      "United Republic of Tanzania",
      "Jamhuri ya Muungano wa Tanzania"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English",
      swa: "Swahili"
    },
    latlng: [
      -6,
      35
    ],
    landlocked: "Yes",
    borders: [
      "BDI",
      "COD",
      "KEN",
      "MWI",
      "MOZ",
      "RWA",
      "UGA",
      "ZMB"
    ],
    area: 945087,
    demonyms: {
      eng: {
        f: "Tanzanian",
        m: "Tanzanian"
      },
      fra: {
        f: "Tanzanienne",
        m: "Tanzanien"
      }
    },
    flag: "🇹🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/NWYMqZYXte4zGZ2Q8",
      openStreetMaps: "https://www.openstreetmap.org/relation/195270"
    },
    population: 59734213,
    gini: {
      2017: 40.5
    },
    fifa: "TAN",
    car: {
      signs: [
        "EAT"
      ],
      side: "left"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tz.png",
      svg: "https://flagcdn.com/tz.svg",
      alt: "The flag of Tanzania features a yellow-edged black diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a green and light blue triangle respectively."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -6.16,
        35.75
      ]
    },
    nativeName: [
      "Tanzania",
      "United Republic of Tanzania",
      "Jamhuri ya Muungano wa Tanzania"
    ]
  },
  {
    name: {
      common: "Kazakhstan",
      official: "Republic of Kazakhstan",
      nativeName: {
        kaz: {
          official: "Қазақстан Республикасы",
          common: "Қазақстан"
        },
        rus: {
          official: "Республика Казахстан",
          common: "Казахстан"
        }
      }
    },
    tld: [
      ".kz",
      ".қаз"
    ],
    cca2: "KZ",
    ccn3: "398",
    cca3: "KAZ",
    cioc: "KAZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Kazakhstani tenge ₸"
    ],
    idd: {
      root: "+7",
      suffixes: [
        "6",
        "7"
      ]
    },
    capital: [
      "Nur-Sultan"
    ],
    altSpellings: [
      "KZ",
      "Qazaqstan",
      "Казахстан",
      "Republic of Kazakhstan",
      "Қазақстан Республикасы",
      "Qazaqstan Respublïkası",
      "Республика Казахстан",
      "Respublika Kazakhstan"
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: {
      kaz: "Kazakh",
      rus: "Russian"
    },
    latlng: [
      48.0196,
      66.9237
    ],
    landlocked: "Yes",
    borders: [
      "CHN",
      "KGZ",
      "RUS",
      "TKM",
      "UZB"
    ],
    area: 2724900,
    demonyms: {
      eng: {
        f: "Kazakhstani",
        m: "Kazakhstani"
      },
      fra: {
        f: "Kazakhstanaise",
        m: "Kazakhstanais"
      }
    },
    flag: "🇰🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/8VohJGu7ShuzZYyeA",
      openStreetMaps: "https://www.openstreetmap.org/relation/214665"
    },
    population: 18754440,
    gini: {
      2018: 27.8
    },
    fifa: "KAZ",
    car: {
      signs: [
        "KZ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+05:00",
      "UTC+06:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kz.png",
      svg: "https://flagcdn.com/kz.svg",
      alt: "The flag of Kazakhstan has a turquoise field, at the center of which is a gold sun with thirty-two rays above a soaring golden steppe eagle. A thin vertical band displays a national ornamental pattern — koshkar-muiz — in gold near the hoist end."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        51.16,
        71.45
      ]
    },
    nativeName: [
      "Kazakhstan",
      "Republic of Kazakhstan",
      "Қазақстан Республикасы",
      "Қазақстан",
      "Республика Казахстан",
      "Казахстан"
    ]
  },
  {
    name: {
      common: "Portugal",
      official: "Portuguese Republic",
      nativeName: {
        por: {
          official: "República português",
          common: "Portugal"
        }
      }
    },
    tld: [
      ".pt"
    ],
    cca2: "PT",
    ccn3: "620",
    cca3: "PRT",
    cioc: "POR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "51"
      ]
    },
    capital: [
      "Lisbon"
    ],
    altSpellings: [
      "PT",
      "Portuguesa",
      "Portuguese Republic",
      "República Portuguesa"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      por: "Portuguese"
    },
    latlng: [
      39.5,
      -8
    ],
    landlocked: "Yes",
    borders: [
      "ESP"
    ],
    area: 92090,
    demonyms: {
      eng: {
        f: "Portuguese",
        m: "Portuguese"
      },
      fra: {
        f: "Portugaise",
        m: "Portugais"
      }
    },
    flag: "🇵🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/BaTBSyc4GWMmbAKB8",
      openStreetMaps: "https://www.openstreetmap.org/relation/295480"
    },
    population: 10305564,
    gini: {
      2018: 33.5
    },
    fifa: "POR",
    car: {
      signs: [
        "P"
      ],
      side: "right"
    },
    timezones: [
      "UTC-01:00",
      "UTC"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pt.png",
      svg: "https://flagcdn.com/pt.svg",
      alt: "The flag of Portugal is composed of two vertical bands of green and red in the ratio of 2:3, with the coat of arms of Portugal centered over the two-color boundary."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pt.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pt.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        38.72,
        -9.13
      ]
    },
    nativeName: [
      "Portugal",
      "Portuguese Republic",
      "República português"
    ]
  },
  {
    name: {
      common: "South Georgia",
      official: "South Georgia and the South Sandwich Islands",
      nativeName: {
        eng: {
          official: "South Georgia and the South Sandwich Islands",
          common: "South Georgia"
        }
      }
    },
    tld: [
      ".gs"
    ],
    cca2: "GS",
    ccn3: "239",
    cca3: "SGS",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Saint Helena pound £"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "00"
      ]
    },
    capital: [
      "King Edward Point"
    ],
    altSpellings: [
      "GS",
      "South Georgia and the South Sandwich Islands"
    ],
    region: "Antarctic",
    languages: {
      eng: "English"
    },
    latlng: [
      -54.5,
      -37
    ],
    landlocked: "Yes",
    area: 3903,
    demonyms: {
      eng: {
        f: "South Georgian South Sandwich Islander",
        m: "South Georgian South Sandwich Islander"
      }
    },
    flag: "🇬🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/mJzdaBwKBbm2B81q9",
      openStreetMaps: "https://www.openstreetmap.org/relation/1983629"
    },
    population: 30,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC-02:00"
    ],
    continents: [
      "Antarctica"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gs.png",
      svg: "https://flagcdn.com/gs.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -54.28,
        -36.5
      ]
    },
    nativeName: [
      "South Georgia",
      "South Georgia and the South Sandwich Islands"
    ]
  },
  {
    name: {
      common: "Sri Lanka",
      official: "Democratic Socialist Republic of Sri Lanka",
      nativeName: {
        sin: {
          official: "ශ්\u200dරී ලංකා ප්\u200dරජාතාන්ත්\u200dරික සමාජවාදී ජනරජය",
              common: "ශ්\u200dරී ලංකාව"
        },
        tam: {
          official: "இலங்கை சனநாயக சோசலிசக் குடியரசு",
          common: "இலங்கை"
        }
      }
    },
    tld: [
      ".lk",
      ".இலங்கை",
      ".ලංකා"
    ],
    cca2: "LK",
    ccn3: "144",
    cca3: "LKA",
    cioc: "SRI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Sri Lankan rupee Rs  රු"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Sri Jayawardenepura Kotte"
    ],
    altSpellings: [
      "LK",
      "ilaṅkai",
      "Democratic Socialist Republic of Sri Lanka"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      sin: "Sinhala",
      tam: "Tamil"
    },
    latlng: [
      7,
      81
    ],
    landlocked: "Yes",
    borders: [
      "IND"
    ],
    area: 65610,
    demonyms: {
      eng: {
        f: "Sri Lankan",
        m: "Sri Lankan"
      },
      fra: {
        f: "Sri-lankaise",
        m: "Sri-lankais"
      }
    },
    flag: "🇱🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/VkPHoeFSfgzRQCDv8",
      openStreetMaps: "https://www.openstreetmap.org/relation/536807"
    },
    population: 21919000,
    gini: {
      2016: 39.3
    },
    fifa: "SRI",
    car: {
      signs: [
        "CL"
      ],
      side: "left"
    },
    timezones: [
      "UTC+05:30"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lk.png",
      svg: "https://flagcdn.com/lk.svg",
      alt: "The flag of Sri Lanka features two large adjacent but separate rectangular areas, centered on a golden-yellow field. The smaller hoist-side rectangle is divided into two equal vertical bands of teal and orange, and the larger fly-side rectangle is maroon with a centered golden-yellow lion holding a Kastane sword in its right fore-paw and four golden-yellow Bo leaves, one in each corner."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.89,
        79.9
      ]
    },
    nativeName: [
      "Sri Lanka",
      "Democratic Socialist Republic of Sri Lanka",
      "ශ්\u200dරී ලංකා ප්\u200dරජාතාන්ත්\u200dරික සමාජවාදී ජනරජය",
          "ශ්\u200dරී ලංකාව",
          "இலங்கை சனநாயக சோசலிசக் குடியரசு",
      "இலங்கை"
    ]
  },
  {
    name: {
      common: "Germany",
      official: "Federal Republic of Germany",
      nativeName: {
        deu: {
          official: "Bundesrepublik Deutschland",
          common: "Deutschland"
        }
      }
    },
    tld: [
      ".de"
    ],
    cca2: "DE",
    ccn3: "276",
    cca3: "DEU",
    cioc: "GER",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "9"
      ]
    },
    capital: [
      "Berlin"
    ],
    altSpellings: [
      "DE",
      "Federal Republic of Germany",
      "Bundesrepublik Deutschland"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      deu: "German"
    },
    latlng: [
      51,
      9
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "BEL",
      "CZE",
      "DNK",
      "FRA",
      "LUX",
      "NLD",
      "POL",
      "CHE"
    ],
    area: 357114,
    demonyms: {
      eng: {
        f: "German",
        m: "German"
      },
      fra: {
        f: "Allemande",
        m: "Allemand"
      }
    },
    flag: "🇩🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/mD9FBMq1nvXUBrkv6",
      openStreetMaps: "https://www.openstreetmap.org/relation/51477"
    },
    population: 83240525,
    gini: {
      2016: 31.9
    },
    fifa: "GER",
    car: {
      signs: [
        "DY"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/de.png",
      svg: "https://flagcdn.com/de.svg",
      alt: "The flag of Germany is composed of three equal horizontal bands of black, red and gold."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/de.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/de.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        52.52,
        13.4
      ]
    },
    nativeName: [
      "Germany",
      "Federal Republic of Germany",
      "Bundesrepublik Deutschland",
      "Deutschland"
    ]
  },
  {
    name: {
      common: "Western Sahara",
      official: "Sahrawi Arab Democratic Republic",
      nativeName: {
        ber: {
          official: "Sahrawi Arab Democratic Republic",
          common: "Western Sahara"
        },
        mey: {
          official: "الجمهورية العربية الصحراوية الديمقراطية",
          common: "الصحراء الغربية"
        },
        spa: {
          official: "República Árabe Saharaui Democrática",
          common: "Sahara Occidental"
        }
      }
    },
    tld: [
      ".eh"
    ],
    cca2: "EH",
    ccn3: "732",
    cca3: "ESH",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Algerian dinar دج",
      "Moroccan dirham DH",
      "Mauritanian ouguiya UM"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "125288",
        "125289"
      ]
    },
    capital: [
      "El Aaiún"
    ],
    altSpellings: [
      "EH",
      "Taneẓroft Tutrimt"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ber: "Berber",
      mey: "Hassaniya",
      spa: "Spanish"
    },
    latlng: [
      24.5,
      -13
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "MRT",
      "MAR"
    ],
    area: 266000,
    demonyms: {
      eng: {
        f: "Sahrawi",
        m: "Sahrawi"
      }
    },
    flag: "🇪🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/7nU3mB69vP6zQp7A8",
      openStreetMaps: "https://www.openstreetmap.org/relation/5441968"
    },
    population: 510713,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+00:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/eh.png",
      svg: "https://flagcdn.com/eh.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -13.28,
        27.14
      ]
    },
    nativeName: [
      "Western Sahara",
      "Sahrawi Arab Democratic Republic",
      "الجمهورية العربية الصحراوية الديمقراطية",
      "الصحراء الغربية",
      "República Árabe Saharaui Democrática",
      "Sahara Occidental"
    ]
  },
  {
    name: {
      common: "Myanmar",
      official: "Republic of the Union of Myanmar",
      nativeName: {
        mya: {
          official: "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်",
          common: "မြန်မာ"
        }
      }
    },
    tld: [
      ".mm"
    ],
    cca2: "MM",
    ccn3: "104",
    cca3: "MMR",
    cioc: "MYA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Burmese kyat Ks"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "5"
      ]
    },
    capital: [
      "Naypyidaw"
    ],
    altSpellings: [
      "MM",
      "Burma",
      "Republic of the Union of Myanmar",
      "Pyidaunzu Thanmăda Myăma Nainngandaw"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      mya: "Burmese"
    },
    latlng: [
      22,
      98
    ],
    landlocked: "Yes",
    borders: [
      "BGD",
      "CHN",
      "IND",
      "LAO",
      "THA"
    ],
    area: 676578,
    demonyms: {
      eng: {
        f: "Burmese",
        m: "Burmese"
      },
      fra: {
        f: "Birmane",
        m: "Birman"
      }
    },
    flag: "🇲🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/4jrZyJkDERUfHyp26",
      openStreetMaps: "https://www.openstreetmap.org/relation/50371"
    },
    population: 54409794,
    gini: {
      2017: 30.7
    },
    fifa: "MYA",
    car: {
      signs: [
        "BUR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+06:30"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mm.png",
      svg: "https://flagcdn.com/mm.svg",
      alt: "The flag of Myanmar is composed of three equal horizontal bands of yellow, green and red, with a large five-pointed white star superimposed at the center of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        19.76,
        96.07
      ]
    },
    nativeName: [
      "Myanmar",
      "Republic of the Union of Myanmar",
      "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်",
      "မြန်မာ"
    ]
  },
  {
    name: {
      common: "Colombia",
      official: "Republic of Colombia",
      nativeName: {
        spa: {
          official: "República de Colombia",
          common: "Colombia"
        }
      }
    },
    tld: [
      ".co"
    ],
    cca2: "CO",
    ccn3: "170",
    cca3: "COL",
    cioc: "COL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Colombian peso $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "7"
      ]
    },
    capital: [
      "Bogotá"
    ],
    altSpellings: [
      "CO",
      "Republic of Colombia",
      "República de Colombia"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      4,
      -72
    ],
    landlocked: "Yes",
    borders: [
      "BRA",
      "ECU",
      "PAN",
      "PER",
      "VEN"
    ],
    area: 1141748,
    demonyms: {
      eng: {
        f: "Colombian",
        m: "Colombian"
      },
      fra: {
        f: "Colombienne",
        m: "Colombien"
      }
    },
    flag: "🇨🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/zix9qNFX69E9yZ2M6",
      openStreetMaps: "https://www.openstreetmap.org/relation/120027"
    },
    population: 50882884,
    gini: {
      2019: 51.3
    },
    fifa: "COL",
    car: {
      signs: [
        "CO"
      ],
      side: "right"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/co.png",
      svg: "https://flagcdn.com/co.svg",
      alt: "The flag of Colombia is composed of three horizontal bands of yellow, blue and red, with the yellow band twice the height of the other two bands."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/co.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/co.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        4.71,
        -74.07
      ]
    },
    nativeName: [
      "Colombia",
      "Republic of Colombia",
      "República de Colombia"
    ]
  },
  {
    name: {
      common: "Peru",
      official: "Republic of Peru",
      nativeName: {
        aym: {
          official: "Piruw Suyu",
          common: "Piruw"
        },
        que: {
          official: "Piruw Ripuwlika",
          common: "Piruw"
        },
        spa: {
          official: "República del Perú",
          common: "Perú"
        }
      }
    },
    tld: [
      ".pe"
    ],
    cca2: "PE",
    ccn3: "604",
    cca3: "PER",
    cioc: "PER",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Peruvian sol S/ "
    ],
    idd: {
      root: "+5",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "Lima"
    ],
    altSpellings: [
      "PE",
      "Republic of Peru",
      "República del Perú"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      aym: "Aymara",
      que: "Quechua",
      spa: "Spanish"
    },
    latlng: [
      -10,
      -76
    ],
    landlocked: "Yes",
    borders: [
      "BOL",
      "BRA",
      "CHL",
      "COL",
      "ECU"
    ],
    area: 1285216,
    demonyms: {
      eng: {
        f: "Peruvian",
        m: "Peruvian"
      },
      fra: {
        f: "Péruvienne",
        m: "Péruvien"
      }
    },
    flag: "🇵🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/uDWEUaXNcZTng1fP6",
      openStreetMaps: "https://www.openstreetmap.org/relation/288247"
    },
    population: 32971846,
    gini: {
      2019: 41.5
    },
    fifa: "PER",
    car: {
      signs: [
        "PE"
      ],
      side: "right"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pe.png",
      svg: "https://flagcdn.com/pe.svg",
      alt: "The flag of Peru is composed of three equal vertical bands of red, white and red, with the national emblem centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pe.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pe.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -12.05,
        -77.05
      ]
    },
    nativeName: [
      "Peru",
      "Republic of Peru",
      "Piruw Suyu",
      "Piruw",
      "Piruw Ripuwlika",
      "República del Perú",
      "Perú"
    ]
  },
  {
    name: {
      common: "Senegal",
      official: "Republic of Senegal",
      nativeName: {
        fra: {
          official: "République du Sénégal",
          common: "Sénégal"
        }
      }
    },
    tld: [
      ".sn"
    ],
    cca2: "SN",
    ccn3: "686",
    cca3: "SEN",
    cioc: "SEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "21"
      ]
    },
    capital: [
      "Dakar"
    ],
    altSpellings: [
      "SN",
      "Republic of Senegal",
      "République du Sénégal"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      14,
      -14
    ],
    landlocked: "Yes",
    borders: [
      "GMB",
      "GIN",
      "GNB",
      "MLI",
      "MRT"
    ],
    area: 196722,
    demonyms: {
      eng: {
        f: "Senegalese",
        m: "Senegalese"
      },
      fra: {
        f: "Sénégalaise",
        m: "Sénégalais"
      }
    },
    flag: "🇸🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/o5f1uD5nyihCL3HCA",
      openStreetMaps: "https://www.openstreetmap.org/relation/192775"
    },
    population: 16743930,
    gini: {
      2011: 40.3
    },
    fifa: "SEN",
    car: {
      signs: [
        "SN"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sn.png",
      svg: "https://flagcdn.com/sn.svg",
      alt: "The flag of Senegal is composed of three equal vertical bands of green, golden-yellow and red, with a five-pointed green star centered in the golden-yellow band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14.73,
        -17.63
      ]
    },
    nativeName: [
      "Senegal",
      "Republic of Senegal",
      "République du Sénégal",
      "Sénégal"
    ]
  },
  {
    name: {
      common: "Kyrgyzstan",
      official: "Kyrgyz Republic",
      nativeName: {
        kir: {
          official: "Кыргыз Республикасы",
          common: "Кыргызстан"
        },
        rus: {
          official: "Кыргызская Республика",
          common: "Киргизия"
        }
      }
    },
    tld: [
      ".kg"
    ],
    cca2: "KG",
    ccn3: "417",
    cca3: "KGZ",
    cioc: "KGZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Kyrgyzstani som с"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "96"
      ]
    },
    capital: [
      "Bishkek"
    ],
    altSpellings: [
      "KG",
      "Киргизия",
      "Kyrgyz Republic",
      "Кыргыз Республикасы",
      "Kyrgyz Respublikasy"
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: {
      kir: "Kyrgyz",
      rus: "Russian"
    },
    latlng: [
      41,
      75
    ],
    landlocked: "Yes",
    borders: [
      "CHN",
      "KAZ",
      "TJK",
      "UZB"
    ],
    area: 199951,
    demonyms: {
      eng: {
        f: "Kirghiz",
        m: "Kirghiz"
      },
      fra: {
        f: "Kirghize",
        m: "Kirghize"
      }
    },
    flag: "🇰🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/SKG8BSMMQVvxkRkB7",
      openStreetMaps: "https://www.openstreetmap.org/relation/178009"
    },
    population: 6591600,
    gini: {
      2019: 29.7
    },
    fifa: "KGZ",
    car: {
      signs: [
        "KS"
      ],
      side: "right"
    },
    timezones: [
      "UTC+06:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kg.png",
      svg: "https://flagcdn.com/kg.svg",
      alt: "The flag of Kyrgyzstan features a yellow sun with forty rays at the center of a red field. At the center of the sun is a stylized depiction of a tunduk."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        42.87,
        74.6
      ]
    },
    nativeName: [
      "Kyrgyzstan",
      "Kyrgyz Republic",
      "Кыргыз Республикасы",
      "Кыргызстан",
      "Кыргызская Республика",
      "Киргизия"
    ]
  },
  {
    name: {
      common: "China",
      official: "People's Republic of China",
      nativeName: {
        zho: {
          official: "中华人民共和国",
          common: "中国"
        }
      }
    },
    tld: [
      ".cn",
      ".中国",
      ".中國",
      ".公司",
      ".网络"
    ],
    cca2: "CN",
    ccn3: "156",
    cca3: "CHN",
    cioc: "CHN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Chinese yuan ¥"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "6"
      ]
    },
    capital: [
      "Beijing"
    ],
    altSpellings: [
      "CN",
      "Zhōngguó",
      "Zhongguo",
      "Zhonghua",
      "People's Republic of China",
      "中华人民共和国",
      "Zhōnghuá Rénmín Gònghéguó"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      zho: "Chinese"
    },
    latlng: [
      35,
      105
    ],
    landlocked: "Yes",
    borders: [
      "AFG",
      "BTN",
      "MMR",
      "HKG",
      "IND",
      "KAZ",
      "NPL",
      "PRK",
      "KGZ",
      "LAO",
      "MAC",
      "MNG",
      "PAK",
      "RUS",
      "TJK",
      "VNM"
    ],
    area: 9706961,
    demonyms: {
      eng: {
        f: "Chinese",
        m: "Chinese"
      },
      fra: {
        f: "Chinoise",
        m: "Chinois"
      }
    },
    flag: "🇨🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/p9qC6vgiFRRXzvGi7",
      openStreetMaps: "https://www.openstreetmap.org/relation/270056"
    },
    population: 1402112000,
    gini: {
      2016: 38.5
    },
    fifa: "CHN",
    car: {
      signs: [
        "RC"
      ],
      side: "right"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cn.png",
      svg: "https://flagcdn.com/cn.svg",
      alt: "The flag of China has a red field. In the canton are five yellow five-pointed stars — a large star and four smaller stars arranged in a vertical arc on the fly side of the large star."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        39.92,
        116.38
      ]
    },
    nativeName: [
      "China",
      "People's Republic of China",
      "中华人民共和国",
      "中国"
    ]
  },
  {
    name: {
      common: "Afghanistan",
      official: "Islamic Republic of Afghanistan",
      nativeName: {
        prs: {
          official: "جمهوری اسلامی افغانستان",
          common: "افغانستان"
        },
        pus: {
          official: "د افغانستان اسلامي جمهوریت",
          common: "افغانستان"
        },
        tuk: {
          official: "Owganystan Yslam Respublikasy",
          common: "Owganystan"
        }
      }
    },
    tld: [
      ".af"
    ],
    cca2: "AF",
    ccn3: "004",
    cca3: "AFG",
    cioc: "AFG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Afghan afghani ؋"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "3"
      ]
    },
    capital: [
      "Kabul"
    ],
    altSpellings: [
      "AF",
      "Afġānistān"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      prs: "Dari",
      pus: "Pashto",
      tuk: "Turkmen"
    },
    latlng: [
      33,
      65
    ],
    landlocked: "Yes",
    borders: [
      "IRN",
      "PAK",
      "TKM",
      "UZB",
      "TJK",
      "CHN"
    ],
    area: 652230,
    demonyms: {
      eng: {
        f: "Afghan",
        m: "Afghan"
      },
      fra: {
        f: "Afghane",
        m: "Afghan"
      }
    },
    flag: "🇦🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/BXBGw7yUUFknCfva9",
      openStreetMaps: "https://www.openstreetmap.org/relation/303427"
    },
    population: 40218234,
    fifa: "AFG",
    car: {
      signs: [
        "AFG"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:30"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
      svg: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
      alt: "The flag of the Islamic Emirate of Afghanistan has a white field with Arabic inscriptions — the Shahada — in black across its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/af.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/af.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        34.52,
        69.18
      ]
    },
    nativeName: [
      "Afghanistan",
      "Islamic Republic of Afghanistan",
      "جمهوری اسلامی افغانستان",
      "افغانستان",
      "د افغانستان اسلامي جمهوریت",
      "Owganystan Yslam Respublikasy",
      "Owganystan"
    ]
  },
  {
    name: {
      common: "Turkey",
      official: "Republic of Turkey",
      nativeName: {
        tur: {
          official: "Türkiye Cumhuriyeti",
          common: "Türkiye"
        }
      }
    },
    tld: [
      ".tr"
    ],
    cca2: "TR",
    ccn3: "792",
    cca3: "TUR",
    cioc: "TUR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Turkish lira ₺"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "0"
      ]
    },
    capital: [
      "Ankara"
    ],
    altSpellings: [
      "TR",
      "Turkiye",
      "Republic of Turkey",
      "Türkiye Cumhuriyeti"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      tur: "Turkish"
    },
    latlng: [
      39,
      35
    ],
    landlocked: "Yes",
    borders: [
      "ARM",
      "AZE",
      "BGR",
      "GEO",
      "GRC",
      "IRN",
      "IRQ",
      "SYR"
    ],
    area: 783562,
    demonyms: {
      eng: {
        f: "Turkish",
        m: "Turkish"
      },
      fra: {
        f: "Turque",
        m: "Turc"
      }
    },
    flag: "🇹🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/dXFFraiUDfcB6Quk6",
      openStreetMaps: "https://www.openstreetmap.org/relation/174737"
    },
    population: 84339067,
    gini: {
      2019: 41.9
    },
    fifa: "TUR",
    car: {
      signs: [
        "TR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Europe",
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tr.png",
      svg: "https://flagcdn.com/tr.svg",
      alt: "The flag of Turkey has a red field bearing a large fly-side facing white crescent and a smaller five-pointed white star placed just outside the crescent opening. The white crescent and star are offset slightly towards the hoist side of center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        39.93,
        32.87
      ]
    },
    nativeName: [
      "Turkey",
      "Republic of Turkey",
      "Türkiye Cumhuriyeti",
      "Türkiye"
    ]
  },
  {
    name: {
      common: "Liberia",
      official: "Republic of Liberia",
      nativeName: {
        eng: {
          official: "Republic of Liberia",
          common: "Liberia"
        }
      }
    },
    tld: [
      ".lr"
    ],
    cca2: "LR",
    ccn3: "430",
    cca3: "LBR",
    cioc: "LBR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Liberian dollar $"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "31"
      ]
    },
    capital: [
      "Monrovia"
    ],
    altSpellings: [
      "LR",
      "Republic of Liberia"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      6.5,
      -9.5
    ],
    landlocked: "Yes",
    borders: [
      "GIN",
      "CIV",
      "SLE"
    ],
    area: 111369,
    demonyms: {
      eng: {
        f: "Liberian",
        m: "Liberian"
      },
      fra: {
        f: "Libérienne",
        m: "Libérien"
      }
    },
    flag: "🇱🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/4VsHsc2oeGeRL3wg6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192780"
    },
    population: 5057677,
    gini: {
      2016: 35.3
    },
    fifa: "LBR",
    car: {
      signs: [
        "LB"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lr.png",
      svg: "https://flagcdn.com/lr.svg",
      alt: "The flag of Liberia is composed of eleven equal horizontal bands of red alternating with white. A blue square bearing a five-pointed white star is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.3,
        -10.8
      ]
    },
    nativeName: [
      "Liberia",
      "Republic of Liberia"
    ]
  },
  {
    name: {
      common: "South Africa",
      official: "Republic of South Africa",
      nativeName: {
        afr: {
          official: "Republiek van Suid-Afrika",
          common: "South Africa"
        },
        eng: {
          official: "Republic of South Africa",
          common: "South Africa"
        },
        nbl: {
          official: "IRiphabliki yeSewula Afrika",
          common: "Sewula Afrika"
        },
        nso: {
          official: "Rephaboliki ya Afrika-Borwa ",
          common: "Afrika-Borwa"
        },
        sot: {
          official: "Rephaboliki ya Afrika Borwa",
          common: "Afrika Borwa"
        },
        ssw: {
          official: "IRiphabhulikhi yeNingizimu Afrika",
          common: "Ningizimu Afrika"
        },
        tsn: {
          official: "Rephaboliki ya Aforika Borwa",
          common: "Aforika Borwa"
        },
        tso: {
          official: "Riphabliki ra Afrika Dzonga",
          common: "Afrika Dzonga"
        },
        ven: {
          official: "Riphabuḽiki ya Afurika Tshipembe",
          common: "Afurika Tshipembe"
        },
        xho: {
          official: "IRiphabliki yaseMzantsi Afrika",
          common: "Mzantsi Afrika"
        },
        zul: {
          official: "IRiphabliki yaseNingizimu Afrika",
          common: "Ningizimu Afrika"
        }
      }
    },
    tld: [
      ".za"
    ],
    cca2: "ZA",
    ccn3: "710",
    cca3: "ZAF",
    cioc: "RSA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "South African rand R"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "7"
      ]
    },
    capital: [
      "Pretoria",
      "Bloemfontein",
      "Cape Town"
    ],
    altSpellings: [
      "ZA",
      "RSA",
      "Suid-Afrika",
      "Republic of South Africa"
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      afr: "Afrikaans",
      eng: "English",
      nbl: "Southern Ndebele",
      nso: "Northern Sotho",
      sot: "Southern Sotho",
      ssw: "Swazi",
      tsn: "Tswana",
      tso: "Tsonga",
      ven: "Venda",
      xho: "Xhosa",
      zul: "Zulu"
    },
    latlng: [
      -29,
      24
    ],
    landlocked: "Yes",
    borders: [
      "BWA",
      "LSO",
      "MOZ",
      "NAM",
      "SWZ",
      "ZWE"
    ],
    area: 1221037,
    demonyms: {
      eng: {
        f: "South African",
        m: "South African"
      },
      fra: {
        f: "Sud-africaine",
        m: "Sud-africain"
      }
    },
    flag: "🇿🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/CLCZ1R8Uz1KpYhRv6",
      openStreetMaps: "https://www.openstreetmap.org/relation/87565"
    },
    population: 59308690,
    gini: {
      2014: 63
    },
    fifa: "RSA",
    car: {
      signs: [
        "ZA"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/za.png",
      svg: "https://flagcdn.com/za.svg",
      alt: "The flag of South Africa is composed of two equal horizontal bands of red and blue, with a yellow-edged black isosceles triangle superimposed on the hoist side of the field. This triangle has its base centered on the hoist end, spans about two-fifth the width and two-third the height of the field, and is enclosed on its sides by the arms of a white-edged green horizontally oriented Y-shaped band which extends along the boundary of the red and blue bands to the fly end of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/za.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/za.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -25.7,
        28.22
      ]
    },
    nativeName: [
      "South Africa",
      "Republic of South Africa",
      "Republiek van Suid-Afrika",
      "IRiphabliki yeSewula Afrika",
      "Sewula Afrika",
      "Rephaboliki ya Afrika-Borwa ",
      "Afrika-Borwa",
      "Rephaboliki ya Afrika Borwa",
      "Afrika Borwa",
      "IRiphabhulikhi yeNingizimu Afrika",
      "Ningizimu Afrika",
      "Rephaboliki ya Aforika Borwa",
      "Aforika Borwa",
      "Riphabliki ra Afrika Dzonga",
      "Afrika Dzonga",
      "Riphabuḽiki ya Afurika Tshipembe",
      "Afurika Tshipembe",
      "IRiphabliki yaseMzantsi Afrika",
      "Mzantsi Afrika",
      "IRiphabliki yaseNingizimu Afrika"
    ]
  },
  {
    name: {
      common: "Chile",
      official: "Republic of Chile",
      nativeName: {
        spa: {
          official: "República de Chile",
          common: "Chile"
        }
      }
    },
    tld: [
      ".cl"
    ],
    cca2: "CL",
    ccn3: "152",
    cca3: "CHL",
    cioc: "CHI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Chilean peso $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "6"
      ]
    },
    capital: [
      "Santiago"
    ],
    altSpellings: [
      "CL",
      "Republic of Chile",
      "República de Chile"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      -30,
      -71
    ],
    landlocked: "Yes",
    borders: [
      "ARG",
      "BOL",
      "PER"
    ],
    area: 756102,
    demonyms: {
      eng: {
        f: "Chilean",
        m: "Chilean"
      },
      fra: {
        f: "Chilienne",
        m: "Chilien"
      }
    },
    flag: "🇨🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/XboxyNHh2fAjCPNn9",
      openStreetMaps: "https://www.openstreetmap.org/relation/167454"
    },
    population: 19116209,
    gini: {
      2017: 44.4
    },
    fifa: "CHI",
    car: {
      signs: [
        "RCH"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00",
      "UTC-04:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cl.png",
      svg: "https://flagcdn.com/cl.svg",
      alt: "The flag of Chile is composed of two equal horizontal bands of white and red, with a blue square of the same height as the white band superimposed in the canton. A white five-pointed star is centered in the blue square."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cl.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cl.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -33.45,
        -70.67
      ]
    },
    nativeName: [
      "Chile",
      "Republic of Chile",
      "República de Chile"
    ]
  },
  {
    name: {
      common: "Malta",
      official: "Republic of Malta",
      nativeName: {
        eng: {
          official: "Republic of Malta",
          common: "Malta"
        },
        mlt: {
          official: "Repubblika ta ' Malta",
          common: "Malta"
        }
      }
    },
    tld: [
      ".mt"
    ],
    cca2: "MT",
    ccn3: "470",
    cca3: "MLT",
    cioc: "MLT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "56"
      ]
    },
    capital: [
      "Valletta"
    ],
    altSpellings: [
      "MT",
      "Republic of Malta",
      "Repubblika ta' Malta"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      eng: "English",
      mlt: "Maltese"
    },
    latlng: [
      35.9375,
      14.3754
    ],
    landlocked: "Yes",
    area: 316,
    demonyms: {
      eng: {
        f: "Maltese",
        m: "Maltese"
      },
      fra: {
        f: "Maltaise",
        m: "Maltais"
      }
    },
    flag: "🇲🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/skXCqguxDxxEKVk47",
      openStreetMaps: "https://www.openstreetmap.org/relation/365307"
    },
    population: 525285,
    gini: {
      2018: 28.7
    },
    fifa: "MLT",
    car: {
      signs: [
        "M"
      ],
      side: "left"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mt.png",
      svg: "https://flagcdn.com/mt.svg",
      alt: "The flag of Malta is composed of two equal vertical bands of white and red. A representation of the George cross edged in red is situated on the upper hoist-side corner of the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mt.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mt.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        35.88,
        14.5
      ]
    },
    nativeName: [
      "Malta",
      "Republic of Malta",
      "Repubblika ta ' Malta"
    ]
  },
  {
    name: {
      common: "Kiribati",
      official: "Independent and Sovereign Republic of Kiribati",
      nativeName: {
        eng: {
          official: "Independent and Sovereign Republic of Kiribati",
          common: "Kiribati"
        },
        gil: {
          official: "Ribaberiki Kiribati",
          common: "Kiribati"
        }
      }
    },
    tld: [
      ".ki"
    ],
    cca2: "KI",
    ccn3: "296",
    cca3: "KIR",
    cioc: "KIR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Australian dollar $",
      "Kiribati dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "86"
      ]
    },
    capital: [
      "South Tarawa"
    ],
    altSpellings: [
      "KI",
      "Republic of Kiribati",
      "Ribaberiki Kiribati"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      eng: "English",
      gil: "Gilbertese"
    },
    latlng: [
      1.41666666,
      173
    ],
    landlocked: "Yes",
    area: 811,
    demonyms: {
      eng: {
        f: "I-Kiribati",
        m: "I-Kiribati"
      },
      fra: {
        f: "Kiribatienne",
        m: "Kiribatien"
      }
    },
    flag: "🇰🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/NBfYvrndW4skAimw9",
      openStreetMaps: "https://www.openstreetmap.org/relation/571178"
    },
    population: 119446,
    gini: {
      2006: 37
    },
    car: {
      signs: [
        "KIR"
      ],
      side: "left"
    },
    timezones: [
      "UTC+12:00",
      "UTC+13:00",
      "UTC+14:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ki.png",
      svg: "https://flagcdn.com/ki.svg",
      alt: "The flag of Kiribati is divided into two halves. While the upper half has a red field, at the center of which is a yellow frigate bird flying over the top half of a rising yellow sun with seventeen visible rays, the lower half is composed of six horizontal wavy bands of white alternating with blue to depict the ocean."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ki.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ki.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        1.33,
        172.98
      ]
    },
    nativeName: [
      "Kiribati",
      "Independent and Sovereign Republic of Kiribati",
      "Ribaberiki Kiribati"
    ]
  },
  {
    name: {
      common: "Cape Verde",
      official: "Republic of Cabo Verde",
      nativeName: {
        por: {
          official: "República de Cabo Verde",
          common: "Cabo Verde"
        }
      }
    },
    tld: [
      ".cv"
    ],
    cca2: "CV",
    ccn3: "132",
    cca3: "CPV",
    cioc: "CPV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Cape Verdean escudo Esc"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "38"
      ]
    },
    capital: [
      "Praia"
    ],
    altSpellings: [
      "CV",
      "Republic of Cabo Verde",
      "República de Cabo Verde"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      por: "Portuguese"
    },
    latlng: [
      16.5388,
      23.0418
    ],
    landlocked: "Yes",
    area: 4033,
    demonyms: {
      eng: {
        f: "Cape Verdian",
        m: "Cape Verdian"
      },
      fra: {
        f: "Cap-verdienne",
        m: "Cap-verdien"
      }
    },
    flag: "🇨🇻",
    maps: {
      googleMaps: "https://goo.gl/maps/Kc9vy5ChjuiAgMfXA",
      openStreetMaps: "https://www.openstreetmap.org/relation/535774"
    },
    population: 555988,
    gini: {
      2015: 42.4
    },
    fifa: "CPV",
    car: {
      signs: [
        "CV"
      ],
      side: "right"
    },
    timezones: [
      "UTC-01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cv.png",
      svg: "https://flagcdn.com/cv.svg",
      alt: "The flag of Cape Verde is composed of five horizontal bands of blue, white, red, white and blue in the ratio of 6:1:1:1:3. A ring of ten five-pointed yellow stars is centered at three-eighth of the height from the bottom edge and three-eighth of the width from the hoist end of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cv.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cv.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14.92,
        -23.52
      ]
    },
    nativeName: [
      "Cape Verde",
      "Republic of Cabo Verde",
      "República de Cabo Verde",
      "Cabo Verde"
    ]
  },
  {
    name: {
      common: "Jersey",
      official: "Bailiwick of Jersey",
      nativeName: {
        eng: {
          official: "Bailiwick of Jersey",
          common: "Jersey"
        },
        fra: {
          official: "Bailliage de Jersey",
          common: "Jersey"
        },
        nrf: {
          official: "Bailliage dé Jèrri",
          common: "Jèrri"
        }
      }
    },
    tld: [
      ".je"
    ],
    cca2: "JE",
    ccn3: "832",
    cca3: "JEY",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "British pound £",
      "Jersey pound £"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Saint Helier"
    ],
    altSpellings: [
      "JE",
      "Bailiwick of Jersey",
      "Bailliage de Jersey",
      "Bailliage dé Jèrri"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      eng: "English",
      fra: "French",
      nrf: "Jèrriais"
    },
    latlng: [
      49.25,
      -2.16666666
    ],
    landlocked: "Yes",
    area: 116,
    demonyms: {
      eng: {
        f: "Channel Islander",
        m: "Channel Islander"
      },
      fra: {
        f: "Jersiaise",
        m: "Jersiais"
      }
    },
    flag: "🇯🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/rXG8GZZtsqK92kTCA",
      openStreetMaps: "https://www.openstreetmap.org/relation/367988"
    },
    population: 100800,
    car: {
      signs: [
        "GBJ"
      ],
      side: "left"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/je.png",
      svg: "https://flagcdn.com/je.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/je.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/je.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        49.18,
        -2.1
      ]
    },
    nativeName: [
      "Jersey",
      "Bailiwick of Jersey",
      "Bailliage de Jersey",
      "Bailliage dé Jèrri",
      "Jèrri"
    ]
  },
  {
    name: {
      common: "Iceland",
      official: "Iceland",
      nativeName: {
        isl: {
          official: "Ísland",
          common: "Ísland"
        }
      }
    },
    tld: [
      ".is"
    ],
    cca2: "IS",
    ccn3: "352",
    cca3: "ISL",
    cioc: "ISL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Icelandic króna kr"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "54"
      ]
    },
    capital: [
      "Reykjavik"
    ],
    altSpellings: [
      "IS",
      "Island",
      "Republic of Iceland",
      "Lýðveldið Ísland"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      isl: "Icelandic"
    },
    latlng: [
      65,
      -18
    ],
    landlocked: "Yes",
    area: 103000,
    demonyms: {
      eng: {
        f: "Icelander",
        m: "Icelander"
      },
      fra: {
        f: "Islandaise",
        m: "Islandais"
      }
    },
    flag: "🇮🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/WxFWSQuc3oamNxoE6",
      openStreetMaps: "https://www.openstreetmap.org/relation/299133"
    },
    population: 366425,
    gini: {
      2017: 26.1
    },
    fifa: "ISL",
    car: {
      signs: [
        "IS"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/is.png",
      svg: "https://flagcdn.com/is.svg",
      alt: "The flag of Iceland has a blue field with a large white-edged red cross that extends to the edges of the field. The vertical part of this cross is offset towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/is.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/is.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        64.15,
        -21.95
      ]
    },
    nativeName: [
      "Iceland",
      "Ísland"
    ]
  },
  {
    name: {
      common: "Gambia",
      official: "Republic of the Gambia",
      nativeName: {
        eng: {
          official: "Republic of the Gambia",
          common: "Gambia"
        }
      }
    },
    tld: [
      ".gm"
    ],
    cca2: "GM",
    ccn3: "270",
    cca3: "GMB",
    cioc: "GAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "dalasi D"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "20"
      ]
    },
    capital: [
      "Banjul"
    ],
    altSpellings: [
      "GM",
      "Republic of the Gambia"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      13.46666666,
      -16.56666666
    ],
    landlocked: "Yes",
    borders: [
      "SEN"
    ],
    area: 10689,
    demonyms: {
      eng: {
        f: "Gambian",
        m: "Gambian"
      },
      fra: {
        f: "Gambienne",
        m: "Gambien"
      }
    },
    flag: "🇬🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/bbGBCxxtfD2A9Z4m6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192774"
    },
    population: 2416664,
    gini: {
      2015: 35.9
    },
    fifa: "GAM",
    car: {
      signs: [
        "WAG"
      ],
      side: "right"
    },
    timezones: [
      "UTC+00:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gm.png",
      svg: "https://flagcdn.com/gm.svg",
      alt: "The flag of Gambia is composed of three equal horizontal bands of red, blue with white top and bottom edges, and green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.45,
        -16.57
      ]
    },
    nativeName: [
      "Gambia",
      "Republic of the Gambia"
    ]
  },
  {
    name: {
      common: "Vanuatu",
      official: "Republic of Vanuatu",
      nativeName: {
        bis: {
          official: "Ripablik blong Vanuatu",
          common: "Vanuatu"
        },
        eng: {
          official: "Republic of Vanuatu",
          common: "Vanuatu"
        },
        fra: {
          official: "République de Vanuatu",
          common: "Vanuatu"
        }
      }
    },
    tld: [
      ".vu"
    ],
    cca2: "VU",
    ccn3: "548",
    cca3: "VUT",
    cioc: "VAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Vanuatu vatu Vt"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "78"
      ]
    },
    capital: [
      "Port Vila"
    ],
    altSpellings: [
      "VU",
      "Republic of Vanuatu",
      "Ripablik blong Vanuatu",
      "République de Vanuatu"
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: {
      bis: "Bislama",
      eng: "English",
      fra: "French"
    },
    latlng: [
      -16,
      167
    ],
    landlocked: "Yes",
    area: 12189,
    demonyms: {
      eng: {
        f: "Ni-Vanuatu",
        m: "Ni-Vanuatu"
      },
      fra: {
        f: "Vanuatuane",
        m: "Vanuatuan"
      }
    },
    flag: "🇻🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/hwAjehcT7VfvP5zJ8",
      openStreetMaps: "https://www.openstreetmap.org/relation/2177246"
    },
    population: 307150,
    gini: {
      2010: 37.6
    },
    fifa: "VAN",
    car: {
      signs: [
        "VU"
      ],
      side: "right"
    },
    timezones: [
      "UTC+11:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/vu.png",
      svg: "https://flagcdn.com/vu.svg",
      alt: "The flag of Vanuatu is composed of two equal horizontal bands of red and green, with a black isosceles triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about two-fifth the width of the field and is enclosed on its sides by the arms of a thin black-edged yellow horizontally oriented Y-shaped band which extends along the boundary of the red and green bands to the fly end of the field. A yellow boar's tusk encircling two yellow crossed namele leaves is centered in the triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/vu.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/vu.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -17.73,
        168.32
      ]
    },
    nativeName: [
      "Vanuatu",
      "Republic of Vanuatu",
      "Ripablik blong Vanuatu",
      "République de Vanuatu"
    ]
  },
  {
    name: {
      common: "Uruguay",
      official: "Oriental Republic of Uruguay",
      nativeName: {
        spa: {
          official: "República Oriental del Uruguay",
          common: "Uruguay"
        }
      }
    },
    tld: [
      ".uy"
    ],
    cca2: "UY",
    ccn3: "858",
    cca3: "URY",
    cioc: "URU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Uruguayan peso $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "98"
      ]
    },
    capital: [
      "Montevideo"
    ],
    altSpellings: [
      "UY",
      "Oriental Republic of Uruguay",
      "República Oriental del Uruguay"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      -33,
      -56
    ],
    landlocked: "Yes",
    borders: [
      "ARG",
      "BRA"
    ],
    area: 181034,
    demonyms: {
      eng: {
        f: "Uruguayan",
        m: "Uruguayan"
      },
      fra: {
        f: "Uruguayenne",
        m: "Uruguayen"
      }
    },
    flag: "🇺🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/tiQ9Baekb1jQtDSD9",
      openStreetMaps: "https://www.openstreetmap.org/relation/287072"
    },
    population: 3473727,
    gini: {
      2019: 39.7
    },
    fifa: "URU",
    car: {
      signs: [
        "ROU"
      ],
      side: "right"
    },
    timezones: [
      "UTC-03:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/uy.png",
      svg: "https://flagcdn.com/uy.svg",
      alt: "The flag of Uruguay is composed of nine equal horizontal bands of white alternating with blue, with a white square superimposed in the canton. In the white square is a yellow sun bearing a human face — the Sun of May — from which sixteen rays extend. The sun's rays alternate between triangular and wavy."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/uy.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/uy.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -34.85,
        -56.17
      ]
    },
    nativeName: [
      "Uruguay",
      "Oriental Republic of Uruguay",
      "República Oriental del Uruguay"
    ]
  },
  {
    name: {
      common: "Oman",
      official: "Sultanate of Oman",
      nativeName: {
        ara: {
          official: "سلطنة عمان",
          common: "عمان"
        }
      }
    },
    tld: [
      ".om"
    ],
    cca2: "OM",
    ccn3: "512",
    cca3: "OMN",
    cioc: "OMA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Omani rial ر.ع."
    ],
    idd: {
      root: "+9",
      suffixes: [
        "68"
      ]
    },
    capital: [
      "Muscat"
    ],
    altSpellings: [
      "OM",
      "Sultanate of Oman",
      "Salṭanat ʻUmān"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      21,
      57
    ],
    landlocked: "Yes",
    borders: [
      "SAU",
      "ARE",
      "YEM"
    ],
    area: 309500,
    demonyms: {
      eng: {
        f: "Omani",
        m: "Omani"
      },
      fra: {
        f: "Omanaise",
        m: "Omanais"
      }
    },
    flag: "🇴🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/L2BoXoAwDDwWecnw5",
      openStreetMaps: "https://www.openstreetmap.org/relation/305138"
    },
    population: 5106622,
    fifa: "OMA",
    car: {
      signs: [
        "OM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/om.png",
      svg: "https://flagcdn.com/om.svg",
      alt: "The flag of Oman features a red vertical band on the hoist side that takes up about one-fourth the width of the field, and three equal horizontal bands of white, red and green adjoining the vertical band. At the top of the vertical band is the white emblem of Oman."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/om.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/om.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        23.62,
        58.58
      ]
    },
    nativeName: [
      "Oman",
      "Sultanate of Oman",
      "سلطنة عمان",
      "عمان"
    ]
  },
  {
    name: {
      common: "Sudan",
      official: "Republic of the Sudan",
      nativeName: {
        ara: {
          official: "جمهورية السودان",
          common: "السودان"
        },
        eng: {
          official: "Republic of the Sudan",
          common: "Sudan"
        }
      }
    },
    tld: [
      ".sd"
    ],
    cca2: "SD",
    ccn3: "729",
    cca3: "SDN",
    cioc: "SUD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Sudanese pound undefined"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "49"
      ]
    },
    capital: [
      "Khartoum"
    ],
    altSpellings: [
      "SD",
      "Republic of the Sudan",
      "Jumhūrīyat as-Sūdān"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ara: "Arabic",
      eng: "English"
    },
    latlng: [
      15,
      30
    ],
    landlocked: "Yes",
    borders: [
      "CAF",
      "TCD",
      "EGY",
      "ERI",
      "ETH",
      "LBY",
      "SSD"
    ],
    area: 1886068,
    demonyms: {
      eng: {
        f: "Sudanese",
        m: "Sudanese"
      },
      fra: {
        f: "Soudanaise",
        m: "Soudanais"
      }
    },
    flag: "🇸🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/bNW7YUJCaqR8zcXn7",
      openStreetMaps: "https://www.openstreetmap.org/relation/192789"
    },
    population: 43849269,
    gini: {
      2014: 34.2
    },
    fifa: "SDN",
    car: {
      signs: [
        "SUD"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sd.png",
      svg: "https://flagcdn.com/sd.svg",
      alt: "The flag of Sudan is composed of three equal horizontal bands of red, white and black, with a green isosceles triangle superimposed on the hoist side. The green triangle spans about two-fifth the width of the field with its base on the hoist end."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sd.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sd.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        15.6,
        32.53
      ]
    },
    nativeName: [
      "Sudan",
      "Republic of the Sudan",
      "جمهورية السودان",
      "السودان"
    ]
  },
  {
    name: {
      common: "India",
      official: "Republic of India",
      nativeName: {
        eng: {
          official: "Republic of India",
          common: "India"
        },
        hin: {
          official: "भारत गणराज्य",
          common: "भारत"
        },
        tam: {
          official: "இந்தியக் குடியரசு",
          common: "இந்தியா"
        }
      }
    },
    tld: [
      ".in"
    ],
    cca2: "IN",
    ccn3: "356",
    cca3: "IND",
    cioc: "IND",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Indian rupee ₹"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "New Delhi"
    ],
    altSpellings: [
      "IN",
      "Bhārat",
      "Republic of India",
      "Bharat Ganrajya",
      "இந்தியா"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      eng: "English",
      hin: "Hindi",
      tam: "Tamil"
    },
    latlng: [
      20,
      77
    ],
    landlocked: "Yes",
    borders: [
      "BGD",
      "BTN",
      "MMR",
      "CHN",
      "NPL",
      "PAK"
    ],
    area: 3287590,
    demonyms: {
      eng: {
        f: "Indian",
        m: "Indian"
      },
      fra: {
        f: "Indienne",
        m: "Indien"
      }
    },
    flag: "🇮🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/WSk3fLwG4vtPQetp7",
      openStreetMaps: "https://www.openstreetmap.org/relation/304716"
    },
    population: 1380004385,
    gini: {
      2011: 35.7
    },
    fifa: "IND",
    car: {
      signs: [
        "IND"
      ],
      side: "left"
    },
    timezones: [
      "UTC+05:30"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/in.png",
      svg: "https://flagcdn.com/in.svg",
      alt: "The flag of India is composed of three equal horizontal bands of saffron, white and green. A navy blue wheel with twenty-four spokes — the Ashoka Chakra — is centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/in.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/in.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        28.6,
        77.2
      ]
    },
    nativeName: [
      "India",
      "Republic of India",
      "भारत गणराज्य",
      "भारत",
      "இந்தியக் குடியரசு",
      "இந்தியா"
    ]
  },
  {
    name: {
      common: "Laos",
      official: "Lao People's Democratic Republic",
      nativeName: {
        lao: {
          official: "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
          common: "ສປປລາວ"
        }
      }
    },
    tld: [
      ".la"
    ],
    cca2: "LA",
    ccn3: "418",
    cca3: "LAO",
    cioc: "LAO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Lao kip ₭"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "56"
      ]
    },
    capital: [
      "Vientiane"
    ],
    altSpellings: [
      "LA",
      "Lao",
      "Lao People's Democratic Republic",
      "Sathalanalat Paxathipatai Paxaxon Lao"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      lao: "Lao"
    },
    latlng: [
      18,
      105
    ],
    landlocked: "Yes",
    borders: [
      "MMR",
      "KHM",
      "CHN",
      "THA",
      "VNM"
    ],
    area: 236800,
    demonyms: {
      eng: {
        f: "Laotian",
        m: "Laotian"
      },
      fra: {
        f: "Laotienne",
        m: "Laotien"
      }
    },
    flag: "🇱🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/F3asVB7sRKgSnwbE7",
      openStreetMaps: "https://www.openstreetmap.org/relation/49903"
    },
    population: 7275556,
    gini: {
      2018: 38.8
    },
    fifa: "LAO",
    car: {
      signs: [
        "LAO"
      ],
      side: "right"
    },
    timezones: [
      "UTC+07:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/la.png",
      svg: "https://flagcdn.com/la.svg",
      alt: "The flag of Laos is composed of three horizontal bands of red, blue and red. The blue band is twice the height of the red bands and bears a white circle at its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/la.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/la.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        17.97,
        102.6
      ]
    },
    nativeName: [
      "Laos",
      "Lao People's Democratic Republic",
      "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
      "ສປປລາວ"
    ]
  },
  {
    name: {
      common: "Aruba",
      official: "Aruba",
      nativeName: {
        nld: {
          official: "Aruba",
          common: "Aruba"
        },
        pap: {
          official: "Aruba",
          common: "Aruba"
        }
      }
    },
    tld: [
      ".aw"
    ],
    cca2: "AW",
    ccn3: "533",
    cca3: "ABW",
    cioc: "ARU",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Aruban florin ƒ"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "97"
      ]
    },
    capital: [
      "Oranjestad"
    ],
    altSpellings: [
      "AW"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      nld: "Dutch",
      pap: "Papiamento"
    },
    latlng: [
      12.5,
      -69.96666666
    ],
    landlocked: "Yes",
    area: 180,
    demonyms: {
      eng: {
        f: "Aruban",
        m: "Aruban"
      },
      fra: {
        f: "Arubaise",
        m: "Arubais"
      }
    },
    flag: "🇦🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/8hopbQqifHAgyZyg8",
      openStreetMaps: "https://www.openstreetmap.org/relation/1231749"
    },
    population: 106766,
    fifa: "ARU",
    car: {
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/aw.png",
      svg: "https://flagcdn.com/aw.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/aw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/aw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.52,
        -70.03
      ]
    },
    nativeName: [
      "Aruba"
    ]
  },
  {
    name: {
      common: "Martinique",
      official: "Martinique",
      nativeName: {
        fra: {
          official: "Martinique",
          common: "Martinique"
        }
      }
    },
    tld: [
      ".mq"
    ],
    cca2: "MQ",
    ccn3: "474",
    cca3: "MTQ",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "96"
      ]
    },
    capital: [
      "Fort-de-France"
    ],
    altSpellings: [
      "MQ"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      fra: "French"
    },
    latlng: [
      14.666667,
      -61
    ],
    landlocked: "Yes",
    area: 1128,
    demonyms: {
      eng: {
        f: "Martinican",
        m: "Martinican"
      },
      fra: {
        f: "Martiniquaise",
        m: "Martiniquais"
      }
    },
    flag: "🇲🇶",
    maps: {
      googleMaps: "https://goo.gl/maps/87ER7sDAFU7JjcvR6",
      openStreetMaps: "https://www.openstreetmap.org/relation/2473088"
    },
    population: 378243,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mq.png",
      svg: "https://flagcdn.com/mq.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mq.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mq.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14.6,
        -61.08
      ]
    },
    nativeName: [
      "Martinique"
    ]
  },
  {
    name: {
      common: "Comoros",
      official: "Union of the Comoros",
      nativeName: {
        ara: {
          official: "الاتحاد القمري",
          common: "القمر\u200e"
        },
        fra: {
          official: "Union des Comores",
          common: "Comores"
        },
        zdj: {
          official: "Udzima wa Komori",
          common: "Komori"
        }
      }
    },
    tld: [
      ".km"
    ],
    cca2: "KM",
    ccn3: "174",
    cca3: "COM",
    cioc: "COM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Comorian franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "69"
      ]
    },
    capital: [
      "Moroni"
    ],
    altSpellings: [
      "KM",
      "Union of the Comoros",
      "Union des Comores",
      "Udzima wa Komori",
      "al-Ittiḥād al-Qumurī"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      ara: "Arabic",
      fra: "French",
      zdj: "Comorian"
    },
    latlng: [
      -12.16666666,
      44.25
    ],
    landlocked: "Yes",
    area: 1862,
    demonyms: {
      eng: {
        f: "Comoran",
        m: "Comoran"
      },
      fra: {
        f: "Comorienne",
        m: "Comorien"
      }
    },
    flag: "🇰🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/eas4GP28C1GyStnu6",
      openStreetMaps: "https://www.openstreetmap.org/relation/535790"
    },
    population: 869595,
    gini: {
      2014: 45.3
    },
    fifa: "COM",
    car: {
      signs: [
        "COM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/km.png",
      svg: "https://flagcdn.com/km.svg",
      alt: "The flag of Comoros is composed of four equal horizontal bands of yellow, white, red and blue, with a green isosceles triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a fly-side facing white crescent and four five-pointed white stars arranged in a vertical line along the opening of the crescent."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/km.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/km.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -11.7,
        43.23
      ]
    },
    nativeName: [
      "Comoros",
      "Union of the Comoros",
      "الاتحاد القمري",
      "القمر\u200e",
          "Union des Comores",
      "Comores",
      "Udzima wa Komori",
      "Komori"
    ]
  },
  {
    name: {
      common: "Spain",
      official: "Kingdom of Spain",
      nativeName: {
        spa: {
          official: "Reino de España",
          common: "España"
        }
      }
    },
    tld: [
      ".es"
    ],
    cca2: "ES",
    ccn3: "724",
    cca3: "ESP",
    cioc: "ESP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Madrid"
    ],
    altSpellings: [
      "ES",
      "Kingdom of Spain",
      "Reino de España"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      spa: "Spanish",
      cat: "Catalan",
      eus: "Basque",
      glc: "Galician"
    },
    latlng: [
      40,
      -4
    ],
    landlocked: "Yes",
    borders: [
      "AND",
      "FRA",
      "GIB",
      "PRT",
      "MAR"
    ],
    area: 505992,
    demonyms: {
      eng: {
        f: "Spanish",
        m: "Spanish"
      },
      fra: {
        f: "Espagnole",
        m: "Espagnol"
      }
    },
    flag: "🇪🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/138JaXW8EZzRVitY9",
      openStreetMaps: "https://www.openstreetmap.org/relation/1311341"
    },
    population: 47351567,
    gini: {
      2018: 34.7
    },
    fifa: "ESP",
    car: {
      signs: [
        "E"
      ],
      side: "right"
    },
    timezones: [
      "UTC",
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/es.png",
      svg: "https://flagcdn.com/es.svg",
      alt: "The flag of Spain is composed of three horizontal bands of red, yellow and red, with the yellow band twice the height of the red bands. In the yellow band is the national coat of arms offset slightly towards the hoist side of center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/es.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/es.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        40.4,
        -3.68
      ]
    },
    nativeName: [
      "Spain",
      "Kingdom of Spain",
      "Reino de España",
      "España"
    ]
  },
  {
    name: {
      common: "Antigua and Barbuda",
      official: "Antigua and Barbuda",
      nativeName: {
        eng: {
          official: "Antigua and Barbuda",
          common: "Antigua and Barbuda"
        }
      }
    },
    tld: [
      ".ag"
    ],
    cca2: "AG",
    ccn3: "028",
    cca3: "ATG",
    cioc: "ANT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "268"
      ]
    },
    capital: [
      "Saint John's"
    ],
    altSpellings: [
      "AG"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      17.05,
      -61.8
    ],
    landlocked: "Yes",
    area: 442,
    demonyms: {
      eng: {
        f: "Antiguan, Barbudan",
        m: "Antiguan, Barbudan"
      },
      fra: {
        f: "Antiguaise et barbudienne",
        m: "Antiguaise et barbudien"
      }
    },
    flag: "🇦🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/fnye4wGJ1RzC9jpX9",
      openStreetMaps: "https://www.openstreetmap.org/relation/536900"
    },
    population: 97928,
    fifa: "ATG",
    car: {
      signs: [
        "AG"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ag.png",
      svg: "https://flagcdn.com/ag.svg",
      alt: "The flag of Antigua and Barbuda has a red field with an inverted isosceles triangle based on the top edge and spanning the height of the field. This triangle has three horizontal bands of black, light blue and white, with the light blue band half the height of the two other bands. The top half of a golden-yellow sun is situated in the lower two-third of the black band to depict a rising sun."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ag.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ag.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        17.12,
        -61.85
      ]
    },
    nativeName: [
      "Antigua and Barbuda"
    ]
  },
  {
    name: {
      common: "Uzbekistan",
      official: "Republic of Uzbekistan",
      nativeName: {
        rus: {
          official: "Республика Узбекистан",
          common: "Узбекистан"
        },
        uzb: {
          official: "O'zbekiston Respublikasi",
          common: "O‘zbekiston"
        }
      }
    },
    tld: [
      ".uz"
    ],
    cca2: "UZ",
    ccn3: "860",
    cca3: "UZB",
    cioc: "UZB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Uzbekistani soʻm so'm"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "98"
      ]
    },
    capital: [
      "Tashkent"
    ],
    altSpellings: [
      "UZ",
      "Republic of Uzbekistan",
      "O‘zbekiston Respublikasi",
      "Ўзбекистон Республикаси"
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: {
      rus: "Russian",
      uzb: "Uzbek"
    },
    latlng: [
      41,
      64
    ],
    landlocked: "Yes",
    borders: [
      "AFG",
      "KAZ",
      "KGZ",
      "TJK",
      "TKM"
    ],
    area: 447400,
    demonyms: {
      eng: {
        f: "Uzbekistani",
        m: "Uzbekistani"
      },
      fra: {
        f: "Ouzbèke",
        m: "Ouzbèke"
      }
    },
    flag: "🇺🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/AJpo6MjMx23qSWCz8",
      openStreetMaps: "https://www.openstreetmap.org/relation/196240"
    },
    population: 34232050,
    gini: {
      2003: 35.3
    },
    fifa: "UZB",
    car: {
      signs: [
        "UZ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/uz.png",
      svg: "https://flagcdn.com/uz.svg",
      alt: "The flag of Uzbekistan is composed of three equal horizontal bands of turquoise, white with red top and bottom edges, and green. On the hoist side of the turquoise band is a fly-side facing white crescent and twelve five-pointed white stars arranged just outside the crescent opening in three rows comprising three, four and five stars."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/uz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/uz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        41.32,
        69.25
      ]
    },
    nativeName: [
      "Uzbekistan",
      "Republic of Uzbekistan",
      "Республика Узбекистан",
      "Узбекистан",
      "O'zbekiston Respublikasi",
      "O‘zbekiston"
    ]
  },
  {
    name: {
      common: "Maldives",
      official: "Republic of the Maldives",
      nativeName: {
        div: {
          official: "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
          common: "ދިވެހިރާއްޖޭގެ"
        }
      }
    },
    tld: [
      ".mv"
    ],
    cca2: "MV",
    ccn3: "462",
    cca3: "MDV",
    cioc: "MDV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Maldivian rufiyaa .ރ"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "60"
      ]
    },
    capital: [
      "Malé"
    ],
    altSpellings: [
      "MV",
      "Maldive Islands",
      "Republic of the Maldives",
      "Dhivehi Raajjeyge Jumhooriyya"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      div: "Maldivian"
    },
    latlng: [
      3.25,
      73
    ],
    landlocked: "Yes",
    area: 300,
    demonyms: {
      eng: {
        f: "Maldivan",
        m: "Maldivan"
      },
      fra: {
        f: "Maldivienne",
        m: "Maldivien"
      }
    },
    flag: "🇲🇻",
    maps: {
      googleMaps: "https://goo.gl/maps/MNAWGq9vEdbZ9vUV7",
      openStreetMaps: "https://www.openstreetmap.org/relation/536773"
    },
    population: 540542,
    gini: {
      2016: 31.3
    },
    fifa: "MDV",
    car: {
      signs: [
        "MV"
      ],
      side: "left"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mv.png",
      svg: "https://flagcdn.com/mv.svg",
      alt: "The flag of Maldives has a red field, at the center of which is a large green rectangle bearing a fly-side facing white crescent."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mv.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mv.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        4.17,
        73.51
      ]
    },
    nativeName: [
      "Maldives",
      "Republic of the Maldives",
      "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
      "ދިވެހިރާއްޖޭގެ"
    ]
  },
  {
    name: {
      common: "Gibraltar",
      official: "Gibraltar",
      nativeName: {
        eng: {
          official: "Gibraltar",
          common: "Gibraltar"
        }
      }
    },
    tld: [
      ".gi"
    ],
    cca2: "GI",
    ccn3: "292",
    cca3: "GIB",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Gibraltar pound £"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "50"
      ]
    },
    capital: [
      "Gibraltar"
    ],
    altSpellings: [
      "GI"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      eng: "English"
    },
    latlng: [
      36.13333333,
      -5.35
    ],
    landlocked: "Yes",
    borders: [
      "ESP"
    ],
    area: 6,
    demonyms: {
      eng: {
        f: "Gibraltar",
        m: "Gibraltar"
      },
      fra: {
        f: "Gibraltarienne",
        m: "Gibraltarien"
      }
    },
    flag: "🇬🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/CEoHAs1t6byCBhHFA",
      openStreetMaps: "https://www.openstreetmap.org/relation/1278736"
    },
    population: 33691,
    fifa: "GIB",
    car: {
      signs: [
        "GBZ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gi.png",
      svg: "https://flagcdn.com/gi.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gi.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gi.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        36.13,
        -5.35
      ]
    },
    nativeName: [
      "Gibraltar"
    ]
  },
  {
    name: {
      common: "Indonesia",
      official: "Republic of Indonesia",
      nativeName: {
        ind: {
          official: "Republik Indonesia",
          common: "Indonesia"
        }
      }
    },
    tld: [
      ".id"
    ],
    cca2: "ID",
    ccn3: "360",
    cca3: "IDN",
    cioc: "INA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Indonesian rupiah Rp"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "2"
      ]
    },
    capital: [
      "Jakarta"
    ],
    altSpellings: [
      "ID",
      "Republic of Indonesia",
      "Republik Indonesia"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      ind: "Indonesian"
    },
    latlng: [
      -5,
      120
    ],
    landlocked: "Yes",
    borders: [
      "TLS",
      "MYS",
      "PNG"
    ],
    area: 1904569,
    demonyms: {
      eng: {
        f: "Indonesian",
        m: "Indonesian"
      },
      fra: {
        f: "Indonésienne",
        m: "Indonésien"
      }
    },
    flag: "🇮🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/9gfPupm5bffixiFJ6",
      openStreetMaps: "https://www.openstreetmap.org/relation/21335"
    },
    population: 273523621,
    gini: {
      2019: 38.2
    },
    fifa: "IDN",
    car: {
      signs: [
        "RI"
      ],
      side: "left"
    },
    timezones: [
      "UTC+07:00",
      "UTC+08:00",
      "UTC+09:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/id.png",
      svg: "https://flagcdn.com/id.svg",
      alt: "The flag of Indonesia is composed of two equal horizontal bands of red and white."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/id.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/id.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -6.17,
        106.82
      ]
    },
    nativeName: [
      "Indonesia",
      "Republic of Indonesia",
      "Republik Indonesia"
    ]
  },
  {
    name: {
      common: "Pitcairn Islands",
      official: "Pitcairn Group of Islands",
      nativeName: {
        eng: {
          official: "Pitcairn Group of Islands",
          common: "Pitcairn Islands"
        }
      }
    },
    tld: [
      ".pn"
    ],
    cca2: "PN",
    ccn3: "612",
    cca3: "PCN",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "New Zealand dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Adamstown"
    ],
    altSpellings: [
      "PN",
      "Pitcairn",
      "Pitcairn Henderson Ducie and Oeno Islands"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English"
    },
    latlng: [
      -25.06666666,
      -130.1
    ],
    landlocked: "Yes",
    area: 47,
    demonyms: {
      eng: {
        f: "Pitcairn Islander",
        m: "Pitcairn Islander"
      },
      fra: {
        f: "Pitcairnaise",
        m: "Pitcairnais"
      }
    },
    flag: "🇵🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/XGJMnMAigXjXcxSa7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2185375"
    },
    population: 56,
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-08:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pn.png",
      svg: "https://flagcdn.com/pn.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -25.07,
        -130.08
      ]
    },
    nativeName: [
      "Pitcairn Islands",
      "Pitcairn Group of Islands"
    ]
  },
  {
    name: {
      common: "Vietnam",
      official: "Socialist Republic of Vietnam",
      nativeName: {
        vie: {
          official: "Cộng hòa xã hội chủ nghĩa Việt Nam",
          common: "Việt Nam"
        }
      }
    },
    tld: [
      ".vn"
    ],
    cca2: "VN",
    ccn3: "704",
    cca3: "VNM",
    cioc: "VIE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Vietnamese đồng ₫"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Hanoi"
    ],
    altSpellings: [
      "VN",
      "Socialist Republic of Vietnam",
      "Cộng hòa Xã hội chủ nghĩa Việt Nam",
      "Viet Nam"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      vie: "Vietnamese"
    },
    latlng: [
      16.16666666,
      107.83333333
    ],
    landlocked: "Yes",
    borders: [
      "KHM",
      "CHN",
      "LAO"
    ],
    area: 331212,
    demonyms: {
      eng: {
        f: "Vietnamese",
        m: "Vietnamese"
      },
      fra: {
        f: "Vietnamienne",
        m: "Vietnamien"
      }
    },
    flag: "🇻🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/PCpVt9WzdJ9A9nEZ9",
      openStreetMaps: "https://www.openstreetmap.org/relation/49915"
    },
    population: 97338583,
    gini: {
      2018: 35.7
    },
    fifa: "VIE",
    car: {
      signs: [
        "VN"
      ],
      side: "right"
    },
    timezones: [
      "UTC+07:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/vn.png",
      svg: "https://flagcdn.com/vn.svg",
      alt: "The flag of Vietnam features a large five-pointed yellow star on a red field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/vn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/vn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        21.03,
        105.85
      ]
    },
    nativeName: [
      "Vietnam",
      "Socialist Republic of Vietnam",
      "Cộng hòa xã hội chủ nghĩa Việt Nam",
      "Việt Nam"
    ]
  },
  {
    name: {
      common: "Malaysia",
      official: "Malaysia",
      nativeName: {
        eng: {
          official: "Malaysia",
          common: "Malaysia"
        },
        msa: {
          official: "مليسيا",
          common: "مليسيا"
        }
      }
    },
    tld: [
      ".my"
    ],
    cca2: "MY",
    ccn3: "458",
    cca3: "MYS",
    cioc: "MAS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Malaysian ringgit RM"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "0"
      ]
    },
    capital: [
      "Kuala Lumpur"
    ],
    altSpellings: [
      "MY"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      eng: "English",
      msa: "Malay"
    },
    latlng: [
      2.5,
      112.5
    ],
    landlocked: "Yes",
    borders: [
      "BRN",
      "IDN",
      "THA"
    ],
    area: 330803,
    demonyms: {
      eng: {
        f: "Malaysian",
        m: "Malaysian"
      },
      fra: {
        f: "Malaisienne",
        m: "Malaisien"
      }
    },
    flag: "🇲🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/qrY1PNeUXGyXDcPy6",
      openStreetMaps: "https://www.openstreetmap.org/relation/2108121"
    },
    population: 32365998,
    gini: {
      2015: 41.1
    },
    fifa: "MAS",
    car: {
      signs: [
        "MAL"
      ],
      side: "left"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/my.png",
      svg: "https://flagcdn.com/my.svg",
      alt: "The flag of Malaysia is composed of fourteen equal horizontal bands of red alternating with white. A blue rectangle, bearing a fly-side facing yellow crescent and a fourteen-pointed yellow star placed just outside the crescent opening, is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/my.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/my.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        3.17,
        101.7
      ]
    },
    nativeName: [
      "Malaysia",
      "مليسيا"
    ]
  },
  {
    name: {
      common: "Cook Islands",
      official: "Cook Islands",
      nativeName: {
        eng: {
          official: "Cook Islands",
          common: "Cook Islands"
        },
        rar: {
          official: "Kūki 'Āirani",
          common: "Kūki 'Āirani"
        }
      }
    },
    tld: [
      ".ck"
    ],
    cca2: "CK",
    ccn3: "184",
    cca3: "COK",
    cioc: "COK",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Cook Islands dollar $",
      "New Zealand dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "82"
      ]
    },
    capital: [
      "Avarua"
    ],
    altSpellings: [
      "CK",
      "Kūki 'Āirani"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      rar: "Cook Islands Māori"
    },
    latlng: [
      -21.23333333,
      -159.76666666
    ],
    landlocked: "Yes",
    area: 236,
    demonyms: {
      eng: {
        f: "Cook Islander",
        m: "Cook Islander"
      },
      fra: {
        f: "Cookienne",
        m: "Cookien"
      }
    },
    flag: "🇨🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/nrGZrvWRGB4WHgDC9",
      openStreetMaps: "https://www.openstreetmap.org/relation/2184233"
    },
    population: 18100,
    fifa: "COK",
    car: {
      signs: [
        "NZ"
      ],
      side: "left"
    },
    timezones: [
      "UTC-10:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ck.png",
      svg: "https://flagcdn.com/ck.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ck.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ck.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -21.2,
        -159.77
      ]
    },
    nativeName: [
      "Cook Islands",
      "Kūki 'Āirani"
    ]
  },
  {
    name: {
      common: "Eswatini",
      official: "Kingdom of Eswatini",
      nativeName: {
        eng: {
          official: "Kingdom of Eswatini",
          common: "Eswatini"
        },
        ssw: {
          official: "Umbuso weSwatini",
          common: "eSwatini"
        }
      }
    },
    tld: [
      ".sz"
    ],
    cca2: "SZ",
    ccn3: "748",
    cca3: "SWZ",
    cioc: "SWZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Swazi lilangeni L",
      "South African rand R"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "68"
      ]
    },
    capital: [
      "Mbabane"
    ],
    altSpellings: [
      "SZ",
      "Swaziland",
      "weSwatini",
      "Swatini",
      "Ngwane",
      "Kingdom of Eswatini",
      "Umbuso weSwatini"
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      eng: "English",
      ssw: "Swazi"
    },
    latlng: [
      -26.5,
      31.5
    ],
    landlocked: "Yes",
    borders: [
      "MOZ",
      "ZAF"
    ],
    area: 17364,
    demonyms: {
      eng: {
        f: "Swazi",
        m: "Swazi"
      },
      fra: {
        f: "Swazie",
        m: "Swazie"
      }
    },
    flag: "🇸🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/cUY79eqQihFSE8hV6",
      openStreetMaps: "https://www.openstreetmap.org/relation/88210"
    },
    population: 1160164,
    gini: {
      2016: 54.6
    },
    fifa: "SWZ",
    car: {
      signs: [
        "SD"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sz.png",
      svg: "https://flagcdn.com/sz.svg",
      alt: "The flag of Eswatini is composed of three horizontal bands — a large central yellow-edged red band, and a light blue band above and beneath the red band. The red band is three times the height of the blue bands and bears a centered emblem made up of a large black and white Nguni shield covering two spears and a staff decorated with feather tassels, all placed horizontally."
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -26.32,
        31.13
      ]
    },
    nativeName: [
      "Eswatini",
      "Kingdom of Eswatini",
      "Umbuso weSwatini",
      "eSwatini"
    ]
  },
  {
    name: {
      common: "Uganda",
      official: "Republic of Uganda",
      nativeName: {
        eng: {
          official: "Republic of Uganda",
          common: "Uganda"
        },
        swa: {
          official: "Republic of Uganda",
          common: "Uganda"
        }
      }
    },
    tld: [
      ".ug"
    ],
    cca2: "UG",
    ccn3: "800",
    cca3: "UGA",
    cioc: "UGA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Ugandan shilling Sh"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "56"
      ]
    },
    capital: [
      "Kampala"
    ],
    altSpellings: [
      "UG",
      "Republic of Uganda",
      "Jamhuri ya Uganda"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English",
      swa: "Swahili"
    },
    latlng: [
      1,
      32
    ],
    landlocked: "Yes",
    borders: [
      "COD",
      "KEN",
      "RWA",
      "SSD",
      "TZA"
    ],
    area: 241550,
    demonyms: {
      eng: {
        f: "Ugandan",
        m: "Ugandan"
      },
      fra: {
        f: "Ougandaise",
        m: "Ougandais"
      }
    },
    flag: "🇺🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/Y7812hFiGa8LD9N68",
      openStreetMaps: "https://www.openstreetmap.org/relation/192796"
    },
    population: 45741000,
    gini: {
      2016: 42.8
    },
    fifa: "UGA",
    car: {
      signs: [
        "EAU"
      ],
      side: "left"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ug.png",
      svg: "https://flagcdn.com/ug.svg",
      alt: "The flag of Uganda is composed of six equal horizontal bands of black, yellow, red, black, yellow and red. A white circle bearing a hoist-side facing grey red-crested crane is superimposed in the center of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ug.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ug.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        0.32,
        32.55
      ]
    },
    nativeName: [
      "Uganda",
      "Republic of Uganda"
    ]
  },
  {
    name: {
      common: "Madagascar",
      official: "Republic of Madagascar",
      nativeName: {
        fra: {
          official: "République de Madagascar",
          common: "Madagascar"
        },
        mlg: {
          official: "Repoblikan'i Madagasikara",
          common: "Madagasikara"
        }
      }
    },
    tld: [
      ".mg"
    ],
    cca2: "MG",
    ccn3: "450",
    cca3: "MDG",
    cioc: "MAD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Malagasy ariary Ar"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "61"
      ]
    },
    capital: [
      "Antananarivo"
    ],
    altSpellings: [
      "MG",
      "Republic of Madagascar",
      "Repoblikan'i Madagasikara",
      "République de Madagascar"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      fra: "French",
      mlg: "Malagasy"
    },
    latlng: [
      -20,
      47
    ],
    landlocked: "Yes",
    area: 587041,
    demonyms: {
      eng: {
        f: "Malagasy",
        m: "Malagasy"
      },
      fra: {
        f: "Malgache",
        m: "Malgache"
      }
    },
    flag: "🇲🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/AHQh2ABBaFW6Ngj26",
      openStreetMaps: "https://www.openstreetmap.org/relation/447325"
    },
    population: 27691019,
    gini: {
      2012: 42.6
    },
    fifa: "MAD",
    car: {
      signs: [
        "RM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mg.png",
      svg: "https://flagcdn.com/mg.svg",
      alt: "The flag of Madagascar features a white vertical band on the hoist side that takes up about one-third the width of the field, and two equal horizontal bands of red and green adjoining the vertical band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -18.92,
        47.52
      ]
    },
    nativeName: [
      "Madagascar",
      "Republic of Madagascar",
      "République de Madagascar",
      "Repoblikan'i Madagasikara",
      "Madagasikara"
    ]
  },
  {
    name: {
      common: "Fiji",
      official: "Republic of Fiji",
      nativeName: {
        eng: {
          official: "Republic of Fiji",
          common: "Fiji"
        },
        fij: {
          official: "Matanitu Tugalala o Viti",
          common: "Viti"
        },
        hif: {
          official: "रिपब्लिक ऑफ फीजी",
          common: "फिजी"
        }
      }
    },
    tld: [
      ".fj"
    ],
    cca2: "FJ",
    ccn3: "242",
    cca3: "FJI",
    cioc: "FIJ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Fijian dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "79"
      ]
    },
    capital: [
      "Suva"
    ],
    altSpellings: [
      "FJ",
      "Viti",
      "Republic of Fiji",
      "Matanitu ko Viti",
      "Fijī Gaṇarājya"
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: {
      eng: "English",
      fij: "Fijian",
      hif: "Fiji Hindi"
    },
    latlng: [
      17.7134,
      178.065
    ],
    landlocked: "Yes",
    area: 18272,
    demonyms: {
      eng: {
        f: "Fijian",
        m: "Fijian"
      },
      fra: {
        f: "Fidjienne",
        m: "Fidjien"
      }
    },
    flag: "🇫🇯",
    maps: {
      googleMaps: "https://goo.gl/maps/r9fhDqoLZdg1zmE99",
      openStreetMaps: "https://www.openstreetmap.org/relation/571747"
    },
    population: 896444,
    gini: {
      2013: 36.7
    },
    fifa: "FIJ",
    car: {
      signs: [
        "FJI"
      ],
      side: "left"
    },
    timezones: [
      "UTC+12:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/fj.png",
      svg: "https://flagcdn.com/fj.svg",
      alt: "The flag of Fiji has a light blue field. It features the flag of the United Kingdom — the Union Jack — in the canton and the shield of the national coat of arms centered in the fly half."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/fj.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/fj.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -18.13,
        178.42
      ]
    },
    nativeName: [
      "Fiji",
      "Republic of Fiji",
      "Matanitu Tugalala o Viti",
      "Viti",
      "रिपब्लिक ऑफ फीजी",
      "फिजी"
    ]
  },
  {
    name: {
      common: "Norway",
      official: "Kingdom of Norway",
      nativeName: {
        nno: {
          official: "Kongeriket Noreg",
          common: "Noreg"
        },
        nob: {
          official: "Kongeriket Norge",
          common: "Norge"
        },
        smi: {
          official: "Norgga gonagasriika",
          common: "Norgga"
        }
      }
    },
    tld: [
      ".no"
    ],
    cca2: "NO",
    ccn3: "578",
    cca3: "NOR",
    cioc: "NOR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Norwegian krone kr"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "7"
      ]
    },
    capital: [
      "Oslo"
    ],
    altSpellings: [
      "NO",
      "Norge",
      "Noreg",
      "Kingdom of Norway",
      "Kongeriket Norge",
      "Kongeriket Noreg"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      nno: "Norwegian Nynorsk",
      nob: "Norwegian Bokmål",
      smi: "Sami"
    },
    latlng: [
      62,
      10
    ],
    landlocked: "Yes",
    borders: [
      "FIN",
      "SWE",
      "RUS"
    ],
    area: 323802,
    demonyms: {
      eng: {
        f: "Norwegian",
        m: "Norwegian"
      },
      fra: {
        f: "Norvégienne",
        m: "Norvégien"
      }
    },
    flag: "🇳🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/htWRrphA7vNgQNdSA",
      openStreetMaps: "https://www.openstreetmap.org/relation/2978650"
    },
    population: 5379475,
    gini: {
      2018: 27.6
    },
    fifa: "NOR",
    car: {
      signs: [
        "N"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/no.png",
      svg: "https://flagcdn.com/no.svg",
      alt: "The flag of Norway has a red field with a large white-edged navy blue cross that extends to the edges of the field. The vertical part of this cross is offset towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/no.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/no.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        59.92,
        10.75
      ]
    },
    nativeName: [
      "Norway",
      "Kingdom of Norway",
      "Kongeriket Noreg",
      "Noreg",
      "Kongeriket Norge",
      "Norge",
      "Norgga gonagasriika",
      "Norgga"
    ]
  },
  {
    name: {
      common: "Antarctica",
      official: "Antarctica"
    },
    tld: [
      ".aq"
    ],
    cca2: "AQ",
    ccn3: "010",
    cca3: "ATA",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    idd: {},
    altSpellings: [
      "AQ"
    ],
    region: "Antarctic",
    latlng: [
      -90,
      0
    ],
    landlocked: "Yes",
    area: 14000000,
    demonyms: {
      eng: {
        f: "Antarctican",
        m: "Antarctican"
      },
      fra: {
        f: "Antarcticaine",
        m: "Antarcticain"
      }
    },
    flag: "🇦🇶",
    maps: {
      googleMaps: "https://goo.gl/maps/kyBuJriu4itiXank7",
      openStreetMaps: "https://www.openstreetmap.org/node/36966060"
    },
    population: 1000,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC-03:00",
      "UTC+03:00",
      "UTC+05:00",
      "UTC+06:00",
      "UTC+07:00",
      "UTC+08:00",
      "UTC+10:00",
      "UTC+12:00"
    ],
    continents: [
      "Antarctica"
    ],
    flags: {
      png: "https://flagcdn.com/w320/aq.png",
      svg: "https://flagcdn.com/aq.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/aq.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/aq.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {},
    nativeName: [
      "Antarctica"
    ]
  },
  {
    name: {
      common: "Bouvet Island",
      official: "Bouvet Island",
      nativeName: {
        nor: {
          official: "Bouvetøya",
          common: "Bouvetøya"
        }
      }
    },
    tld: [
      ".bv"
    ],
    cca2: "BV",
    ccn3: "074",
    cca3: "BVT",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    idd: {
      root: "+4",
      suffixes: [
        "7"
      ]
    },
    altSpellings: [
      "BV",
      "Bouvetøya",
      "Bouvet-øya"
    ],
    region: "Antarctic",
    languages: {
      nor: "Norwegian"
    },
    latlng: [
      54.4208,
      3.3464
    ],
    landlocked: "Yes",
    area: 49,
    flag: "🇧🇻",
    maps: {
      googleMaps: "https://goo.gl/maps/7WRQAEKZb4uK36yi9",
      openStreetMaps: "https://www.openstreetmap.org/way/174996681"
    },
    population: 0,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Antarctica"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bv.png",
      svg: "https://flagcdn.com/bv.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {},
    nativeName: [
      "Bouvet Island",
      "Bouvetøya"
    ]
  },
  {
    name: {
      common: "Cyprus",
      official: "Republic of Cyprus",
      nativeName: {
        ell: {
          official: "Δημοκρατία της Κύπρος",
          common: "Κύπρος"
        },
        tur: {
          official: "Kıbrıs Cumhuriyeti",
          common: "Kıbrıs"
        }
      }
    },
    tld: [
      ".cy"
    ],
    cca2: "CY",
    ccn3: "196",
    cca3: "CYP",
    cioc: "CYP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "57"
      ]
    },
    capital: [
      "Nicosia"
    ],
    altSpellings: [
      "CY",
      "Kýpros",
      "Kıbrıs",
      "Republic of Cyprus",
      "Κυπριακή Δημοκρατία",
      "Kıbrıs Cumhuriyeti"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      ell: "Greek",
      tur: "Turkish"
    },
    latlng: [
      35,
      33
    ],
    landlocked: "Yes",
    area: 9251,
    demonyms: {
      eng: {
        f: "Cypriot",
        m: "Cypriot"
      },
      fra: {
        f: "Chypriote",
        m: "Chypriote"
      }
    },
    flag: "🇨🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/77hPBRdLid8yD5Bm7",
      openStreetMaps: "https://www.openstreetmap.org/relation/307787"
    },
    population: 1207361,
    gini: {
      2018: 32.7
    },
    fifa: "CYP",
    car: {
      signs: [
        "CY"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cy.png",
      svg: "https://flagcdn.com/cy.svg",
      alt: "The flag of Cyprus has a white field, at the center of which is a copper-colored silhouette of the Island of Cyprus above two green olive branches crossed at the stem."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cy.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cy.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        35.17,
        33.37
      ]
    },
    nativeName: [
      "Cyprus",
      "Republic of Cyprus",
      "Δημοκρατία της Κύπρος",
      "Κύπρος",
      "Kıbrıs Cumhuriyeti",
      "Kıbrıs"
    ]
  },
  {
    name: {
      common: "Taiwan",
      official: "Republic of China (Taiwan)",
      nativeName: {
        zho: {
          official: "中華民國",
          common: "台灣"
        }
      }
    },
    tld: [
      ".tw",
      ".台灣",
      ".台湾"
    ],
    cca2: "TW",
    ccn3: "158",
    cca3: "TWN",
    cioc: "TPE",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "New Taiwan dollar $"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "86"
      ]
    },
    capital: [
      "Taipei"
    ],
    altSpellings: [
      "TW",
      "Táiwān",
      "Republic of China",
      "中華民國",
      "Zhōnghuá Mínguó",
      "Chinese Taipei"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      zho: "Chinese"
    },
    latlng: [
      23.5,
      121
    ],
    landlocked: "Yes",
    area: 36193,
    demonyms: {
      eng: {
        f: "Taiwanese",
        m: "Taiwanese"
      },
      fra: {
        f: "Taïwanaise",
        m: "Taïwanais"
      }
    },
    flag: "🇹🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/HgMKFQjNadF3Wa6B6",
      openStreetMaps: "https://www.openstreetmap.org/relation/449220"
    },
    population: 23503349,
    fifa: "TPE",
    car: {
      signs: [
        "RC"
      ],
      side: "right"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tw.png",
      svg: "https://flagcdn.com/tw.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        25.03,
        121.52
      ]
    },
    nativeName: [
      "Taiwan",
      "Republic of China (Taiwan)",
      "中華民國",
      "台灣"
    ]
  },
  {
    name: {
      common: "Papua New Guinea",
      official: "Independent State of Papua New Guinea",
      nativeName: {
        eng: {
          official: "Independent State of Papua New Guinea",
          common: "Papua New Guinea"
        },
        hmo: {
          official: "Independen Stet bilong Papua Niugini",
          common: "Papua Niu Gini"
        },
        tpi: {
          official: "Independen Stet bilong Papua Niugini",
          common: "Papua Niugini"
        }
      }
    },
    tld: [
      ".pg"
    ],
    cca2: "PG",
    ccn3: "598",
    cca3: "PNG",
    cioc: "PNG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Papua New Guinean kina K"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "75"
      ]
    },
    capital: [
      "Port Moresby"
    ],
    altSpellings: [
      "PG",
      "Independent State of Papua New Guinea",
      "Independen Stet bilong Papua Niugini"
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: {
      eng: "English",
      hmo: "Hiri Motu",
      tpi: "Tok Pisin"
    },
    latlng: [
      -6,
      147
    ],
    landlocked: "Yes",
    borders: [
      "IDN"
    ],
    area: 462840,
    demonyms: {
      eng: {
        f: "Papua New Guinean",
        m: "Papua New Guinean"
      },
      fra: {
        f: "Papouasienne",
        m: "Papouasien"
      }
    },
    flag: "🇵🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/ChGmzZBjZ3vnBwR2A",
      openStreetMaps: "https://goo.gl/maps/ChGmzZBjZ3vnBwR2A"
    },
    population: 8947027,
    gini: {
      2009: 41.9
    },
    fifa: "PNG",
    car: {
      signs: [
        "PNG"
      ],
      side: "left"
    },
    timezones: [
      "UTC+10:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pg.png",
      svg: "https://flagcdn.com/pg.svg",
      alt: "The flag of Papua New Guinea is divided diagonally, from the upper hoist-side corner to the lower fly-side corner, into a lower black and an upper red triangle. On the hoist side of the lower black triangle is a representation of the Southern Cross constellation made up of one small and four larger five-pointed white stars. A golden Raggiana bird-of-paradise is situated on the fly side of the upper red triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/pg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/pg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -9.45,
        147.18
      ]
    },
    nativeName: [
      "Papua New Guinea",
      "Independent State of Papua New Guinea",
      "Independen Stet bilong Papua Niugini",
      "Papua Niu Gini",
      "Papua Niugini"
    ]
  },
  {
    name: {
      common: "Rwanda",
      official: "Republic of Rwanda",
      nativeName: {
        eng: {
          official: "Republic of Rwanda",
          common: "Rwanda"
        },
        fra: {
          official: "République rwandaise",
          common: "Rwanda"
        },
        kin: {
          official: "Repubulika y'u Rwanda",
          common: "Rwanda"
        }
      }
    },
    tld: [
      ".rw"
    ],
    cca2: "RW",
    ccn3: "646",
    cca3: "RWA",
    cioc: "RWA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Rwandan franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "50"
      ]
    },
    capital: [
      "Kigali"
    ],
    altSpellings: [
      "RW",
      "Republic of Rwanda",
      "Repubulika y'u Rwanda",
      "République du Rwanda"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English",
      fra: "French",
      kin: "Kinyarwanda"
    },
    latlng: [
      -2,
      30
    ],
    landlocked: "Yes",
    borders: [
      "BDI",
      "COD",
      "TZA",
      "UGA"
    ],
    area: 26338,
    demonyms: {
      eng: {
        f: "Rwandan",
        m: "Rwandan"
      },
      fra: {
        f: "Rwandaise",
        m: "Rwandais"
      }
    },
    flag: "🇷🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/j5xb5r7CLqjYbyP86",
      openStreetMaps: "https://www.openstreetmap.org/relation/171496"
    },
    population: 12952209,
    gini: {
      2016: 43.7
    },
    fifa: "RWA",
    car: {
      signs: [
        "RWA"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/rw.png",
      svg: "https://flagcdn.com/rw.svg",
      alt: "The flag of Rwanda is composed of three horizontal bands of light blue, yellow and green. The light blue band is twice the height of the other two bands and bears a yellow sun with twenty-four rays on its fly side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/rw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/rw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -1.95,
        30.05
      ]
    },
    nativeName: [
      "Rwanda",
      "Republic of Rwanda",
      "République rwandaise",
      "Repubulika y'u Rwanda"
    ]
  },
  {
    name: {
      common: "DR Congo",
      official: "Democratic Republic of the Congo",
      nativeName: {
        fra: {
          official: "République démocratique du Congo",
          common: "RD Congo"
        },
        kon: {
          official: "Repubilika ya Kongo Demokratiki",
          common: "Repubilika ya Kongo Demokratiki"
        },
        lin: {
          official: "Republiki ya Kongó Demokratiki",
          common: "Republiki ya Kongó Demokratiki"
        },
        lua: {
          official: "Ditunga dia Kongu wa Mungalaata",
          common: "Ditunga dia Kongu wa Mungalaata"
        },
        swa: {
          official: "Jamhuri ya Kidemokrasia ya Kongo",
          common: "Jamhuri ya Kidemokrasia ya Kongo"
        }
      }
    },
    tld: [
      ".cd"
    ],
    cca2: "CD",
    ccn3: "180",
    cca3: "COD",
    cioc: "COD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Congolese franc FC"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "43"
      ]
    },
    capital: [
      "Kinshasa"
    ],
    altSpellings: [
      "CD",
      "DR Congo",
      "Congo-Kinshasa",
      "Congo, the Democratic Republic of the",
      "DRC"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      fra: "French",
      kon: "Kikongo",
      lin: "Lingala",
      lua: "Tshiluba",
      swa: "Swahili"
    },
    latlng: [
      0,
      25
    ],
    landlocked: "Yes",
    borders: [
      "AGO",
      "BDI",
      "CAF",
      "COG",
      "RWA",
      "SSD",
      "TZA",
      "UGA",
      "ZMB"
    ],
    area: 2344858,
    demonyms: {
      eng: {
        f: "Congolese",
        m: "Congolese"
      },
      fra: {
        f: "Congolaise",
        m: "Congolais"
      }
    },
    flag: "🇨🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/KfhNVn6VqdZXWu8n9",
      openStreetMaps: "https://www.openstreetmap.org/relation/192795"
    },
    population: 108407721,
    gini: {
      2012: 42.1
    },
    fifa: "COD",
    car: {
      signs: [
        "CGO"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00",
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cd.png",
      svg: "https://flagcdn.com/cd.svg",
      alt: "The flag of the Democratic Republic of the Congo has a sky-blue field with a yellow-edged red diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. A large five-pointed yellow star is situated above the diagonal band on the upper hoist side of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cd.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cd.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -4.32,
        15.3
      ]
    },
    nativeName: [
      "DR Congo",
      "Democratic Republic of the Congo",
      "République démocratique du Congo",
      "RD Congo",
      "Repubilika ya Kongo Demokratiki",
      "Republiki ya Kongó Demokratiki",
      "Ditunga dia Kongu wa Mungalaata",
      "Jamhuri ya Kidemokrasia ya Kongo"
    ]
  },
  {
    name: {
      common: "Cameroon",
      official: "Republic of Cameroon",
      nativeName: {
        eng: {
          official: "Republic of Cameroon",
          common: "Cameroon"
        },
        fra: {
          official: "République du Cameroun",
          common: "Cameroun"
        }
      }
    },
    tld: [
      ".cm"
    ],
    cca2: "CM",
    ccn3: "120",
    cca3: "CMR",
    cioc: "CMR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Central African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "37"
      ]
    },
    capital: [
      "Yaoundé"
    ],
    altSpellings: [
      "CM",
      "Republic of Cameroon",
      "République du Cameroun"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      eng: "English",
      fra: "French"
    },
    latlng: [
      6,
      12
    ],
    landlocked: "Yes",
    borders: [
      "CAF",
      "TCD",
      "COG",
      "GNQ",
      "GAB",
      "NGA"
    ],
    area: 475442,
    demonyms: {
      eng: {
        f: "Cameroonian",
        m: "Cameroonian"
      },
      fra: {
        f: "Camerounaise",
        m: "Camerounais"
      }
    },
    flag: "🇨🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/JqiipHgFboG3rBJh9",
      openStreetMaps: "https://www.openstreetmap.org/relation/192830"
    },
    population: 26545864,
    gini: {
      2014: 46.6
    },
    fifa: "CMR",
    car: {
      signs: [
        "CAM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cm.png",
      svg: "https://flagcdn.com/cm.svg",
      alt: "The flag of Cameroon is composed of three equal vertical bands of green, red and yellow, with a yellow five-pointed star in the center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        3.85,
        11.5
      ]
    },
    nativeName: [
      "Cameroon",
      "Republic of Cameroon",
      "République du Cameroun",
      "Cameroun"
    ]
  },
  {
    name: {
      common: "Serbia",
      official: "Republic of Serbia",
      nativeName: {
        srp: {
          official: "Република Србија",
          common: "Србија"
        }
      }
    },
    tld: [
      ".rs",
      ".срб"
    ],
    cca2: "RS",
    ccn3: "688",
    cca3: "SRB",
    cioc: "SRB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Serbian dinar дин."
    ],
    idd: {
      root: "+3",
      suffixes: [
        "81"
      ]
    },
    capital: [
      "Belgrade"
    ],
    altSpellings: [
      "RS",
      "Srbija",
      "Republic of Serbia",
      "Република Србија",
      "Republika Srbija"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      srp: "Serbian"
    },
    latlng: [
      44,
      21
    ],
    landlocked: "Yes",
    borders: [
      "BIH",
      "BGR",
      "HRV",
      "HUN",
      "UNK",
      "MKD",
      "MNE",
      "ROU"
    ],
    area: 88361,
    demonyms: {
      eng: {
        f: "Serbian",
        m: "Serbian"
      },
      fra: {
        f: "Serbe",
        m: "Serbe"
      }
    },
    flag: "🇷🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/2Aqof7aV2Naq8YEK8",
      openStreetMaps: "https://www.openstreetmap.org/relation/1741311"
    },
    population: 6908224,
    gini: {
      2017: 36.2
    },
    fifa: "SRB",
    car: {
      signs: [
        "SRB"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/rs.png",
      svg: "https://flagcdn.com/rs.svg",
      alt: "The flag of Serbia is composed of three equal horizontal bands of red, blue and white. The coat of arms of Serbia is superimposed at the center of the field slightly towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/rs.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/rs.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        44.83,
        20.5
      ]
    },
    nativeName: [
      "Serbia",
      "Republic of Serbia",
      "Република Србија",
      "Србија"
    ]
  },
  {
    name: {
      common: "Lithuania",
      official: "Republic of Lithuania",
      nativeName: {
        lit: {
          official: "Lietuvos Respublikos",
          common: "Lietuva"
        }
      }
    },
    tld: [
      ".lt"
    ],
    cca2: "LT",
    ccn3: "440",
    cca3: "LTU",
    cioc: "LTU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "70"
      ]
    },
    capital: [
      "Vilnius"
    ],
    altSpellings: [
      "LT",
      "Republic of Lithuania",
      "Lietuvos Respublika"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      lit: "Lithuanian"
    },
    latlng: [
      56,
      24
    ],
    landlocked: "Yes",
    borders: [
      "BLR",
      "LVA",
      "POL",
      "RUS"
    ],
    area: 65300,
    demonyms: {
      eng: {
        f: "Lithuanian",
        m: "Lithuanian"
      },
      fra: {
        f: "Lituanienne",
        m: "Lituanien"
      }
    },
    flag: "🇱🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/dd1s9rrLjrK2G8yY6",
      openStreetMaps: "https://www.openstreetmap.org/relation/72596"
    },
    population: 2794700,
    gini: {
      2018: 35.7
    },
    fifa: "LTU",
    car: {
      signs: [
        "LT"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lt.png",
      svg: "https://flagcdn.com/lt.svg",
      alt: "The flag of Lithuania is composed of three equal horizontal bands of yellow, green and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lt.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lt.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        54.68,
        25.32
      ]
    },
    nativeName: [
      "Lithuania",
      "Republic of Lithuania",
      "Lietuvos Respublikos",
      "Lietuva"
    ]
  },
  {
    name: {
      common: "French Southern and Antarctic Lands",
      official: "Territory of the French Southern and Antarctic Lands",
      nativeName: {
        fra: {
          official: "Territoire des Terres australes et antarctiques françaises",
          common: "Terres australes et antarctiques françaises"
        }
      }
    },
    tld: [
      ".tf"
    ],
    cca2: "TF",
    ccn3: "260",
    cca3: "ATF",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "62"
      ]
    },
    capital: [
      "Port-aux-Français"
    ],
    altSpellings: [
      "TF",
      "French Southern Territories"
    ],
    region: "Antarctic",
    languages: {
      fra: "French"
    },
    latlng: [
      -49.25,
      69.167
    ],
    landlocked: "Yes",
    area: 7747,
    demonyms: {
      eng: {
        f: "French",
        m: "French"
      },
      fra: {
        f: "Française",
        m: "Français"
      }
    },
    flag: "🇹🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/6ua6CX1m4w1xF2Em7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2186658"
    },
    population: 400,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Antarctica"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tf.png",
      svg: "https://flagcdn.com/tf.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tf.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tf.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        48.81,
        -1.4
      ]
    },
    nativeName: [
      "French Southern and Antarctic Lands",
      "Territory of the French Southern and Antarctic Lands",
      "Territoire des Terres australes et antarctiques françaises",
      "Terres australes et antarctiques françaises"
    ]
  },
  {
    name: {
      common: "Cambodia",
      official: "Kingdom of Cambodia",
      nativeName: {
        khm: {
          official: "ព្រះរាជាណាចក្រកម្ពុជា",
          common: "Kâmpŭchéa"
        }
      }
    },
    tld: [
      ".kh"
    ],
    cca2: "KH",
    ccn3: "116",
    cca3: "KHM",
    cioc: "CAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Cambodian riel ៛",
      "United States dollar $"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "55"
      ]
    },
    capital: [
      "Phnom Penh"
    ],
    altSpellings: [
      "KH",
      "Kingdom of Cambodia"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      khm: "Khmer"
    },
    latlng: [
      13,
      105
    ],
    landlocked: "Yes",
    borders: [
      "LAO",
      "THA",
      "VNM"
    ],
    area: 181035,
    demonyms: {
      eng: {
        f: "Cambodian",
        m: "Cambodian"
      },
      fra: {
        f: "Cambodgienne",
        m: "Cambodgien"
      }
    },
    flag: "🇰🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/nztQtFSrUXZymJaW8",
      openStreetMaps: "https://www.openstreetmap.org/relation/49898"
    },
    population: 16718971,
    fifa: "CAM",
    car: {
      signs: [
        "K"
      ],
      side: "right"
    },
    timezones: [
      "UTC+07:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kh.png",
      svg: "https://flagcdn.com/kh.svg",
      alt: "The flag of Cambodia features three horizontal bands of blue, red and blue, with a white depiction of the temple complex, Angkor Wat centered in the red band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kh.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kh.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        11.55,
        104.92
      ]
    },
    nativeName: [
      "Cambodia",
      "Kingdom of Cambodia",
      "ព្រះរាជាណាចក្រកម្ពុជា",
      "Kâmpŭchéa"
    ]
  },
  {
    name: {
      common: "Saint Helena, Ascension and Tristan da Cunha",
      official: "Saint Helena, Ascension and Tristan da Cunha",
      nativeName: {
        eng: {
          official: "Saint Helena, Ascension and Tristan da Cunha",
          common: "Saint Helena, Ascension and Tristan da Cunha"
        }
      }
    },
    tld: [
      ".sh",
      ".ac"
    ],
    cca2: "SH",
    ccn3: "654",
    cca3: "SHN",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Pound sterling £",
      "Saint Helena pound £"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "90",
        "47"
      ]
    },
    capital: [
      "Jamestown"
    ],
    altSpellings: [
      "Saint Helena",
      "St. Helena, Ascension and Tristan da Cunha"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      -15.95,
      -5.72
    ],
    landlocked: "Yes",
    area: 394,
    demonyms: {
      eng: {
        f: "Saint Helenian",
        m: "Saint Helenian"
      },
      fra: {
        f: "Sainte-Hélénoise",
        m: "Sainte-Hélènois"
      }
    },
    flag: "🇸🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/iv4VxnPzHkjLCJuc6",
      openStreetMaps: "https://www.openstreetmap.org/relation/4868269#map=13/-15.9657/-5.7120"
    },
    population: 53192,
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC+00:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sh.png",
      svg: "https://flagcdn.com/sh.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -15.93,
        -5.72
      ]
    },
    nativeName: [
      "Saint Helena, Ascension and Tristan da Cunha"
    ]
  },
  {
    name: {
      common: "United Arab Emirates",
      official: "United Arab Emirates",
      nativeName: {
        ara: {
          official: "الإمارات العربية المتحدة",
          common: "دولة الإمارات العربية المتحدة"
        }
      }
    },
    tld: [
      ".ae",
      "امارات."
    ],
    cca2: "AE",
    ccn3: "784",
    cca3: "ARE",
    cioc: "UAE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United Arab Emirates dirham د.إ"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "71"
      ]
    },
    capital: [
      "Abu Dhabi"
    ],
    altSpellings: [
      "AE",
      "UAE",
      "Emirates"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      24,
      54
    ],
    landlocked: "Yes",
    borders: [
      "OMN",
      "SAU"
    ],
    area: 83600,
    demonyms: {
      eng: {
        f: "Emirati",
        m: "Emirati"
      },
      fra: {
        f: "Emirienne",
        m: "Emirien"
      }
    },
    flag: "🇦🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/AZZTDA6GzVAnKMVd8",
      openStreetMaps: "https://www.openstreetmap.org/relation/307763"
    },
    population: 9890400,
    gini: {
      2018: 26
    },
    fifa: "UAE",
    car: {
      signs: [
        "UAE"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ae.png",
      svg: "https://flagcdn.com/ae.svg",
      alt: "The flag of United Arab Emirates features a red vertical band on its hoist side that takes up about one-fourth the width of the field and three equal horizontal bands of green, white and black adjoining the vertical band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ae.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ae.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        24.47,
        54.37
      ]
    },
    nativeName: [
      "United Arab Emirates",
      "الإمارات العربية المتحدة",
      "دولة الإمارات العربية المتحدة"
    ]
  },
  {
    name: {
      common: "Bermuda",
      official: "Bermuda",
      nativeName: {
        eng: {
          official: "Bermuda",
          common: "Bermuda"
        }
      }
    },
    tld: [
      ".bm"
    ],
    cca2: "BM",
    ccn3: "060",
    cca3: "BMU",
    cioc: "BER",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Bermudian dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "441"
      ]
    },
    capital: [
      "Hamilton"
    ],
    altSpellings: [
      "BM",
      "The Islands of Bermuda",
      "The Bermudas",
      "Somers Isles"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      eng: "English"
    },
    latlng: [
      32.33333333,
      -64.75
    ],
    landlocked: "Yes",
    area: 54,
    demonyms: {
      eng: {
        f: "Bermudian",
        m: "Bermudian"
      },
      fra: {
        f: "Bermudienne",
        m: "Bermudien"
      }
    },
    flag: "🇧🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/NLsRGNjTzDghTtAJA",
      openStreetMaps: "https://www.openstreetmap.org/relation/1993208"
    },
    population: 63903,
    fifa: "BER",
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bm.png",
      svg: "https://flagcdn.com/bm.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        32.28,
        -64.78
      ]
    },
    nativeName: [
      "Bermuda"
    ]
  },
  {
    name: {
      common: "Monaco",
      official: "Principality of Monaco",
      nativeName: {
        fra: {
          official: "Principauté de Monaco",
          common: "Monaco"
        }
      }
    },
    tld: [
      ".mc"
    ],
    cca2: "MC",
    ccn3: "492",
    cca3: "MCO",
    cioc: "MON",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "77"
      ]
    },
    capital: [
      "Monaco"
    ],
    altSpellings: [
      "MC",
      "Principality of Monaco",
      "Principauté de Monaco"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      fra: "French"
    },
    latlng: [
      43.73333333,
      7.4
    ],
    landlocked: "Yes",
    borders: [
      "FRA"
    ],
    area: 2.02,
    demonyms: {
      eng: {
        f: "Monegasque",
        m: "Monegasque"
      },
      fra: {
        f: "Monégasque",
        m: "Monégasque"
      }
    },
    flag: "🇲🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/DGpndDot28bYdXYn7",
      openStreetMaps: "https://www.openstreetmap.org/relation/1124039"
    },
    population: 39244,
    car: {
      signs: [
        "MC"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mc.png",
      svg: "https://flagcdn.com/mc.svg",
      alt: "The flag of Monaco is composed of two equal horizontal bands of red and white."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mc.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mc.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        43.73,
        7.42
      ]
    },
    nativeName: [
      "Monaco",
      "Principality of Monaco",
      "Principauté de Monaco"
    ]
  },
  {
    name: {
      common: "Cuba",
      official: "Republic of Cuba",
      nativeName: {
        spa: {
          official: "República de Cuba",
          common: "Cuba"
        }
      }
    },
    tld: [
      ".cu"
    ],
    cca2: "CU",
    ccn3: "192",
    cca3: "CUB",
    cioc: "CUB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Cuban convertible peso $",
      "Cuban peso $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "3"
      ]
    },
    capital: [
      "Havana"
    ],
    altSpellings: [
      "CU",
      "Republic of Cuba",
      "República de Cuba"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      21.5,
      -80
    ],
    landlocked: "Yes",
    area: 109884,
    demonyms: {
      eng: {
        f: "Cuban",
        m: "Cuban"
      },
      fra: {
        f: "Cubaine",
        m: "Cubain"
      }
    },
    flag: "🇨🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/1dDw1QfZspfMUTm99",
      openStreetMaps: "https://www.openstreetmap.org/relation/307833"
    },
    population: 11326616,
    fifa: "CUB",
    car: {
      signs: [
        "C"
      ],
      side: "right"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cu.png",
      svg: "https://flagcdn.com/cu.svg",
      alt: "The flag of Cuba is composed of five equal horizontal bands of blue alternating with white and a red equilateral triangle superimposed on the hoist side of the field. The triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a white five-pointed star at its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cu.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cu.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        23.12,
        -82.35
      ]
    },
    nativeName: [
      "Cuba",
      "Republic of Cuba",
      "República de Cuba"
    ]
  },
  {
    name: {
      common: "Hong Kong",
      official: "Hong Kong Special Administrative Region of the People's Republic of China",
      nativeName: {
        eng: {
          official: "Hong Kong Special Administrative Region of the People's Republic of China",
          common: "Hong Kong"
        },
        zho: {
          official: "中华人民共和国香港特别行政区",
          common: "香港"
        }
      }
    },
    tld: [
      ".hk",
      ".香港"
    ],
    cca2: "HK",
    ccn3: "344",
    cca3: "HKG",
    cioc: "HKG",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Hong Kong dollar $"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "52"
      ]
    },
    capital: [
      "City of Victoria"
    ],
    altSpellings: [
      "HK"
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: {
      eng: "English",
      zho: "Chinese"
    },
    latlng: [
      22.267,
      114.188
    ],
    landlocked: "Yes",
    borders: [
      "CHN"
    ],
    area: 1104,
    demonyms: {
      eng: {
        f: "Hong Konger",
        m: "Hong Konger"
      },
      fra: {
        f: "Hongkongaise",
        m: "Hongkongais"
      }
    },
    flag: "🇭🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/1sEnNmT47ffrC8MU8",
      openStreetMaps: "https://www.openstreetmap.org/relation/913110"
    },
    population: 7500700,
    fifa: "HKG",
    car: {
      signs: [
        "HK"
      ],
      side: "left"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/hk.png",
      svg: "https://flagcdn.com/hk.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/hk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/hk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        22.267,
        114.188
      ]
    },
    nativeName: [
      "Hong Kong",
      "Hong Kong Special Administrative Region of the People's Republic of China",
      "中华人民共和国香港特别行政区",
      "香港"
    ]
  },
  {
    name: {
      common: "Northern Mariana Islands",
      official: "Commonwealth of the Northern Mariana Islands",
      nativeName: {
        cal: {
          official: "Commonwealth of the Northern Mariana Islands",
          common: "Northern Mariana Islands"
        },
        cha: {
          official: "Sankattan Siha Na Islas Mariånas",
          common: "Na Islas Mariånas"
        },
        eng: {
          official: "Commonwealth of the Northern Mariana Islands",
          common: "Northern Mariana Islands"
        }
      }
    },
    tld: [
      ".mp"
    ],
    cca2: "MP",
    ccn3: "580",
    cca3: "MNP",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "670"
      ]
    },
    capital: [
      "Saipan"
    ],
    altSpellings: [
      "MP",
      "Commonwealth of the Northern Mariana Islands",
      "Sankattan Siha Na Islas Mariånas"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      cal: "Carolinian",
      cha: "Chamorro",
      eng: "English"
    },
    latlng: [
      15.2,
      145.75
    ],
    landlocked: "Yes",
    area: 464,
    demonyms: {
      eng: {
        f: "American",
        m: "American"
      },
      fra: {
        f: "Américaine",
        m: "Américan"
      }
    },
    flag: "🇲🇵",
    maps: {
      googleMaps: "https://goo.gl/maps/cpZ67knoRAcfu1417",
      openStreetMaps: "https://www.openstreetmap.org/relation/306004"
    },
    population: 57557,
    car: {
      signs: [
        "USA"
      ],
      side: "right"
    },
    timezones: [
      "UTC+10:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mp.png",
      svg: "https://flagcdn.com/mp.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        15.2,
        145.75
      ]
    },
    nativeName: [
      "Northern Mariana Islands",
      "Commonwealth of the Northern Mariana Islands",
      "Sankattan Siha Na Islas Mariånas",
      "Na Islas Mariånas"
    ]
  },
  {
    name: {
      common: "Togo",
      official: "Togolese Republic",
      nativeName: {
        fra: {
          official: "République togolaise",
          common: "Togo"
        }
      }
    },
    tld: [
      ".tg"
    ],
    cca2: "TG",
    ccn3: "768",
    cca3: "TGO",
    cioc: "TOG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "28"
      ]
    },
    capital: [
      "Lomé"
    ],
    altSpellings: [
      "TG",
      "Togolese",
      "Togolese Republic",
      "République Togolaise"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      8,
      1.16666666
    ],
    landlocked: "Yes",
    borders: [
      "BEN",
      "BFA",
      "GHA"
    ],
    area: 56785,
    demonyms: {
      eng: {
        f: "Togolese",
        m: "Togolese"
      },
      fra: {
        f: "Togolaise",
        m: "Togolais"
      }
    },
    flag: "🇹🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/jzAa9feXuXPrKVb89",
      openStreetMaps: "https://www.openstreetmap.org/relation/192782"
    },
    population: 8278737,
    gini: {
      2015: 43.1
    },
    fifa: "TOG",
    car: {
      signs: [
        "TG"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tg.png",
      svg: "https://flagcdn.com/tg.svg",
      alt: "The flag of Togo is composed of five equal horizontal bands of green alternating with yellow. A red square bearing a five-pointed white star is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.14,
        1.21
      ]
    },
    nativeName: [
      "Togo",
      "Togolese Republic",
      "République togolaise"
    ]
  },
  {
    name: {
      common: "Luxembourg",
      official: "Grand Duchy of Luxembourg",
      nativeName: {
        deu: {
          official: "Großherzogtum Luxemburg",
          common: "Luxemburg"
        },
        fra: {
          official: "Grand-Duché de Luxembourg",
          common: "Luxembourg"
        },
        ltz: {
          official: "Groussherzogtum Lëtzebuerg",
          common: "Lëtzebuerg"
        }
      }
    },
    tld: [
      ".lu"
    ],
    cca2: "LU",
    ccn3: "442",
    cca3: "LUX",
    cioc: "LUX",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "52"
      ]
    },
    capital: [
      "Luxembourg"
    ],
    altSpellings: [
      "LU",
      "Grand Duchy of Luxembourg",
      "Grand-Duché de Luxembourg",
      "Großherzogtum Luxemburg",
      "Groussherzogtum Lëtzebuerg"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      deu: "German",
      fra: "French",
      ltz: "Luxembourgish"
    },
    latlng: [
      49.75,
      6.16666666
    ],
    landlocked: "Yes",
    borders: [
      "BEL",
      "FRA",
      "DEU"
    ],
    area: 2586,
    demonyms: {
      eng: {
        f: "Luxembourger",
        m: "Luxembourger"
      },
      fra: {
        f: "Luxembourgeoise",
        m: "Luxembourgeois"
      }
    },
    flag: "🇱🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/L6b2AgndgHprt2Ko9",
      openStreetMaps: "https://www.openstreetmap.org/relation/2171347#map=10/49.8167/6.1335"
    },
    population: 632275,
    gini: {
      2018: 35.4
    },
    fifa: "LUX",
    car: {
      signs: [
        "L"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lu.png",
      svg: "https://flagcdn.com/lu.svg",
      alt: "The flag of Luxembourg is composed of three equal horizontal bands of red, white and light blue."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lu.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lu.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        49.6,
        6.12
      ]
    },
    nativeName: [
      "Luxembourg",
      "Grand Duchy of Luxembourg",
      "Großherzogtum Luxemburg",
      "Luxemburg",
      "Grand-Duché de Luxembourg",
      "Groussherzogtum Lëtzebuerg",
      "Lëtzebuerg"
    ]
  },
  {
    name: {
      common: "Mauritius",
      official: "Republic of Mauritius",
      nativeName: {
        eng: {
          official: "Republic of Mauritius",
          common: "Mauritius"
        },
        fra: {
          official: "République de Maurice",
          common: "Maurice"
        },
        mfe: {
          official: "Republik Moris",
          common: "Moris"
        }
      }
    },
    tld: [
      ".mu"
    ],
    cca2: "MU",
    ccn3: "480",
    cca3: "MUS",
    cioc: "MRI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Mauritian rupee ₨"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "30"
      ]
    },
    capital: [
      "Port Louis"
    ],
    altSpellings: [
      "MU",
      "Republic of Mauritius",
      "République de Maurice"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English",
      fra: "French",
      mfe: "Mauritian Creole"
    },
    latlng: [
      -20.28333333,
      57.55
    ],
    landlocked: "Yes",
    area: 2040,
    demonyms: {
      eng: {
        f: "Mauritian",
        m: "Mauritian"
      },
      fra: {
        f: "Mauricienne",
        m: "Mauricien"
      }
    },
    flag: "🇲🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/PpKtZ4W3tir5iGrz7",
      openStreetMaps: "https://www.openstreetmap.org/relation/535828"
    },
    population: 1265740,
    gini: {
      2017: 36.8
    },
    fifa: "MRI",
    car: {
      signs: [
        "MS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mu.png",
      svg: "https://flagcdn.com/mu.svg",
      alt: "The flag of Mauritius is composed of four equal horizontal bands of red, blue, yellow and green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mu.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mu.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -20.15,
        57.48
      ]
    },
    nativeName: [
      "Mauritius",
      "Republic of Mauritius",
      "République de Maurice",
      "Maurice",
      "Republik Moris",
      "Moris"
    ]
  },
  {
    name: {
      common: "Argentina",
      official: "Argentine Republic",
      nativeName: {
        grn: {
          official: "Argentine Republic",
          common: "Argentina"
        },
        spa: {
          official: "República Argentina",
          common: "Argentina"
        }
      }
    },
    tld: [
      ".ar"
    ],
    cca2: "AR",
    ccn3: "032",
    cca3: "ARG",
    cioc: "ARG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Argentine peso $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Buenos Aires"
    ],
    altSpellings: [
      "AR",
      "Argentine Republic",
      "República Argentina"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      grn: "Guaraní",
      spa: "Spanish"
    },
    latlng: [
      -34,
      -64
    ],
    landlocked: "Yes",
    borders: [
      "BOL",
      "BRA",
      "CHL",
      "PRY",
      "URY"
    ],
    area: 2780400,
    demonyms: {
      eng: {
        f: "Argentine",
        m: "Argentine"
      },
      fra: {
        f: "Argentine",
        m: "Argentin"
      }
    },
    flag: "🇦🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/Z9DXNxhf2o93kvyc6",
      openStreetMaps: "https://www.openstreetmap.org/relation/286393"
    },
    population: 45376763,
    gini: {
      2019: 42.9
    },
    fifa: "ARG",
    car: {
      signs: [
        "RA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-03:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ar.png",
      svg: "https://flagcdn.com/ar.svg",
      alt: "The flag of Argentina features three equal horizontal bands of light blue, white and light blue. A brown-edged golden sun is centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ar.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ar.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -34.58,
        -58.67
      ]
    },
    nativeName: [
      "Argentina",
      "Argentine Republic",
      "República Argentina"
    ]
  },
  {
    name: {
      common: "Grenada",
      official: "Grenada",
      nativeName: {
        eng: {
          official: "Grenada",
          common: "Grenada"
        }
      }
    },
    tld: [
      ".gd"
    ],
    cca2: "GD",
    ccn3: "308",
    cca3: "GRD",
    cioc: "GRN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "473"
      ]
    },
    capital: [
      "St. George's"
    ],
    altSpellings: [
      "GD"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      12.11666666,
      -61.66666666
    ],
    landlocked: "Yes",
    area: 344,
    demonyms: {
      eng: {
        f: "Grenadian",
        m: "Grenadian"
      },
      fra: {
        f: "Grenadienne",
        m: "Grenadien"
      }
    },
    flag: "🇬🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/rqWyfUAt4xhvk1Zy9",
      openStreetMaps: "https://www.openstreetmap.org/relation/550727"
    },
    population: 112519,
    fifa: "GRN",
    car: {
      signs: [
        "WG"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gd.png",
      svg: "https://flagcdn.com/gd.svg",
      alt: "The flag of Grenada features a large central rectangular area surrounded by a red border, with three five-pointed yellow stars centered on the top and bottom borders. The central rectangle is divided diagonally into four alternating triangular areas of yellow at the top and bottom and green on the hoist and fly sides, and a five-pointed yellow star on a red circle is superimposed at its center. A symbolic nutmeg pod is situated on the green hoist-side triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gd.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gd.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        32.38,
        -64.68
      ]
    },
    nativeName: [
      "Grenada"
    ]
  },
  {
    name: {
      common: "Nicaragua",
      official: "Republic of Nicaragua",
      nativeName: {
        spa: {
          official: "República de Nicaragua",
          common: "Nicaragua"
        }
      }
    },
    tld: [
      ".ni"
    ],
    cca2: "NI",
    ccn3: "558",
    cca3: "NIC",
    cioc: "NCA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Nicaraguan córdoba C$"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "05"
      ]
    },
    capital: [
      "Managua"
    ],
    altSpellings: [
      "NI",
      "Republic of Nicaragua",
      "República de Nicaragua"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      13,
      -85
    ],
    landlocked: "Yes",
    borders: [
      "CRI",
      "HND"
    ],
    area: 130373,
    demonyms: {
      eng: {
        f: "Nicaraguan",
        m: "Nicaraguan"
      },
      fra: {
        f: "Nicaraguayenne",
        m: "Nicaraguayen"
      }
    },
    flag: "🇳🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/P77LaEVkKJKXneRC6",
      openStreetMaps: "https://www.openstreetmap.org/relation/287666"
    },
    population: 6624554,
    gini: {
      2014: 46.2
    },
    fifa: "NCA",
    car: {
      signs: [
        "NIC"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ni.png",
      svg: "https://flagcdn.com/ni.svg",
      alt: "The flag of Nicaragua is composed of three equal horizontal bands of blue, white and blue, with the national coat of arms centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ni.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ni.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.13,
        -86.25
      ]
    },
    nativeName: [
      "Nicaragua",
      "Republic of Nicaragua",
      "República de Nicaragua"
    ]
  },
  {
    name: {
      common: "Niue",
      official: "Niue",
      nativeName: {
        eng: {
          official: "Niue",
          common: "Niue"
        },
        niu: {
          official: "Niuē",
          common: "Niuē"
        }
      }
    },
    tld: [
      ".nu"
    ],
    cca2: "NU",
    ccn3: "570",
    cca3: "NIU",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "New Zealand dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "83"
      ]
    },
    capital: [
      "Alofi"
    ],
    altSpellings: [
      "NU"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      niu: "Niuean"
    },
    latlng: [
      -19.03333333,
      -169.86666666
    ],
    landlocked: "Yes",
    area: 260,
    demonyms: {
      eng: {
        f: "Niuean",
        m: "Niuean"
      },
      fra: {
        f: "Niuéenne",
        m: "Niuéen"
      }
    },
    flag: "🇳🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/xFgdzs3E55Rk1y8P9",
      openStreetMaps: "https://www.openstreetmap.org/relation/1558556"
    },
    population: 1470,
    car: {
      signs: [
        "NZ"
      ],
      side: "left"
    },
    timezones: [
      "UTC-11:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/nu.png",
      svg: "https://flagcdn.com/nu.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -19.02,
        -169.92
      ]
    },
    nativeName: [
      "Niue",
      "Niuē"
    ]
  },
  {
    name: {
      common: "Ukraine",
      official: "Ukraine",
      nativeName: {
        ukr: {
          official: "Україна",
          common: "Україна"
        }
      }
    },
    tld: [
      ".ua",
      ".укр"
    ],
    cca2: "UA",
    ccn3: "804",
    cca3: "UKR",
    cioc: "UKR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Ukrainian hryvnia ₴"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "80"
      ]
    },
    capital: [
      "Kyiv"
    ],
    altSpellings: [
      "UA",
      "Ukrayina"
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: {
      ukr: "Ukrainian"
    },
    latlng: [
      49,
      32
    ],
    landlocked: "Yes",
    borders: [
      "BLR",
      "HUN",
      "MDA",
      "POL",
      "ROU",
      "RUS",
      "SVK"
    ],
    area: 603500,
    demonyms: {
      eng: {
        f: "Ukrainian",
        m: "Ukrainian"
      },
      fra: {
        f: "Ukrainienne",
        m: "Ukrainien"
      }
    },
    flag: "🇺🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/DvgJMiPJ7aozKFZv7",
      openStreetMaps: "https://www.openstreetmap.org/relation/60199"
    },
    population: 44134693,
    gini: {
      2019: 26.6
    },
    fifa: "UKR",
    car: {
      signs: [
        "UA"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ua.png",
      svg: "https://flagcdn.com/ua.svg",
      alt: "The flag of Ukraine is composed of two equal horizontal bands of blue and yellow."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ua.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ua.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        50.43,
        30.52
      ]
    },
    nativeName: [
      "Ukraine",
      "Україна"
    ]
  },
  {
    name: {
      common: "Guyana",
      official: "Co-operative Republic of Guyana",
      nativeName: {
        eng: {
          official: "Co-operative Republic of Guyana",
          common: "Guyana"
        }
      }
    },
    tld: [
      ".gy"
    ],
    cca2: "GY",
    ccn3: "328",
    cca3: "GUY",
    cioc: "GUY",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Guyanese dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "92"
      ]
    },
    capital: [
      "Georgetown"
    ],
    altSpellings: [
      "GY",
      "Co-operative Republic of Guyana"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      eng: "English"
    },
    latlng: [
      5,
      -59
    ],
    landlocked: "Yes",
    borders: [
      "BRA",
      "SUR",
      "VEN"
    ],
    area: 214969,
    demonyms: {
      eng: {
        f: "Guyanese",
        m: "Guyanese"
      },
      fra: {
        f: "Guyanienne",
        m: "Guyanien"
      }
    },
    flag: "🇬🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/DFsme2xEeugUAsCx5",
      openStreetMaps: "https://www.openstreetmap.org/relation/287083"
    },
    population: 786559,
    gini: {
      1998: 45.1
    },
    fifa: "GUY",
    car: {
      signs: [
        "GUY"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gy.png",
      svg: "https://flagcdn.com/gy.svg",
      alt: "The flag of Guyana has a green field with two isosceles triangles which share a common base on the hoist end. The smaller black-edged red triangle spanning half the width of the field is superimposed on the larger white-edged yellow triangle which spans the full width of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gy.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gy.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.8,
        -58.15
      ]
    },
    nativeName: [
      "Guyana",
      "Co-operative Republic of Guyana"
    ]
  },
  {
    name: {
      common: "Niger",
      official: "Republic of Niger",
      nativeName: {
        fra: {
          official: "République du Niger",
          common: "Niger"
        }
      }
    },
    tld: [
      ".ne"
    ],
    cca2: "NE",
    ccn3: "562",
    cca3: "NER",
    cioc: "NIG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "27"
      ]
    },
    capital: [
      "Niamey"
    ],
    altSpellings: [
      "NE",
      "Nijar"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      16,
      8
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "BEN",
      "BFA",
      "TCD",
      "LBY",
      "MLI",
      "NGA"
    ],
    area: 1267000,
    demonyms: {
      eng: {
        f: "Nigerien",
        m: "Nigerien"
      },
      fra: {
        f: "Nigérienne",
        m: "Nigérien"
      }
    },
    flag: "🇳🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/VKNU2TLsZcgxM49c8",
      openStreetMaps: "https://www.openstreetmap.org/relation/192786"
    },
    population: 24206636,
    gini: {
      2014: 34.3
    },
    fifa: "NIG",
    car: {
      signs: [
        "RN"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ne.png",
      svg: "https://flagcdn.com/ne.svg",
      alt: "The flag of Niger features three equal horizontal bands of orange, white and green, with an orange circle centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ne.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ne.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.52,
        2.12
      ]
    },
    nativeName: [
      "Niger",
      "Republic of Niger",
      "République du Niger"
    ]
  },
  {
    name: {
      common: "Benin",
      official: "Republic of Benin",
      nativeName: {
        fra: {
          official: "République du Bénin",
          common: "Bénin"
        }
      }
    },
    tld: [
      ".bj"
    ],
    cca2: "BJ",
    ccn3: "204",
    cca3: "BEN",
    cioc: "BEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "29"
      ]
    },
    capital: [
      "Porto-Novo"
    ],
    altSpellings: [
      "BJ",
      "Republic of Benin",
      "République du Bénin"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      9.5,
      2.25
    ],
    landlocked: "Yes",
    borders: [
      "BFA",
      "NER",
      "NGA",
      "TGO"
    ],
    area: 112622,
    demonyms: {
      eng: {
        f: "Beninese",
        m: "Beninese"
      },
      fra: {
        f: "Béninoise",
        m: "Béninois"
      }
    },
    flag: "🇧🇯",
    maps: {
      googleMaps: "https://goo.gl/maps/uMw1BsHEXQYgVFFu6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192784"
    },
    population: 12123198,
    gini: {
      2015: 47.8
    },
    fifa: "BEN",
    car: {
      signs: [
        "DY"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bj.png",
      svg: "https://flagcdn.com/bj.svg",
      alt: "The flag of Benin features a green vertical band on its hoist side that takes up about two-fifth the width of the field and two equal horizontal bands of yellow and red adjoining the vertical band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bj.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bj.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.48,
        2.62
      ]
    },
    nativeName: [
      "Benin",
      "Republic of Benin",
      "République du Bénin",
      "Bénin"
    ]
  },
  {
    name: {
      common: "Saint Lucia",
      official: "Saint Lucia",
      nativeName: {
        eng: {
          official: "Saint Lucia",
          common: "Saint Lucia"
        }
      }
    },
    tld: [
      ".lc"
    ],
    cca2: "LC",
    ccn3: "662",
    cca3: "LCA",
    cioc: "LCA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "758"
      ]
    },
    capital: [
      "Castries"
    ],
    altSpellings: [
      "LC"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      13.88333333,
      -60.96666666
    ],
    landlocked: "Yes",
    area: 616,
    demonyms: {
      eng: {
        f: "Saint Lucian",
        m: "Saint Lucian"
      },
      fra: {
        f: "Saint-Lucienne",
        m: "Saint-Lucien"
      }
    },
    flag: "🇱🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/4HhJ2jkPdSL9BPRcA",
      openStreetMaps: "https://www.openstreetmap.org/relation/550728"
    },
    population: 183629,
    gini: {
      2016: 51.2
    },
    fifa: "LCA",
    car: {
      signs: [
        "WL"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lc.png",
      svg: "https://flagcdn.com/lc.svg",
      alt: "The flag of Saint Lucia has a light blue field, at the center of which are two triangles which share a common base — a small golden-yellow isosceles triangle superimposed on a large white-edged black isosceles triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lc.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lc.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14,
        -61
      ]
    },
    nativeName: [
      "Saint Lucia"
    ]
  },
  {
    name: {
      common: "Tuvalu",
      official: "Tuvalu",
      nativeName: {
        eng: {
          official: "Tuvalu",
          common: "Tuvalu"
        },
        tvl: {
          official: "Tuvalu",
          common: "Tuvalu"
        }
      }
    },
    tld: [
      ".tv"
    ],
    cca2: "TV",
    ccn3: "798",
    cca3: "TUV",
    cioc: "TUV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Australian dollar $",
      "Tuvaluan dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "88"
      ]
    },
    capital: [
      "Funafuti"
    ],
    altSpellings: [
      "TV"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      tvl: "Tuvaluan"
    },
    latlng: [
      -8,
      178
    ],
    landlocked: "Yes",
    area: 26,
    demonyms: {
      eng: {
        f: "Tuvaluan",
        m: "Tuvaluan"
      },
      fra: {
        f: "Tuvaluane",
        m: "Tuvaluan"
      }
    },
    flag: "🇹🇻",
    maps: {
      googleMaps: "https://goo.gl/maps/LbuUxtkgm1dfN1Pn6",
      openStreetMaps: "https://www.openstreetmap.org/relation/2177266"
    },
    population: 11792,
    gini: {
      2010: 39.1
    },
    car: {
      signs: [
        "TUV"
      ],
      side: "left"
    },
    timezones: [
      "UTC+12:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tv.png",
      svg: "https://flagcdn.com/tv.svg",
      alt: "The flag of Tuvalu has a light blue field with the flag of the United Kingdom — the Union Jack — in the canton. A representation of the country's nine Islands using nine five-pointed yellow stars is situated in the fly half of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tv.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tv.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -8.52,
        179.22
      ]
    },
    nativeName: [
      "Tuvalu"
    ]
  },
  {
    name: {
      common: "Norfolk Island",
      official: "Territory of Norfolk Island",
      nativeName: {
        eng: {
          official: "Territory of Norfolk Island",
          common: "Norfolk Island"
        },
        pih: {
          official: "Teratri of Norf'k Ailen",
          common: "Norf'k Ailen"
        }
      }
    },
    tld: [
      ".nf"
    ],
    cca2: "NF",
    ccn3: "574",
    cca3: "NFK",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Australian dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "72"
      ]
    },
    capital: [
      "Kingston"
    ],
    altSpellings: [
      "NF",
      "Territory of Norfolk Island",
      "Teratri of Norf'k Ailen"
    ],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: {
      eng: "English",
      pih: "Norfuk"
    },
    latlng: [
      -29.03333333,
      167.95
    ],
    landlocked: "Yes",
    area: 36,
    demonyms: {
      eng: {
        f: "Norfolk Islander",
        m: "Norfolk Islander"
      },
      fra: {
        f: "Norfolkaise",
        m: "Norfolkais"
      }
    },
    flag: "🇳🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/pbvtm6XYd1iZbjky5",
      openStreetMaps: "https://www.openstreetmap.org/relation/2574988"
    },
    population: 2302,
    car: {
      signs: [
        "AUS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+11:30"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/nf.png",
      svg: "https://flagcdn.com/nf.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -29.05,
        167.97
      ]
    },
    nativeName: [
      "Norfolk Island",
      "Territory of Norfolk Island",
      "Teratri of Norf'k Ailen",
      "Norf'k Ailen"
    ]
  },
  {
    name: {
      common: "Egypt",
      official: "Arab Republic of Egypt",
      nativeName: {
        ara: {
          official: "جمهورية مصر العربية",
          common: "مصر"
        }
      }
    },
    tld: [
      ".eg",
      ".مصر"
    ],
    cca2: "EG",
    ccn3: "818",
    cca3: "EGY",
    cioc: "EGY",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Egyptian pound £"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "0"
      ]
    },
    capital: [
      "Cairo"
    ],
    altSpellings: [
      "EG",
      "Arab Republic of Egypt"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      27,
      30
    ],
    landlocked: "Yes",
    borders: [
      "ISR",
      "LBY",
      "PSE",
      "SDN"
    ],
    area: 1002450,
    demonyms: {
      eng: {
        f: "Egyptian",
        m: "Egyptian"
      },
      fra: {
        f: "Égyptienne",
        m: "Égyptien"
      }
    },
    flag: "🇪🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/uoDRhXbsqjG6L7VG7",
      openStreetMaps: "https://www.openstreetmap.org/relation/1473947"
    },
    population: 102334403,
    gini: {
      2017: 31.5
    },
    fifa: "EGY",
    car: {
      signs: [
        "ET"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/eg.png",
      svg: "https://flagcdn.com/eg.svg",
      alt: "The flag of Egypt is composed of three equal horizontal bands of red, white and black, with Egypt's national emblem — a hoist-side facing gold eagle of Saladin — centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/eg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/eg.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        30.05,
        31.25
      ]
    },
    nativeName: [
      "Egypt",
      "Arab Republic of Egypt",
      "جمهورية مصر العربية",
      "مصر"
    ]
  },
  {
    name: {
      common: "Saint Kitts and Nevis",
      official: "Federation of Saint Christopher and Nevis",
      nativeName: {
        eng: {
          official: "Federation of Saint Christopher and Nevis",
          common: "Saint Kitts and Nevis"
        }
      }
    },
    tld: [
      ".kn"
    ],
    cca2: "KN",
    ccn3: "659",
    cca3: "KNA",
    cioc: "SKN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "869"
      ]
    },
    capital: [
      "Basseterre"
    ],
    altSpellings: [
      "KN",
      "Federation of Saint Christopher and Nevis"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      17.33333333,
      -62.75
    ],
    landlocked: "Yes",
    area: 261,
    demonyms: {
      eng: {
        f: "Kittitian or Nevisian",
        m: "Kittitian or Nevisian"
      },
      fra: {
        f: "Kittitienne-et-nevicienne",
        m: "Kittitien-et-nevicien"
      }
    },
    flag: "🇰🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/qiaVwcLVTXX3eoTNA",
      openStreetMaps: "https://www.openstreetmap.org/relation/536899"
    },
    population: 53192,
    fifa: "SKN",
    car: {
      signs: [
        "KN"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kn.png",
      svg: "https://flagcdn.com/kn.svg",
      alt: "The flag of Saint Kitts and Nevis features two large five-pointed white stars within a yellow-edged black diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a green and red triangle respectively."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        17.3,
        -62.72
      ]
    },
    nativeName: [
      "Saint Kitts and Nevis",
      "Federation of Saint Christopher and Nevis"
    ]
  },
  {
    name: {
      common: "Lesotho",
      official: "Kingdom of Lesotho",
      nativeName: {
        eng: {
          official: "Kingdom of Lesotho",
          common: "Lesotho"
        },
        sot: {
          official: "Kingdom of Lesotho",
          common: "Lesotho"
        }
      }
    },
    tld: [
      ".ls"
    ],
    cca2: "LS",
    ccn3: "426",
    cca3: "LSO",
    cioc: "LES",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Lesotho loti L",
      "South African rand R"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "66"
      ]
    },
    capital: [
      "Maseru"
    ],
    altSpellings: [
      "LS",
      "Kingdom of Lesotho",
      "Muso oa Lesotho"
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      eng: "English",
      sot: "Sotho"
    },
    latlng: [
      -29.5,
      28.5
    ],
    landlocked: "Yes",
    borders: [
      "ZAF"
    ],
    area: 30355,
    demonyms: {
      eng: {
        f: "Mosotho",
        m: "Mosotho"
      },
      fra: {
        f: "Lésothienne",
        m: "Lésothien"
      }
    },
    flag: "🇱🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/H8gJi5mL4Cmd1SF28",
      openStreetMaps: "https://www.openstreetmap.org/relation/2093234"
    },
    population: 2142252,
    gini: {
      2017: 44.9
    },
    fifa: "LES",
    car: {
      signs: [
        "LS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ls.png",
      svg: "https://flagcdn.com/ls.svg",
      alt: "The flag of Lesotho is composed of three horizontal bands of blue, white and green in the ratio of 3:4:3. A black mokorotlo — a Basotho hat — is centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ls.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ls.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -29.32,
        27.48
      ]
    },
    nativeName: [
      "Lesotho",
      "Kingdom of Lesotho"
    ]
  },
  {
    name: {
      common: "Tonga",
      official: "Kingdom of Tonga",
      nativeName: {
        eng: {
          official: "Kingdom of Tonga",
          common: "Tonga"
        },
        ton: {
          official: "Kingdom of Tonga",
          common: "Tonga"
        }
      }
    },
    tld: [
      ".to"
    ],
    cca2: "TO",
    ccn3: "776",
    cca3: "TON",
    cioc: "TGA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Tongan paʻanga T$"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "76"
      ]
    },
    capital: [
      "Nuku'alofa"
    ],
    altSpellings: [
      "TO"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      eng: "English",
      ton: "Tongan"
    },
    latlng: [
      -20,
      -175
    ],
    landlocked: "Yes",
    area: 747,
    demonyms: {
      eng: {
        f: "Tongan",
        m: "Tongan"
      },
      fra: {
        f: "Tonguienne",
        m: "Tonguien"
      }
    },
    flag: "🇹🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/p5YALBY2QdEzswRo7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2186665"
    },
    population: 105697,
    gini: {
      2015: 37.6
    },
    fifa: "TGA",
    car: {
      signs: [
        "TO"
      ],
      side: "left"
    },
    timezones: [
      "UTC+13:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/to.png",
      svg: "https://flagcdn.com/to.svg",
      alt: "The flag of Tonga has a red field. A white rectangle bearing a red Greek cross is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/to.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/to.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -21.13,
        -175.2
      ]
    },
    nativeName: [
      "Tonga",
      "Kingdom of Tonga"
    ]
  },
  {
    name: {
      common: "Georgia",
      official: "Georgia",
      nativeName: {
        kat: {
          official: "საქართველო",
          common: "საქართველო"
        }
      }
    },
    tld: [
      ".ge"
    ],
    cca2: "GE",
    ccn3: "268",
    cca3: "GEO",
    cioc: "GEO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "lari ₾"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "95"
      ]
    },
    capital: [
      "Tbilisi"
    ],
    altSpellings: [
      "GE",
      "Sakartvelo"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      kat: "Georgian"
    },
    latlng: [
      42,
      43.5
    ],
    landlocked: "Yes",
    borders: [
      "ARM",
      "AZE",
      "RUS",
      "TUR"
    ],
    area: 69700,
    demonyms: {
      eng: {
        f: "Georgian",
        m: "Georgian"
      },
      fra: {
        f: "Géorgienne",
        m: "Géorgien"
      }
    },
    flag: "🇬🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/bvCaGBePR1ZEDK5cA",
      openStreetMaps: "https://www.openstreetmap.org/relation/28699"
    },
    population: 3714000,
    gini: {
      2019: 35.9
    },
    fifa: "GEO",
    car: {
      signs: [
        "GE"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ge.png",
      svg: "https://flagcdn.com/ge.svg",
      alt: "The flag of Georgia has a white field with a large centered red cross that extends to the edges and divides the field into four quarters. A small red Bolnur-Katskhuri cross is centered in each quarter."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ge.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ge.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        41.68,
        44.83
      ]
    },
    nativeName: [
      "Georgia",
      "საქართველო"
    ]
  },
  {
    name: {
      common: "Ethiopia",
      official: "Federal Democratic Republic of Ethiopia",
      nativeName: {
        amh: {
          official: "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
          common: "ኢትዮጵያ"
        }
      }
    },
    tld: [
      ".et"
    ],
    cca2: "ET",
    ccn3: "231",
    cca3: "ETH",
    cioc: "ETH",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Ethiopian birr Br"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "51"
      ]
    },
    capital: [
      "Addis Ababa"
    ],
    altSpellings: [
      "ET",
      "ʾĪtyōṗṗyā",
      "Federal Democratic Republic of Ethiopia",
      "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      amh: "Amharic"
    },
    latlng: [
      8,
      38
    ],
    landlocked: "Yes",
    borders: [
      "DJI",
      "ERI",
      "KEN",
      "SOM",
      "SSD",
      "SDN"
    ],
    area: 1104300,
    demonyms: {
      eng: {
        f: "Ethiopian",
        m: "Ethiopian"
      },
      fra: {
        f: "Éthiopienne",
        m: "Éthiopien"
      }
    },
    flag: "🇪🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/2Q4hQWCbhuZLj3fG6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192800"
    },
    population: 114963583,
    gini: {
      2015: 35
    },
    fifa: "ETH",
    car: {
      signs: [
        "ETH"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/et.png",
      svg: "https://flagcdn.com/et.svg",
      alt: "The flag of Ethiopia is composed of three equal horizontal bands of green, yellow and red, with the national emblem superimposed at the center of the field. The national emblem comprises a light blue circle bearing a golden-yellow pentagram with single yellow rays emanating from the angles between the points of the pentagram."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/et.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/et.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        9.03,
        38.7
      ]
    },
    nativeName: [
      "Ethiopia",
      "Federal Democratic Republic of Ethiopia",
      "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
      "ኢትዮጵያ"
    ]
  },
  {
    name: {
      common: "Dominican Republic",
      official: "Dominican Republic",
      nativeName: {
        spa: {
          official: "República Dominicana",
          common: "República Dominicana"
        }
      }
    },
    tld: [
      ".do"
    ],
    cca2: "DO",
    ccn3: "214",
    cca3: "DOM",
    cioc: "DOM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Dominican peso $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "809",
        "829",
        "849"
      ]
    },
    capital: [
      "Santo Domingo"
    ],
    altSpellings: [
      "DO"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      19,
      -70.66666666
    ],
    landlocked: "Yes",
    borders: [
      "HTI"
    ],
    area: 48671,
    demonyms: {
      eng: {
        f: "Dominican",
        m: "Dominican"
      },
      fra: {
        f: "Dominicaine",
        m: "Dominicain"
      }
    },
    flag: "🇩🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/soxooTHxEeiAbn3UA",
      openStreetMaps: "https://www.openstreetmap.org/relation/307828"
    },
    population: 10847904,
    gini: {
      2019: 41.9
    },
    fifa: "DOM",
    car: {
      signs: [
        "DOM"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/do.png",
      svg: "https://flagcdn.com/do.svg",
      alt: "The flag of the Dominican Republic is divided into four rectangles by a centered white cross that extends to the edges of the field and bears the national coat of arms in its center. The upper hoist-side and lower fly-side rectangles are blue and the lower hoist-side and upper fly-side rectangles are red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/do.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/do.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.47,
        -69.9
      ]
    },
    nativeName: [
      "Dominican Republic",
      "República Dominicana"
    ]
  },
  {
    name: {
      common: "Saint Vincent and the Grenadines",
      official: "Saint Vincent and the Grenadines",
      nativeName: {
        eng: {
          official: "Saint Vincent and the Grenadines",
          common: "Saint Vincent and the Grenadines"
        }
      }
    },
    tld: [
      ".vc"
    ],
    cca2: "VC",
    ccn3: "670",
    cca3: "VCT",
    cioc: "VIN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "784"
      ]
    },
    capital: [
      "Kingstown"
    ],
    altSpellings: [
      "VC"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      13.25,
      -61.2
    ],
    landlocked: "Yes",
    area: 389,
    demonyms: {
      eng: {
        f: "Saint Vincentian",
        m: "Saint Vincentian"
      },
      fra: {
        f: "Vincentaise",
        m: "Vincentais"
      }
    },
    flag: "🇻🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/wMbnMqjG37FMnrwf7",
      openStreetMaps: "https://www.openstreetmap.org/relation/550725"
    },
    population: 110947,
    fifa: "VIN",
    car: {
      signs: [
        "WV"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/vc.png",
      svg: "https://flagcdn.com/vc.svg",
      alt: "The flag of Saint Vincent and the Grenadines is composed of three vertical bands of blue, gold and green. The gold band is twice as wide as the other two bands and bears three green diamonds arranged to form the letter V at its center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/vc.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/vc.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.13,
        -61.22
      ]
    },
    nativeName: [
      "Saint Vincent and the Grenadines"
    ]
  },
  {
    name: {
      common: "Belize",
      official: "Belize",
      nativeName: {
        bjz: {
          official: "Belize",
          common: "Belize"
        },
        eng: {
          official: "Belize",
          common: "Belize"
        },
        spa: {
          official: "Belice",
          common: "Belice"
        }
      }
    },
    tld: [
      ".bz"
    ],
    cca2: "BZ",
    ccn3: "084",
    cca3: "BLZ",
    cioc: "BIZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Belize dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "01"
      ]
    },
    capital: [
      "Belmopan"
    ],
    altSpellings: [
      "BZ"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      bjz: "Belizean Creole",
      eng: "English",
      spa: "Spanish"
    },
    latlng: [
      17.25,
      -88.75
    ],
    landlocked: "Yes",
    borders: [
      "GTM",
      "MEX"
    ],
    area: 22966,
    demonyms: {
      eng: {
        f: "Belizean",
        m: "Belizean"
      },
      fra: {
        f: "Bélizienne",
        m: "Bélizien"
      }
    },
    flag: "🇧🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/jdCccpdLodm1uTmo9",
      openStreetMaps: "https://www.openstreetmap.org/relation/287827"
    },
    population: 397621,
    gini: {
      1999: 53.3
    },
    fifa: "BLZ",
    car: {
      signs: [
        "BH"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bz.png",
      svg: "https://flagcdn.com/bz.svg",
      alt: "The flag of Belize has a royal blue field with a thin red horizontal band at the top and bottom of the field and the national coat of arms in the center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        17.25,
        -88.77
      ]
    },
    nativeName: [
      "Belize",
      "Belice"
    ]
  },
  {
    name: {
      common: "Isle of Man",
      official: "Isle of Man",
      nativeName: {
        eng: {
          official: "Isle of Man",
          common: "Isle of Man"
        },
        glv: {
          official: "Ellan Vannin or Mannin",
          common: "Mannin"
        }
      }
    },
    tld: [
      ".im"
    ],
    cca2: "IM",
    ccn3: "833",
    cca3: "IMN",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "British pound £",
      "Manx pound £"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Douglas"
    ],
    altSpellings: [
      "IM",
      "Ellan Vannin",
      "Mann",
      "Mannin"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      eng: "English",
      glv: "Manx"
    },
    latlng: [
      54.25,
      -4.5
    ],
    landlocked: "Yes",
    area: 572,
    demonyms: {
      eng: {
        f: "Manx",
        m: "Manx"
      }
    },
    flag: "🇮🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/4DqVHDgVaFgnh8ZV8",
      openStreetMaps: "https://www.openstreetmap.org/relation/62269"
    },
    population: 85032,
    car: {
      signs: [
        "GBM"
      ],
      side: "left"
    },
    timezones: [
      "UTC+00:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/im.png",
      svg: "https://flagcdn.com/im.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/im.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/im.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        54.15,
        -4.48
      ]
    },
    nativeName: [
      "Isle of Man",
      "Ellan Vannin or Mannin",
      "Mannin"
    ]
  },
  {
    name: {
      common: "Morocco",
      official: "Kingdom of Morocco",
      nativeName: {
        ara: {
          official: "المملكة المغربية",
          common: "المغرب"
        },
        ber: {
          official: "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ",
          common: "ⵍⵎⴰⵖⵔⵉⴱ"
        }
      }
    },
    tld: [
      ".ma",
      "المغرب."
    ],
    cca2: "MA",
    ccn3: "504",
    cca3: "MAR",
    cioc: "MAR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Moroccan dirham د.م."
    ],
    idd: {
      root: "+2",
      suffixes: [
        "12"
      ]
    },
    capital: [
      "Rabat"
    ],
    altSpellings: [
      "MA",
      "Kingdom of Morocco",
      "Al-Mamlakah al-Maġribiyah"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ara: "Arabic",
      ber: "Berber"
    },
    latlng: [
      32,
      -5
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "ESH",
      "ESP"
    ],
    area: 446550,
    demonyms: {
      eng: {
        f: "Moroccan",
        m: "Moroccan"
      },
      fra: {
        f: "Marocaine",
        m: "Marocain"
      }
    },
    flag: "🇲🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/6oMv3dyBZg3iaXQ5A",
      openStreetMaps: "https://www.openstreetmap.org/relation/3630439"
    },
    population: 36910558,
    gini: {
      2013: 39.5
    },
    fifa: "MAR",
    car: {
      signs: [
        "MA"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ma.png",
      svg: "https://flagcdn.com/ma.svg",
      alt: "The flag of Morocco features a green pentagram — a five-pointed linear star — centered on a red field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ma.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ma.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        34.02,
        -6.82
      ]
    },
    nativeName: [
      "Morocco",
      "Kingdom of Morocco",
      "المملكة المغربية",
      "المغرب",
      "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ",
      "ⵍⵎⴰⵖⵔⵉⴱ"
    ]
  },
  {
    name: {
      common: "Haiti",
      official: "Republic of Haiti",
      nativeName: {
        fra: {
          official: "République d'Haïti",
          common: "Haïti"
        },
        hat: {
          official: "Repiblik Ayiti",
          common: "Ayiti"
        }
      }
    },
    tld: [
      ".ht"
    ],
    cca2: "HT",
    ccn3: "332",
    cca3: "HTI",
    cioc: "HAI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Haitian gourde G"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "09"
      ]
    },
    capital: [
      "Port-au-Prince"
    ],
    altSpellings: [
      "HT",
      "Republic of Haiti",
      "République d'Haïti",
      "Repiblik Ayiti"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      fra: "French",
      hat: "Haitian Creole"
    },
    latlng: [
      19,
      -72.41666666
    ],
    landlocked: "Yes",
    borders: [
      "DOM"
    ],
    area: 27750,
    demonyms: {
      eng: {
        f: "Haitian",
        m: "Haitian"
      },
      fra: {
        f: "Haïtienne",
        m: "Haïtien"
      }
    },
    flag: "🇭🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/9o13xtjuUdqFnHbn9",
      openStreetMaps: "https://www.openstreetmap.org/relation/307829"
    },
    population: 11402533,
    gini: {
      2012: 41.1
    },
    fifa: "HAI",
    car: {
      signs: [
        "RH"
      ],
      side: "right"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ht.png",
      svg: "https://flagcdn.com/ht.svg",
      alt: "The flag of Haiti is composed of two equal horizontal bands of blue and red. A white square bearing the national coat of arms is superimposed at the center of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ht.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ht.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.53,
        -72.33
      ]
    },
    nativeName: [
      "Haiti",
      "Republic of Haiti",
      "République d'Haïti",
      "Haïti",
      "Repiblik Ayiti",
      "Ayiti"
    ]
  },
  {
    name: {
      common: "Mayotte",
      official: "Department of Mayotte",
      nativeName: {
        fra: {
          official: "Département de Mayotte",
          common: "Mayotte"
        }
      }
    },
    tld: [
      ".yt"
    ],
    cca2: "YT",
    ccn3: "175",
    cca3: "MYT",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "62"
      ]
    },
    capital: [
      "Mamoudzou"
    ],
    altSpellings: [
      "YT",
      "Department of Mayotte",
      "Département de Mayotte"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      -12.83333333,
      45.16666666
    ],
    landlocked: "Yes",
    area: 374,
    demonyms: {
      eng: {
        f: "Mahoran",
        m: "Mahoran"
      },
      fra: {
        f: "Mahoraise",
        m: "Mahorais"
      }
    },
    flag: "🇾🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/1e7MXmfBwQv3TQGF7",
      openStreetMaps: "https://www.openstreetmap.org/relation/1259885"
    },
    population: 226915,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/yt.png",
      svg: "https://flagcdn.com/yt.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -12.78,
        45.22
      ]
    },
    nativeName: [
      "Mayotte",
      "Department of Mayotte",
      "Département de Mayotte"
    ]
  },
  {
    name: {
      common: "Burkina Faso",
      official: "Burkina Faso",
      nativeName: {
        fra: {
          official: "République du Burkina",
          common: "Burkina Faso"
        }
      }
    },
    tld: [
      ".bf"
    ],
    cca2: "BF",
    ccn3: "854",
    cca3: "BFA",
    cioc: "BUR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "26"
      ]
    },
    capital: [
      "Ouagadougou"
    ],
    altSpellings: [
      "BF"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      13,
      -2
    ],
    landlocked: "Yes",
    borders: [
      "BEN",
      "CIV",
      "GHA",
      "MLI",
      "NER",
      "TGO"
    ],
    area: 272967,
    demonyms: {
      eng: {
        f: "Burkinabe",
        m: "Burkinabe"
      },
      fra: {
        f: "Burkinabée",
        m: "Burkinabé"
      }
    },
    flag: "🇧🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/rKRmpcMbFher2ozb7",
      openStreetMaps: "https://www.openstreetmap.org/relation/192783"
    },
    population: 20903278,
    gini: {
      2014: 35.3
    },
    fifa: "BFA",
    car: {
      signs: [
        "BF"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bf.png",
      svg: "https://flagcdn.com/bf.svg",
      alt: "The flag of Burkina Faso features two equal horizontal bands of red and green, with a yellow five-pointed star in the center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bf.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bf.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.37,
        -1.52
      ]
    },
    nativeName: [
      "Burkina Faso",
      "République du Burkina"
    ]
  },
  {
    name: {
      common: "Bangladesh",
      official: "People's Republic of Bangladesh",
      nativeName: {
        ben: {
          official: "বাংলাদেশ গণপ্রজাতন্ত্রী",
          common: "বাংলাদেশ"
        }
      }
    },
    tld: [
      ".bd"
    ],
    cca2: "BD",
    ccn3: "050",
    cca3: "BGD",
    cioc: "BAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bangladeshi taka ৳"
    ],
    idd: {
      root: "+8",
      suffixes: [
        "80"
      ]
    },
    capital: [
      "Dhaka"
    ],
    altSpellings: [
      "BD",
      "People's Republic of Bangladesh",
      "Gônôprôjatôntri Bangladesh"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      ben: "Bengali"
    },
    latlng: [
      24,
      90
    ],
    landlocked: "Yes",
    borders: [
      "MMR",
      "IND"
    ],
    area: 147570,
    demonyms: {
      eng: {
        f: "Bangladeshi",
        m: "Bangladeshi"
      },
      fra: {
        f: "Bangladaise",
        m: "Bangladais"
      }
    },
    flag: "🇧🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/op6gmLbHcvv6rLhH6",
      openStreetMaps: "https://www.openstreetmap.org/relation/184640"
    },
    population: 164689383,
    gini: {
      2016: 32.4
    },
    fifa: "BAN",
    car: {
      signs: [
        "BD"
      ],
      side: "left"
    },
    timezones: [
      "UTC+06:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bd.png",
      svg: "https://flagcdn.com/bd.svg",
      alt: "The flag of Bangladesh has a dark green field bearing a large red circle that is offset slightly towards the hoist side of center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bd.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bd.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        23.72,
        90.4
      ]
    },
    nativeName: [
      "Bangladesh",
      "People's Republic of Bangladesh",
      "বাংলাদেশ গণপ্রজাতন্ত্রী",
      "বাংলাদেশ"
    ]
  },
  {
    name: {
      common: "Kuwait",
      official: "State of Kuwait",
      nativeName: {
        ara: {
          official: "دولة الكويت",
          common: "الكويت"
        }
      }
    },
    tld: [
      ".kw"
    ],
    cca2: "KW",
    ccn3: "414",
    cca3: "KWT",
    cioc: "KUW",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Kuwaiti dinar د.ك"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "65"
      ]
    },
    capital: [
      "Kuwait City"
    ],
    altSpellings: [
      "KW",
      "State of Kuwait",
      "Dawlat al-Kuwait"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      29.5,
      45.75
    ],
    landlocked: "Yes",
    borders: [
      "IRQ",
      "SAU"
    ],
    area: 17818,
    demonyms: {
      eng: {
        f: "Kuwaiti",
        m: "Kuwaiti"
      },
      fra: {
        f: "Koweïtienne",
        m: "Koweïtien"
      }
    },
    flag: "🇰🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/aqr3aNQjS1BAvksJ7",
      openStreetMaps: "https://www.openstreetmap.org/relation/305099"
    },
    population: 4270563,
    fifa: "KUW",
    car: {
      signs: [
        "KWT"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/kw.png",
      svg: "https://flagcdn.com/kw.svg",
      alt: "The flag of Kuwait is composed of three equal horizontal bands of green, white and red, with a black trapezium superimposed on the hoist side of the field. This trapezium has its base on the hoist end and spans about one-fourth the width of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/kw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/kw.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        29.37,
        47.97
      ]
    },
    nativeName: [
      "Kuwait",
      "State of Kuwait",
      "دولة الكويت",
      "الكويت"
    ]
  },
  {
    name: {
      common: "Réunion",
      official: "Réunion Island",
      nativeName: {
        fra: {
          official: "Ile de la Réunion",
          common: "La Réunion"
        }
      }
    },
    tld: [
      ".re"
    ],
    cca2: "RE",
    ccn3: "638",
    cca3: "REU",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "62"
      ]
    },
    capital: [
      "Saint-Denis"
    ],
    altSpellings: [
      "RE",
      "Reunion"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      -21.15,
      55.5
    ],
    landlocked: "Yes",
    area: 2511,
    demonyms: {
      eng: {
        f: "Réunionese",
        m: "Réunionese"
      },
      fra: {
        f: "Réunionnaise",
        m: "Réunionnais"
      }
    },
    flag: "🇷🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/wWpBrXsp8UHVbah29",
      openStreetMaps: "https://www.openstreetmap.org/relation/1785276"
    },
    population: 840974,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/re.png",
      svg: "https://flagcdn.com/re.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -20.88,
        55.45
      ]
    },
    nativeName: [
      "Réunion",
      "Réunion Island",
      "Ile de la Réunion",
      "La Réunion"
    ]
  },
  {
    name: {
      common: "Jamaica",
      official: "Jamaica",
      nativeName: {
        eng: {
          official: "Jamaica",
          common: "Jamaica"
        },
        jam: {
          official: "Jamaica",
          common: "Jamaica"
        }
      }
    },
    tld: [
      ".jm"
    ],
    cca2: "JM",
    ccn3: "388",
    cca3: "JAM",
    cioc: "JAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Jamaican dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "876"
      ]
    },
    capital: [
      "Kingston"
    ],
    altSpellings: [
      "JM"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English",
      jam: "Jamaican Patois"
    },
    latlng: [
      18.25,
      -77.5
    ],
    landlocked: "Yes",
    area: 10991,
    demonyms: {
      eng: {
        f: "Jamaican",
        m: "Jamaican"
      },
      fra: {
        f: "Jamaïcaine",
        m: "Jamaïcain"
      }
    },
    flag: "🇯🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/Z8mQ6jxnRQKFwJy9A",
      openStreetMaps: "https://www.openstreetmap.org/relation/555017"
    },
    population: 2961161,
    gini: {
      2004: 45.5
    },
    fifa: "JAM",
    car: {
      signs: [
        "JA"
      ],
      side: "left"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/jm.png",
      svg: "https://flagcdn.com/jm.svg",
      alt: "The flag of Jamaica is divided by a gold diagonal cross into four alternating triangular areas of green at the top and bottom, and black on the hoist and fly sides"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/jm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/jm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        17.99702,
        -76.79358
      ]
    },
    nativeName: [
      "Jamaica"
    ]
  },
  {
    name: {
      common: "Romania",
      official: "Romania",
      nativeName: {
        ron: {
          official: "România",
          common: "România"
        }
      }
    },
    tld: [
      ".ro"
    ],
    cca2: "RO",
    ccn3: "642",
    cca3: "ROU",
    cioc: "ROU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Romanian leu lei"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "0"
      ]
    },
    capital: [
      "Bucharest"
    ],
    altSpellings: [
      "RO",
      "Rumania",
      "Roumania",
      "România"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      ron: "Romanian"
    },
    latlng: [
      46,
      25
    ],
    landlocked: "Yes",
    borders: [
      "BGR",
      "HUN",
      "MDA",
      "SRB",
      "UKR"
    ],
    area: 238391,
    demonyms: {
      eng: {
        f: "Romanian",
        m: "Romanian"
      },
      fra: {
        f: "Roumaine",
        m: "Roumain"
      }
    },
    flag: "🇷🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/845hAgCf1mDkN3vr7",
      openStreetMaps: "https://www.openstreetmap.org/relation/90689"
    },
    population: 19286123,
    gini: {
      2018: 35.8
    },
    fifa: "ROU",
    car: {
      signs: [
        "RO"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ro.png",
      svg: "https://flagcdn.com/ro.svg",
      alt: "The flag of Romania is composed of three equal vertical bands of navy blue, yellow and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ro.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ro.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        44.43,
        26.1
      ]
    },
    nativeName: [
      "Romania",
      "România"
    ]
  },
  {
    name: {
      common: "São Tomé and Príncipe",
      official: "Democratic Republic of São Tomé and Príncipe",
      nativeName: {
        por: {
          official: "República Democrática do São Tomé e Príncipe",
          common: "São Tomé e Príncipe"
        }
      }
    },
    tld: [
      ".st"
    ],
    cca2: "ST",
    ccn3: "678",
    cca3: "STP",
    cioc: "STP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "São Tomé and Príncipe dobra Db"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "39"
      ]
    },
    capital: [
      "São Tomé"
    ],
    altSpellings: [
      "ST",
      "Democratic Republic of São Tomé and Príncipe",
      "Sao Tome and Principe",
      "República Democrática de São Tomé e Príncipe"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      por: "Portuguese"
    },
    latlng: [
      1,
      7
    ],
    landlocked: "Yes",
    area: 964,
    demonyms: {
      eng: {
        f: "Sao Tomean",
        m: "Sao Tomean"
      },
      fra: {
        f: "Santoméenne",
        m: "Santoméen"
      }
    },
    flag: "🇸🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/9EUppm13RtPX9oF46",
      openStreetMaps: "https://www.openstreetmap.org/relation/535880"
    },
    population: 219161,
    gini: {
      2017: 56.3
    },
    fifa: "STP",
    car: {
      signs: [
        "STP"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/st.png",
      svg: "https://flagcdn.com/st.svg",
      alt: "The flag of South Sudan is composed of three equal horizontal bands of black, red with white top and bottom edges, and green. A blue equilateral triangle which spans about two-fifth the width of the field is superimposed on the hoist side with its base on the hoist end of the field. At the center of this triangle is a five-pointed yellow star."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/st.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/st.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        0.34,
        6.73
      ]
    },
    nativeName: [
      "São Tomé and Príncipe",
      "Democratic Republic of São Tomé and Príncipe",
      "República Democrática do São Tomé e Príncipe",
      "São Tomé e Príncipe"
    ]
  },
  {
    name: {
      common: "Bahamas",
      official: "Commonwealth of the Bahamas",
      nativeName: {
        eng: {
          official: "Commonwealth of the Bahamas",
          common: "Bahamas"
        }
      }
    },
    tld: [
      ".bs"
    ],
    cca2: "BS",
    ccn3: "044",
    cca3: "BHS",
    cioc: "BAH",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bahamian dollar $",
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "242"
      ]
    },
    capital: [
      "Nassau"
    ],
    altSpellings: [
      "BS",
      "Commonwealth of the Bahamas"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      25.0343,
      -77.3963
    ],
    landlocked: "Yes",
    area: 13943,
    demonyms: {
      eng: {
        f: "Bahamian",
        m: "Bahamian"
      },
      fra: {
        f: "Bahamienne",
        m: "Bahamien"
      }
    },
    flag: "🇧🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/1YzRs1BZrG8p8pmVA",
      openStreetMaps: "https://www.openstreetmap.org/relation/547469"
    },
    population: 393248,
    fifa: "BAH",
    car: {
      signs: [
        "BS"
      ],
      side: "left"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bs.png",
      svg: "https://flagcdn.com/bs.svg",
      alt: "The flag of the Bahamas is composed of three equal horizontal bands of aquamarine, yellow and aquamarine, with a black equilateral triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end and spans about one-third the width of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bs.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bs.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        25.08,
        -77.35
      ]
    },
    nativeName: [
      "Bahamas",
      "Commonwealth of the Bahamas"
    ]
  },
  {
    name: {
      common: "Mexico",
      official: "United Mexican States",
      nativeName: {
        spa: {
          official: "Estados Unidos Mexicanos",
          common: "México"
        }
      }
    },
    tld: [
      ".mx"
    ],
    cca2: "MX",
    ccn3: "484",
    cca3: "MEX",
    cioc: "MEX",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Mexican peso $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "2"
      ]
    },
    capital: [
      "Mexico City"
    ],
    altSpellings: [
      "MX",
      "Mexicanos",
      "United Mexican States",
      "Estados Unidos Mexicanos"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      23,
      -102
    ],
    landlocked: "Yes",
    borders: [
      "BLZ",
      "GTM",
      "USA"
    ],
    area: 1964375,
    demonyms: {
      eng: {
        f: "Mexican",
        m: "Mexican"
      },
      fra: {
        f: "Mexicaine",
        m: "Mexicain"
      }
    },
    flag: "🇲🇽",
    maps: {
      googleMaps: "https://goo.gl/maps/s5g7imNPMDEePxzbA",
      openStreetMaps: "https://www.openstreetmap.org/relation/114686"
    },
    population: 128932753,
    gini: {
      2018: 45.4
    },
    fifa: "MEX",
    car: {
      signs: [
        "MEX"
      ],
      side: "right"
    },
    timezones: [
      "UTC-08:00",
      "UTC-07:00",
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mx.png",
      svg: "https://flagcdn.com/mx.svg",
      alt: "The flag of Mexico is composed of three equal vertical bands of green, white and red, with the national coat of arms centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mx.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mx.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        19.43,
        -99.13
      ]
    },
    nativeName: [
      "Mexico",
      "United Mexican States",
      "Estados Unidos Mexicanos",
      "México"
    ]
  },
  {
    name: {
      common: "Saint Martin",
      official: "Saint Martin",
      nativeName: {
        fra: {
          official: "Saint-Martin",
          common: "Saint-Martin"
        }
      }
    },
    tld: [
      ".fr",
      ".gp"
    ],
    cca2: "MF",
    ccn3: "663",
    cca3: "MAF",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "90"
      ]
    },
    capital: [
      "Marigot"
    ],
    altSpellings: [
      "MF",
      "Collectivity of Saint Martin",
      "Collectivité de Saint-Martin",
      "Saint Martin (French part)"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      fra: "French"
    },
    latlng: [
      18.0708,
      63.0501
    ],
    landlocked: "Yes",
    borders: [
      "SXM"
    ],
    area: 53,
    demonyms: {
      eng: {
        f: "Saint Martin Islander",
        m: "Saint Martin Islander"
      },
      fra: {
        f: "Saint-Martinoise",
        m: "Saint-Martinois"
      }
    },
    flag: "🇲🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/P9ho9QuJ9EAR28JEA",
      openStreetMaps: "https://www.openstreetmap.org/relation/63064"
    },
    population: 38659,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mf.png",
      svg: "https://flagcdn.com/mf.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.07,
        -63.08
      ]
    },
    nativeName: [
      "Saint Martin",
      "Saint-Martin"
    ]
  },
  {
    name: {
      common: "El Salvador",
      official: "Republic of El Salvador",
      nativeName: {
        spa: {
          official: "República de El Salvador",
          common: "El Salvador"
        }
      }
    },
    tld: [
      ".sv"
    ],
    cca2: "SV",
    ccn3: "222",
    cca3: "SLV",
    cioc: "ESA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "03"
      ]
    },
    capital: [
      "San Salvador"
    ],
    altSpellings: [
      "SV",
      "Republic of El Salvador",
      "República de El Salvador"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      13.83333333,
      -88.91666666
    ],
    landlocked: "Yes",
    borders: [
      "GTM",
      "HND"
    ],
    area: 21041,
    demonyms: {
      eng: {
        f: "Salvadoran",
        m: "Salvadoran"
      },
      fra: {
        f: "Salvadorienne",
        m: "Salvadorien"
      }
    },
    flag: "🇸🇻",
    maps: {
      googleMaps: "https://goo.gl/maps/cZnCEi5sEMQtKKcB7",
      openStreetMaps: "https://www.openstreetmap.org/relation/1520612"
    },
    population: 6486201,
    gini: {
      2019: 38.8
    },
    fifa: "SLV",
    car: {
      signs: [
        "ES"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sv.png",
      svg: "https://flagcdn.com/sv.svg",
      alt: "The flag of El Salvador is composed of three equal horizontal bands of cobalt blue, white and cobalt blue, with the national coat of arms centered in the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sv.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sv.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.7,
        -89.2
      ]
    },
    nativeName: [
      "El Salvador",
      "Republic of El Salvador",
      "República de El Salvador"
    ]
  },
  {
    name: {
      common: "Saint Barthélemy",
      official: "Collectivity of Saint Barthélemy",
      nativeName: {
        fra: {
          official: "Collectivité de Saint-Barthélemy",
          common: "Saint-Barthélemy"
        }
      }
    },
    tld: [
      ".bl"
    ],
    cca2: "BL",
    ccn3: "652",
    cca3: "BLM",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "90"
      ]
    },
    capital: [
      "Gustavia"
    ],
    altSpellings: [
      "BL",
      "St. Barthelemy",
      "Collectivity of Saint Barthélemy",
      "Collectivité de Saint-Barthélemy"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      fra: "French"
    },
    latlng: [
      18.5,
      -63.41666666
    ],
    landlocked: "Yes",
    area: 21,
    demonyms: {
      eng: {
        f: "Saint Barthélemy Islander",
        m: "Saint Barthélemy Islander"
      },
      fra: {
        f: "Barthéloméenne",
        m: "Barthéloméen"
      }
    },
    flag: "🇧🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/Mc7GqH466S7AAk297",
      openStreetMaps: "https://www.openstreetmap.org/relation/7552779"
    },
    population: 4255,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bl.png",
      svg: "https://flagcdn.com/bl.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        17.88,
        -62.85
      ]
    },
    nativeName: [
      "Saint Barthélemy",
      "Collectivity of Saint Barthélemy",
      "Collectivité de Saint-Barthélemy",
      "Saint-Barthélemy"
    ]
  },
  {
    name: {
      common: "Micronesia",
      official: "Federated States of Micronesia",
      nativeName: {
        eng: {
          official: "Federated States of Micronesia",
          common: "Micronesia"
        }
      }
    },
    tld: [
      ".fm"
    ],
    cca2: "FM",
    ccn3: "583",
    cca3: "FSM",
    cioc: "FSM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "91"
      ]
    },
    capital: [
      "Palikir"
    ],
    altSpellings: [
      "FM",
      "Federated States of Micronesia",
      "Micronesia, Federated States of"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      eng: "English"
    },
    latlng: [
      6.91666666,
      158.25
    ],
    landlocked: "Yes",
    area: 702,
    demonyms: {
      eng: {
        f: "Micronesian",
        m: "Micronesian"
      },
      fra: {
        f: "Micronésienne",
        m: "Micronésien"
      }
    },
    flag: "🇫🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/LLcnofC5LxZsJXTo8",
      openStreetMaps: "https://www.openstreetmap.org/relation/571802"
    },
    population: 115021,
    gini: {
      2013: 40.1
    },
    car: {
      signs: [
        "FSM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+10:00",
      "UTC+11:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/fm.png",
      svg: "https://flagcdn.com/fm.svg",
      alt: "The flag of Micronesia has a light blue field, at the center of which are four five-pointed white stars arranged in the shape of a diamond."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/fm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/fm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.92,
        158.15
      ]
    },
    nativeName: [
      "Micronesia",
      "Federated States of Micronesia"
    ]
  },
  {
    name: {
      common: "Turkmenistan",
      official: "Turkmenistan",
      nativeName: {
        rus: {
          official: "Туркменистан",
          common: "Туркмения"
        },
        tuk: {
          official: "Türkmenistan",
          common: "Türkmenistan"
        }
      }
    },
    tld: [
      ".tm"
    ],
    cca2: "TM",
    ccn3: "795",
    cca3: "TKM",
    cioc: "TKM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Turkmenistan manat m"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "93"
      ]
    },
    capital: [
      "Ashgabat"
    ],
    altSpellings: [
      "TM"
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: {
      rus: "Russian",
      tuk: "Turkmen"
    },
    latlng: [
      40,
      60
    ],
    landlocked: "Yes",
    borders: [
      "AFG",
      "IRN",
      "KAZ",
      "UZB"
    ],
    area: 488100,
    demonyms: {
      eng: {
        f: "Turkmen",
        m: "Turkmen"
      },
      fra: {
        f: "Turkmène",
        m: "Turkmène"
      }
    },
    flag: "🇹🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/cgfUcaQHSWKuqeKk9",
      openStreetMaps: "https://www.openstreetmap.org/relation/223026"
    },
    population: 6031187,
    gini: {
      1998: 40.8
    },
    fifa: "TKM",
    car: {
      signs: [
        "TM"
      ],
      side: "right"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tm.png",
      svg: "https://flagcdn.com/tm.svg",
      alt: "The flag of Turkmenistan has a green field. It features a red vertical band, bearing five carpet guls stacked above two crossed olive branches, near the hoist end of the field. Just to the fly side of the vertical band near the top edge of the field is a hoist-side facing white crescent and five small five-pointed white stars placed just outside the crescent opening."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        37.95,
        58.38
      ]
    },
    nativeName: [
      "Turkmenistan",
      "Туркменистан",
      "Туркмения",
      "Türkmenistan"
    ]
  },
  {
    name: {
      common: "Anguilla",
      official: "Anguilla",
      nativeName: {
        eng: {
          official: "Anguilla",
          common: "Anguilla"
        }
      }
    },
    tld: [
      ".ai"
    ],
    cca2: "AI",
    ccn3: "660",
    cca3: "AIA",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "264"
      ]
    },
    capital: [
      "The Valley"
    ],
    altSpellings: [
      "AI"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      18.25,
      -63.16666666
    ],
    landlocked: "Yes",
    area: 91,
    demonyms: {
      eng: {
        f: "Anguillian",
        m: "Anguillian"
      },
      fra: {
        f: "Anguillane",
        m: "Anguillan"
      }
    },
    flag: "🇦🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/3KgLnEyN7amdno2p9",
      openStreetMaps: "https://www.openstreetmap.org/relation/2177161"
    },
    population: 13452,
    fifa: "AIA",
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ai.png",
      svg: "https://flagcdn.com/ai.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ai.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ai.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.22,
        -63.05
      ]
    },
    nativeName: [
      "Anguilla"
    ]
  },
  {
    name: {
      common: "Central African Republic",
      official: "Central African Republic",
      nativeName: {
        fra: {
          official: "République centrafricaine",
          common: "République centrafricaine"
        },
        sag: {
          official: "Ködörösêse tî Bêafrîka",
          common: "Bêafrîka"
        }
      }
    },
    tld: [
      ".cf"
    ],
    cca2: "CF",
    ccn3: "140",
    cca3: "CAF",
    cioc: "CAF",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Central African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "36"
      ]
    },
    capital: [
      "Bangui"
    ],
    altSpellings: [
      "CF",
      "Central African Republic",
      "République centrafricaine"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      fra: "French",
      sag: "Sango"
    },
    latlng: [
      7,
      21
    ],
    landlocked: "Yes",
    borders: [
      "CMR",
      "TCD",
      "COD",
      "COG",
      "SSD",
      "SDN"
    ],
    area: 622984,
    demonyms: {
      eng: {
        f: "Central African",
        m: "Central African"
      },
      fra: {
        f: "Centrafricaine",
        m: "Centrafricain"
      }
    },
    flag: "🇨🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/51V8dsi2rGYC9n3c9",
      openStreetMaps: "https://www.openstreetmap.org/relation/192790"
    },
    population: 4829764,
    gini: {
      2008: 56.2
    },
    fifa: "CTA",
    car: {
      signs: [
        "RCA"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cf.png",
      svg: "https://flagcdn.com/cf.svg",
      alt: "The flag of Central African Republic is composed of four equal horizontal bands of blue, white, green and yellow intersected at the center by a vertical red band of equal size as the horizontal bands. A yellow five-pointed star is situated on the hoist side of the blue band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cf.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cf.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        4.37,
        18.58
      ]
    },
    nativeName: [
      "Central African Republic",
      "République centrafricaine",
      "Ködörösêse tî Bêafrîka",
      "Bêafrîka"
    ]
  },
  {
    name: {
      common: "Suriname",
      official: "Republic of Suriname",
      nativeName: {
        nld: {
          official: "Republiek Suriname",
          common: "Suriname"
        }
      }
    },
    tld: [
      ".sr"
    ],
    cca2: "SR",
    ccn3: "740",
    cca3: "SUR",
    cioc: "SUR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Surinamese dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "97"
      ]
    },
    capital: [
      "Paramaribo"
    ],
    altSpellings: [
      "SR",
      "Sarnam",
      "Sranangron",
      "Republic of Suriname",
      "Republiek Suriname"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      nld: "Dutch"
    },
    latlng: [
      4,
      -56
    ],
    landlocked: "Yes",
    borders: [
      "BRA",
      "GUF",
      "GUY"
    ],
    area: 163820,
    demonyms: {
      eng: {
        f: "Surinamer",
        m: "Surinamer"
      },
      fra: {
        f: "Surinamaise",
        m: "Surinamais"
      }
    },
    flag: "🇸🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/iy7TuQLSi4qgoBoG7",
      openStreetMaps: "https://www.openstreetmap.org/relation/287082"
    },
    population: 586634,
    gini: {
      1999: 57.9
    },
    fifa: "SUR",
    car: {
      signs: [
        "SME"
      ],
      side: "left"
    },
    timezones: [
      "UTC-03:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sr.png",
      svg: "https://flagcdn.com/sr.svg",
      alt: "The flag of Suriname is composed of five horizontal bands of green, white, red, white and green in the ratio of 2:1:4:1:2. A large five-pointed yellow star is centered in the red band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        5.83,
        -55.17
      ]
    },
    nativeName: [
      "Suriname",
      "Republic of Suriname",
      "Republiek Suriname"
    ]
  },
  {
    name: {
      common: "Belgium",
      official: "Kingdom of Belgium",
      nativeName: {
        deu: {
          official: "Königreich Belgien",
          common: "Belgien"
        },
        fra: {
          official: "Royaume de Belgique",
          common: "Belgique"
        },
        nld: {
          official: "Koninkrijk België",
          common: "België"
        }
      }
    },
    tld: [
      ".be"
    ],
    cca2: "BE",
    ccn3: "056",
    cca3: "BEL",
    cioc: "BEL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "2"
      ]
    },
    capital: [
      "Brussels"
    ],
    altSpellings: [
      "BE",
      "België",
      "Belgie",
      "Belgien",
      "Belgique",
      "Kingdom of Belgium",
      "Koninkrijk België",
      "Royaume de Belgique",
      "Königreich Belgien"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      deu: "German",
      fra: "French",
      nld: "Dutch"
    },
    latlng: [
      50.83333333,
      4
    ],
    landlocked: "Yes",
    borders: [
      "FRA",
      "DEU",
      "LUX",
      "NLD"
    ],
    area: 30528,
    demonyms: {
      eng: {
        f: "Belgian",
        m: "Belgian"
      },
      fra: {
        f: "Belge",
        m: "Belge"
      }
    },
    flag: "🇧🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/UQQzat85TCtPRXAL8",
      openStreetMaps: "https://www.openstreetmap.org/relation/52411"
    },
    population: 11555997,
    gini: {
      2018: 27.2
    },
    fifa: "BEL",
    car: {
      signs: [
        "B"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/be.png",
      svg: "https://flagcdn.com/be.svg",
      alt: "The flag of Belgium is composed of three equal vertical bands of black, yellow and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/be.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/be.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        50.83,
        4.33
      ]
    },
    nativeName: [
      "Belgium",
      "Kingdom of Belgium",
      "Königreich Belgien",
      "Belgien",
      "Royaume de Belgique",
      "Belgique",
      "Koninkrijk België",
      "België"
    ]
  },
  {
    name: {
      common: "Sweden",
      official: "Kingdom of Sweden",
      nativeName: {
        swe: {
          official: "Konungariket Sverige",
          common: "Sverige"
        }
      }
    },
    tld: [
      ".se"
    ],
    cca2: "SE",
    ccn3: "752",
    cca3: "SWE",
    cioc: "SWE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Swedish krona kr"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "6"
      ]
    },
    capital: [
      "Stockholm"
    ],
    altSpellings: [
      "SE",
      "Kingdom of Sweden",
      "Konungariket Sverige"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      swe: "Swedish"
    },
    latlng: [
      62,
      15
    ],
    landlocked: "Yes",
    borders: [
      "FIN",
      "NOR"
    ],
    area: 450295,
    demonyms: {
      eng: {
        f: "Swedish",
        m: "Swedish"
      },
      fra: {
        f: "Suédoise",
        m: "Suédois"
      }
    },
    flag: "🇸🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/iqygE491ADVgnBW39",
      openStreetMaps: "https://www.openstreetmap.org/relation/52822"
    },
    population: 10353442,
    gini: {
      2018: 30
    },
    fifa: "SWE",
    car: {
      signs: [
        "S"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/se.png",
      svg: "https://flagcdn.com/se.svg",
      alt: "The flag of Sweden has a blue field with a large golden-yellow cross that extend to the edges of the field. The vertical part of this cross is offset towards the hoist side."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/se.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/se.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        59.33,
        18.05
      ]
    },
    nativeName: [
      "Sweden",
      "Kingdom of Sweden",
      "Konungariket Sverige",
      "Sverige"
    ]
  },
  {
    name: {
      common: "Bolivia",
      official: "Plurinational State of Bolivia",
      nativeName: {
        aym: {
          official: "Wuliwya Suyu",
          common: "Wuliwya"
        },
        grn: {
          official: "Tetã Volívia",
          common: "Volívia"
        },
        que: {
          official: "Buliwya Mamallaqta",
          common: "Buliwya"
        },
        spa: {
          official: "Estado Plurinacional de Bolivia",
          common: "Bolivia"
        }
      }
    },
    tld: [
      ".bo"
    ],
    cca2: "BO",
    ccn3: "068",
    cca3: "BOL",
    cioc: "BOL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bolivian boliviano Bs."
    ],
    idd: {
      root: "+5",
      suffixes: [
        "91"
      ]
    },
    capital: [
      "Sucre"
    ],
    altSpellings: [
      "BO",
      "Buliwya",
      "Wuliwya",
      "Bolivia, Plurinational State of",
      "Plurinational State of Bolivia",
      "Estado Plurinacional de Bolivia",
      "Buliwya Mamallaqta",
      "Wuliwya Suyu",
      "Tetã Volívia"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      aym: "Aymara",
      grn: "Guaraní",
      que: "Quechua",
      spa: "Spanish"
    },
    latlng: [
      -17,
      -65
    ],
    landlocked: "Yes",
    borders: [
      "ARG",
      "BRA",
      "CHL",
      "PRY",
      "PER"
    ],
    area: 1098581,
    demonyms: {
      eng: {
        f: "Bolivian",
        m: "Bolivian"
      },
      fra: {
        f: "Bolivienne",
        m: "Bolivien"
      }
    },
    flag: "🇧🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/9DfnyfbxNM2g5U9b9",
      openStreetMaps: "https://www.openstreetmap.org/relation/252645"
    },
    population: 11673029,
    gini: {
      2019: 41.6
    },
    fifa: "BOL",
    car: {
      signs: [
        "BOL"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bo.png",
      svg: "https://flagcdn.com/bo.svg",
      alt: "The flag of Bolivia is composed of three equal horizontal bands of red, yellow and green, with the national coat of arms centered in the yellow band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bo.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bo.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -19.02,
        -65.26
      ]
    },
    nativeName: [
      "Bolivia",
      "Plurinational State of Bolivia",
      "Wuliwya Suyu",
      "Wuliwya",
      "Tetã Volívia",
      "Volívia",
      "Buliwya Mamallaqta",
      "Buliwya",
      "Estado Plurinacional de Bolivia"
    ]
  },
  {
    name: {
      common: "Montenegro",
      official: "Montenegro",
      nativeName: {
        cnr: {
          official: "Црна Гора",
          common: "Црна Гора"
        }
      }
    },
    tld: [
      ".me"
    ],
    cca2: "ME",
    ccn3: "499",
    cca3: "MNE",
    cioc: "MNE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "82"
      ]
    },
    capital: [
      "Podgorica"
    ],
    altSpellings: [
      "ME",
      "Crna Gora"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      cnr: "Montenegrin"
    },
    latlng: [
      42.5,
      19.3
    ],
    landlocked: "Yes",
    borders: [
      "ALB",
      "BIH",
      "HRV",
      "UNK",
      "SRB"
    ],
    area: 13812,
    demonyms: {
      eng: {
        f: "Montenegrin",
        m: "Montenegrin"
      },
      fra: {
        f: "Monténégrine",
        m: "Monténégrin"
      }
    },
    flag: "🇲🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/4THX1fM7WqANuPbB8",
      openStreetMaps: "https://www.openstreetmap.org/relation/53296"
    },
    population: 621718,
    gini: {
      2016: 38.5
    },
    fifa: "MNE",
    car: {
      signs: [
        "SCG"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/me.png",
      svg: "https://flagcdn.com/me.svg",
      alt: "The flag of Montenegro features a large red central rectangular area surrounded by a golden-yellow border. The coat of arms of Montenegro is centered in the red rectangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/me.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/me.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        42.43,
        19.27
      ]
    },
    nativeName: [
      "Montenegro",
      "Црна Гора"
    ]
  },
  {
    name: {
      common: "Mozambique",
      official: "Republic of Mozambique",
      nativeName: {
        por: {
          official: "República de Moçambique",
          common: "Moçambique"
        }
      }
    },
    tld: [
      ".mz"
    ],
    cca2: "MZ",
    ccn3: "508",
    cca3: "MOZ",
    cioc: "MOZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Mozambican metical MT"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "58"
      ]
    },
    capital: [
      "Maputo"
    ],
    altSpellings: [
      "MZ",
      "Republic of Mozambique",
      "República de Moçambique"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      por: "Portuguese"
    },
    latlng: [
      -18.25,
      35
    ],
    landlocked: "Yes",
    borders: [
      "MWI",
      "ZAF",
      "SWZ",
      "TZA",
      "ZMB",
      "ZWE"
    ],
    area: 801590,
    demonyms: {
      eng: {
        f: "Mozambican",
        m: "Mozambican"
      },
      fra: {
        f: "Mozambicaine",
        m: "Mozambicain"
      }
    },
    flag: "🇲🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/xCLcY9fzU6x4Pueu5",
      openStreetMaps: "https://www.openstreetmap.org/relation/195273"
    },
    population: 31255435,
    gini: {
      2014: 54
    },
    fifa: "MOZ",
    car: {
      signs: [
        "MOC"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mz.png",
      svg: "https://flagcdn.com/mz.svg",
      alt: "The flag of Mozambique is composed of three equal horizontal bands of teal, black with white top and bottom edges, and yellow. A red isosceles triangle spanning about two-fifth the width of the field is superimposed on the hoist side with its base on the hoist end. This triangle bears a crossed rifle and hoe in black superimposed on an open white book which is superimposed on a five-pointed yellow star."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -25.95,
        32.58
      ]
    },
    nativeName: [
      "Mozambique",
      "Republic of Mozambique",
      "República de Moçambique",
      "Moçambique"
    ]
  },
  {
    name: {
      common: "Latvia",
      official: "Republic of Latvia",
      nativeName: {
        lav: {
          official: "Latvijas Republikas",
          common: "Latvija"
        }
      }
    },
    tld: [
      ".lv"
    ],
    cca2: "LV",
    ccn3: "428",
    cca3: "LVA",
    cioc: "LAT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "71"
      ]
    },
    capital: [
      "Riga"
    ],
    altSpellings: [
      "LV",
      "Republic of Latvia",
      "Latvijas Republika"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      lav: "Latvian"
    },
    latlng: [
      57,
      25
    ],
    landlocked: "Yes",
    borders: [
      "BLR",
      "EST",
      "LTU",
      "RUS"
    ],
    area: 64559,
    demonyms: {
      eng: {
        f: "Latvian",
        m: "Latvian"
      },
      fra: {
        f: "Lettone",
        m: "Letton"
      }
    },
    flag: "🇱🇻",
    maps: {
      googleMaps: "https://goo.gl/maps/iQpUkH7ghq31ZtXe9",
      openStreetMaps: "https://www.openstreetmap.org/relation/72594"
    },
    population: 1901548,
    gini: {
      2018: 35.1
    },
    fifa: "LVA",
    car: {
      signs: [
        "LV"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/lv.png",
      svg: "https://flagcdn.com/lv.svg",
      alt: "The flag of Latvia has a carmine-red field with a thin white horizontal band across the middle of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/lv.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/lv.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        56.95,
        24.1
      ]
    },
    nativeName: [
      "Latvia",
      "Republic of Latvia",
      "Latvijas Republikas",
      "Latvija"
    ]
  },
  {
    name: {
      common: "Malawi",
      official: "Republic of Malawi",
      nativeName: {
        eng: {
          official: "Republic of Malawi",
          common: "Malawi"
        },
        nya: {
          official: "Chalo cha Malawi, Dziko la Malaŵi",
          common: "Malaŵi"
        }
      }
    },
    tld: [
      ".mw"
    ],
    cca2: "MW",
    ccn3: "454",
    cca3: "MWI",
    cioc: "MAW",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Malawian kwacha MK"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "65"
      ]
    },
    capital: [
      "Lilongwe"
    ],
    altSpellings: [
      "MW",
      "Republic of Malawi"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English",
      nya: "Chewa"
    },
    latlng: [
      -13.5,
      34
    ],
    landlocked: "Yes",
    borders: [
      "MOZ",
      "TZA",
      "ZMB"
    ],
    area: 118484,
    demonyms: {
      eng: {
        f: "Malawian",
        m: "Malawian"
      },
      fra: {
        f: "Malawienne",
        m: "Malawien"
      }
    },
    flag: "🇲🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/mc6z83pW9m98X2Ef6",
      openStreetMaps: "https://www.openstreetmap.org/relation/195290"
    },
    population: 19129955,
    gini: {
      2016: 44.7
    },
    fifa: "MWI",
    car: {
      signs: [
        "MW"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mw.png",
      svg: "https://flagcdn.com/mw.svg",
      alt: "The flag of Malawi is composed of three equal horizontal bands of black, red and green. The top half of a red sun with thirty-one visible rays is centered in the black band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -13.97,
        33.78
      ]
    },
    nativeName: [
      "Malawi",
      "Republic of Malawi",
      "Chalo cha Malawi, Dziko la Malaŵi",
      "Malaŵi"
    ]
  },
  {
    name: {
      common: "Mali",
      official: "Republic of Mali",
      nativeName: {
        fra: {
          official: "République du Mali",
          common: "Mali"
        }
      }
    },
    tld: [
      ".ml"
    ],
    cca2: "ML",
    ccn3: "466",
    cca3: "MLI",
    cioc: "MLI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "23"
      ]
    },
    capital: [
      "Bamako"
    ],
    altSpellings: [
      "ML",
      "Republic of Mali",
      "République du Mali"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      17,
      -4
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "BFA",
      "GIN",
      "CIV",
      "MRT",
      "NER",
      "SEN"
    ],
    area: 1240192,
    demonyms: {
      eng: {
        f: "Malian",
        m: "Malian"
      },
      fra: {
        f: "Malienne",
        m: "Malien"
      }
    },
    flag: "🇲🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/u9mYJkCB19wyuzh27",
      openStreetMaps: "https://www.openstreetmap.org/relation/192785"
    },
    population: 20250834,
    gini: {
      2009: 33
    },
    fifa: "MLI",
    car: {
      signs: [
        "RMM"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ml.png",
      svg: "https://flagcdn.com/ml.svg",
      alt: "The flag of Mali is composed of three equal vertical bands of green, yellow and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ml.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ml.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        12.65,
        -8
      ]
    },
    nativeName: [
      "Mali",
      "Republic of Mali",
      "République du Mali"
    ]
  },
  {
    name: {
      common: "Vatican City",
      official: "Vatican City State",
      nativeName: {
        ita: {
          official: "Stato della Città del Vaticano",
          common: "Vaticano"
        },
        lat: {
          official: "Status Civitatis Vaticanæ",
          common: "Vaticanæ"
        }
      }
    },
    tld: [
      ".va"
    ],
    cca2: "VA",
    ccn3: "336",
    cca3: "VAT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "906698",
        "79"
      ]
    },
    capital: [
      "Vatican City"
    ],
    altSpellings: [
      "VA",
      "Holy See (Vatican City State)",
      "Vatican City State",
      "Stato della Città del Vaticano"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      ita: "Italian",
      lat: "Latin"
    },
    latlng: [
      41.9,
      12.45
    ],
    landlocked: "Yes",
    borders: [
      "ITA"
    ],
    area: 0.44,
    demonyms: {
      eng: {
        f: "Vatican",
        m: "Vatican"
      },
      fra: {
        f: "Vaticane",
        m: "Vatican"
      }
    },
    flag: "🇻🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/DTKvw5Bd1QZaDZmE8",
      openStreetMaps: "https://www.openstreetmap.org/relation/36989"
    },
    population: 451,
    car: {
      signs: [
        "V"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/va.png",
      svg: "https://flagcdn.com/va.svg",
      alt: "The flag of Vatican City is square shaped. It is composed of two equal vertical bands of yellow and white, with national coat of arms centered in the white band. The national coat of arms comprises the Papal Tiara superimposed on two crossed keys."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/va.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/va.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        41.9,
        12.45
      ]
    },
    nativeName: [
      "Vatican City",
      "Vatican City State",
      "Stato della Città del Vaticano",
      "Vaticano",
      "Status Civitatis Vaticanæ",
      "Vaticanæ"
    ]
  },
  {
    name: {
      common: "Montserrat",
      official: "Montserrat",
      nativeName: {
        eng: {
          official: "Montserrat",
          common: "Montserrat"
        }
      }
    },
    tld: [
      ".ms"
    ],
    cca2: "MS",
    ccn3: "500",
    cca3: "MSR",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "664"
      ]
    },
    capital: [
      "Plymouth"
    ],
    altSpellings: [
      "MS"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      16.75,
      -62.2
    ],
    landlocked: "Yes",
    area: 102,
    demonyms: {
      eng: {
        f: "Montserratian",
        m: "Montserratian"
      },
      fra: {
        f: "Montserratienne",
        m: "Montserratien"
      }
    },
    flag: "🇲🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/CSbe7UmxPmiwQB7GA",
      openStreetMaps: "https://www.openstreetmap.org/relation/537257"
    },
    population: 4922,
    fifa: "MSR",
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ms.png",
      svg: "https://flagcdn.com/ms.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ms.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ms.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        16.7,
        -62.22
      ]
    },
    nativeName: [
      "Montserrat"
    ]
  },
  {
    name: {
      common: "Austria",
      official: "Republic of Austria",
      nativeName: {
        bar: {
          official: "Republik Österreich",
          common: "Österreich"
        }
      }
    },
    tld: [
      ".at"
    ],
    cca2: "AT",
    ccn3: "040",
    cca3: "AUT",
    cioc: "AUT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "3"
      ]
    },
    capital: [
      "Vienna"
    ],
    altSpellings: [
      "AT",
      "Osterreich",
      "Oesterreich"
    ],
    region: "Europe",
    subregion: "Central Europe",
    languages: {
      de: "German"
    },
    latlng: [
      47.33333333,
      13.33333333
    ],
    landlocked: "Yes",
    borders: [
      "CZE",
      "DEU",
      "HUN",
      "ITA",
      "LIE",
      "SVK",
      "SVN",
      "CHE"
    ],
    area: 83871,
    demonyms: {
      eng: {
        f: "Austrian",
        m: "Austrian"
      },
      fra: {
        f: "Autrichienne",
        m: "Autrichien"
      }
    },
    flag: "🇦🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/pCWpWQhznHyRzQcu9",
      openStreetMaps: "https://www.openstreetmap.org/relation/16239"
    },
    population: 8917205,
    gini: {
      2018: 30.8
    },
    fifa: "AUT",
    car: {
      signs: [
        "A"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/at.png",
      svg: "https://flagcdn.com/at.svg",
      alt: "The flag of Austria is composed of three equal horizontal bands of red, white and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/at.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/at.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        48.2,
        16.37
      ]
    },
    nativeName: [
      "Austria",
      "Republic of Austria",
      "Republik Österreich",
      "Österreich"
    ]
  },
  {
    name: {
      common: "Albania",
      official: "Republic of Albania",
      nativeName: {
        sqi: {
          official: "Republika e Shqipërisë",
          common: "Shqipëria"
        }
      }
    },
    tld: [
      ".al"
    ],
    cca2: "AL",
    ccn3: "008",
    cca3: "ALB",
    cioc: "ALB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Albanian lek L"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "55"
      ]
    },
    capital: [
      "Tirana"
    ],
    altSpellings: [
      "AL",
      "Shqipëri",
      "Shqipëria",
      "Shqipnia"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      sqi: "Albanian"
    },
    latlng: [
      41,
      20
    ],
    landlocked: "Yes",
    borders: [
      "MNE",
      "GRC",
      "MKD",
      "UNK"
    ],
    area: 28748,
    demonyms: {
      eng: {
        f: "Albanian",
        m: "Albanian"
      },
      fra: {
        f: "Albanaise",
        m: "Albanais"
      }
    },
    flag: "🇦🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/BzN9cTuj68ZA8SyZ8",
      openStreetMaps: "https://www.openstreetmap.org/relation/53292"
    },
    population: 2837743,
    gini: {
      2017: 33.2
    },
    fifa: "ALB",
    car: {
      signs: [
        "AL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/al.png",
      svg: "https://flagcdn.com/al.svg",
      alt: "The flag of Albania features a silhouetted double-headed black eagle at the center of a red field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/al.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/al.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        41.32,
        19.82
      ]
    },
    nativeName: [
      "Albania",
      "Republic of Albania",
      "Republika e Shqipërisë",
      "Shqipëria"
    ]
  },
  {
    name: {
      common: "British Virgin Islands",
      official: "Virgin Islands",
      nativeName: {
        eng: {
          official: "Virgin Islands",
          common: "British Virgin Islands"
        }
      }
    },
    tld: [
      ".vg"
    ],
    cca2: "VG",
    ccn3: "092",
    cca3: "VGB",
    cioc: "IVB",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "284"
      ]
    },
    capital: [
      "Road Town"
    ],
    altSpellings: [
      "VG",
      "Virgin Islands, British"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      18.431383,
      -64.62305
    ],
    landlocked: "Yes",
    area: 151,
    demonyms: {
      eng: {
        f: "Virgin Islander",
        m: "Virgin Islander"
      }
    },
    flag: "🇻🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/49C9cSesNVAR9DQk8",
      openStreetMaps: "https://www.openstreetmap.org/relation/285454"
    },
    population: 30237,
    fifa: "VGB",
    car: {
      signs: [
        "BVI"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/vg.png",
      svg: "https://flagcdn.com/vg.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/vg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/vg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.42,
        -64.62
      ]
    },
    nativeName: [
      "British Virgin Islands",
      "Virgin Islands"
    ]
  },
  {
    name: {
      common: "Zambia",
      official: "Republic of Zambia",
      nativeName: {
        eng: {
          official: "Republic of Zambia",
          common: "Zambia"
        }
      }
    },
    tld: [
      ".zm"
    ],
    cca2: "ZM",
    ccn3: "894",
    cca3: "ZMB",
    cioc: "ZAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Zambian kwacha ZK"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "60"
      ]
    },
    capital: [
      "Lusaka"
    ],
    altSpellings: [
      "ZM",
      "Republic of Zambia"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      -15,
      30
    ],
    landlocked: "Yes",
    borders: [
      "AGO",
      "BWA",
      "COD",
      "MWI",
      "MOZ",
      "NAM",
      "TZA",
      "ZWE"
    ],
    area: 752612,
    demonyms: {
      eng: {
        f: "Zambian",
        m: "Zambian"
      },
      fra: {
        f: "Zambienne",
        m: "Zambien"
      }
    },
    flag: "🇿🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/mweBcqvW8TppZW6q9",
      openStreetMaps: "https://www.openstreetmap.org/relation/195271"
    },
    population: 18383956,
    gini: {
      2015: 57.1
    },
    fifa: "ZAM",
    car: {
      signs: [
        "RNR"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/zm.png",
      svg: "https://flagcdn.com/zm.svg",
      alt: "The flag of Zambia has a green field, on the fly side of which is a soaring orange African fish eagle above a rectangular area divided into three equal vertical bands of red, black and orange."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/zm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/zm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -15.42,
        28.28
      ]
    },
    nativeName: [
      "Zambia",
      "Republic of Zambia"
    ]
  },
  {
    name: {
      common: "French Guiana",
      official: "Guiana",
      nativeName: {
        fra: {
          official: "Guyane",
          common: "Guyane française"
        }
      }
    },
    tld: [
      ".gf"
    ],
    cca2: "GF",
    ccn3: "254",
    cca3: "GUF",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "94"
      ]
    },
    capital: [
      "Cayenne"
    ],
    altSpellings: [
      "GF",
      "Guiana",
      "Guyane"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      fra: "French"
    },
    latlng: [
      4,
      -53
    ],
    landlocked: "Yes",
    borders: [
      "BRA",
      "SUR"
    ],
    area: 83534,
    demonyms: {
      eng: {
        f: "Guianan",
        m: "Guianan"
      },
      fra: {
        f: "Guyanaise",
        m: "Guyanais"
      }
    },
    flag: "🇬🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/NJawFwMzG7YtCrVP7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2502058"
    },
    population: 254541,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC-03:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gf.png",
      svg: "https://flagcdn.com/gf.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gf.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gf.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        4.94,
        -52.33
      ]
    },
    nativeName: [
      "French Guiana",
      "Guiana",
      "Guyane",
      "Guyane française"
    ]
  },
  {
    name: {
      common: "Liechtenstein",
      official: "Principality of Liechtenstein",
      nativeName: {
        deu: {
          official: "Fürstentum Liechtenstein",
          common: "Liechtenstein"
        }
      }
    },
    tld: [
      ".li"
    ],
    cca2: "LI",
    ccn3: "438",
    cca3: "LIE",
    cioc: "LIE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Swiss franc Fr"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "23"
      ]
    },
    capital: [
      "Vaduz"
    ],
    altSpellings: [
      "LI",
      "Principality of Liechtenstein",
      "Fürstentum Liechtenstein"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      deu: "German"
    },
    latlng: [
      47.26666666,
      9.53333333
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "CHE"
    ],
    area: 160,
    demonyms: {
      eng: {
        f: "Liechtensteiner",
        m: "Liechtensteiner"
      },
      fra: {
        f: "Liechtensteinoise",
        m: "Liechtensteinois"
      }
    },
    flag: "🇱🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/KNuHeiJzAPodwM7y6",
      openStreetMaps: "https://www.openstreetmap.org/relation/1155955"
    },
    population: 38137,
    fifa: "LIE",
    car: {
      signs: [
        "FL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/li.png",
      svg: "https://flagcdn.com/li.svg",
      alt: "The flag of Liechtenstein is composed of two equal horizontal bands of blue and red, with a golden-yellow crown on the hoist side of the blue band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/li.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/li.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        47.13,
        9.52
      ]
    },
    nativeName: [
      "Liechtenstein",
      "Principality of Liechtenstein",
      "Fürstentum Liechtenstein"
    ]
  },
  {
    name: {
      common: "Qatar",
      official: "State of Qatar",
      nativeName: {
        ara: {
          official: "دولة قطر",
          common: "قطر"
        }
      }
    },
    tld: [
      ".qa",
      "قطر."
    ],
    cca2: "QA",
    ccn3: "634",
    cca3: "QAT",
    cioc: "QAT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Qatari riyal ر.ق"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "74"
      ]
    },
    capital: [
      "Doha"
    ],
    altSpellings: [
      "QA",
      "State of Qatar",
      "Dawlat Qaṭar"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      25.5,
      51.25
    ],
    landlocked: "Yes",
    borders: [
      "SAU"
    ],
    area: 11586,
    demonyms: {
      eng: {
        f: "Qatari",
        m: "Qatari"
      },
      fra: {
        f: "Qatarienne",
        m: "Qatarien"
      }
    },
    flag: "🇶🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/ZV76Y49z7LLUZ2KQ6",
      openStreetMaps: "https://www.openstreetmap.org/relation/305095"
    },
    population: 2881060,
    fifa: "QAT",
    car: {
      signs: [
        "Q"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/qa.png",
      svg: "https://flagcdn.com/qa.svg",
      alt: "The flag of Qatar has a maroon field, on the hoist side of which is a white vertical band that spans about one-third the width of the field and is separated from the rest of the field by nine adjoining fly-side pointing white isosceles triangles that serve as a serrated line."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/qa.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/qa.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        25.28,
        51.53
      ]
    },
    nativeName: [
      "Qatar",
      "State of Qatar",
      "دولة قطر",
      "قطر"
    ]
  },
  {
    name: {
      common: "Solomon Islands",
      official: "Solomon Islands",
      nativeName: {
        eng: {
          official: "Solomon Islands",
          common: "Solomon Islands"
        }
      }
    },
    tld: [
      ".sb"
    ],
    cca2: "SB",
    ccn3: "090",
    cca3: "SLB",
    cioc: "SOL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Solomon Islands dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "77"
      ]
    },
    capital: [
      "Honiara"
    ],
    altSpellings: [
      "SB"
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: {
      eng: "English"
    },
    latlng: [
      -8,
      159
    ],
    landlocked: "Yes",
    area: 28896,
    demonyms: {
      eng: {
        f: "Solomon Islander",
        m: "Solomon Islander"
      },
      fra: {
        f: "Salomonienne",
        m: "Salomonien"
      }
    },
    flag: "🇸🇧",
    maps: {
      googleMaps: "https://goo.gl/maps/JbPkx86Ywjv8C1n8A",
      openStreetMaps: "https://www.openstreetmap.org/relation/1857436"
    },
    population: 686878,
    gini: {
      2012: 37.1
    },
    fifa: "SOL",
    car: {
      signs: [
        "SOL"
      ],
      side: "left"
    },
    timezones: [
      "UTC+11:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sb.png",
      svg: "https://flagcdn.com/sb.svg",
      alt: "The flag of Solomon Islands features a thin yellow diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a blue and green triangle respectively. Five white five-pointed stars arranged in an X shape are situated on the hoist side of the upper blue triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sb.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sb.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -9.43,
        159.95
      ]
    },
    nativeName: [
      "Solomon Islands"
    ]
  },
  {
    name: {
      common: "Nauru",
      official: "Republic of Nauru",
      nativeName: {
        eng: {
          official: "Republic of Nauru",
          common: "Nauru"
        },
        nau: {
          official: "Republic of Nauru",
          common: "Nauru"
        }
      }
    },
    tld: [
      ".nr"
    ],
    cca2: "NR",
    ccn3: "520",
    cca3: "NRU",
    cioc: "NRU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Australian dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "74"
      ]
    },
    capital: [
      "Yaren"
    ],
    altSpellings: [
      "NR",
      "Naoero",
      "Pleasant Island",
      "Republic of Nauru",
      "Ripublik Naoero"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      eng: "English",
      nau: "Nauru"
    },
    latlng: [
      -0.53333333,
      166.91666666
    ],
    landlocked: "Yes",
    area: 21,
    demonyms: {
      eng: {
        f: "Nauruan",
        m: "Nauruan"
      },
      fra: {
        f: "Nauruane",
        m: "Nauruan"
      }
    },
    flag: "🇳🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/kyAGw6XEJgjSMsTK7",
      openStreetMaps: "https://www.openstreetmap.org/relation/571804"
    },
    population: 10834,
    gini: {
      2012: 34.8
    },
    car: {
      signs: [
        "NAU"
      ],
      side: "left"
    },
    timezones: [
      "UTC+12:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/nr.png",
      svg: "https://flagcdn.com/nr.svg",
      alt: "The flag of Nauru has a dark blue field with a thin yellow horizontal band across the center and a large white twelve-pointed star beneath the horizontal band on the hoist side of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/nr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/nr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -0.55,
        166.92
      ]
    },
    nativeName: [
      "Nauru",
      "Republic of Nauru"
    ]
  },
  {
    name: {
      common: "Greece",
      official: "Hellenic Republic",
      nativeName: {
        ell: {
          official: "Ελληνική Δημοκρατία",
          common: "Ελλάδα"
        }
      }
    },
    tld: [
      ".gr"
    ],
    cca2: "GR",
    ccn3: "300",
    cca3: "GRC",
    cioc: "GRE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "0"
      ]
    },
    capital: [
      "Athens"
    ],
    altSpellings: [
      "GR",
      "Elláda",
      "Hellenic Republic",
      "Ελληνική Δημοκρατία"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      ell: "Greek"
    },
    latlng: [
      39,
      22
    ],
    landlocked: "Yes",
    borders: [
      "ALB",
      "BGR",
      "TUR",
      "MKD"
    ],
    area: 131990,
    demonyms: {
      eng: {
        f: "Greek",
        m: "Greek"
      },
      fra: {
        f: "Grecque",
        m: "Grec"
      }
    },
    flag: "🇬🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/LHGcAvuRyD2iKECC6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192307"
    },
    population: 10715549,
    gini: {
      2018: 32.9
    },
    fifa: "GRE",
    car: {
      signs: [
        "GR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gr.png",
      svg: "https://flagcdn.com/gr.svg",
      alt: "The flag of Greece is composed of nine equal horizontal bands of blue alternating with white. A blue square bearing a white cross is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        37.98,
        23.73
      ]
    },
    nativeName: [
      "Greece",
      "Hellenic Republic",
      "Ελληνική Δημοκρατία",
      "Ελλάδα"
    ]
  },
  {
    name: {
      common: "Libya",
      official: "State of Libya",
      nativeName: {
        ara: {
          official: "الدولة ليبيا",
          common: "\u200fليبي"
        }
      }
    },
    tld: [
      ".ly"
    ],
    cca2: "LY",
    ccn3: "434",
    cca3: "LBY",
    cioc: "LBA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Libyan dinar ل.د"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "18"
      ]
    },
    capital: [
      "Tripoli"
    ],
    altSpellings: [
      "LY",
      "State of Libya",
      "Dawlat Libya"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      25,
      17
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "TCD",
      "EGY",
      "NER",
      "SDN",
      "TUN"
    ],
    area: 1759540,
    demonyms: {
      eng: {
        f: "Libyan",
        m: "Libyan"
      },
      fra: {
        f: "Libyenne",
        m: "Libyen"
      }
    },
    flag: "🇱🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/eLgGnaQWcJEdYRMy5",
      openStreetMaps: "openstreetmap.org/relation/192758"
    },
    population: 6871287,
    fifa: "LBY",
    car: {
      signs: [
        "LAR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ly.png",
      svg: "https://flagcdn.com/ly.svg",
      alt: "The flag of Libya is composed of three horizontal bands of red, black and green, with the black band twice the height of the other two bands. At the center of the black band is a fly-side facing white crescent and a five-pointed white star placed just outside the crescent opening."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ly.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ly.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        32.88,
        13.17
      ]
    },
    nativeName: [
      "Libya",
      "State of Libya",
      "الدولة ليبيا",
      "\u200fليبي"
      ]
  },
  {
    name: {
      common: "Guinea-Bissau",
      official: "Republic of Guinea-Bissau",
      nativeName: {
        por: {
          official: "República da Guiné-Bissau",
          common: "Guiné-Bissau"
        },
        pov: {
          official: "República da Guiné-Bissau",
          common: "Guiné-Bissau"
        }
      }
    },
    tld: [
      ".gw"
    ],
    cca2: "GW",
    ccn3: "624",
    cca3: "GNB",
    cioc: "GBS",
    independent: true,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "45"
      ]
    },
    capital: [
      "Bissau"
    ],
    altSpellings: [
      "GW",
      "Republic of Guinea-Bissau",
      "República da Guiné-Bissau"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      por: "Portuguese",
      pov: "Upper Guinea Creole"
    },
    latlng: [
      12,
      -15
    ],
    landlocked: "Yes",
    borders: [
      "GIN",
      "SEN"
    ],
    area: 36125,
    demonyms: {
      eng: {
        f: "Guinea-Bissauan",
        m: "Guinea-Bissauan"
      },
      fra: {
        f: "Bissau-Guinéenne",
        m: "Bissau-Guinéen"
      }
    },
    flag: "🇬🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/5Wyaz17miUc1zLc67",
      openStreetMaps: "https://www.openstreetmap.org/relation/192776"
    },
    population: 1967998,
    gini: {
      2010: 50.7
    },
    fifa: "GNB",
    car: {
      signs: [
        "RGB"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gw.png",
      svg: "https://flagcdn.com/gw.svg",
      alt: "The flag of Guinea-Bissau features a red vertical band on its hoist side that takes up about two-fifth the width of the field, and two equal horizontal bands of yellow and green adjoining the vertical band. A five-pointed black star is centered in the vertical band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        11.85,
        -15.58
      ]
    },
    nativeName: [
      "Guinea-Bissau",
      "Republic of Guinea-Bissau",
      "República da Guiné-Bissau",
      "Guiné-Bissau"
    ]
  },
  {
    name: {
      common: "Barbados",
      official: "Barbados",
      nativeName: {
        eng: {
          official: "Barbados",
          common: "Barbados"
        }
      }
    },
    tld: [
      ".bb"
    ],
    cca2: "BB",
    ccn3: "052",
    cca3: "BRB",
    cioc: "BAR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Barbadian dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "246"
      ]
    },
    capital: [
      "Bridgetown"
    ],
    altSpellings: [
      "BB"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      13.16666666,
      -59.53333333
    ],
    landlocked: "Yes",
    area: 430,
    demonyms: {
      eng: {
        f: "Barbadian",
        m: "Barbadian"
      },
      fra: {
        f: "Barbadienne",
        m: "Barbadien"
      }
    },
    flag: "🇧🇧",
    maps: {
      googleMaps: "https://goo.gl/maps/2m36v8STvbGAWd9c7",
      openStreetMaps: "https://www.openstreetmap.org/relation/547511"
    },
    population: 287371,
    fifa: "BRB",
    car: {
      signs: [
        "BDS"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bb.png",
      svg: "https://flagcdn.com/bb.svg",
      alt: "The flag of Barbados is composed of three equal vertical bands of ultramarine, gold and ultramarine. The head of a black trident is centered in the gold band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bb.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bb.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.1,
        -59.62
      ]
    },
    nativeName: [
      "Barbados"
    ]
  },
  {
    name: {
      common: "Honduras",
      official: "Republic of Honduras",
      nativeName: {
        spa: {
          official: "República de Honduras",
          common: "Honduras"
        }
      }
    },
    tld: [
      ".hn"
    ],
    cca2: "HN",
    ccn3: "340",
    cca3: "HND",
    cioc: "HON",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Honduran lempira L"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "04"
      ]
    },
    capital: [
      "Tegucigalpa"
    ],
    altSpellings: [
      "HN",
      "Republic of Honduras",
      "República de Honduras"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      15,
      -86.5
    ],
    landlocked: "Yes",
    borders: [
      "GTM",
      "SLV",
      "NIC"
    ],
    area: 112492,
    demonyms: {
      eng: {
        f: "Honduran",
        m: "Honduran"
      },
      fra: {
        f: "Hondurienne",
        m: "Hondurien"
      }
    },
    flag: "🇭🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/BbeJK8Sk2VkMHbdF8",
      openStreetMaps: "https://www.openstreetmap.org/relation/287670"
    },
    population: 9904608,
    gini: {
      2019: 48.2
    },
    fifa: "HON",
    car: {
      signs: [
        "HN"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/hn.png",
      svg: "https://flagcdn.com/hn.svg",
      alt: "The flag of Honduras is composed of three equal horizontal bands of turquoise, white and turquoise, with five small five-pointed turquoise stars arranged in a quincuncial pattern at the center of the white band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/hn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/hn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        14.1,
        -87.22
      ]
    },
    nativeName: [
      "Honduras",
      "Republic of Honduras",
      "República de Honduras"
    ]
  },
  {
    name: {
      common: "Somalia",
      official: "Federal Republic of Somalia",
      nativeName: {
        ara: {
          official: "جمهورية الصومال\u200e\u200e",
              common: "الصومال\u200e\u200e"
        },
        som: {
          official: "Jamhuuriyadda Federaalka Soomaaliya",
          common: "Soomaaliya"
        }
      }
    },
    tld: [
      ".so"
    ],
    cca2: "SO",
    ccn3: "706",
    cca3: "SOM",
    cioc: "SOM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Somali shilling Sh"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "52"
      ]
    },
    capital: [
      "Mogadishu"
    ],
    altSpellings: [
      "SO",
      "aṣ-Ṣūmāl",
      "Federal Republic of Somalia",
      "Jamhuuriyadda Federaalka Soomaaliya",
      "Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      ara: "Arabic",
      som: "Somali"
    },
    latlng: [
      10,
      49
    ],
    landlocked: "Yes",
    borders: [
      "DJI",
      "ETH",
      "KEN"
    ],
    area: 637657,
    demonyms: {
      eng: {
        f: "Somali",
        m: "Somali"
      },
      fra: {
        f: "Somalienne",
        m: "Somalien"
      }
    },
    flag: "🇸🇴",
    maps: {
      googleMaps: "https://goo.gl/maps/8of8q7D1a8p7R6Fc9",
      openStreetMaps: "https://www.openstreetmap.org/relation/192799"
    },
    population: 15893219,
    gini: {
      2017: 36.8
    },
    fifa: "SOM",
    car: {
      signs: [
        "SO"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/so.png",
      svg: "https://flagcdn.com/so.svg",
      alt: "The flag of Somalia features a large five-pointed white star centered on a light blue field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/so.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/so.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        2.07,
        45.33
      ]
    },
    nativeName: [
      "Somalia",
      "Federal Republic of Somalia",
      "جمهورية الصومال\u200e\u200e",
          "الصومال\u200e\u200e",
          "Jamhuuriyadda Federaalka Soomaaliya",
      "Soomaaliya"
    ]
  },
  {
    name: {
      common: "Cayman Islands",
      official: "Cayman Islands",
      nativeName: {
        eng: {
          official: "Cayman Islands",
          common: "Cayman Islands"
        }
      }
    },
    tld: [
      ".ky"
    ],
    cca2: "KY",
    ccn3: "136",
    cca3: "CYM",
    cioc: "CAY",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Cayman Islands dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "345"
      ]
    },
    capital: [
      "George Town"
    ],
    altSpellings: [
      "KY"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      19.3133,
      -81.2546
    ],
    landlocked: "Yes",
    area: 264,
    demonyms: {
      eng: {
        f: "Caymanian",
        m: "Caymanian"
      },
      fra: {
        f: "Caïmanienne",
        m: "Caïmanien"
      }
    },
    flag: "🇰🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/P3ZVXX3LH63t91hL8",
      openStreetMaps: "https://www.openstreetmap.org/relation/7269765"
    },
    population: 65720,
    fifa: "CAY",
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-05:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ky.png",
      svg: "https://flagcdn.com/ky.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ky.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ky.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        19.3,
        -81.38
      ]
    },
    nativeName: [
      "Cayman Islands"
    ]
  },
  {
    name: {
      common: "Italy",
      official: "Italian Republic",
      nativeName: {
        ita: {
          official: "Repubblica italiana",
          common: "Italia"
        }
      }
    },
    tld: [
      ".it"
    ],
    cca2: "IT",
    ccn3: "380",
    cca3: "ITA",
    cioc: "ITA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "9"
      ]
    },
    capital: [
      "Rome"
    ],
    altSpellings: [
      "IT",
      "Italian Republic",
      "Repubblica italiana"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      ita: "Italian"
    },
    latlng: [
      42.83333333,
      12.83333333
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "FRA",
      "SMR",
      "SVN",
      "CHE",
      "VAT"
    ],
    area: 301336,
    demonyms: {
      eng: {
        f: "Italian",
        m: "Italian"
      },
      fra: {
        f: "Italienne",
        m: "Italien"
      }
    },
    flag: "🇮🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/8M1K27TDj7StTRTq8",
      openStreetMaps: "https://www.openstreetmap.org/relation/365331"
    },
    population: 59554023,
    gini: {
      2017: 35.9
    },
    fifa: "ITA",
    car: {
      signs: [
        "I"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/it.png",
      svg: "https://flagcdn.com/it.svg",
      alt: "The flag of Italy is composed of three equal vertical bands of green, white and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/it.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/it.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        41.9,
        12.48
      ]
    },
    nativeName: [
      "Italy",
      "Italian Republic",
      "Repubblica italiana",
      "Italia"
    ]
  },
  {
    name: {
      common: "Wallis and Futuna",
      official: "Territory of the Wallis and Futuna Islands",
      nativeName: {
        fra: {
          official: "Territoire des îles Wallis et Futuna",
          common: "Wallis et Futuna"
        }
      }
    },
    tld: [
      ".wf"
    ],
    cca2: "WF",
    ccn3: "876",
    cca3: "WLF",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "CFP franc ₣"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "81"
      ]
    },
    capital: [
      "Mata-Utu"
    ],
    altSpellings: [
      "WF",
      "Territory of the Wallis and Futuna Islands",
      "Territoire des îles Wallis et Futuna"
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: {
      fra: "French"
    },
    latlng: [
      -13.3,
      -176.2
    ],
    landlocked: "Yes",
    area: 142,
    demonyms: {
      eng: {
        f: "Wallis and Futuna Islander",
        m: "Wallis and Futuna Islander"
      }
    },
    flag: "🇼🇫",
    maps: {
      googleMaps: "https://goo.gl/maps/CzVqK74QYtbHv65r5",
      openStreetMaps: "https://www.openstreetmap.org/relation/3412448"
    },
    population: 11750,
    car: {
      signs: [
        "F"
      ],
      side: "right"
    },
    timezones: [
      "UTC+12:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/wf.png",
      svg: "https://flagcdn.com/wf.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -13.95,
        -171.93
      ]
    },
    nativeName: [
      "Wallis and Futuna",
      "Territory of the Wallis and Futuna Islands",
      "Territoire des îles Wallis et Futuna",
      "Wallis et Futuna"
    ]
  },
  {
    name: {
      common: "Andorra",
      official: "Principality of Andorra",
      nativeName: {
        cat: {
          official: "Principat d'Andorra",
          common: "Andorra"
        }
      }
    },
    tld: [
      ".ad"
    ],
    cca2: "AD",
    ccn3: "020",
    cca3: "AND",
    cioc: "AND",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "76"
      ]
    },
    capital: [
      "Andorra la Vella"
    ],
    altSpellings: [
      "AD",
      "Principality of Andorra",
      "Principat d'Andorra"
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: {
      cat: "Catalan"
    },
    latlng: [
      42.5,
      1.5
    ],
    landlocked: "Yes",
    borders: [
      "FRA",
      "ESP"
    ],
    area: 468,
    demonyms: {
      eng: {
        f: "Andorran",
        m: "Andorran"
      },
      fra: {
        f: "Andorrane",
        m: "Andorran"
      }
    },
    flag: "🇦🇩",
    maps: {
      googleMaps: "https://goo.gl/maps/JqAnacWE2qEznKgw7",
      openStreetMaps: "https://www.openstreetmap.org/relation/9407"
    },
    population: 77265,
    fifa: "AND",
    car: {
      signs: [
        "AND"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ad.png",
      svg: "https://flagcdn.com/ad.svg",
      alt: "The flag of Andorra features three equal vertical bands of blue, yellow and red, with the coat of arms of Andorra centered in the yellow band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ad.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ad.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        42.5,
        1.52
      ]
    },
    nativeName: [
      "Andorra",
      "Principality of Andorra",
      "Principat d'Andorra"
    ]
  },
  {
    name: {
      common: "Ecuador",
      official: "Republic of Ecuador",
      nativeName: {
        spa: {
          official: "República del Ecuador",
          common: "Ecuador"
        }
      }
    },
    tld: [
      ".ec"
    ],
    cca2: "EC",
    ccn3: "218",
    cca3: "ECU",
    cioc: "ECU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "93"
      ]
    },
    capital: [
      "Quito"
    ],
    altSpellings: [
      "EC",
      "Republic of Ecuador",
      "República del Ecuador"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      -2,
      -77.5
    ],
    landlocked: "Yes",
    borders: [
      "COL",
      "PER"
    ],
    area: 276841,
    demonyms: {
      eng: {
        f: "Ecuadorean",
        m: "Ecuadorean"
      },
      fra: {
        f: "Équatorienne",
        m: "Équatorien"
      }
    },
    flag: "🇪🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/TbX8hUW4gcbRPZiK7",
      openStreetMaps: "https://www.openstreetmap.org/relation/108089"
    },
    population: 17643060,
    gini: {
      2019: 45.7
    },
    fifa: "ECU",
    car: {
      signs: [
        "EC"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00",
      "UTC-05:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ec.png",
      svg: "https://flagcdn.com/ec.svg",
      alt: "The flag of Ecuador is composed of the horizontal bands of yellow, blue and red, with the yellow band twice the height of the other two bands. The Ecuadorian coat of arms is superimposed in the center of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ec.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ec.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -0.22,
        -78.5
      ]
    },
    nativeName: [
      "Ecuador",
      "Republic of Ecuador",
      "República del Ecuador"
    ]
  },
  {
    name: {
      common: "Kenya",
      official: "Republic of Kenya",
      nativeName: {
        eng: {
          official: "Republic of Kenya",
          common: "Kenya"
        },
        swa: {
          official: "Republic of Kenya",
          common: "Kenya"
        }
      }
    },
    tld: [
      ".ke"
    ],
    cca2: "KE",
    ccn3: "404",
    cca3: "KEN",
    cioc: "KEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Kenyan shilling Sh"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "54"
      ]
    },
    capital: [
      "Nairobi"
    ],
    altSpellings: [
      "KE",
      "Republic of Kenya",
      "Jamhuri ya Kenya"
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      eng: "English",
      swa: "Swahili"
    },
    latlng: [
      1,
      38
    ],
    landlocked: "Yes",
    borders: [
      "ETH",
      "SOM",
      "SSD",
      "TZA",
      "UGA"
    ],
    area: 580367,
    demonyms: {
      eng: {
        f: "Kenyan",
        m: "Kenyan"
      },
      fra: {
        f: "Kényane",
        m: "Kényan"
      }
    },
    flag: "🇰🇪",
    maps: {
      googleMaps: "https://goo.gl/maps/Ni9M7wcCxf8bJHLX8",
      openStreetMaps: "https://www.openstreetmap.org/relation/192798"
    },
    population: 53771300,
    gini: {
      2015: 40.8
    },
    fifa: "KEN",
    car: {
      signs: [
        "EAK"
      ],
      side: "left"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ke.png",
      svg: "https://flagcdn.com/ke.svg",
      alt: "The flag of Kenya is composed of three equal horizontal bands of black, red with white top and bottom edges, and green. An emblem comprising a red, black and white Maasai shield covering two crossed white spears is superimposed at the center of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ke.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ke.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -1.28,
        36.82
      ]
    },
    nativeName: [
      "Kenya",
      "Republic of Kenya"
    ]
  },
  {
    name: {
      common: "Bahrain",
      official: "Kingdom of Bahrain",
      nativeName: {
        ara: {
          official: "مملكة البحرين",
          common: "\u200fالبحر"
        }
      }
    },
    tld: [
      ".bh"
    ],
    cca2: "BH",
    ccn3: "048",
    cca3: "BHR",
    cioc: "BHR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bahraini dinar .د.ب"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "73"
      ]
    },
    capital: [
      "Manama"
    ],
    altSpellings: [
      "BH",
      "Kingdom of Bahrain",
      "Mamlakat al-Baḥrayn"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      26,
      50.55
    ],
    landlocked: "Yes",
    area: 765,
    demonyms: {
      eng: {
        f: "Bahraini",
        m: "Bahraini"
      },
      fra: {
        f: "Bahreïnienne",
        m: "Bahreïnien"
      }
    },
    flag: "🇧🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/5Zue99Zc6vFBHxzJ7",
      openStreetMaps: "https://www.openstreetmap.org/relation/378734"
    },
    population: 1701583,
    fifa: "BHR",
    car: {
      signs: [
        "BRN"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bh.png",
      svg: "https://flagcdn.com/bh.svg",
      alt: "The flag of Bahrain has a red field. On the hoist side, it features a white vertical band that spans about one-third the width of the field and is separated from the rest of the field by five adjoining fly-side pointing white isosceles triangles that serve as a serrated line."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bh.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bh.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        26.23,
        50.57
      ]
    },
    nativeName: [
      "Bahrain",
      "Kingdom of Bahrain",
      "مملكة البحرين",
      "\u200fالبحر"
      ]
  },
  {
    name: {
      common: "United Kingdom",
      official: "United Kingdom of Great Britain and Northern Ireland",
      nativeName: {
        eng: {
          official: "United Kingdom of Great Britain and Northern Ireland",
          common: "United Kingdom"
        }
      }
    },
    tld: [
      ".uk"
    ],
    cca2: "GB",
    ccn3: "826",
    cca3: "GBR",
    cioc: "GBR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "British pound £"
    ],
    idd: {
      root: "+4",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "London"
    ],
    altSpellings: [
      "GB",
      "UK",
      "Great Britain"
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      eng: "English"
    },
    latlng: [
      54,
      -2
    ],
    landlocked: "Yes",
    borders: [
      "IRL"
    ],
    area: 242900,
    demonyms: {
      eng: {
        f: "British",
        m: "British"
      },
      fra: {
        f: "Britannique",
        m: "Britannique"
      }
    },
    flag: "🇬🇧",
    maps: {
      googleMaps: "https://goo.gl/maps/FoDtc3UKMkFsXAjHA",
      openStreetMaps: "https://www.openstreetmap.org/relation/62149"
    },
    population: 67215293,
    gini: {
      2017: 35.1
    },
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-08:00",
      "UTC-05:00",
      "UTC-04:00",
      "UTC-03:00",
      "UTC-02:00",
      "UTC",
      "UTC+01:00",
      "UTC+02:00",
      "UTC+06:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gb.png",
      svg: "https://flagcdn.com/gb.svg",
      alt: "The flag of the United Kingdom — the Union Jack — has a blue field. It features the white-edged red cross of Saint George superimposed on the diagonal red cross of Saint Patrick which is superimposed on the diagonal white cross of Saint Andrew."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gb.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gb.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        51.5,
        -0.08
      ]
    },
    nativeName: [
      "United Kingdom",
      "United Kingdom of Great Britain and Northern Ireland"
    ]
  },
  {
    name: {
      common: "Guinea",
      official: "Republic of Guinea",
      nativeName: {
        fra: {
          official: "République de Guinée",
          common: "Guinée"
        }
      }
    },
    tld: [
      ".gn"
    ],
    cca2: "GN",
    ccn3: "324",
    cca3: "GIN",
    cioc: "GUI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Guinean franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "24"
      ]
    },
    capital: [
      "Conakry"
    ],
    altSpellings: [
      "GN",
      "Republic of Guinea",
      "République de Guinée"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      11,
      -10
    ],
    landlocked: "Yes",
    borders: [
      "CIV",
      "GNB",
      "LBR",
      "MLI",
      "SEN",
      "SLE"
    ],
    area: 245857,
    demonyms: {
      eng: {
        f: "Guinean",
        m: "Guinean"
      },
      fra: {
        f: "Guinéenne",
        m: "Guinéen"
      }
    },
    flag: "🇬🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/8J5oM5sA4Ayr1ZYGA",
      openStreetMaps: "https://www.openstreetmap.org/relation/192778"
    },
    population: 13132792,
    gini: {
      2012: 33.7
    },
    fifa: "GUI",
    car: {
      signs: [
        "RG"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gn.png",
      svg: "https://flagcdn.com/gn.svg",
      alt: "The flag of Guinea is composed of three equal vertical bands of red, yellow and green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        9.5,
        -13.7
      ]
    },
    nativeName: [
      "Guinea",
      "Republic of Guinea",
      "République de Guinée",
      "Guinée"
    ]
  },
  {
    name: {
      common: "Heard Island and McDonald Islands",
      official: "Heard Island and McDonald Islands",
      nativeName: {
        eng: {
          official: "Heard Island and McDonald Islands",
          common: "Heard Island and McDonald Islands"
        }
      }
    },
    tld: [
      ".hm",
      ".aq"
    ],
    cca2: "HM",
    ccn3: "334",
    cca3: "HMD",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    idd: {},
    altSpellings: [
      "HM",
      "Heard Island and McDonald Islands"
    ],
    region: "Antarctic",
    languages: {
      eng: "English"
    },
    latlng: [
      53.0818,
      73.5042
    ],
    landlocked: "Yes",
    area: 412,
    demonyms: {
      eng: {
        f: "Heard and McDonald Islander",
        m: "Heard and McDonald Islander"
      }
    },
    flag: "🇭🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/k5FBAiVaVyozuYeA7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2177227"
    },
    population: 0,
    car: {
      signs: [
        ""
      ],
      side: "right"
    },
    timezones: [
      "UTC+05:00"
    ],
    continents: [
      "Antarctica"
    ],
    flags: {
      png: "https://flagcdn.com/w320/hm.png",
      svg: "https://flagcdn.com/hm.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {},
    nativeName: [
      "Heard Island and McDonald Islands"
    ]
  },
  {
    name: {
      common: "Russia",
      official: "Russian Federation",
      nativeName: {
        rus: {
          official: "Российская Федерация",
          common: "Россия"
        }
      }
    },
    tld: [
      ".ru",
      ".su",
      ".рф"
    ],
    cca2: "RU",
    ccn3: "643",
    cca3: "RUS",
    cioc: "RUS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Russian ruble ₽"
    ],
    idd: {
      root: "+7",
      suffixes: [
        "3",
        "4",
        "5",
        "8",
        "9"
      ]
    },
    capital: [
      "Moscow"
    ],
    altSpellings: [
      "RU",
      "Russian Federation",
      "Российская Федерация"
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: {
      rus: "Russian"
    },
    latlng: [
      60,
      100
    ],
    landlocked: "Yes",
    borders: [
      "AZE",
      "BLR",
      "CHN",
      "EST",
      "FIN",
      "GEO",
      "KAZ",
      "PRK",
      "LVA",
      "LTU",
      "MNG",
      "NOR",
      "POL",
      "UKR"
    ],
    area: 17098242,
    demonyms: {
      eng: {
        f: "Russian",
        m: "Russian"
      },
      fra: {
        f: "Russe",
        m: "Russe"
      }
    },
    flag: "🇷🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/4F4PpDhGJgVvLby57",
      openStreetMaps: "https://www.openstreetmap.org/relation/60189#map=3/65.15/105.29"
    },
    population: 144104080,
    gini: {
      2018: 37.5
    },
    fifa: "RUS",
    car: {
      signs: [
        "RUS"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00",
      "UTC+04:00",
      "UTC+06:00",
      "UTC+07:00",
      "UTC+08:00",
      "UTC+09:00",
      "UTC+10:00",
      "UTC+11:00",
      "UTC+12:00"
    ],
    continents: [
      "Europe",
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ru.png",
      svg: "https://flagcdn.com/ru.svg",
      alt: "The flag of Russia is composed of three equal horizontal bands of white, blue and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ru.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ru.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        55.75,
        37.6
      ]
    },
    nativeName: [
      "Russia",
      "Russian Federation",
      "Российская Федерация",
      "Россия"
    ]
  },
  {
    name: {
      common: "Brunei",
      official: "Nation of Brunei, Abode of Peace",
      nativeName: {
        msa: {
          official: "Nation of Brunei, Abode Damai",
          common: "Negara Brunei Darussalam"
        }
      }
    },
    tld: [
      ".bn"
    ],
    cca2: "BN",
    ccn3: "096",
    cca3: "BRN",
    cioc: "BRU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Brunei dollar $",
      "Singapore dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "73"
      ]
    },
    capital: [
      "Bandar Seri Begawan"
    ],
    altSpellings: [
      "BN",
      "Brunei Darussalam",
      "Nation of Brunei",
      "the Abode of Peace"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      msa: "Malay"
    },
    latlng: [
      4.5,
      114.66666666
    ],
    landlocked: "Yes",
    borders: [
      "MYS"
    ],
    area: 5765,
    demonyms: {
      eng: {
        f: "Bruneian",
        m: "Bruneian"
      },
      fra: {
        f: "Brunéienne",
        m: "Brunéien"
      }
    },
    flag: "🇧🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/4jb4CqBXhr8vNh579",
      openStreetMaps: "https://www.openstreetmap.org/relation/2103120"
    },
    population: 437483,
    fifa: "BRU",
    car: {
      signs: [
        "BRU"
      ],
      side: "left"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bn.png",
      svg: "https://flagcdn.com/bn.svg",
      alt: "The flag of Brunei has a yellow field with two adjoining diagonal bands of white and black that extend from the upper hoist side of the field to the lower fly side. The red emblem of Brunei is centered on the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        4.88,
        114.93
      ]
    },
    nativeName: [
      "Brunei",
      "Nation of Brunei, Abode of Peace",
      "Nation of Brunei, Abode Damai",
      "Negara Brunei Darussalam"
    ]
  },
  {
    name: {
      common: "Zimbabwe",
      official: "Republic of Zimbabwe",
      nativeName: {
        bwg: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        eng: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        kck: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        khi: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        ndc: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        nde: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        nya: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        sna: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        sot: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        toi: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        tsn: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        tso: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        ven: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        xho: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        },
        zib: {
          official: "Republic of Zimbabwe",
          common: "Zimbabwe"
        }
      }
    },
    tld: [
      ".zw"
    ],
    cca2: "ZW",
    ccn3: "716",
    cca3: "ZWE",
    cioc: "ZIM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Zimbabwean dollar $"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "63"
      ]
    },
    capital: [
      "Harare"
    ],
    altSpellings: [
      "ZW",
      "Republic of Zimbabwe"
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      bwg: "Chibarwe",
      eng: "English",
      kck: "Kalanga",
      khi: "Khoisan",
      ndc: "Ndau",
      nde: "Northern Ndebele",
      nya: "Chewa",
      sna: "Shona",
      sot: "Sotho",
      toi: "Tonga",
      tsn: "Tswana",
      tso: "Tsonga",
      ven: "Venda",
      xho: "Xhosa",
      zib: "Zimbabwean Sign Language"
    },
    latlng: [
      -20,
      30
    ],
    landlocked: "Yes",
    borders: [
      "BWA",
      "MOZ",
      "ZAF",
      "ZMB"
    ],
    area: 390757,
    demonyms: {
      eng: {
        f: "Zimbabwean",
        m: "Zimbabwean"
      },
      fra: {
        f: "Zimbabwéenne",
        m: "Zimbabwéen"
      }
    },
    flag: "🇿🇼",
    maps: {
      googleMaps: "https://goo.gl/maps/M26BqdwQctqxXS65A",
      openStreetMaps: "https://www.openstreetmap.org/relation/195272"
    },
    population: 14862927,
    gini: {
      2019: 50.3
    },
    fifa: "ZIM",
    car: {
      signs: [
        "ZW"
      ],
      side: "left"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/zw.png",
      svg: "https://flagcdn.com/zw.svg",
      alt: "The flag of Zimbabwe is composed of seven equal horizontal bands of green, yellow, red, black, red, yellow and green, with a white isosceles triangle superimposed on the hoist side of the field. This triangle is edged in black, spans about one-fourth the width of the field and has its base on the hoist end. A yellow Zimbabwe bird superimposed on a five-pointed red star is centered in the triangle."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/zw.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/zw.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -17.82,
        31.03
      ]
    },
    nativeName: [
      "Zimbabwe",
      "Republic of Zimbabwe"
    ]
  },
  {
    name: {
      common: "Guam",
      official: "Guam",
      nativeName: {
        cha: {
          official: "Guåhån",
          common: "Guåhån"
        },
        eng: {
          official: "Guam",
          common: "Guam"
        },
        spa: {
          official: "Guam",
          common: "Guam"
        }
      }
    },
    tld: [
      ".gu"
    ],
    cca2: "GU",
    ccn3: "316",
    cca3: "GUM",
    cioc: "GUM",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "671"
      ]
    },
    capital: [
      "Hagåtña"
    ],
    altSpellings: [
      "GU",
      "Guåhån"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      cha: "Chamorro",
      eng: "English",
      spa: "Spanish"
    },
    latlng: [
      13.46666666,
      144.78333333
    ],
    landlocked: "Yes",
    area: 549,
    demonyms: {
      eng: {
        f: "Guamanian",
        m: "Guamanian"
      }
    },
    flag: "🇬🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/Xfnq2i279b18cH3C9",
      openStreetMaps: "https://www.openstreetmap.org/relation/306001"
    },
    population: 168783,
    fifa: "GUM",
    car: {
      signs: [
        "USA"
      ],
      side: "right"
    },
    timezones: [
      "UTC+10:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/gu.png",
      svg: "https://flagcdn.com/gu.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/gu.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/gu.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.48,
        144.75
      ]
    },
    nativeName: [
      "Guam",
      "Guåhån"
    ]
  },
  {
    name: {
      common: "Australia",
      official: "Commonwealth of Australia",
      nativeName: {
        eng: {
          official: "Commonwealth of Australia",
          common: "Australia"
        }
      }
    },
    tld: [
      ".au"
    ],
    cca2: "AU",
    ccn3: "036",
    cca3: "AUS",
    cioc: "AUS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Australian dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "Canberra"
    ],
    altSpellings: [
      "AU"
    ],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: {
      eng: "English"
    },
    latlng: [
      -27,
      133
    ],
    landlocked: "Yes",
    area: 7692024,
    demonyms: {
      eng: {
        f: "Australian",
        m: "Australian"
      },
      fra: {
        f: "Australienne",
        m: "Australien"
      }
    },
    flag: "🇦🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/DcjaDa7UbhnZTndH6",
      openStreetMaps: "https://www.openstreetmap.org/relation/80500"
    },
    population: 25687041,
    gini: {
      2014: 34.4
    },
    fifa: "AUS",
    car: {
      signs: [
        "AUS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+05:00",
      "UTC+06:30",
      "UTC+07:00",
      "UTC+08:00",
      "UTC+09:30",
      "UTC+10:00",
      "UTC+10:30",
      "UTC+11:30"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/au.png",
      svg: "https://flagcdn.com/au.svg",
      alt: "The flag of Australia has a dark blue field. It features the flag of the United Kingdom — the Union Jack — in the canton, beneath which is a large white seven-pointed star. A representation of the Southern Cross constellation, made up of one small five-pointed and four larger seven-pointed white stars, is situated on the fly side of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/au.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/au.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -35.27,
        149.13
      ]
    },
    nativeName: [
      "Australia",
      "Commonwealth of Australia"
    ]
  },
  {
    name: {
      common: "Slovenia",
      official: "Republic of Slovenia",
      nativeName: {
        slv: {
          official: "Republika Slovenija",
          common: "Slovenija"
        }
      }
    },
    tld: [
      ".si"
    ],
    cca2: "SI",
    ccn3: "705",
    cca3: "SVN",
    cioc: "SLO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "86"
      ]
    },
    capital: [
      "Ljubljana"
    ],
    altSpellings: [
      "SI",
      "Republic of Slovenia",
      "Republika Slovenija"
    ],
    region: "Europe",
    subregion: "Central Europe",
    languages: {
      slv: "Slovene"
    },
    latlng: [
      46.11666666,
      14.81666666
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "HRV",
      "ITA",
      "HUN"
    ],
    area: 20273,
    demonyms: {
      eng: {
        f: "Slovene",
        m: "Slovene"
      },
      fra: {
        f: "Slovène",
        m: "Slovène"
      }
    },
    flag: "🇸🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/7zgFmswcCJh5L5D49",
      openStreetMaps: "https://www.openstreetmap.org/relation/218657"
    },
    population: 2100126,
    gini: {
      2018: 24.6
    },
    fifa: "SVN",
    car: {
      signs: [
        "SLO"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/si.png",
      svg: "https://flagcdn.com/si.svg",
      alt: "The flag of Slovenia is composed of three equal horizontal bands of white, blue and red. The national coat of arms is situated in the upper hoist side of the field centered on the boundary between the white and blue bands."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/si.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/si.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        46.05,
        14.52
      ]
    },
    nativeName: [
      "Slovenia",
      "Republic of Slovenia",
      "Republika Slovenija",
      "Slovenija"
    ]
  },
  {
    name: {
      common: "Belarus",
      official: "Republic of Belarus",
      nativeName: {
        bel: {
          official: "Рэспубліка Беларусь",
          common: "Белару́сь"
        },
        rus: {
          official: "Республика Беларусь",
          common: "Беларусь"
        }
      }
    },
    tld: [
      ".by"
    ],
    cca2: "BY",
    ccn3: "112",
    cca3: "BLR",
    cioc: "BLR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Belarusian ruble Br"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "75"
      ]
    },
    capital: [
      "Minsk"
    ],
    altSpellings: [
      "BY",
      "Bielaruś",
      "Republic of Belarus",
      "Белоруссия",
      "Республика Белоруссия"
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: {
      bel: "Belarusian",
      rus: "Russian"
    },
    latlng: [
      53,
      28
    ],
    landlocked: "Yes",
    borders: [
      "LVA",
      "LTU",
      "POL",
      "RUS",
      "UKR"
    ],
    area: 207600,
    demonyms: {
      eng: {
        f: "Belarusian",
        m: "Belarusian"
      },
      fra: {
        f: "Biélorusse",
        m: "Biélorusse"
      }
    },
    flag: "🇧🇾",
    maps: {
      googleMaps: "https://goo.gl/maps/PJUDU3EBPSszCQcu6",
      openStreetMaps: "https://www.openstreetmap.org/relation/59065"
    },
    population: 9398861,
    gini: {
      2019: 25.3
    },
    fifa: "BLR",
    car: {
      signs: [
        "BY"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/by.png",
      svg: "https://flagcdn.com/by.svg",
      alt: "The flag of Belarus features a vertical band, with a white and red ornamental pattern, spanning about one-fifth the width of the field on the hoist side. Adjoining the vertical band are two horizontal bands of red and green, with the red band twice the height of the green band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/by.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/by.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        53.9,
        27.57
      ]
    },
    nativeName: [
      "Belarus",
      "Republic of Belarus",
      "Рэспубліка Беларусь",
      "Белару́сь",
      "Республика Беларусь",
      "Беларусь"
    ]
  },
  {
    name: {
      common: "Thailand",
      official: "Kingdom of Thailand",
      nativeName: {
        tha: {
          official: "ราชอาณาจักรไทย",
          common: "ประเทศไทย"
        }
      }
    },
    tld: [
      ".th",
      ".ไทย"
    ],
    cca2: "TH",
    ccn3: "764",
    cca3: "THA",
    cioc: "THA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Thai baht ฿"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "6"
      ]
    },
    capital: [
      "Bangkok"
    ],
    altSpellings: [
      "TH",
      "Prathet",
      "Thai",
      "Kingdom of Thailand",
      "ราชอาณาจักรไทย",
      "Ratcha Anachak Thai"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      tha: "Thai"
    },
    latlng: [
      15,
      100
    ],
    landlocked: "Yes",
    borders: [
      "MMR",
      "KHM",
      "LAO",
      "MYS"
    ],
    area: 513120,
    demonyms: {
      eng: {
        f: "Thai",
        m: "Thai"
      },
      fra: {
        f: "Thaïlandaise",
        m: "Thaïlandais"
      }
    },
    flag: "🇹🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/qeU6uqsfW4nCCwzw9",
      openStreetMaps: "https://www.openstreetmap.org/relation/2067731"
    },
    population: 69799978,
    gini: {
      2019: 34.9
    },
    fifa: "THA",
    car: {
      signs: [
        "T"
      ],
      side: "left"
    },
    timezones: [
      "UTC+07:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/th.png",
      svg: "https://flagcdn.com/th.svg",
      alt: "The flag of Thailand is composed of five horizontal bands of red, white, blue, white and red, with the central blue band twice the height of the other four bands."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/th.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/th.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        13.75,
        100.52
      ]
    },
    nativeName: [
      "Thailand",
      "Kingdom of Thailand",
      "ราชอาณาจักรไทย",
      "ประเทศไทย"
    ]
  },
  {
    name: {
      common: "New Zealand",
      official: "New Zealand",
      nativeName: {
        eng: {
          official: "New Zealand",
          common: "New Zealand"
        },
        mri: {
          official: "Aotearoa",
          common: "Aotearoa"
        },
        nzs: {
          official: "New Zealand",
          common: "New Zealand"
        }
      }
    },
    tld: [
      ".nz"
    ],
    cca2: "NZ",
    ccn3: "554",
    cca3: "NZL",
    cioc: "NZL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "New Zealand dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "4"
      ]
    },
    capital: [
      "Wellington"
    ],
    altSpellings: [
      "NZ",
      "Aotearoa"
    ],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: {
      eng: "English",
      mri: "Māori",
      nzs: "New Zealand Sign Language"
    },
    latlng: [
      -41,
      174
    ],
    landlocked: "Yes",
    area: 270467,
    demonyms: {
      eng: {
        f: "New Zealander",
        m: "New Zealander"
      },
      fra: {
        f: "Neo-Zélandaise",
        m: "Neo-Zélandais"
      }
    },
    flag: "🇳🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/xXiDQo65dwdpw9iu8",
      openStreetMaps: "https://www.openstreetmap.org/relation/556706#map=5/-46.710/172.046"
    },
    population: 5084300,
    fifa: "NZL",
    car: {
      signs: [
        "NZ"
      ],
      side: "left"
    },
    timezones: [
      "UTC-11:00",
      "UTC-10:00",
      "UTC+12:00",
      "UTC+12:45",
      "UTC+13:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/nz.png",
      svg: "https://flagcdn.com/nz.svg",
      alt: "The flag of New Zealand has a dark blue field with the flag of the United Kingdom — the Union Jack — in the canton and a representation of the Southern Cross constellation, made up of four five-pointed white-edged red stars, on the fly side of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/nz.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/nz.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -41.3,
        174.78
      ]
    },
    nativeName: [
      "New Zealand",
      "Aotearoa"
    ]
  },
  {
    name: {
      common: "Tunisia",
      official: "Tunisian Republic",
      nativeName: {
        ara: {
          official: "الجمهورية التونسية",
          common: "تونس"
        }
      }
    },
    tld: [
      ".tn"
    ],
    cca2: "TN",
    ccn3: "788",
    cca3: "TUN",
    cioc: "TUN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Tunisian dinar د.ت"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "16"
      ]
    },
    capital: [
      "Tunis"
    ],
    altSpellings: [
      "TN",
      "Republic of Tunisia",
      "al-Jumhūriyyah at-Tūnisiyyah"
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: {
      ara: "Arabic"
    },
    latlng: [
      34,
      9
    ],
    landlocked: "Yes",
    borders: [
      "DZA",
      "LBY"
    ],
    area: 163610,
    demonyms: {
      eng: {
        f: "Tunisian",
        m: "Tunisian"
      },
      fra: {
        f: "Tunisienne",
        m: "Tunisien"
      }
    },
    flag: "🇹🇳",
    maps: {
      googleMaps: "https://goo.gl/maps/KgUmpZdUuNRaougs8",
      openStreetMaps: "https://www.openstreetmap.org/relation/192757"
    },
    population: 11818618,
    gini: {
      2015: 32.8
    },
    fifa: "TUN",
    car: {
      signs: [
        "TN"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tn.png",
      svg: "https://flagcdn.com/tn.svg",
      alt: "The flag of Tunisia has a red field. A white circle bearing a five-pointed red star within a fly-side facing red crescent is situated at the center of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tn.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tn.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        36.8,
        10.18
      ]
    },
    nativeName: [
      "Tunisia",
      "Tunisian Republic",
      "الجمهورية التونسية",
      "تونس"
    ]
  },
  {
    name: {
      common: "Marshall Islands",
      official: "Republic of the Marshall Islands",
      nativeName: {
        eng: {
          official: "Republic of the Marshall Islands",
          common: "Marshall Islands"
        },
        mah: {
          official: "Republic of the Marshall Islands",
          common: "M̧ajeļ"
        }
      }
    },
    tld: [
      ".mh"
    ],
    cca2: "MH",
    ccn3: "584",
    cca3: "MHL",
    cioc: "MHL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "92"
      ]
    },
    capital: [
      "Majuro"
    ],
    altSpellings: [
      "MH",
      "Republic of the Marshall Islands",
      "Aolepān Aorōkin M̧ajeļ"
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: {
      eng: "English",
      mah: "Marshallese"
    },
    latlng: [
      9,
      168
    ],
    landlocked: "Yes",
    area: 181,
    demonyms: {
      eng: {
        f: "Marshallese",
        m: "Marshallese"
      },
      fra: {
        f: "Marshallaise",
        m: "Marshallais"
      }
    },
    flag: "🇲🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/A4xLi1XvcX88gi3W8",
      openStreetMaps: "https://www.openstreetmap.org/relation/571771"
    },
    population: 59194,
    car: {
      signs: [
        "MH"
      ],
      side: "right"
    },
    timezones: [
      "UTC+12:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/mh.png",
      svg: "https://flagcdn.com/mh.svg",
      alt: "The flag of Marshall Islands has a blue field with two broadening adjacent diagonal bands of orange and white that extend from the lower hoist-side corner to the upper fly-side corner of the field. A large white star with twenty-four rays — four large rays at the cardinal points and twenty smaller rays — is situated in the upper hoist-side corner above the diagonal bands."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/mh.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/mh.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        7.1,
        171.38
      ]
    },
    nativeName: [
      "Marshall Islands",
      "Republic of the Marshall Islands",
      "M̧ajeļ"
    ]
  },
  {
    name: {
      common: "Sierra Leone",
      official: "Republic of Sierra Leone",
      nativeName: {
        eng: {
          official: "Republic of Sierra Leone",
          common: "Sierra Leone"
        }
      }
    },
    tld: [
      ".sl"
    ],
    cca2: "SL",
    ccn3: "694",
    cca3: "SLE",
    cioc: "SLE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Sierra Leonean leone Le"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "32"
      ]
    },
    capital: [
      "Freetown"
    ],
    altSpellings: [
      "SL",
      "Republic of Sierra Leone"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      eng: "English"
    },
    latlng: [
      8.5,
      -11.5
    ],
    landlocked: "Yes",
    borders: [
      "GIN",
      "LBR"
    ],
    area: 71740,
    demonyms: {
      eng: {
        f: "Sierra Leonean",
        m: "Sierra Leonean"
      },
      fra: {
        f: "Sierra-leonaise",
        m: "Sierra-leonais"
      }
    },
    flag: "🇸🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/jhacar85oq9QaeKB7",
      openStreetMaps: "https://www.openstreetmap.org/relation/192777"
    },
    population: 7976985,
    gini: {
      2018: 35.7
    },
    fifa: "SLE",
    car: {
      signs: [
        "WAL"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sl.png",
      svg: "https://flagcdn.com/sl.svg",
      alt: "The flag of Sierra Leone is composed of three equal horizontal bands of green, white and blue."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sl.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sl.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        8.48,
        -13.23
      ]
    },
    nativeName: [
      "Sierra Leone",
      "Republic of Sierra Leone"
    ]
  },
  {
    name: {
      common: "Bhutan",
      official: "Kingdom of Bhutan",
      nativeName: {
        dzo: {
          official: "འབྲུག་རྒྱལ་ཁབ་",
          common: "འབྲུག་ཡུལ་"
        }
      }
    },
    tld: [
      ".bt"
    ],
    cca2: "BT",
    ccn3: "064",
    cca3: "BTN",
    cioc: "BHU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bhutanese ngultrum Nu.",
      "Indian rupee ₹"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "75"
      ]
    },
    capital: [
      "Thimphu"
    ],
    altSpellings: [
      "BT",
      "Kingdom of Bhutan"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      dzo: "Dzongkha"
    },
    latlng: [
      27.5,
      90.5
    ],
    landlocked: "Yes",
    borders: [
      "CHN",
      "IND"
    ],
    area: 38394,
    demonyms: {
      eng: {
        f: "Bhutanese",
        m: "Bhutanese"
      },
      fra: {
        f: "Bhoutanaise",
        m: "Bhoutanais"
      }
    },
    flag: "🇧🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/VEfXXBftTFLUpNgp8",
      openStreetMaps: "https://www.openstreetmap.org/relation/184629"
    },
    population: 771612,
    gini: {
      2017: 37.4
    },
    fifa: "BHU",
    car: {
      signs: [
        "BHT"
      ],
      side: "left"
    },
    timezones: [
      "UTC+06:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bt.png",
      svg: "https://flagcdn.com/bt.svg",
      alt: "The flag of Bhutan is divided diagonally, from the lower hoist-side corner to the upper fly-side corner, into an upper yellow and a lower orange triangle. A fly-side facing white dragon holding four jewels in its claws is situated along the boundary of the two triangles."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bt.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bt.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        27.47,
        89.63
      ]
    },
    nativeName: [
      "Bhutan",
      "Kingdom of Bhutan",
      "འབྲུག་རྒྱལ་ཁབ་",
      "འབྲུག་ཡུལ་"
    ]
  },
  {
    name: {
      common: "United States",
      official: "United States of America",
      nativeName: {
        eng: {
          official: "United States of America",
          common: "United States"
        }
      }
    },
    tld: [
      ".us"
    ],
    cca2: "US",
    ccn3: "840",
    cca3: "USA",
    cioc: "USA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "201",
        "202",
        "203",
        "205",
        "206",
        "207",
        "208",
        "209",
        "210",
        "212",
        "213",
        "214",
        "215",
        "216",
        "217",
        "218",
        "219",
        "220",
        "224",
        "225",
        "227",
        "228",
        "229",
        "231",
        "234",
        "239",
        "240",
        "248",
        "251",
        "252",
        "253",
        "254",
        "256",
        "260",
        "262",
        "267",
        "269",
        "270",
        "272",
        "274",
        "276",
        "281",
        "283",
        "301",
        "302",
        "303",
        "304",
        "305",
        "307",
        "308",
        "309",
        "310",
        "312",
        "313",
        "314",
        "315",
        "316",
        "317",
        "318",
        "319",
        "320",
        "321",
        "323",
        "325",
        "327",
        "330",
        "331",
        "334",
        "336",
        "337",
        "339",
        "346",
        "347",
        "351",
        "352",
        "360",
        "361",
        "364",
        "380",
        "385",
        "386",
        "401",
        "402",
        "404",
        "405",
        "406",
        "407",
        "408",
        "409",
        "410",
        "412",
        "413",
        "414",
        "415",
        "417",
        "419",
        "423",
        "424",
        "425",
        "430",
        "432",
        "434",
        "435",
        "440",
        "442",
        "443",
        "447",
        "458",
        "463",
        "464",
        "469",
        "470",
        "475",
        "478",
        "479",
        "480",
        "484",
        "501",
        "502",
        "503",
        "504",
        "505",
        "507",
        "508",
        "509",
        "510",
        "512",
        "513",
        "515",
        "516",
        "517",
        "518",
        "520",
        "530",
        "531",
        "534",
        "539",
        "540",
        "541",
        "551",
        "559",
        "561",
        "562",
        "563",
        "564",
        "567",
        "570",
        "571",
        "573",
        "574",
        "575",
        "580",
        "585",
        "586",
        "601",
        "602",
        "603",
        "605",
        "606",
        "607",
        "608",
        "609",
        "610",
        "612",
        "614",
        "615",
        "616",
        "617",
        "618",
        "619",
        "620",
        "623",
        "626",
        "628",
        "629",
        "630",
        "631",
        "636",
        "641",
        "646",
        "650",
        "651",
        "657",
        "660",
        "661",
        "662",
        "667",
        "669",
        "678",
        "681",
        "682",
        "701",
        "702",
        "703",
        "704",
        "706",
        "707",
        "708",
        "712",
        "713",
        "714",
        "715",
        "716",
        "717",
        "718",
        "719",
        "720",
        "724",
        "725",
        "727",
        "730",
        "731",
        "732",
        "734",
        "737",
        "740",
        "743",
        "747",
        "754",
        "757",
        "760",
        "762",
        "763",
        "765",
        "769",
        "770",
        "772",
        "773",
        "774",
        "775",
        "779",
        "781",
        "785",
        "786",
        "801",
        "802",
        "803",
        "804",
        "805",
        "806",
        "808",
        "810",
        "812",
        "813",
        "814",
        "815",
        "816",
        "817",
        "818",
        "828",
        "830",
        "831",
        "832",
        "843",
        "845",
        "847",
        "848",
        "850",
        "854",
        "856",
        "857",
        "858",
        "859",
        "860",
        "862",
        "863",
        "864",
        "865",
        "870",
        "872",
        "878",
        "901",
        "903",
        "904",
        "906",
        "907",
        "908",
        "909",
        "910",
        "912",
        "913",
        "914",
        "915",
        "916",
        "917",
        "918",
        "919",
        "920",
        "925",
        "928",
        "929",
        "930",
        "931",
        "934",
        "936",
        "937",
        "938",
        "940",
        "941",
        "947",
        "949",
        "951",
        "952",
        "954",
        "956",
        "959",
        "970",
        "971",
        "972",
        "973",
        "975",
        "978",
        "979",
        "980",
        "984",
        "985",
        "989"
      ]
    },
    capital: [
      "Washington, D.C."
    ],
    altSpellings: [
      "US",
      "USA",
      "United States of America"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      eng: "English"
    },
    latlng: [
      38,
      -97
    ],
    landlocked: "Yes",
    borders: [
      "CAN",
      "MEX"
    ],
    area: 9372610,
    demonyms: {
      eng: {
        f: "American",
        m: "American"
      },
      fra: {
        f: "Américaine",
        m: "Américain"
      }
    },
    flag: "🇺🇸",
    maps: {
      googleMaps: "https://goo.gl/maps/e8M246zY4BSjkjAv6",
      openStreetMaps: "https://www.openstreetmap.org/relation/148838#map=2/20.6/-85.8"
    },
    population: 329484123,
    gini: {
      2018: 41.4
    },
    fifa: "USA",
    car: {
      signs: [
        "USA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-12:00",
      "UTC-11:00",
      "UTC-10:00",
      "UTC-09:00",
      "UTC-08:00",
      "UTC-07:00",
      "UTC-06:00",
      "UTC-05:00",
      "UTC-04:00",
      "UTC+10:00",
      "UTC+12:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/us.png",
      svg: "https://flagcdn.com/us.svg",
      alt: "The flag of the United States of America is composed of thirteen equal horizontal bands of red alternating with white. A blue rectangle, bearing fifty small five-pointed white stars arranged in nine rows where rows of six stars alternate with rows of five stars, is superimposed in the canton."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/us.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/us.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        38.89,
        -77.05
      ]
    },
    nativeName: [
      "United States",
      "United States of America"
    ]
  },
  {
    name: {
      common: "Switzerland",
      official: "Swiss Confederation",
      nativeName: {
        fra: {
          official: "Confédération suisse",
          common: "Suisse"
        },
        gsw: {
          official: "Schweizerische Eidgenossenschaft",
          common: "Schweiz"
        },
        ita: {
          official: "Confederazione Svizzera",
          common: "Svizzera"
        },
        roh: {
          official: "Confederaziun svizra",
          common: "Svizra"
        }
      }
    },
    tld: [
      ".ch"
    ],
    cca2: "CH",
    ccn3: "756",
    cca3: "CHE",
    cioc: "SUI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Swiss franc Fr."
    ],
    idd: {
      root: "+4",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "Bern"
    ],
    altSpellings: [
      "CH",
      "Swiss Confederation",
      "Schweiz",
      "Suisse",
      "Svizzera",
      "Svizra"
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      fra: "French",
      gsw: "Swiss German",
      ita: "Italian",
      roh: "Romansh"
    },
    latlng: [
      47,
      8
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "FRA",
      "ITA",
      "LIE",
      "DEU"
    ],
    area: 41284,
    demonyms: {
      eng: {
        f: "Swiss",
        m: "Swiss"
      },
      fra: {
        f: "Suisse",
        m: "Suisse"
      }
    },
    flag: "🇨🇭",
    maps: {
      googleMaps: "https://goo.gl/maps/uVuZcXaxSx5jLyEC9",
      openStreetMaps: "https://www.openstreetmap.org/relation/51701"
    },
    population: 8654622,
    gini: {
      2018: 33.1
    },
    fifa: "SUI",
    car: {
      signs: [
        "CH"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ch.png",
      svg: "https://flagcdn.com/ch.svg",
      alt: "The flag of Switzerland is square shaped. It features a white Swiss cross centered on a red field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ch.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ch.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        46.92,
        7.47
      ]
    },
    nativeName: [
      "Switzerland",
      "Swiss Confederation",
      "Confédération suisse",
      "Suisse",
      "Schweizerische Eidgenossenschaft",
      "Schweiz",
      "Confederazione Svizzera",
      "Svizzera",
      "Confederaziun svizra",
      "Svizra"
    ]
  },
  {
    name: {
      common: "Falkland Islands",
      official: "Falkland Islands",
      nativeName: {
        eng: {
          official: "Falkland Islands",
          common: "Falkland Islands"
        }
      }
    },
    tld: [
      ".fk"
    ],
    cca2: "FK",
    ccn3: "238",
    cca3: "FLK",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Falkland Islands pound £"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "00"
      ]
    },
    capital: [
      "Stanley"
    ],
    altSpellings: [
      "FK",
      "Islas Malvinas",
      "Falkland Islands (Malvinas)"
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      eng: "English"
    },
    latlng: [
      -51.75,
      -59
    ],
    landlocked: "Yes",
    area: 12173,
    demonyms: {
      eng: {
        f: "Falkland Islander",
        m: "Falkland Islander"
      },
      fra: {
        f: "Malouinne",
        m: "Malouin"
      }
    },
    flag: "🇫🇰",
    maps: {
      googleMaps: "https://goo.gl/maps/TZH1x7AGanQKifNk7",
      openStreetMaps: "https://www.openstreetmap.org/relation/2185374"
    },
    population: 2563,
    car: {
      signs: [
        "GB"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "South America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/fk.png",
      svg: "https://flagcdn.com/fk.svg"
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/fk.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/fk.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -51.7,
        -57.85
      ]
    },
    nativeName: [
      "Falkland Islands"
    ]
  },
  {
    name: {
      common: "Cocos (Keeling) Islands",
      official: "Territory of the Cocos (Keeling) Islands",
      nativeName: {
        eng: {
          official: "Territory of the Cocos (Keeling) Islands",
          common: "Cocos (Keeling) Islands"
        }
      }
    },
    tld: [
      ".cc"
    ],
    cca2: "CC",
    ccn3: "166",
    cca3: "CCK",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "Australian dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "1"
      ]
    },
    capital: [
      "West Island"
    ],
    altSpellings: [
      "CC",
      "Keeling Islands",
      "Cocos Islands"
    ],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: {
      eng: "English"
    },
    latlng: [
      12.1642,
      96.871
    ],
    landlocked: "Yes",
    area: 14,
    demonyms: {
      eng: {
        f: "Cocos Islander",
        m: "Cocos Islander"
      }
    },
    flag: "🇨🇨",
    maps: {
      googleMaps: "https://goo.gl/maps/3eCdKVpVfMcZyKcK6",
      openStreetMaps: "https://www.openstreetmap.org/relation/82636"
    },
    population: 544,
    car: {
      signs: [
        "AUS"
      ],
      side: "left"
    },
    timezones: [
      "UTC+06:30"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cc.png",
      svg: "https://flagcdn.com/cc.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -12.17,
        96.83
      ]
    },
    nativeName: [
      "Cocos (Keeling) Islands",
      "Territory of the Cocos (Keeling) Islands"
    ]
  },
  {
    name: {
      common: "Gabon",
      official: "Gabonese Republic",
      nativeName: {
        fra: {
          official: "République gabonaise",
          common: "Gabon"
        }
      }
    },
    tld: [
      ".ga"
    ],
    cca2: "GA",
    ccn3: "266",
    cca3: "GAB",
    cioc: "GAB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Central African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "41"
      ]
    },
    capital: [
      "Libreville"
    ],
    altSpellings: [
      "GA",
      "Gabonese Republic",
      "République Gabonaise"
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      -1,
      11.75
    ],
    landlocked: "Yes",
    borders: [
      "CMR",
      "COG",
      "GNQ"
    ],
    area: 267668,
    demonyms: {
      eng: {
        f: "Gabonese",
        m: "Gabonese"
      },
      fra: {
        f: "Gabonaise",
        m: "Gabonais"
      }
    },
    flag: "🇬🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/vyRSkqw1H1fnq4ry6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192793"
    },
    population: 2225728,
    gini: {
      2017: 38
    },
    fifa: "GAB",
    car: {
      signs: [
        "G"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ga.png",
      svg: "https://flagcdn.com/ga.svg",
      alt: "The flag of Gabon is composed of three equal horizontal bands of green, yellow and blue."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ga.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ga.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        0.38,
        9.45
      ]
    },
    nativeName: [
      "Gabon",
      "Gabonese Republic",
      "République gabonaise"
    ]
  },
  {
    name: {
      common: "Dominica",
      official: "Commonwealth of Dominica",
      nativeName: {
        eng: {
          official: "Commonwealth of Dominica",
          common: "Dominica"
        }
      }
    },
    tld: [
      ".dm"
    ],
    cca2: "DM",
    ccn3: "212",
    cca3: "DMA",
    cioc: "DMA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Eastern Caribbean dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "767"
      ]
    },
    capital: [
      "Roseau"
    ],
    altSpellings: [
      "DM",
      "Dominique",
      "Wai‘tu kubuli",
      "Commonwealth of Dominica"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      15.41666666,
      -61.33333333
    ],
    landlocked: "Yes",
    area: 751,
    demonyms: {
      eng: {
        f: "Dominican",
        m: "Dominican"
      },
      fra: {
        f: "Dominiquaise",
        m: "Dominiquais"
      }
    },
    flag: "🇩🇲",
    maps: {
      googleMaps: "https://goo.gl/maps/HSKdHYpFC8oHHuyV7",
      openStreetMaps: "https://www.openstreetmap.org/relation/307823"
    },
    population: 71991,
    fifa: "DMA",
    car: {
      signs: [
        "WD"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/dm.png",
      svg: "https://flagcdn.com/dm.svg",
      alt: "The flag of Dominica has a green field with a large centered tricolor cross. The vertical and horizontal parts of the cross each comprise three bands of yellow, black and white. A red circle, bearing a hoist-side facing purple Sisserou parrot standing on a twig and encircled by ten five-pointed yellow-edged green stars, is superimposed at the center of the cross."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/dm.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/dm.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        15.3,
        -61.4
      ]
    },
    nativeName: [
      "Dominica",
      "Commonwealth of Dominica"
    ]
  },
  {
    name: {
      common: "Canada",
      official: "Canada",
      nativeName: {
        eng: {
          official: "Canada",
          common: "Canada"
        },
        fra: {
          official: "Canada",
          common: "Canada"
        }
      }
    },
    tld: [
      ".ca"
    ],
    cca2: "CA",
    ccn3: "124",
    cca3: "CAN",
    cioc: "CAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Canadian dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        ""
      ]
    },
    capital: [
      "Ottawa"
    ],
    altSpellings: [
      "CA"
    ],
    region: "Americas",
    subregion: "North America",
    languages: {
      eng: "English",
      fra: "French"
    },
    latlng: [
      60,
      -95
    ],
    landlocked: "Yes",
    borders: [
      "USA"
    ],
    area: 9984670,
    demonyms: {
      eng: {
        f: "Canadian",
        m: "Canadian"
      },
      fra: {
        f: "Canadienne",
        m: "Canadien"
      }
    },
    flag: "🇨🇦",
    maps: {
      googleMaps: "https://goo.gl/maps/jmEVLugreeqiZXxbA",
      openStreetMaps: "https://www.openstreetmap.org/relation/1428125"
    },
    population: 38005238,
    gini: {
      2017: 33.3
    },
    fifa: "CAN",
    car: {
      signs: [
        "CDN"
      ],
      side: "right"
    },
    timezones: [
      "UTC-08:00",
      "UTC-07:00",
      "UTC-06:00",
      "UTC-05:00",
      "UTC-04:00",
      "UTC-03:30"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ca.png",
      svg: "https://flagcdn.com/ca.svg",
      alt: "The flag of Canada is composed of a red vertical band on the hoist and fly sides and a central white square that is twice the width of the vertical bands. A large eleven-pointed red maple leaf is centered in the white square."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ca.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ca.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        45.42,
        -75.7
      ]
    },
    nativeName: [
      "Canada"
    ]
  },
  {
    name: {
      common: "Trinidad and Tobago",
      official: "Republic of Trinidad and Tobago",
      nativeName: {
        eng: {
          official: "Republic of Trinidad and Tobago",
          common: "Trinidad and Tobago"
        }
      }
    },
    tld: [
      ".tt"
    ],
    cca2: "TT",
    ccn3: "780",
    cca3: "TTO",
    cioc: "TTO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Trinidad and Tobago dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "868"
      ]
    },
    capital: [
      "Port of Spain"
    ],
    altSpellings: [
      "TT",
      "Republic of Trinidad and Tobago"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English"
    },
    latlng: [
      10.6918,
      -61.2225
    ],
    landlocked: "Yes",
    area: 5130,
    demonyms: {
      eng: {
        f: "Trinidadian",
        m: "Trinidadian"
      },
      fra: {
        f: "Trinidadienne",
        m: "Trinidadien"
      }
    },
    flag: "🇹🇹",
    maps: {
      googleMaps: "https://goo.gl/maps/NrRfDEWoG8FGZqWY7",
      openStreetMaps: "https://www.openstreetmap.org/relation/555717"
    },
    population: 1399491,
    gini: {
      1992: 40.3
    },
    fifa: "TRI",
    car: {
      signs: [
        "TT"
      ],
      side: "left"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tt.png",
      svg: "https://flagcdn.com/tt.svg",
      alt: "The flag of Trinidad and Tobago has a red field with a white-edged black diagonal band that extends from the upper hoist-side corner to the lower fly-side corner of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/tt.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/tt.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        10.65,
        -61.52
      ]
    },
    nativeName: [
      "Trinidad and Tobago",
      "Republic of Trinidad and Tobago"
    ]
  },
  {
    name: {
      common: "Puerto Rico",
      official: "Commonwealth of Puerto Rico",
      nativeName: {
        eng: {
          official: "Commonwealth of Puerto Rico",
          common: "Puerto Rico"
        },
        spa: {
          official: "Estado Libre Asociado de Puerto Rico",
          common: "Puerto Rico"
        }
      }
    },
    tld: [
      ".pr"
    ],
    cca2: "PR",
    ccn3: "630",
    cca3: "PRI",
    cioc: "PUR",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+1",
      suffixes: [
        "787",
        "939"
      ]
    },
    capital: [
      "San Juan"
    ],
    altSpellings: [
      "PR",
      "Commonwealth of Puerto Rico",
      "Estado Libre Asociado de Puerto Rico"
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: {
      eng: "English",
      spa: "Spanish"
    },
    latlng: [
      18.25,
      -66.5
    ],
    landlocked: "Yes",
    area: 8870,
    demonyms: {
      eng: {
        f: "Puerto Rican",
        m: "Puerto Rican"
      },
      fra: {
        f: "Portoricaine",
        m: "Portoricain"
      }
    },
    flag: "🇵🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/sygfDbtwn389wu8x5",
      openStreetMaps: "https://www.openstreetmap.org/relation/4422604"
    },
    population: 3194034,
    fifa: "PUR",
    car: {
      signs: [
        "USA"
      ],
      side: "right"
    },
    timezones: [
      "UTC-04:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/pr.png",
      svg: "https://flagcdn.com/pr.svg"
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        18.47,
        -66.12
      ]
    },
    nativeName: [
      "Puerto Rico",
      "Commonwealth of Puerto Rico",
      "Estado Libre Asociado de Puerto Rico"
    ]
  },
  {
    name: {
      common: "Singapore",
      official: "Republic of Singapore",
      nativeName: {
        eng: {
          official: "Republic of Singapore",
          common: "Singapore"
        },
        zho: {
          official: "新加坡共和国",
          common: "新加坡"
        },
        msa: {
          official: "Republik Singapura",
          common: "Singapura"
        },
        tam: {
          official: "சிங்கப்பூர் குடியரசு",
          common: "சிங்கப்பூர்"
        }
      }
    },
    tld: [
      ".sg",
      ".新加坡",
      ".சிங்கப்பூர்"
    ],
    cca2: "SG",
    ccn3: "702",
    cca3: "SGP",
    cioc: "SGP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Singapore dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "5"
      ]
    },
    capital: [
      "Singapore"
    ],
    altSpellings: [
      "SG",
      "Singapura",
      "Republik Singapura",
      "新加坡共和国"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      eng: "English",
      zho: "Chinese",
      msa: "Malay",
      tam: "Tamil"
    },
    latlng: [
      1.36666666,
      103.8
    ],
    landlocked: "Yes",
    area: 710,
    demonyms: {
      eng: {
        f: "Singaporean",
        m: "Singaporean"
      },
      fra: {
        f: "Singapourienne",
        m: "Singapourien"
      }
    },
    flag: "🇸🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/QbQt9Y9b5KFzsahV6",
      openStreetMaps: "https://www.openstreetmap.org/relation/536780"
    },
    population: 5685807,
    fifa: "SIN",
    car: {
      signs: [
        "SGP"
      ],
      side: "left"
    },
    timezones: [
      "UTC+08:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/sg.png",
      svg: "https://flagcdn.com/sg.svg",
      alt: "The flag of Singapore is composed of two equal horizontal bands of red and white. On the hoist side of the red band is a fly-side facing white crescent which partially encloses five small five-pointed white stars arranged in the shape of a pentagon."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/sg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/sg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        1.28,
        103.85
      ]
    },
    nativeName: [
      "Singapore",
      "Republic of Singapore",
      "新加坡共和国",
      "新加坡",
      "Republik Singapura",
      "Singapura",
      "சிங்கப்பூர் குடியரசு",
      "சிங்கப்பூர்"
    ]
  },
  {
    name: {
      common: "Hungary",
      official: "Hungary",
      nativeName: {
        hun: {
          official: "Magyarország",
          common: "Magyarország"
        }
      }
    },
    tld: [
      ".hu"
    ],
    cca2: "HU",
    ccn3: "348",
    cca3: "HUN",
    cioc: "HUN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Hungarian forint Ft"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "6"
      ]
    },
    capital: [
      "Budapest"
    ],
    altSpellings: [
      "HU"
    ],
    region: "Europe",
    subregion: "Central Europe",
    languages: {
      hun: "Hungarian"
    },
    latlng: [
      47,
      20
    ],
    landlocked: "Yes",
    borders: [
      "AUT",
      "HRV",
      "ROU",
      "SRB",
      "SVK",
      "SVN",
      "UKR"
    ],
    area: 93028,
    demonyms: {
      eng: {
        f: "Hungarian",
        m: "Hungarian"
      },
      fra: {
        f: "Hongroise",
        m: "Hongrois"
      }
    },
    flag: "🇭🇺",
    maps: {
      googleMaps: "https://goo.gl/maps/9gfPupm5bffixiFJ6",
      openStreetMaps: "https://www.openstreetmap.org/relation/21335"
    },
    population: 9749763,
    gini: {
      2018: 29.6
    },
    fifa: "HUN",
    car: {
      signs: [
        "H"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/hu.png",
      svg: "https://flagcdn.com/hu.svg",
      alt: "The flag of Hungary is composed of three equal horizontal bands of red, white and green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/hu.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/hu.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        47.5,
        19.08
      ]
    },
    nativeName: [
      "Hungary",
      "Magyarország"
    ]
  },
  {
    name: {
      common: "Costa Rica",
      official: "Republic of Costa Rica",
      nativeName: {
        spa: {
          official: "República de Costa Rica",
          common: "Costa Rica"
        }
      }
    },
    tld: [
      ".cr"
    ],
    cca2: "CR",
    ccn3: "188",
    cca3: "CRI",
    cioc: "CRC",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Costa Rican colón ₡"
    ],
    idd: {
      root: "+5",
      suffixes: [
        "06"
      ]
    },
    capital: [
      "San José"
    ],
    altSpellings: [
      "CR",
      "Republic of Costa Rica",
      "República de Costa Rica"
    ],
    region: "Americas",
    subregion: "Central America",
    languages: {
      spa: "Spanish"
    },
    latlng: [
      10,
      -84
    ],
    landlocked: "Yes",
    borders: [
      "NIC",
      "PAN"
    ],
    area: 51100,
    demonyms: {
      eng: {
        f: "Costa Rican",
        m: "Costa Rican"
      },
      fra: {
        f: "Costaricaine",
        m: "Costaricain"
      }
    },
    flag: "🇨🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/RFiwytjvNrpfKN7k6",
      openStreetMaps: "https://www.openstreetmap.org/relation/287667"
    },
    population: 5094114,
    gini: {
      2019: 48.2
    },
    fifa: "CRC",
    car: {
      signs: [
        "CR"
      ],
      side: "right"
    },
    timezones: [
      "UTC-06:00"
    ],
    continents: [
      "North America"
    ],
    flags: {
      png: "https://flagcdn.com/w320/cr.png",
      svg: "https://flagcdn.com/cr.svg",
      alt: "The flag of Costa Rica is composed of five horizontal bands of blue, white, red, white and blue. The central red band is twice the height of the other four bands."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/cr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/cr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        9.93,
        -84.09
      ]
    },
    nativeName: [
      "Costa Rica",
      "Republic of Costa Rica",
      "República de Costa Rica"
    ]
  },
  {
    name: {
      common: "Israel",
      official: "State of Israel",
      nativeName: {
        ara: {
          official: "دولة إسرائيل",
          common: "إسرائيل"
        },
        heb: {
          official: "מדינת ישראל",
          common: "ישראל"
        }
      }
    },
    tld: [
      ".il"
    ],
    cca2: "IL",
    ccn3: "376",
    cca3: "ISR",
    cioc: "ISR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Israeli new shekel ₪"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "72"
      ]
    },
    capital: [
      "Jerusalem"
    ],
    altSpellings: [
      "IL",
      "State of Israel",
      "Medīnat Yisrā'el"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      ara: "Arabic",
      heb: "Hebrew"
    },
    latlng: [
      31.47,
      35.13
    ],
    landlocked: "Yes",
    borders: [
      "EGY",
      "JOR",
      "LBN",
      "PSE",
      "SYR"
    ],
    area: 20770,
    demonyms: {
      eng: {
        f: "Israeli",
        m: "Israeli"
      },
      fra: {
        f: "Israélienne",
        m: "Israélien"
      }
    },
    flag: "🇮🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/6UY1AH8XeafVwdC97",
      openStreetMaps: "https://www.openstreetmap.org/relation/1473946"
    },
    population: 9216900,
    gini: {
      2016: 39
    },
    fifa: "ISR",
    car: {
      signs: [
        "IL"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/il.png",
      svg: "https://flagcdn.com/il.svg",
      alt: "The flag of Israel has a white field with a blue hexagram — the Magen David — centered between two equal horizontal blue bands situated near the top and bottom edges of the field."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/il.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/il.svg"
    },
    startOfWeek: "sunday",
    capitalInfo: {
      latlng: [
        31.77,
        35.23
      ]
    },
    nativeName: [
      "Israel",
      "State of Israel",
      "دولة إسرائيل",
      "إسرائيل",
      "מדינת ישראל",
      "ישראל"
    ]
  },
  {
    name: {
      common: "Bulgaria",
      official: "Republic of Bulgaria",
      nativeName: {
        bul: {
          official: "Република България",
          common: "България"
        }
      }
    },
    tld: [
      ".bg"
    ],
    cca2: "BG",
    ccn3: "100",
    cca3: "BGR",
    cioc: "BUL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Bulgarian lev лв"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "59"
      ]
    },
    capital: [
      "Sofia"
    ],
    altSpellings: [
      "BG",
      "Republic of Bulgaria",
      "Република България"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      bul: "Bulgarian"
    },
    latlng: [
      43,
      25
    ],
    landlocked: "Yes",
    borders: [
      "GRC",
      "MKD",
      "ROU",
      "SRB",
      "TUR"
    ],
    area: 110879,
    demonyms: {
      eng: {
        f: "Bulgarian",
        m: "Bulgarian"
      },
      fra: {
        f: "Bulgare",
        m: "Bulgare"
      }
    },
    flag: "🇧🇬",
    maps: {
      googleMaps: "https://goo.gl/maps/F5uAhDGWzc3BrHfm9",
      openStreetMaps: "https://www.openstreetmap.org/relation/186382"
    },
    population: 6927288,
    gini: {
      2018: 41.3
    },
    fifa: "BUL",
    car: {
      signs: [
        "BG"
      ],
      side: "right"
    },
    timezones: [
      "UTC+02:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/bg.png",
      svg: "https://flagcdn.com/bg.svg",
      alt: "The flag of Bulgaria is composed of three equal horizontal bands of white, green and red."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/bg.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/bg.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        42.68,
        23.32
      ]
    },
    nativeName: [
      "Bulgaria",
      "Republic of Bulgaria",
      "Република България",
      "България"
    ]
  },
  {
    name: {
      common: "Azerbaijan",
      official: "Republic of Azerbaijan",
      nativeName: {
        aze: {
          official: "Azərbaycan Respublikası",
          common: "Azərbaycan"
        }
      }
    },
    tld: [
      ".az"
    ],
    cca2: "AZ",
    ccn3: "031",
    cca3: "AZE",
    cioc: "AZE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Azerbaijani manat ₼"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "94"
      ]
    },
    capital: [
      "Baku"
    ],
    altSpellings: [
      "AZ",
      "Republic of Azerbaijan",
      "Azərbaycan Respublikası"
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: {
      aze: "Azerbaijani"
    },
    latlng: [
      40.5,
      47.5
    ],
    landlocked: "Yes",
    borders: [
      "ARM",
      "GEO",
      "IRN",
      "RUS",
      "TUR"
    ],
    area: 86600,
    demonyms: {
      eng: {
        f: "Azerbaijani",
        m: "Azerbaijani"
      },
      fra: {
        f: "Azerbaïdjanaise",
        m: "Azerbaïdjanais"
      }
    },
    flag: "🇦🇿",
    maps: {
      googleMaps: "https://goo.gl/maps/az3Zz7ar2aoB9AUc6",
      openStreetMaps: "https://www.openstreetmap.org/relation/364110"
    },
    population: 10110116,
    gini: {
      2005: 26.6
    },
    fifa: "AZE",
    car: {
      signs: [
        "AZ"
      ],
      side: "right"
    },
    timezones: [
      "UTC+04:00"
    ],
    continents: [
      "Europe",
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/az.png",
      svg: "https://flagcdn.com/az.svg",
      alt: "The flag of Azerbaijan features three equal horizontal bands of blue, red and green, with a white fly-side facing crescent and eight-pointed star centered in the red band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/az.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/az.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        40.38,
        49.87
      ]
    },
    nativeName: [
      "Azerbaijan",
      "Republic of Azerbaijan",
      "Azərbaycan Respublikası",
      "Azərbaycan"
    ]
  },
  {
    name: {
      common: "Timor-Leste",
      official: "Democratic Republic of Timor-Leste",
      nativeName: {
        por: {
          official: "República Democrática de Timor-Leste",
          common: "Timor-Leste"
        },
        tet: {
          official: "Repúblika Demokrátika Timór-Leste",
          common: "Timór-Leste"
        }
      }
    },
    tld: [
      ".tl"
    ],
    cca2: "TL",
    ccn3: "626",
    cca3: "TLS",
    cioc: "TLS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "United States dollar $"
    ],
    idd: {
      root: "+6",
      suffixes: [
        "70"
      ]
    },
    capital: [
      "Dili"
    ],
    altSpellings: [
      "TL",
      "East Timor",
      "Democratic Republic of Timor-Leste",
      "República Democrática de Timor-Leste",
      "Repúblika Demokrátika Timór-Leste",
      "Timór Lorosa'e",
      "Timor Lorosae"
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: {
      por: "Portuguese",
      tet: "Tetum"
    },
    latlng: [
      -8.83333333,
      125.91666666
    ],
    landlocked: "Yes",
    borders: [
      "IDN"
    ],
    area: 14874,
    demonyms: {
      eng: {
        f: "East Timorese",
        m: "East Timorese"
      },
      fra: {
        f: "Est-timoraise",
        m: "Est-timorais"
      }
    },
    flag: "🇹🇱",
    maps: {
      googleMaps: "https://goo.gl/maps/sFqBC9zjgUXPR1iTA",
      openStreetMaps: "https://www.openstreetmap.org/relation/305142"
    },
    population: 1318442,
    gini: {
      2014: 28.7
    },
    fifa: "TLS",
    car: {
      signs: [
        "TL"
      ],
      side: "left"
    },
    timezones: [
      "UTC+09:00"
    ],
    continents: [
      "Oceania"
    ],
    flags: {
      png: "https://flagcdn.com/w320/tl.png",
      svg: "https://flagcdn.com/tl.svg",
      alt: "The flag of Timor-Leste has a red field with two isosceles triangles which share a common base on the hoist end. The smaller black triangle, which bears a five-pointed white star at its center and spans one-third the width of the field, is superimposed on the larger yellow triangle that extends to the center of the field."
    },
    coatOfArms: {},
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        -8.58,
        125.6
      ]
    },
    nativeName: [
      "Timor-Leste",
      "Democratic Republic of Timor-Leste",
      "República Democrática de Timor-Leste",
      "Repúblika Demokrátika Timór-Leste",
      "Timór-Leste"
    ]
  },
  {
    name: {
      common: "Iran",
      official: "Islamic Republic of Iran",
      nativeName: {
        fas: {
          official: "جمهوری اسلامی ایران",
          common: "ایران"
        }
      }
    },
    tld: [
      ".ir",
      "ایران."
    ],
    cca2: "IR",
    ccn3: "364",
    cca3: "IRN",
    cioc: "IRI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Iranian rial ﷼"
    ],
    idd: {
      root: "+9",
      suffixes: [
        "8"
      ]
    },
    capital: [
      "Tehran"
    ],
    altSpellings: [
      "IR",
      "Islamic Republic of Iran",
      "Iran, Islamic Republic of",
      "Jomhuri-ye Eslāmi-ye Irān"
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: {
      fas: "Persian (Farsi)"
    },
    latlng: [
      32,
      53
    ],
    landlocked: "Yes",
    borders: [
      "AFG",
      "ARM",
      "AZE",
      "IRQ",
      "PAK",
      "TUR",
      "TKM"
    ],
    area: 1648195,
    demonyms: {
      eng: {
        f: "Iranian",
        m: "Iranian"
      },
      fra: {
        f: "Iranienne",
        m: "Iranien"
      }
    },
    flag: "🇮🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/dMgEGuacBPGYQnjY7",
      openStreetMaps: "https://www.openstreetmap.org/relation/304938"
    },
    population: 83992953,
    gini: {
      2018: 42
    },
    fifa: "IRN",
    car: {
      signs: [
        "IR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+03:30"
    ],
    continents: [
      "Asia"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ir.png",
      svg: "https://flagcdn.com/ir.svg",
      alt: "The flag of Iran is composed of three equal horizontal bands of green, white and red. A red emblem of Iran is centered in the white band and Arabic inscriptions in white span the bottom edge of the green band and the top edge of the red band."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ir.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ir.svg"
    },
    startOfWeek: "saturday",
    capitalInfo: {
      latlng: [
        35.7,
        51.42
      ]
    },
    nativeName: [
      "Iran",
      "Islamic Republic of Iran",
      "جمهوری اسلامی ایران",
      "ایران"
    ]
  },
  {
    name: {
      common: "Croatia",
      official: "Republic of Croatia",
      nativeName: {
        hrv: {
          official: "Republika Hrvatska",
          common: "Hrvatska"
        }
      }
    },
    tld: [
      ".hr"
    ],
    cca2: "HR",
    ccn3: "191",
    cca3: "HRV",
    cioc: "CRO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "Euro €"
    ],
    idd: {
      root: "+3",
      suffixes: [
        "85"
      ]
    },
    capital: [
      "Zagreb"
    ],
    altSpellings: [
      "HR",
      "Hrvatska",
      "Republic of Croatia",
      "Republika Hrvatska"
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: {
      hrv: "Croatian"
    },
    latlng: [
      45.16666666,
      15.5
    ],
    landlocked: "Yes",
    borders: [
      "BIH",
      "HUN",
      "MNE",
      "SRB",
      "SVN"
    ],
    area: 56594,
    demonyms: {
      eng: {
        f: "Croatian",
        m: "Croatian"
      },
      fra: {
        f: "Croate",
        m: "Croate"
      }
    },
    flag: "🇭🇷",
    maps: {
      googleMaps: "https://goo.gl/maps/qSG6xTKUmrYpwmGQ6",
      openStreetMaps: "https://www.openstreetmap.org/relation/214885"
    },
    population: 4047200,
    gini: {
      2018: 29.7
    },
    fifa: "CRO",
    car: {
      signs: [
        "HR"
      ],
      side: "right"
    },
    timezones: [
      "UTC+01:00"
    ],
    continents: [
      "Europe"
    ],
    flags: {
      png: "https://flagcdn.com/w320/hr.png",
      svg: "https://flagcdn.com/hr.svg",
      alt: "The flag of Croatia is composed of three equal horizontal bands of red, white and blue, with coat of arms of Croatia superimposed in the center."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/hr.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/hr.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        45.8,
        16
      ]
    },
    nativeName: [
      "Croatia",
      "Republic of Croatia",
      "Republika Hrvatska",
      "Hrvatska"
    ]
  },
  {
    name: {
      common: "Ivory Coast",
      official: "Republic of Côte d'Ivoire",
      nativeName: {
        fra: {
          official: "République de Côte d'Ivoire",
          common: "Côte d'Ivoire"
        }
      }
    },
    tld: [
      ".ci"
    ],
    cca2: "CI",
    ccn3: "384",
    cca3: "CIV",
    cioc: "CIV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [
      "West African CFA franc Fr"
    ],
    idd: {
      root: "+2",
      suffixes: [
        "25"
      ]
    },
    capital: [
      "Yamoussoukro"
    ],
    altSpellings: [
      "CI",
      "Côte d'Ivoire",
      "Ivory Coast",
      "Republic of Côte d'Ivoire",
      "République de Côte d'Ivoire"
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: {
      fra: "French"
    },
    latlng: [
      8,
      -5
    ],
    landlocked: "Yes",
    borders: [
      "BFA",
      "GHA",
      "GIN",
      "LBR",
      "MLI"
    ],
    area: 322463,
    demonyms: {
      eng: {
        f: "Ivorian",
        m: "Ivorian"
      },
      fra: {
        f: "Ivoirienne",
        m: "Ivoirien"
      }
    },
    flag: "🇨🇮",
    maps: {
      googleMaps: "https://goo.gl/maps/wKsmN7f5qAeNtGjP6",
      openStreetMaps: "https://www.openstreetmap.org/relation/192779"
    },
    population: 26378275,
    gini: {
      2015: 41.5
    },
    fifa: "CIV",
    car: {
      signs: [
        "CI"
      ],
      side: "right"
    },
    timezones: [
      "UTC"
    ],
    continents: [
      "Africa"
    ],
    flags: {
      png: "https://flagcdn.com/w320/ci.png",
      svg: "https://flagcdn.com/ci.svg",
      alt: "The flag of Ivory Coast is composed of three equal vertical bands of orange, white and green."
    },
    coatOfArms: {
      png: "https://mainfacts.com/media/images/coats_of_arms/ci.png",
      svg: "https://mainfacts.com/media/images/coats_of_arms/ci.svg"
    },
    startOfWeek: "monday",
    capitalInfo: {
      latlng: [
        6.82,
        -5.27
      ]
    },
    nativeName: [
      "Ivory Coast",
      "Republic of Côte d'Ivoire",
      "République de Côte d'Ivoire",
      "Côte d'Ivoire"
    ]
  }
]

export default countries;
