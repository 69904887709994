import { useRef } from 'react'
import Score from '../shared-components/Score'
import Country from './Country'
import Nav from '../shared-components/Nav'
import Confetti from 'react-confetti'
import SocialMediaButtons from '../shared-components/SocialMediaButtons'
import { countrySocialMediaData } from '../shared-components/social-media-data'

function Game(props) {
  const {
    borderCSS,
    countryData,
    currentQuestion,
    setCurrentQuestion,
    score,
    setScore,
    countriesGuessed,
    setCountriesGuessed,
    level,
    showConfetti
  } = props
  const windowWidth = useRef(window.innerWidth)
  const windowHeight = useRef(window.innerHeight)
  const blinkingStars = <span className="Blink-me">⭐🌟⭐</span>
  const TOTAL_COUNTRIES = 194

  return (
    <>
      <Nav name="Guess the Country" />

      <Score
        level={level}
        score={score}
        amountGuessed={countriesGuessed}
        outline={borderCSS['border']?.split(' ')[0] || null}
      />

      {showConfetti &&
        <>
          <Confetti
            width={windowWidth.current}
            height={windowHeight.current}
          />
          {countriesGuessed === TOTAL_COUNTRIES ?
            <div className="Congrats-container">
              <p className="Congrats-message">{blinkingStars} Congratulations! Great job! {blinkingStars}</p>
              <p className="Congrats-message">You attempted to guess all {TOTAL_COUNTRIES} countries.</p>
              <p className="Congrats-message">Play again to get the countries in a new and different order.</p>
              <div>
                <iframe title="Celebrating chickens" src="https://giphy.com/embed/llUeFDNRaLWvokhbat" width="320" height="270" ></iframe>
              </div>
            </div>
            : <p className="Blink-me">Next level reached! Starting level {level}.</p>
          }
        </>
      }

      {countriesGuessed < TOTAL_COUNTRIES ?
        <Country
          borderCSS={borderCSS}
          countryData={countryData}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          score={score}
          setScore={setScore}
          countriesGuessed={countriesGuessed}
          setCountriesGuessed={setCountriesGuessed}
        />
        : null}

      <div className="Share-score-container">
        <div style={{ marginTop: '30px' }}>Share your score: </div>
        <SocialMediaButtons socialMediaData={countrySocialMediaData(score, countriesGuessed)} />
      </div>
    </>
  )
}

export default Game
