export const countrySocialMediaData = (score, amountGuessed) => {
  return {
    URL: 'https://fungamesforlearning.com/',
    scoreMessage: `Play Guess the Country! I got ${score} countries right out of ${amountGuessed} attempted. Try to beat me!`,
    homeMessage: "Play Guess the Country! It's fun and I bet I can beat you."
  }
}

export const stateSocialMediaData = (score, amountGuessed) => {
  return {
    URL: 'https://fungamesforlearning.com/',
    scoreMessage: `Play Guess the US State! I got ${score} states right out of ${amountGuessed} attempted. Try to beat me!`,
    homeMessage: "Play Guess the US State! It's fun and I bet I can beat you."
  }
}
