const dinosaurs = [
  'https://media1.tenor.com/m/k5eDtPlWickAAAAC/t-rex-front-flip.gif',
  'https://media1.tenor.com/m/rTxO8txn5ZQAAAAC/dinosaur-jumping.gif',
  'https://media1.tenor.com/m/cm2OEA1FOPkAAAAC/dinosaur-dancing.gif',
  'https://media1.tenor.com/m/duFPToiLh10AAAAC/sml-t-rex.gif',
  'https://media1.tenor.com/m/fLaNAcnREj8AAAAC/blue-velociraptor.gif',
  'https://media1.tenor.com/m/tZ6PDWjVEPAAAAAd/dinosaur-dance.gif',
  'https://media1.tenor.com/m/3-ZVIZlM-oIAAAAd/trex-broadway.gif',
  'https://media1.tenor.com/m/ZrYBgbSK2w4AAAAd/lucylovescats-dance.gif',
  'https://media1.tenor.com/m/9AoNFGrsG_YAAAAC/dinosaur-dinozor.gif',
  'https://media1.tenor.com/m/nvzO8MjC2P0AAAAd/smexy-dino.gif',
]

export default dinosaurs
