import Game from './Game'
import { randomizeCountries } from '../../utils/sortingMethods'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import getBorderCSS from '../shared-components/clueBorders'

function CountryMain() {
  const [score, setScore] = useState(0)
  const [countriesGuessed, setCountriesGuessed] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [countries, setCountries] = useState([])
  const [level, setLevel] = useState(1)
  const [showConfetti, setShowConfetti] = useState(true)
  const [borderCSS, setBorderCSS] = useState({ border: 'white solid 2px' })
  const COUNTRIES_PER_LEVEL = 20

  const shuffleCountries = () => {
    const shuffledCountries = randomizeCountries()
    setCountries(shuffledCountries)
  }

  useEffect(() => {
    shuffleCountries()
  }, [])

  useEffect(() => {
    if (countriesGuessed > 0 &&
      countriesGuessed % COUNTRIES_PER_LEVEL === 0) {
      setShowConfetti(true)
      const newLevel = countriesGuessed / COUNTRIES_PER_LEVEL
      setLevel(newLevel + 1)
    } else {
      setShowConfetti(false)
    }
  }, [countriesGuessed])

  useEffect(() => {
    if (currentQuestion > 1) {
      setShowConfetti(false)
    }
  }, [currentQuestion])

  useEffect(() => {
    if (level > 1) {
      const styling = getBorderCSS(level)
      setBorderCSS(styling)
    }
  }, [level])

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Guess the Country Game</title>
        <meta name="description" content="Quiz style game to test your knowledge of the 250 countries of the world." />
      </Helmet>

      {countries.length ?
        <Game
          borderCSS={borderCSS}
          countryData={countries[currentQuestion - 1]}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          score={score}
          setScore={setScore}
          countriesGuessed={countriesGuessed}
          setCountriesGuessed={setCountriesGuessed}
          level={level}
          showConfetti={showConfetti}
        />
        : null}

      <footer>
        <div>Thank you to <a className="App-link" href="https://restcountries.com/#rest-countries" target="blank">Rest Countries</a> for the data.</div>
        <div style={{ marginTop: '5px' }}>And thanks to <a className="App-link" href="https://tenor.com/view/dinosaur-jumping-gif-7495888" target="blank">Tenor</a> for the dinosaur GIFs.</div>
      </footer>
    </div>
  );
}

export default CountryMain;
